import {useEffect, useState} from "react";

import ArrowLeftIcon from 'core/assets/images/header/icon_arrow_left.svg';
import HomeIcon from 'core/assets/images/header/icon_home.svg';
import PhoneIcon from 'core/assets/images/header/icon_phone.svg';
import KakaoIcon from 'core/assets/images/header/icon_kakao.svg';
import MarkerIcon from 'core/assets/images/header/icon_marker.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { RouterName } from "core/router";
import { useAppSetting } from "modules/AppSettings/hooks";
const MainHeader = (props: any) => {
    const [_props, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        iconLeft: props.iconLeft || true,
        title: props.title || '',
        handleLeftClick: props.handleLeftClick || {},
        iconRight: props.iconRight || true,
        handleCallPhoneClick: props.handleCallPhoneClick || {},
        handleOpenKakaoClick: props.handleOpenKakaoClick || {},
        handleOpenMarkerClick: props.handleOpenMarkerClick || {},
    })
    const navigate = useNavigate();
    const appSettings = useAppSetting({});
    const location = useLocation();
    

    const handleLeftClick = (e: any) => {
        if (typeof props.handleLeftClick == 'function') {
            props.handleLeftClick(e)
        }
    }
    const handleCallPhoneClick = (e: any) => {
        if (typeof props.handleCallPhoneClick == 'function') {
            props.handleCallPhoneClick(e)
        }else {
            (window as any).webViewRequestPhoneDial(appSettings?.consult_kakao_phone || '')
        }
    }
    const handleOpenKakaoClick = (e: any) => {
        if (typeof props.handleOpenKakaoClick == 'function') {
            props.handleOpenKakaoClick(e)
        }else {
            if(appSettings?.chanelId) {
                (window as any).webViewRequestKakaoChannel(appSettings?.chanelId || '')
            }
        }
    }
    const handleOpenMarkerClick = (e: any) => {
        if (typeof props.handleOpenMarkerClick == 'function') {
            props.handleOpenMarkerClick(e)
        }else {
            console.log('location', location);
            
            navigate(RouterName.ADDRESS_BOOK, {
                state: {
                    back_type: location.pathname, 
                    back_url: location.pathname,
                }
            })
        }
    }

    useEffect(()=>{
        setProps(props)
    }, [props])

    return <>
        <header className={`${_props.className} main-header`} id={_props.id}>
            <div className="header-wrapper">
                <div className="header-content">
                    <div className="left">
                        {_props.iconLeft ? <a title="" href={void(0)}
                                              onClick={(e) => handleLeftClick(e)}>
                            <img src={ArrowLeftIcon} alt={''}/>
                        </a> : ''}
                        {_props.title ? <h2>{_props.title}</h2> : ''}
                    </div>
                    {
                        props.iconRight ?
                            (<div className="right">
                                    <div className={'btn-action-right'}>
                                        <a title="" href={void(0)}
                                           onClick={(e) => handleCallPhoneClick(e)}>
                                            <img src={PhoneIcon} alt={''}/>
                                        </a>
                                        <a title="" href={void(0)}
                                           onClick={(e) => handleOpenKakaoClick(e)}>
                                            <img src={KakaoIcon} alt={''}/>
                                        </a>
                                        <a title="" href={void(0)}
                                           onClick={(e) => handleOpenMarkerClick(e)}>
                                            <img src={MarkerIcon} alt={''}/>
                                        </a>
                                    </div>
                                </div>
                            )
                            : ''
                    }
                </div>
            </div>
        </header>
    </>
}

export default MainHeader