import { useDispatch, useSelector } from "react-redux";
import { resetDataPayment, updateDataPayment } from "../store/actions";
import API from "core/networks/API";
import { API_PAYMENT } from "../api";
import { UpdateStatusPayment } from "../interfaces/Types";
import { useState } from "react";

export const useDataPayment = (params: any) => {
  const dataPayment = useSelector((state: any) => state.Orders.paymentReducer || []);
  
  return [dataPayment.loadingStores, dataPayment?.data];
};

export const useUpdateDataPayment = (form: any) => {
  const dispatch = useDispatch();

  const handleUpdate = (values: any) => {
    dispatch(updateDataPayment(values));
  }

  const handleReset = () => {
    dispatch(resetDataPayment({}));
  }

  return [handleUpdate, handleReset]
}

export const useUpdatePaymentStatus = () => {
  const [loading, setLoading] = useState<any>(false);

  const handleUpdateStatus = async (values: UpdateStatusPayment) => {
    setLoading(true);
    try {
      const res = await API.post(API_PAYMENT.UPDATE_PAYMENT_STATUS, values);
      console.log('res', res);
      setLoading(false);
    }catch (error: any) {
      setLoading(false);
    }
  }

  return [loading, handleUpdateStatus];
}