import "modules/Policy/assets/scss/policy.scss";
import AppLayout from "core/layouts/AppLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IconHeader from "core/components/header/IconHeader";
import { RouterName } from "core/router";

const PrivacyPolicy = (props: any) => {

  const navigate = useNavigate();
  const params = window.location.search;
  const [stateHeaderRight, setStateHeaderRight] = useState(false)

  useEffect(() => {
    if (params && params.includes('sign-up=true')) {
    } else {
      setStateHeaderRight(true)
    }
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (e: any) => {
    navigate(-1)
    if (typeof props?.handleClick == 'function') {
      props?.handleClick(e)
    }
  }


  return (
    <AppLayout className={'policy-use'}>
      <IconHeader
        title={'개인정보 처리방침'}
        iconLeft={true}
        handleLeftClick={handleClick}
        handleRightClick={() => {
          navigate(RouterName.MAIN)
        }}
        iconRight={stateHeaderRight}
      />
      <div className={'policy-use__main'}>

        <div>
          <p><strong>개인정보 처리 방침</strong></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>'(주)살구나무'는(이하 '회사'는) 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 본 방침은 : 2023 년 8 월 23 일 부터 시행됩니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 수집하는 개인정보 항목</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 고객님의 적립금의 적립 및 출금 등 서비스 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 수집항목 : 이름, 휴대전화번호, 추가 연락처명, 추가 연락처 전화번호, 서비스 이용기록 , 접속 로그 , 쿠키</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 개인정보 수집방법 : 살구나무집 도시락 어플의 추천인 등록 또는 개인정보수정정보를 통한 수집</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보의 수집 및 이용목적</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 회원 관리</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 고지사항 전달</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 마케팅 및 광고에 활용</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>이벤트 등 광고성 정보 전달</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보의 보유 및 이용기간</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 개인정보는 수집.이용에 관한 동의일부터 이용 종료일로부터의 5년까지 위 이용목적을 위하여 보유.이용됩니다. 단, 이용 종료일 이후에는 분쟁 해결, 민원처리, 법령상 의무이행 및 회사의 리스크 관리업무만을 위하여 보유.이용 됩니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 회원 가입 및 관리 목적으로 수집된 개인 정보는 고객의 회원 가입일로부터 회원 탈퇴일까지 보유.이용됩니다. 단, 회원 탈퇴일 후에는 제1조의 목적과 관련된 사고 조사, 분쟁 해결, 민원처리, 법령상 의무이행 만을 위하여 보유.이용됩니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보의 파기절차 및 방법</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 파기절차</span></p>
          <p><span style={{ fontWeight: 400 }}>회원님이 추천인등록 또는 개인정보수정 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>ο 파기방법</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보 제공</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 이용자들이 사전에 동의한 경우</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 수집한 개인정보의 위탁</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보 관리책임자</strong></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 상호 : 살구나무집</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 전화번호 : 042-528-0306</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 이메일주소 : sgnm@gmail.com</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 이용자 및 법정대리인의 권리와 그 행사방법</strong></p>
          <p><span style={{ fontWeight: 400 }}>이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 회사의 어플을 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>▶ 쿠키 등 사용 목적</span></p>
          <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공</span></p>
          <p><span style={{ fontWeight: 400 }}>귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>▶ 쿠키 설정 거부 방법</span></p>
          <p><span style={{ fontWeight: 400 }}>예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>설정방법 예(인터넷 익스플로어의 경우)</span></p>
          <p><span style={{ fontWeight: 400 }}>: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보</span></p>
          <p><span style={{ fontWeight: 400 }}>단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><strong>■ 개인정보에 관한 민원서비스</strong></p>
          <p><span style={{ fontWeight: 400 }}>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다..</span></p>
          <p><span style={{ fontWeight: 400 }}>고객서비스담당&nbsp; : 살구나무집</span></p>
          <p><span style={{ fontWeight: 400 }}>이메일주소 : sgnm@gmail.com</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>귀하께서는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>1.개인정보침해신고센터 (www.1336.or.kr/국번없이 118)</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>3.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><span style={{ fontWeight: 400 }}>4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</span></p>
          <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
          <p><br /><br /></p>
        </div>

      </div>
    </AppLayout>
  )

};

export default PrivacyPolicy;