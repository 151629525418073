import CompanyIntroductionPage from "./pages/CompanyIntroductionPage";
import MemberRankingPage from "./pages/MemberRankingPage";
import PromotionPage from "./pages/PromotionPage";

const router =  [
    {
        name: "COMPANY_INTRODUCTION",
        auth: false,
        path: "/company-introduction",
        element: <CompanyIntroductionPage/>
    },
    {
        name: "MEMBER_RANKING",
        auth: false,
        path: "/member-ranking",
        element: <MemberRankingPage/>
    },
    {
        name: "PROMOTION",
        auth: false,
        path: "/promotion",
        element: <PromotionPage/>
    },
]
export default router
