
import 'modules/StaticPage/assets/scss/static-page.scss'
import AppLayout from "core/layouts/AppLayout";

import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
const MemberRankingPage = (props: any) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }


    // const timeLineData = [
    //     {time: "2013년", content: <div>한정식 도시락의 <span>품격에 맞는 용기를 <br/>최초 개발</span>하였습니다.</div>},
    //     {time: "2015년", content: <div>살구나무집 대전 유성점을 오픈하여,<br/> <span>자체개발한 도시락 용기를 </span> <br/>(제30-08249847) 하였습니다.</div>},
    //     {time: "2017년", content: <div>주식회사 살구나무집 법인을 설립하여 <br/><span>청년벤처 기업에 선정</span>되었습니다.</div>}
    // ]
    // // const renderTimeLine = () => {
    // //     return timeLineData.map((item: any, k:number)=>{
    // //         return <li key={k}>
    // //             <div className="timeline-item">
    // //                 <div className="left"><div className="time">{item.time}</div></div>
    // //                 <div className="right">{item.content}</div>
    // //             </div>
    // //         </li>
    // //     })
    // // }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(()=>{
        countSize()
    }, [])

    return (
        <AppLayout className={"static-page member-ranking-page"}>
            <IconHeader
                title={'혜택'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <main id={"list-panel"}>
                <h1>적립금 혜택 정보</h1>
                <h3>적립 시스템</h3>
                <dl>
                    <dt>본인 적립</dt>
                    <dd>- 배달 서비스 이용 시 이용 금액의 <strong>5%</strong> 지급</dd>
                    <dt>추천인 적립</dt>
                    <dd>- 신규 회원이 회원 가입 시 추천인으로 등록하면 <strong>2,000원</strong> 지급</dd>
                    <dd>- 추천인이 배달 서비스 이용 시 이용 금액의 <strong>3%</strong> 지급</dd>
                </dl>
                <h3>적립금 출금 요청</h3>
                <dl>
                    <dt>이용하신 서비스로 쌓여진 적립금은 <strong>1일1회</strong> 기준 <strong>1만원, 2만원, 3만원</strong> 중 선택 출금요청이 가능합니다.</dt>
                    <dt>요청된 출금 요청 금액은 평일 기준 신청일 익일에 회원님이 요청하신 계좌로 입금됩니다.</dt>
                    <dt>1일 3만원을 초과하여 출금 요청을 원하시는 경우, 카톡
                        문의로 개별 요청하시면 지급 금액의 <strong>3.3%</strong> 공제 후 지급
                        되며, 입금 받으시는 개인 정보 명의로 국세청에 개인
                        사업소득으로 신고됩니다.</dt>
                </dl>
                <h3>부업 희망 문의</h3>
                <dl><dt>본 서비스를 통한 부업에 관심 있으신 분은 <strong>내 정보 </strong>메뉴의
                    <strong>불편문의로 </strong>문의해주세요.</dt></dl>
            </main>
        </AppLayout>
    )
}


export default MemberRankingPage
