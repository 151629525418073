
import { config } from "core/config/app";
import { memo } from "react";
const nameKsPay = config.KSPAYForm;

interface Props {
  formRef: any;
  dataOrders: any;
}

const KsPayForm = ({ formRef, dataOrders }: Props) => {
  
  const renderInputForm:any = () => {
    let inputHtml: any = ``;
     for (const [key, value] of Object.entries(nameKsPay)) {
      inputHtml += `<input type="hidden" name="${key}" value="${value}" />`
    }
    
    return inputHtml
  }

  return (
    <>
      {dataOrders?.paymentMethod?.value === "CARD" && (
        <form
          action={config.apiKsPay}
          id="form-kspay"
          method="POST"
          ref={formRef}
        >
          <div dangerouslySetInnerHTML={{ __html: renderInputForm() }} />
        </form>
      )}
    </>
  );
}

export default memo(KsPayForm);