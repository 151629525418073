import { call, put, takeLatest } from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import API from "core/networks/API";
import { GET_APP_SETTING, GET_APP_SETTING_FAILED, GET_APP_SETTING_SUCCESS } from './types';
import { API_SYSTEM_SETTING } from '../api';

function* getAppSetting(_action: any): any {
  try {
    const response = yield call(() => {
      let url = API_SYSTEM_SETTING;
      return API.get(url, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put({
        type: GET_APP_SETTING_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: GET_APP_SETTING_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: GET_APP_SETTING_FAILED,
      payload: error || {},
    });
  }
}

function* AppSettingSaga() {
  yield takeLatest(GET_APP_SETTING, getAppSetting);
 
}

export default AppSettingSaga;
