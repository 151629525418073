import {SOCKET_EMIT_PRIVATE, SOCKET_EMIT_PUBLIC} from './types';

export interface DataState {
    data: any,
    type: string,
    uuid: string
}

const initialState: DataState = {
    data: [],
    type: "",
    uuid: ""
};

const Reducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    switch (action.type) {
        case SOCKET_EMIT_PUBLIC:
        case SOCKET_EMIT_PRIVATE:
            return { ...state, type: action.type, data: [...state.data, action.payload], uuid: action.uuid};
        default:
            return state;
    }
};

export default Reducer
