import {memo, useEffect, useRef, useState} from "react";

import IconArrow from 'core/assets/images/icon_arrow_down.svg'
const CDropdown = (props: any) => {
    const [_props, setProps] = useState({
        name: props.name,
        value: props.value || '',
        className: props.className || '',
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        handleChange: props.handleChange || '',
        placeholder: props.placeholder || '',
        dataSet: props.dataSet || 0,
        selected: props.selected || 0,
        iconArrow : props.iconArrow || IconArrow
    })
    const inputRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const [dropdownPos, setDropdownPos] = useState<any>({});
    const [documentHeight, setDocumentHeight] = useState(0)
    const [selected, setSelected] = useState<any>(_props.selected)


    
    const handleChange = (e: any) => {
        setProps({..._props, value: e.target.value})
        if(_props.handleChange)
            _props.handleChange(e)
    }

    const renderList = () => {
        if(_props.dataSet?.length > 0){
            return _props.dataSet.map((item: any, k: number) => {
                return <li key={k} onClick={(e) => handleClick(e, item)} className={`${item?.disable ? 'disabled' : ''}`}>{item.label}</li>
            })
        }else{
            return <></>
        }
    }

    const calculatePositions = () => {
        let top = 0, bottom = 0, menuHeight= 0, inputWidth=0, inputHeight = 0, vheight = 0

        if (inputRef.current) {
            const inputRect = inputRef.current.getBoundingClientRect();
            top = inputRect.top + window.scrollY;
            bottom = inputRect.bottom + window.scrollY;
            inputHeight = inputRect.height
            inputWidth = inputRect.width
        }
        if (menuRef.current) {
            const elementRect = menuRef.current.getBoundingClientRect();
            menuHeight = elementRect.height;
        }
        // vheight = window.innerHeight;
        // if(bottom + menuHeight + 50 > documentHeight) {
        //     return {width: inputWidth, top: bottom-menuHeight-inputHeight, icon: "up"}
        // }else{
        //     return {width: inputWidth, top: bottom, icon: "down"}
        // }
        return {width: inputWidth, top: bottom, icon: "down"}
    };

    const handleFocus = () => {
        if (props.disabled) return;
        const pos = calculatePositions();
        setDropdownPos({...pos, visibility: 'visible'})
    }

    const handleBlur = (e: any) => {
        if (menuRef.current && !menuRef.current.contains(e.target) && inputRef.current && !inputRef.current.contains(e.target)) {
            const pos = calculatePositions()
            setDropdownPos({...pos, visibility: 'hidden'})
        }
    }

    const handleClick = (e:any, item:any) => {
        if (item?.disable) return;
        const pos = calculatePositions()
        setSelected(item)
        console.log(selected);
        
        setDropdownPos({...pos, visibility: 'hidden'})
        if(_props.handleChange)
        _props.handleChange(item)
    }

    useEffect(() => {
        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        setDocumentHeight(documentHeight)
        document.addEventListener('click', handleBlur);

        return () => {
            document.removeEventListener('click', handleBlur);
        };
    }, []);

    const getSelectedItem = (id: string) => {
        if(_props.selected) {
            for (const item of _props.dataSet) {
                if (item.id === _props.selected) return item
            }
        }
        return null;
    }

    useEffect(() => {
        let selectedItem = _props.selected
        if(typeof _props.selected === 'string'){
            selectedItem = getSelectedItem(_props.selected)
        }
        setSelected(selectedItem)
    }, [_props.selected])

    useEffect(()=>{
        setProps(props)
        // if(props.dataSet?.length > 0){
        //     let _selected = {}
        //     props.dataSet.forEach((item: any) =>{
        //         if(props.selected == item.id) _selected = item
        //     })
        //     setSelected(_selected)
        // }
    }, [props])
    
    return <>
        <div className="c-dropdown-wrapper">
            <div ref={inputRef} className="c-dropdown-input" onFocus={handleFocus}>
                <input readOnly={true} disabled={props.disabled} type="text" placeholder={_props.placeholder} value={selected?.id ? selected.label : ""}/>
                <div className={`icon ${dropdownPos.icon}`} onClick={handleFocus}><img src={_props.iconArrow || IconArrow} alt=""/></div>
            </div>
            <div className="c-dropdown-menu" ref={menuRef} style={dropdownPos}>
                <div className="c-dropdown-menu__list">
                    <ul>
                        {renderList()}
                    </ul>
                </div>
            </div>
        </div>

    </>
}

export default memo(CDropdown);