import { useFetch } from "core/hooks/useFetch";
import AppLayout from "core/layouts/AppLayout";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_ORDER_HISTORY } from "../api";
import IconWonCancel from "../assets/icon/iconWonCancel.svg";
import IconNote from "../assets/icon/icon_note.svg";
import "../assets/scss/orderCancelDetail.scss";
import { numberFormat } from "core/utils/helper";
import ShowMoreLessComponent from "../components/ShowMoreLessComponent";
import { RouterName } from "core/router";
const OrderCancelDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [paramsDetail, setParamsDetail] = useState<any>({
    id: param.id || null,
    "relation[]": ["creator", "items", "shippingApp", "cancellation.bank"],
  });

  const {
    data: orderDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({ url: API_ORDER_HISTORY.GET_DETAIL, options: paramsDetail });

  const goBack = () => {
    if (location?.state?.back_type) {
      navigate(location?.state?.back_url, {
        state: {
          back_type: location?.pathname,
          back_url: RouterName.MY_INFO
        }
      })
    }else{
      navigate(-1)
    }
  }

  (window as any).backFromDeviceAndroid = function() {
    goBack()
  }

  return (
    <AppLayout className={"order-cancel-detail-page"}>
      <header className="icon-header " id="main-header">
        <div className="header-wrapper">
          <div className="header-content">
            <h2>취소 상세</h2>
          </div>
        </div>
      </header>
      <main>
        <div className="content">
          <div className="date-cancel">
            <div className="date">
              <label htmlFor="" className="title-left">취소 신청일</label>
              <label htmlFor="" className="title-right">
                {orderDetail?.data?.cancel_at} 
                {
                 orderDetail?.data?.payment_method === 'MONEY' ? ` (${orderDetail?.data?.creator?.name})` : ''
                }
              </label>
            </div>
            <ShowMoreLessComponent lengthRemove={11} text={orderDetail?.data?.cancellation?.reason}/>
            
          </div>
          <div className="info-cancel">
            <div className="info-head">
              <img src={IconWonCancel} alt="" />
              <h2>취소 정보</h2>  
            </div>
            <div className="amount">
              <label htmlFor="" className="title-left">환불 예정 금액</label>
              <label htmlFor="" className="title-right">{numberFormat(orderDetail?.data?.amount)} 원</label>
            </div>
          </div>
          <div className="info-payment-method">
            <div className="top">
              <label htmlFor="" className="title-left">
                {
                  orderDetail?.data?.payment_method === 'MONEY' ? '무통장 입금' : '신용카드'
                }
              </label>
              <label htmlFor="" className="title-right">{numberFormat(orderDetail?.data?.amount)} 원</label>
            </div>
            <div className="bottom">
              {
                orderDetail?.data?.payment_method === 'MONEY' ?
                <>
                  <span>{orderDetail?.data?.cancellation?.bank_account_name} 
                  {
                    orderDetail?.data?.cancellation?.bank?.name ? ` (${orderDetail?.data?.cancellation?.bank?.name})` : ''
                  }
                  </span>
                  <span>{orderDetail?.data?.cancellation?.bank_account_number}</span>
                </> : ''
              }
            </div>
          </div>
          <div className="info-note">
            <div className="note-bgr">
              {
                orderDetail?.data?.payment_method === "MONEY" && <label htmlFor="">
                  <span>취소 건 접수 후 </span> 
                  <span>영업일 1 ~ 2일 이내 환불</span>
                </label>
              }

              {
                orderDetail?.data?.payment_method === "CARD" && <label htmlFor="">
                  <span>최소완료 즉시 </span> 
                  <span>카드 승인 취소, 이후 3 ~ 5 영업일 소요</span>
                </label>
              }
            </div>
            <div className="icon">
              <img src={IconNote} alt="" />
              <span>확인해주세요!</span>
            </div>
            <div className="content">
              <label htmlFor="">카드사의 사정에 따라 승인취소 후 환불이 지연될 수 있습니다.</label>
            </div>
          </div>
          <div className="btn-group">
            <div className="d-grid">
              <button className="cbtn btn-primary" type="button" onClick={goBack}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </main>
    </AppLayout>
  );
};

export default OrderCancelDetail;
