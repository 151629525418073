import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppSetting } from "../store/actions";
import API from "core/networks/API";
import { API_SYSTEM_SETTING } from "../api";

export const useFetchAppSetting = (form: any) => {
  const dispatch = useDispatch();
  const _handleAppSetting = () => {
    dispatch(getAppSetting({}));
  };

  return _handleAppSetting;
}

// export const useFetchAppVersion = () => {
//   const _handleGetAppVersion = async () => {
//     try {
//       const res = await API.get(API_SYSTEM_SETTING, {});
//       console.log('_handleGetAppVersion', res);

//     }catch (error: any) {
//       console.log(error);
//     }
//   };

//   return [data, _handleGetAppVersion]
// }

export const useAppSetting = (form: any) => {
  const appSettings = useSelector((state: any) => state.AppSettings?.data || []);
  // console.log(appSettings, 'appSettings');
  
  let _appSettings:any = appSettings;
  if (appSettings?.consult_kakao_phone && appSettings?.consult_kakao_link) {
    const _chanelId =  appSettings?.consult_kakao_link.split('/');
    _appSettings = {
      ...appSettings,
      chanelId: _chanelId[_chanelId.length - 1]
    }
  }
  // console.log(_appSettings, '_appSettings');
  

  return _appSettings;
}
