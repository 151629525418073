import {useEffect, useState} from "react";
import DaumPostcodeEmbed from 'react-daum-postcode';
import API from "core/networks/API";
const AddressSearch = (props: any) => {
    const [_props, setProps] = useState<any>(props)
    const [data, setData] = useState({
        query: "",
        list: ""
    })

    useEffect(()=>{
        setProps(props)
    }, [props])

    const handleComplete = (data: any) => {
        props.handleSelect(data)
        props.handleClose()
    };

    

    return !_props.open ? <></>:
        <div className="address-search">
            <div className="text-end" onClick={props.handleClose}>닫기</div>
            <hr/>
            <DaumPostcodeEmbed onComplete={handleComplete} style={{width: "100%", height: "calc(100vh - 100px"}} />
        </div>
}
export default AddressSearch