import React, { useEffect, useRef, useState } from "react";

interface ShowMoreLessProps{
  lengthRemove: number;
  text: string
}

const ShowMoreLessComponent = ({ text, lengthRemove }: ShowMoreLessProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [firstLine, setFirstLine] = useState<any>(text && text !== "" ? text.split("\n")[0] : "");
  
  const textContainerRef = useRef<any>(null);
  const [maxCharacters, setMaxCharacters] = useState(17);

  useEffect(() => {
    const _first = text && text !== "" ? text.split("\n")[0] : ""
    if (_first !== "") {
      setFirstLine(_first);
    }
    const calculateMaxCharacters = () => {
      if (textContainerRef.current) {
        const containerWidth = textContainerRef.current.offsetWidth - 75;
        console.log(textContainerRef.current.offsetWidth, 'textContainerRef.current.offsetWidth');
        
        console.log('containerWidth', containerWidth);
        
        const sampleText = "제";
        const sampleElement = document.createElement('span');

        sampleElement.style.position = 'absolute';
        sampleElement.style.visibility = 'hidden';
        sampleElement.textContent = sampleText;
        document.body.appendChild(sampleElement);

        let totalWidth = 0;
        let charCount = 0;

        while (totalWidth <= containerWidth) {
          totalWidth += sampleElement.offsetWidth;
          charCount++;
        }

        document.body.removeChild(sampleElement);

        setMaxCharacters(charCount - lengthRemove);
      }
    };

    window.addEventListener('resize', calculateMaxCharacters);

    calculateMaxCharacters();

    return () => {
      window.removeEventListener('resize', calculateMaxCharacters);
    };
  }, [text]);
  
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = text && text.split("\n").length > 0 ? isExpanded ? text : `${firstLine.slice(0, maxCharacters)}` : '';

  console.log('maxCharacters', maxCharacters);
  console.log('firstLine', firstLine);
  console.log('text', text && text.split("\n").length);
  
  
  const expanSpan = (
    <>
      {((firstLine && firstLine.length > maxCharacters) || (text && text.split("\n").length > 1)) && (
        <span onClick={toggleText}>
          {isExpanded ? " [닫기]" : <><span className="three-dot">...</span>[자세히보기]</>}
        </span>
      )}
    </>
  );
  return (
    <>
      <div
        className={`reason ${isExpanded ? "reason--block" : "reason--inline"}`}
        ref={textContainerRef}
      >
        <label htmlFor="" className={`title-left `}>
          취소 사유
        </label>
        {
          (isExpanded && lengthRemove !== 4) && <br />
        }
        
        <label htmlFor="" className="title-right">
          <span className="reason-content" >
            {isExpanded ? (
              <><div className="content-more" dangerouslySetInnerHTML={{__html: displayText.replaceAll('\n','<br/>')}}>
              </div>
              {expanSpan}</>
            ) : (
              <>
                {`${displayText}`} {expanSpan}
              </>
            )}
          </span>
        </label>
      </div>
    </>
  );
};

export default ShowMoreLessComponent;
