export interface AuthData {
    user: object,
    token: string
}

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export const GET_APP_SETTING = 'GET_APP_SETTING';
export const GET_APP_SETTING_SUCCESS = 'GET_APP_SETTING_SUCCESS';
export const GET_APP_SETTING_FAILED = 'GET_APP_SETTING_FAILED';


