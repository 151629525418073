import { MINIMUM_PAYMENT } from "core/constants/Commons";
import { numberFormat } from "core/utils/helper";

interface DataPaymentAmountProps {
  amount: number;
  isOpen: boolean;
  handleAddCart: () => void;
  handlePayment: () => void;
  isExceed?: boolean;
  isAddCartSuccess?: boolean;
}

const PaymentAmountModalComponent = ({ amount, isOpen, handleAddCart, handlePayment, isExceed, isAddCartSuccess }: DataPaymentAmountProps) => {


  const addCart = () => {
    if (isExceed) return;
    handleAddCart();
  };

  const payment = () => {
    handlePayment();
  };

  return (
    <>
      {isOpen && (
        <div className="payment-amount">
          <div className="payment-amount__group">
            <label htmlFor="" className="title">
              결제 예상 금액
            </label>
            <span className="amount">{numberFormat(amount)}원</span>
          </div>
          <div className="payment-amount__button">
            <button
              className="btn btn--shopping-cart"
              onClick={() => addCart()}
              disabled={isAddCartSuccess}
            >
              장바구니 담기
            </button>
            <button className={`btn btn--order ${+amount < MINIMUM_PAYMENT ? 'disabled' : ''}`} onClick={() => payment()} disabled={isAddCartSuccess}>
              바로 구매
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentAmountModalComponent;
