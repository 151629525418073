import {memo, useEffect, useState} from "react";

import showIcon from 'core/assets/images/icon/show.svg';
import hideIcon from 'core/assets/images/icon/hide.svg';

const CFormControl = (props: any) => {
    const [_props, setProps] = useState<any>({
        name: props.name,
        type: props.type,
        value: props.value || '',
        label: props.label || '',
        desc: props.desc || '',
        className: props.className || '',
        checked: props.checked || false,
        showPassword: props.showPassword || false,
        showPasswordIcon: props.showPasswordIcon || false,
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        required: props.required || false,
        error: props.error || '',
        warning: props.warning || '',
        handleChange: props.handleChange || '',
        placeholder: props.placeholder || '',
        maxLength: props.maxLength || 100,
        pattern: props.pattern || '',
        onClick: props.onClick || function (e: any){},
        onFocus: props.onFocus || function (e: any){},
        handleShowHideStatePassword: props.handleShowHideStatePassword || '',
        format: props.format || '',
        onBlur: props.onBlur || function (e: any){},
        id: props.id || '',
        inputMode: props.inputMode || 'text',
    })

    useEffect(()=>{
        setProps(props)
    }, [props])

    const handleChange = (e: any) => {
        let value = e.target.value
        if(_props?.format && _props?.format == 'phone'){
            let pattern = /[^\d-]/g;
            value = value.replace(pattern, '');
        }
        if(_props?.format && _props?.format == 'text_not_special_characters'){
            // var regex = /[-!@#$%^&*(),.?":{}|<>+=\\/[\]]+$/g;
            var regex = /[^\p{L}\p{M}\d]/gu;
            value = value.replace(regex, '');
        }
        if(_props?.format && _props?.format == 'number'){
            let pattern = /[^\d]/g;
            value = value.replace(pattern, '');
        }
        if(_props?.format && _props?.format == 'only_text'){
            // var regex = /[^\a-zA-Z가-힣]+$/g;
            var regex = /[^\p{L}\p{M}]/gu;
            value = value.replace(regex, '');
        }
        if(e.target.value && e.target.value.length > _props.maxLength){
            return
        }
        // console.log(value,'value555')
        e.target.value = value
        setProps({..._props, value: value})
        if(typeof props.handleChange == 'function')
            _props.handleChange(e)
    }

    const handleClick = (e: any) => {
        if(_props.onClick)
            _props.onClick()
    }

    const handleFocus = (e: any) => {
        if(_props.onFocus)
            _props.onFocus(e)
    }
    const handleBlur = (e: any) => {
        if(_props.onBlur)
            _props.onBlur(e)
    }


    const handleShowHidePassword = () => {
        setProps({..._props, showPassword: !_props.showPassword})
        if(typeof props.handleShowHideStatePassword == 'function') props.handleShowHideStatePassword(_props.showPassword)
    }

    const renderInput = (type: string) => {
        let className = ["c-input-wrapper"]
        if(_props.showPasswordIcon) className.push("show-hide-password")
        let _value = _props.value
        if(_value && type == "password" && !_props.showPassword){
            _value = _value.replace(/./g, '*')
        }
        if(_value && type == "password" && _props.showPassword){
            type = "text"
        }
        return <div className={className.join(' ')}>
            {(type == 'password' && !_props.showPassword) && <div className="markup">{_value}</div>}
                <input
                    name={_props.name}
                    type={type}
                    value={_props.value}
                    placeholder={_props.placeholder}
                    readOnly={_props.readOnly}
                    disabled={_props.disabled}
                    onChange={handleChange}
                    autoComplete={"one-time-code"}
                    autoCorrect={"one-time-code"}
                    maxLength={_props.maxLength}
                    pattern={_props.pattern}
                    onClick={handleClick}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    id={_props.id}
                    inputMode={_props.inputMode}
                />
            {_props.showPasswordIcon && <span className={'password-icon'} onClick={handleShowHidePassword}><img src={_props.showPassword ? hideIcon: showIcon} alt={""}/> </span>}
            </div>
    }


    const renderControl = () => {
        switch (_props.type) {
            case "input": return renderInput('text'); break;
            case "number": return renderInput('number'); break;
            case "password": return renderInput('password'); break;
            default: return <>{props.children}</>;
        }
    }

    return (
        <div className={_props.className}>
            <div className="c-form-control">
                {_props.rightDesc &&<div className="row">
                    <div className="col-6">{_props.label && <label className="c-label">{_props.label} {_props.required && <span className="required">*</span>}</label>}</div>
                    <div className="col-6 text-end"><div className="right-desc"><span className="required">*</span> {_props.rightDesc}</div></div>
                </div>
                }
                {_props.label && !_props.rightDesc && <label className="c-label">{_props.label} {_props.required && <span className="required">*</span>}</label>}
                {_props.desc && <p className="c-desc">{_props.desc}</p>}
                {renderControl()}
                {_props.error && <p className="c-form-error">{_props.error}</p>}
                {_props.warning && <p className="c-form-error"><span>*</span><span>{_props.warning}</span></p>}
            </div>
        </div>
    );
}

export default memo(CFormControl);