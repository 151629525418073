import "modules/Sample/assets/scss/samplepage.scss";
import AppLayout from "core/layouts/AppLayout";
import { useNavigate, useParams } from "react-router-dom";
import TermsOfService from "../components/TermsOfService";
import PrivacyPolicy from "../components/PrivacyPolicy";
import DeliveryProductPrecautions from "../components/DeliveryProductPrecautions";
import ElectronicFinancial from "../components/ElectronicFinancial";
import CancellationRefund from "../components/CancellationRefund";
import IconHeader from "core/components/header/IconHeader";
import { POLICY_PAYMENT } from "core/constants/Commons";
import DeliveryAndPayment from "../components/DeliveryAndPayment";

const Policy = (props: any) => {
	const param: any = useParams();
	const navigate = useNavigate();
	// Terms of Service  => terms-of-service
	// privacy policy    => privacy-policy
	// Delivery product precaution s=> delivery-product-precautions
	// Electronic financial transaction terms and conditions => electronic-financial
	// Delivery and payment information => delivery-and-payment
	// Cancellation/refund information => cancellation-refund

	const renderPolicy = () => {
		switch (param?.id) {
			case 'terms-of-service':
				return <TermsOfService />
				break;
			case 'privacy-policy':
				return <PrivacyPolicy />
				break;
			case 'delivery-product-precautions':
				return <DeliveryProductPrecautions/>
				break;
			case 'electronic-financial':
				return <ElectronicFinancial />
				break;
			case 'delivery-and-payment':
				return <DeliveryAndPayment />
				break;
			case 'cancellation-refund':
				return <CancellationRefund />
				break;
			default:
				break;
		}
	}

  const handleGoback = () => {
		navigate(-1)
	}

  return (
		<AppLayout className={"policy-page"}>
			<IconHeader
				title={POLICY_PAYMENT.find((item: any) => item.value === param?.id)?.title}
				iconLeft={true}
				handleLeftClick={() => handleGoback()}
			/>
			<div className="policy-content">
				{renderPolicy()}
			</div>
		</AppLayout>
	);
};

export default Policy;