import IconHeader from "core/components/header/IconHeader";
import { useCountSize } from "core/hooks/useCountSize";
import AppLayout from "core/layouts/AppLayout";
import InfinityScrollContainer from "core/layouts/InfinityScrollContainer";
import { RouterName } from "core/router";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/scss/orderhistorylist.scss";
import { DATE_RANGES } from "../constants/DateFilter";
import { useLists, useUpdateReload } from "../hooks/useLists";
import TimeSettingFilterComponent from "../components/TimeSettingFilterComponent";
import OrderHistoryLoadingComponent from "../components/OrderHistoryLoadingComponent";
import OrderHistoryListComponent from "../components/OrderHistoryListComponent";
import { useDataPayment, useUpdatePaymentStatus } from "../hooks/usePayment";

const OrderHistoryList = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, handleUpdatePaymentStatus] = useUpdatePaymentStatus(); 
  const [, paymentData] = useDataPayment({});
  const [filter, setFilter] = useState<any>({
    start_date: DATE_RANGES[1].value[0],
    end_date: DATE_RANGES[1].value[1],
    page: 1,
    time_option: '15일',
    reload: 1
  });
  console.log('filter', filter);
  console.log('DATE_RANGES[1].value[0]', DATE_RANGES[1].value[0]);
  
  
  const updateReload = useUpdateReload();
  const [loadingList, dataOrderHistories, listParams, errors] = useLists(filter);
  const [paramsCountSize, setParamsCountSize] = useState<any>(false);
  useCountSize(paramsCountSize);
  useEffect(() => {
    if (+listParams.reload === 1) {
      let el:  any = document.getElementById('list-panel');
      setTimeout(() => {
        el.scrollTo(0, window.localStorage.scrollY)
      }, 300);
      setFilter({
        ...listParams,
        reload: +listParams.reload,
      })
    }else{
      console.log('listParams', listParams);
      
      window.scrollTo(0, 0)
      window.localStorage.scrollY = 0
      setFilter({
        ...filter,
        // start_date: DATE_RANGES[1].value[0],
        ...listParams,
        end_date: DATE_RANGES[1].value[1],
        reload: 0,
      })
    }
    (window as any).webViewCallGetListPath(['/order-history-list', '/order-cancel/', '/order-history-detail/', '/carts-list', '/order-cancel-detail/'])
  }, [])


  const handleLoadMore = () => {
    if (
      filter.page !== undefined &&
      filter.page < dataOrderHistories.totalPage &&
      !dataOrderHistories.loading
    ) {
      updateReload({reload: 0})
      setFilter((p: any) => ({ ...p, reload: 0, page: filter.page + 1 }));
    } else {
    }
  };

  useEffect(() => {
    const {lastOrder} = paymentData;
    if (lastOrder?.id && lastOrder?.payment_method === "CARD" && lastOrder?.payment_status === "PENDING" ) {
      handleUpdatePaymentStatus({
        order_id: lastOrder?.id,
        status: "FAILED"
      })
    }
  }, [paymentData.lastOrder])

  useEffect(() => {
    setParamsCountSize(loadingList);
  }, [dataOrderHistories.data]);

  const setDateFilter = (data: any) => {
    setFilter((p: any) => ({
      ...p,
      time_option: data.label,
      start_date: data.value[0],
      end_date: data.value[1],
      page: 1,
      reload: 0,
    }))
  }
  
  const updateAddress = (data: any) => {
    if (updateReload) {
      updateReload({
        reload: 0
      })
    }
    navigate(RouterName.ADDRESS_BOOK, {
      state: {
        order_id: data?.id || null,
        back_type: RouterName.ORDER_HISTORY_LIST, 
        back_url: RouterName.ORDER_HISTORY_LIST
      }
    })
  }

  const goDetail = (item: any) => {
    if (updateReload) {
      updateReload({
        reload: 0
      })
    }
    navigate(RouterName.ORDER_HISTORY_DETAIL.replace(":id", item.id));
  }

  const goBack = () => {
    if (updateReload) {
      updateReload({
        reload: 0
      })
    }
//http://192.168.10.44:3000/order-history-list
    // console.log(location.state, 'location.stat');
    
    if (location.search.includes("backTo=main")) {
      navigate(RouterName.MAIN);
    }else{
      if (location.state && location.state?.back_type !== '') {
        switch (location?.state?.back_type) {
          case RouterName.PAYMENT_SUCCESS:
            navigate(RouterName.MAIN);
            break;
          default:
            navigate(location.state?.back_url);
            break;
        }
      }else{
        navigate(-1);
      }
    }

  }

  (window as any).backFromDeviceAndroid = function() {
    goBack()
  }

  return (
    <AppLayout className={"order-history-list-page"}>
      <div id="summary-panel">
        <IconHeader
          className="order-history-list-page__custom-header"
          title={"주문내역 및 배달 조회"}
          iconLeft={true}
          handleLeftClick={() => goBack()}
          handleRightClick={() => {
            navigate(RouterName.MAIN);
          }}
          iconRight={true}
        />
        <TimeSettingFilterComponent filter={filter} setDateFilter={setDateFilter} data={(Object.values(DATE_RANGES).filter((item: any) => item.type.includes("history")))}/>
      </div>

      {loadingList && filter.page <= 1 ? (
        <div className="order-history-list-page__content">
          <OrderHistoryLoadingComponent className={""} />
        </div>
      ) : (
        <>
          <InfinityScrollContainer
            className={`order-history-list-page__content order-history-list-page--${
              dataOrderHistories?.data?.length === 0 ? "empty" : ""
            }`}
            id={"list-panel"}
            filter={filter}
            data={{...dataOrderHistories, loading: loadingList}}
            fetchMoreData={handleLoadMore}
            bottom={1}
            loadingElement={<OrderHistoryLoadingComponent className={""} />}
          >
            <OrderHistoryListComponent data={dataOrderHistories || []} handleUpdateAddress={updateAddress} filter={filter} goDetail={goDetail}/>
          </InfinityScrollContainer>
        </>
      )}
    </AppLayout>
  );
};

export default OrderHistoryList;
