
import 'modules/Referrer/assets/scss/referrer.scss'
import AppLayout from "core/layouts/AppLayout";
import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {phoneNumberFormat} from "core/utils/helper";
import moment from "moment";
import CEmptyIcon from "core/components/CEmptyIcon";
import {API_LIST_REFERRER} from "../api";
import API from "core/networks/API";
import {ShareMessage} from "../utils/constants";


const ReferrerPage = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({
        loading: true,
        total: 0,
        list: [
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
            // {id: 1, phone: "01011111111", date: "2023-08-01 00:00:00"},
        ]
    })
    const handleGoBack = () => {
        if (location?.search.includes("backTo=main")) {
            navigate(RouterName.MAIN);
        }else{
            navigate(-1)
        }
    }

    const fetchData = async () => {
        try{
            const res = await API.get(API_LIST_REFERRER, {page_size: 9999999})
            console.log(res.data.status);
            if(res.data.status){
                setData({
                    loading: false,
                    total: res.data.data.total,
                    list: res.data.data.data
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleInvite = (e:any) => {
        (window as any).webViewRequestShare(ShareMessage)
    }

    const renderList = () => {
        console.log(data)
        if(data.list.length > 0){
            return data.list.map((item: any, k:number) => {
                return <tr>
                    <td>{phoneNumberFormat(item.phone, true)}</td>
                    <td>{moment(item.created_at).format('Y-MM-DD')}</td>
                </tr>
            })
        }
        return []
    }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 60
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
        const boxRenderList = document.getElementById('box-render-list');
        if(boxRenderList) {
            boxRenderList.style.height = (contentHeight - 52).toString() + 'px'
        }
    }

    (window as any).backFromDeviceAndroid = function() {
    //code webview
        handleGoBack()
    }
    
    useEffect(()=>{
        (window as any).webViewCallGetListPath(['/referrer'])
        countSize()
        fetchData()
    }, [])
    
    return (
        <AppLayout className={"referrer-page"}>
            <IconHeader
                title={'추천인 명단'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <div className="summary" id={'summary-panel'}>
                내 추천인
                <h1>{data.total}명</h1>
            </div>

            <main id={'list-panel'}>
                <div className="breakpoint">-</div>
                {data.total > 0 ? <div className="box-title-list">
                    <label>전화번호</label>
                    <label>등록일자</label>
                </div> : ''}
                <div className='box-render-list' id='box-render-list' style={{marginTop:(data.total > 0 ? '34px' : 0)}}>
                    {!data.loading ? <>
                    {data.total > 0 ?
                    <table className="table">
                        <thead className='d-none'>
                            <tr>
                                <td>전화번호</td>
                                <td>등록일자</td>
                            </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>:
                        <div style={{padding: '16px'}}>
                            <CEmptyIcon text={"아직 등록된 추천인이 없습니다."} description={"친구에게 추천하고 적립금 혜택을 받아보세요!"}/>
                            <div className="d-grid mt-4">
                                <button className="cbtn btn-primary" onClick={handleInvite}>친구 초대</button>
                            </div>
                        </div>
                    }
                    </> : <>
                            <table className="table">
                                <thead className='d-none'>
                                <tr>
                                    <td>전화번호</td>
                                    <td>등록일자</td>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                    </>
                    }
                </div>
            </main>
        </AppLayout>
    )
}


export default ReferrerPage
