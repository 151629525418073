
import 'modules/StaticPage/assets/scss/static-page.scss'
import AppLayout from "core/layouts/AppLayout";

import RankingImage from 'modules/StaticPage/assets/images/ranking.png';

import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
const MemberRankingPage = (props: any) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }


    // const timeLineData = [
    //     {time: "2013년", content: <div>한정식 도시락의 <span>품격에 맞는 용기를 <br/>최초 개발</span>하였습니다.</div>},
    //     {time: "2015년", content: <div>살구나무집 대전 유성점을 오픈하여,<br/> <span>자체개발한 도시락 용기를 </span> <br/>(제30-08249847) 하였습니다.</div>},
    //     {time: "2017년", content: <div>주식회사 살구나무집 법인을 설립하여 <br/><span>청년벤처 기업에 선정</span>되었습니다.</div>}
    // ]
    // const renderTimeLine = () => {
    //     return timeLineData.map((item: any, k:number)=>{
    //         return <li key={k}>
    //             <div className="timeline-item">
    //                 <div className="left"><div className="time">{item.time}</div></div>
    //                 <div className="right">{item.content}</div>
    //             </div>
    //         </li>
    //     })
    // }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(()=>{
        countSize()
    }, [])

    return (
        <AppLayout className={"static-page member-ranking-page"}>
            <IconHeader
                title={'회원 등급'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <main id={"list-panel"}>
                <h1>회원 등급 혜택 안내</h1>
                <div>
                    <img src={RankingImage} alt="" style={{width: "100%"}}/>
                </div>
                <div className="content">
                    - 가입한 <strong>모든 회원</strong>에게 적용<br/>
                    - 배달 서비스 이용 시 이용 금액의 <strong>5%</strong> 지급<br/>
                    - 추천인이 배달 서비스 이용 시 이용 금액의 <strong>3%</strong> 지급<br/>
                </div>
            </main>
        </AppLayout>
    )
}


export default MemberRankingPage
