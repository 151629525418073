
import 'modules/Sample/assets/scss/samplepage.scss'
import AppLayout from "core/layouts/AppLayout";


const Sample = (props: any) => {
    return (
        <AppLayout className={"sample-page"}>

        </AppLayout>
    )
}


export default Sample
