export interface AuthData {
    user: object,
    token: string
}

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';

export const UPDATE_FORM_SIGNUP = 'UPDATE_FORM_SIGNUP';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILD = 'LOGOUT_FAILD';

export const UPDATE_IDLE_TIME = 'UPDATE_IDLE_TIME';
export const UPDATE_TIME_RESUME = 'UPDATE_TIME_RESUME';