import { MESSAGE } from 'core/constants/Commons';
import { CSpinNumberProps } from 'core/interface/CSpinNumber';
import React, { memo, useEffect, useState } from 'react';

const CSpinNumber = ({
  className,
  label,
  value,
  min,
  max,
  onIncrease,
  onDecrease,
  onClickInput,
  increaseIconEnabled,
  decreaseIconEnabled,
  increaseIconDisabled,
  decreaseIconDisabled,
  isExceed,
  isAddCartSuccess,
}: CSpinNumberProps) => {
  
  const [quantity, setQuantity] = useState<any>(value || '');

  useEffect(() => {
    if (max !== undefined && min !== undefined && value !== undefined && (value > max || value < min) ) {
      setQuantity('')
      return;
    }else{
      if(value !== undefined && min !== undefined)  setQuantity(value || '')
    }
  }, [max, min, value])

  const increaseQuantity = () => {
    if (max !== undefined) {
      if (+quantity >= max || isExceed) return;
      console.log('_quantity', typeof quantity);
      let q;
      if (quantity === '') {
        q = +quantity + 2 < max ? +quantity + 2 : max;
        
      }else{
        q = +quantity + 1 < max ? +quantity + 1 : max;
      }
      console.log('q', q);
      
      setQuantity(q)
      onIncrease(q);
    }
  }
  
  const decreaseQuantity = () => {
    if (min !== undefined) {
      if (+quantity <= min) return;
      let q = +quantity - 1 > min ? +quantity - 1 : min;
      setQuantity(q)
      onDecrease(q);
    }
  }

  const handleClickInput = () => {
    onClickInput();
  }
  
  return (
    <div className={`cspin-number ${className}`}>
      <div className="cspin-number__group">
        {
          label && <label className="title">{label}</label>
        }
        <div className="cspin">
          <div className={`action action--minus ${min !== undefined && quantity < min ? 'disabled' : ''}`} onClick={() => decreaseQuantity()}>
            <img src={min !== undefined &&  quantity <= min ? decreaseIconDisabled : decreaseIconEnabled} alt="" />
          </div>
          <div className={`input-number ${isExceed ? 'error' : ''}`} onClick={() => handleClickInput()}>
            <span className="number">
              {quantity}              
            </span>
          </div>
          <div className={`action action--plus ${(max !== undefined && (quantity > max)) ? 'disabled' : ''}`} onClick={() => increaseQuantity()}>
            <img src={(max !== undefined && quantity >= max) ?  increaseIconDisabled : increaseIconEnabled} alt="" />
          </div>
        </div>

      </div>
      {
        isExceed && <div className="error-exceed-quantity">
          <label htmlFor="">
            {MESSAGE.MAX_EXCEED}
          </label>
        </div>
      }

      {
        isAddCartSuccess && <div className="error-exceed-quantity">
        <label htmlFor="">{MESSAGE.ADD_CART_SUCCESS}</label>
      </div>
      }
    </div>
  )
};

export default memo(CSpinNumber);