import {useEffect, useState} from "react";

import ArrowDownIcon from 'core/assets/images/icon_arrow_down.svg';
const CAccoditionItem = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        question: props.question || '',
        answer: props.answer || '',
        type: props?.type || 1,
        title: props.title || '',
        description: props.description || '',
        isShow:props.isShow || false,
        expandSingle: props.expandSingle || false,
        handleClickOpen: props.handleClickOpen || {},
        item: props.item || {},
    })
    const [expand, setExpand] = useState(props?.isShow);

    useEffect(() => {
        setProps(props)
    }, [props.answer])

    useEffect(() => {
        
        if(props.expandSingle){
            if(props?.isShow?.state && props?.isShow?.id == props?.item?.id){
                setExpand(true)
            }else {
                setExpand(false)
            }
        }else {
            if(props?.isShow){
                // setExpand(!expand)
            }
        }
        
    }, [props?.isShow])

    const handleClick = (e: any) => {
        if(props.expandSingle){
            let data = {state:!expand,id:props.item?.id}
            props.handleClickOpen(data)
        }else {
            setExpand(!expand)
        }
    }

    return <>

        <div className={`c-accodition ${_propsState.className} ${expand ? ' open' : ''} 
        ${_propsState?.type && _propsState?.type == 2 ? ' accodition-type' : ''}`} id={`id-c-accodition-${_propsState?.id}`}>
            <div className='c-accodition__question' onClick={(e) =>handleClick(e)}>
                <div className='box-content-question'>
                    <p className='c-accodition__question__text'>{_propsState.question}</p>
                    {
                        _propsState?.type && _propsState?.type == 2 ?
                            <p className='c-accodition__question__title c-accodition__question__title--update'>{_propsState?.title}</p> : ''
                    }
                </div>
                {
                    _propsState?.type && _propsState?.type == 2 ?
                        <span className='c-accodition__question__description'>{_propsState?.description}</span> : ''
                }

                <img className={`c-accodition__question__icon ${expand ? ' show' : ''}`} src={ArrowDownIcon} alt={''}/>
            </div>
            <div className={`c-accodition__answer ${expand ? ' open' : ''}`}>
                {expand ? _propsState.answer : ''}
            </div>
        </div>
    </>
}

export default CAccoditionItem