import {useNavigate} from "react-router-dom";
import {memo, useEffect, useRef, useState} from "react";
import ACMICON from "../assets/icon/acm.svg";
import WDRICON from "../assets/icon/wdr.svg";
import moment from "moment/moment";
import {formatAccountNumber, numberFormat, priceFormat} from "../../../core/utils/helper";
import AppLayout from "../../../core/layouts/AppLayout";
import IconHeader from "../../../core/components/header/IconHeader";
import {RouterName} from "../../../core/router";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import CEmptyIcon from "../../../core/components/CEmptyIcon";
import EMPTYICON from "../assets/icon/empty.svg";
import CFormControl from "../../../core/components/form-controls/CFormControl";
import PlusIcon from "../../../core/assets/images/icon/plus.svg";
import BottomSwiper from "core/components/BottomSwiper";
import BankSelect from "../../../core/components/BankSelect";
import {AxiosError} from "axios";
import API from "../../../core/networks/API";
import {API_ACCUMULATE_REQUEST_WITHDRAW, API_OWN_BALANCE} from "../api";
import AccumulateRequestDialog from "../components/AccumulateRequestDialog";
import ArrowDownIcon from "../../../core/assets/images/icon/icon_arrow_down.svg";
import { NumericFormat, PatternFormat } from 'react-number-format';

const AccumulateWithdrawForm = (props: any) => {
    const navigate = useNavigate();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [balance, setBalance] = useState({
        total: 0,
        withdrawable_total: 0
    })
    const MAX_LIMIT = 10;
    const MAX_BANK_LIMIT = 30;
    const [swipper, setSwipper] = useState({
        bankSelect: false,
        isActive: false
    })

    const [dialog, setDialog] = useState({
        show: false,
        data: {}
    })

    const [formData, setFormData] = useState<any>({
        loading: false,
        data: {
            bank_id: null,
            bank_label: "",
            bank_account_name: "",
            bank_account_number: "",
            amount: 0
        },
        errors: {
            bank_account_name: "",
            bank_account_number: "",
            amount: ""
        },
        editId: null,
        valid: false,
        dirty: false
    })

    const fetchBalance = async () => {
        try{
            const res = await API.get(API_OWN_BALANCE, {});
            if(res?.data?.data) setBalance({...balance, loading: false, ...res.data.data})
        } catch (e: any) {
            console.log(e)
        }
    }
    const handleGoBack = () => {
        navigate(-1)
    }

    const validateFormData = (key: string, value: any) => {
        let valid = true
        let errors = {[key]: ""}
        if(key == 'bank_id'){
            if(value == 0) {
                valid = false
                errors = {[key]: "Vui lòng chọn ngân hàng"}
            }
        }
        if(key == 'bank_account_name'){
            if(value.length == 0) {
                valid = false
                errors = {[key]: "Vui lòng nhập tên tài khoản"}
            }
        }
        if(key == 'bank_account_number'){
            if(value.length == 0) {
                valid = false
                errors = {[key]: "Vui lòng nhập số tài khoản"}
            }
        }
        if(key == 'amount'){
            // const numberRegex = /^[1-9]\d{0,}$/;
            // if(!numberRegex.test(value)) {
            //     valid = false
            //     errors = {[key]: "Vui lòng nhập số tiền hợp lệ"}
            // }
            // if(value.length == 0){
            //     valid = false
            //     errors = {[key]: "Vui lòng nhập số tiền"}
            // }
            // if(value > balance.withdrawable_total){
            //     valid = false
            //     errors = {[key]: "Nhiều hơn số tiền có thể rút"}
            // }

            if(value.length == 0) {
                valid = false
                errors = {[key]: "Vui lòng nhập số tài khoản"}
            }
            
        }
        console.log(valid, 'valid')
        return {valid, errors}
    }

    const withdrawProcess = async () => {
        try{
            const res = await API.post(API_ACCUMULATE_REQUEST_WITHDRAW, formData.data);
            console.log(res, 'API_ACCUMULATE_REQUEST_WITHDRAW');
            setFormData({...formData, loading: false})
            //200
            navigate(-1);
            // setDialog({
            //     show: true,
            //     data: {
            //         code: 200,
            //         ...formData
            //     }
            // })
        } catch (e: any) {
            console.log(e.response.data, 'e.response.data');
            setFormData({...formData, loading: false})
            setDialog({
                show: true,
                data: e.response.data
            })
        }
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        // withdrawProcess()
        setDialog({
            show: true,
            data: {
                code: 202,
                ...formData
            }
        })
        
    }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 16
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(()=>{
        countSize()
        fetchBalance()
        return () => {
            setSwipper({
                bankSelect: false,
                isActive: false
            })
        }
    }, [])
    
    const handleInputChange = (e: any) => {
        let formErrors = {...formData.errors}
        
        const {valid, errors} = validateFormData(e.target.name, e.target.value)
        if(!errors[e.target.name]) delete formErrors[e.target.name]
        if(errors[e.target.name]){
            formErrors = {...formErrors, ...errors}
        }
        if ((e.target.name === 'amount' && e.target.value.length > 20) || (e.target.name !== 'amount' && e.target.value.length > 30)) return;
        let valuePrice;
        if (e.target.name === 'amount') {
            let pattern = /\D/g;
            valuePrice = e.target.value.replace(pattern, ''); 
            // valuePrice = formatNumber(e.target.value);
        }
        
        setFormData({
            ...formData,
            data: {
                ...formData.data,
                [e.target.name]: e.target.name === 'amount' ? valuePrice.replaceAll(',', '') : e.target.value
            },
            errors: formErrors,
            // dirty: valid
        })
    }

    const handleNumericChange = (e: any) => {
        console.log(e.target.name, e.target.value);
        let pattern = /\./g;
        let valuePrice;
        valuePrice = e.target.value.replace(pattern, ''); 
        
        console.log(valuePrice, 'valuePrice');
        
        setFormData({
            ...formData,
            data: {
                ...formData.data,
                [e.target.name]: valuePrice.replaceAll(',', '')
            },
        })
    }



    console.log(formData.data, 'formData');
    

    const handleBankSelect = (item: any) => {
        setSwipper({...swipper, bankSelect: false})
        setFormData({...formData,  dirty: false, data: {...formData.data, bank_label: item.name, bank_id: item.id}})
    }

    useEffect(()=>{
        let { data } = formData;
        if (+data.amount > 0 && data.bank_account_name !== '' && data.bank_account_number !== '' && data.bank_id !== null) {
            setFormData({
                ...formData,
                dirty: true
            })
        }else{
            setFormData({
                ...formData,
                dirty: false
            })
        }
    }, [formData.data])

    const handleShow = () => {
        if (swipper.isActive) {
            setSwipper({
                isActive: true,
                bankSelect: false
            })
        }
    }

    useEffect(() => {
        if (swipper.bankSelect) {
            setSwipper({
                ...swipper,
                isActive: true
            });
        }
      }, [swipper.bankSelect]);

    const handleSubmitDialog = (errCode: number) => {
        switch (errCode) {
            case 500:
                navigate(RouterName.CONSULT_LIST)
                break;
            case 200:
                navigate(RouterName.ACCUMULATE_LIST)
                break;
            case 202:
                withdrawProcess()
                break;
            case 9003: 
                navigate(RouterName.GOODS_LIST)
                break;
            case 9002: 
                navigate(RouterName.CONSULT_LIST)
                break;
            break;
            default:
            navigate(RouterName.ACCUMULATE_LIST)
                break;
        }
    }

    return (
        <AppLayout className={"accumulate-page withdraw-form"}>
            <IconHeader
                id={"main-header"}
                title={'출금 신청'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <div className="summary" id={"summary-panel"}>
                <div className="benefit-information" onClick={()=>{
                    navigate(RouterName.PROMOTION)
                }}>
                    <label>!</label>
                    <span>혜택 안내</span>
                </div>
                출금 가능
                <h1>{numberFormat(balance.withdrawable_total,0,true)}원</h1>
                <p>보유 적립금 : {numberFormat(balance.total,0,true)}원</p>
                <button className="cbtn btn btn-primary" style={{visibility: "hidden"}}>출금하기</button>
            </div>

            <main id={'list-panel'}>
                <form onSubmit={handleSubmit}>
                    <div className="group-bank-name">
                        <CFormControl
                            label={"은행명"} placeholder={"은행 선택"}
                            type={"input"} required={true} disabled={false} readOnly={true}
                            name={'bank_label'} className={""}
                            value={formData.data.bank_label}
                            handleChange={()=>{}}
                            onClick={()=>setSwipper({...swipper, bankSelect: true, isActive: true})}
                            // error={formData.errors['bank_id']}
                        />
                        <img className="icon-arrow" src={ArrowDownIcon} alt={""}/>
                    </div>
                    <CFormControl
                        label={"예금주"} placeholder={"예금주 명을 입력 하세요."}
                        type={"input"} required={true} disabled={false} readOnly={false}
                        name={"bank_account_name"} className={""}
                        // error={formData.errors['bank_account_name']}
                        maxLength={30}
                        handleChange={handleInputChange}
                    />
                    {/* <CFormControl
                        label={"계좌번호"} placeholder={"계좌번호를 – 없이 입력하세요."}
                        type={"number"} required={true} disabled={false} readOnly={false}
                        name={'bank_account_number'} className={""}
                        pattern={"[0-9]"}
                        // error={formData.errors['bank_account_number']}
                        maxLength={30}
                        handleChange={handleInputChange}
                    /> */}

                    <div className="c-form-control">
                        <label className="c-label">계좌번호 <span className="required">*</span></label>
                        <div className="c-input-wrapper">
                            <NumericFormat 
                                inputMode="decimal"
                                allowLeadingZeros
                                placeholder="계좌번호를 – 없이 입력하세요."
                                allowNegative={false}
                                value={formData.data?.bank_account_number || null}
                                isAllowed={(values: any) => {
                                    const { formattedValue, floatValue }: any = values;
                                    if (formattedValue.includes(".")) {
                                        return false; 
                                    }
                                    return formattedValue === "" || formattedValue.length <= MAX_BANK_LIMIT;
                                }}  
                                name="bank_account_number"  
                                onChange={handleInputChange}
                                decimalSeparator=''
                            />
                        </div>
                    </div>

                    

                    <div className="c-form-control">
                        <label className="c-label">출금액 <span className="required">*</span></label>
                        <div className="c-input-wrapper">
                            <NumericFormat 
                                inputMode="decimal"
                                placeholder="출금액을 입력해 주세요."
                                allowNegative={false}
                                value={formData.data?.amount || null}
                                thousandSeparator="," 
                                isAllowed={(values: any) => {
                                    const { formattedValue, floatValue }: any = values;
                                    return formattedValue === "" || formattedValue.length <= MAX_LIMIT;
                                }}  
                                name="amount"  
                                onChange={handleNumericChange}
                            />
                        </div>
                    </div>
                    <div className="d-grid" style={{marginTop: '40px', marginBottom: '24px'}}>
                        <button disabled={!formData.dirty || formData.loading} type="submit" className="cbtn btn-primary"> 출금</button>
                    </div>
                </form>
                <div className="content">
                    <p>* 유의 사항</p>
                    <ul>
                        <li>출금하기는 1회 이상 서비스 이용해야 이체되며,<br/>
                    이용 없을 경우 자동 취소됩니다.<br/><br/></li>
                        <li>출금액은 10,500원 이상일때 10,000원 단위로 가능하며,<br/>
                    출금 시 500원의 수수료가 부과됩니다.<br/><br/></li>
                        <li>출금 신청 시, 매장에서 확인 후 지급됩니다.<br/><br/></li>
                        <li>주말이나 공휴일은 이용에 제한이 있을 수 있습니다. <br/><br/></li>
                        <li>출금 취소는 전화 문의로 취소하셔야 합니다.</li>
                    </ul>
                </div>
            </main>
            <AccumulateRequestDialog data={dialog} handleClose={()=>{setDialog({...dialog, show: false})}} handleSubmit={handleSubmitDialog}/>
            <BottomSwiper
                title={"은행을 선택해주세요"}
                suggest={"입금 받을 은행명을 선택해주세요."}
                className={"bottom-swiper--bank-select"}
                isActive={swipper.isActive}
                isOpen={swipper.bankSelect}
                onSlideDown={handleShow}
            >
                <BankSelect onSelect={handleBankSelect} className={""} key={""}/>
            </BottomSwiper>
        </AppLayout>
    )
}

export default AccumulateWithdrawForm