import IconHeader from "../../../core/components/header/IconHeader";
import { RouterName } from "../../../core/router";
import CEmptyIcon from "../../../core/components/CEmptyIcon";
import EmptyIcon from "../assets/icon/empty.svg";
import AppLayout from "../../../core/layouts/AppLayout";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../core/networks/API";
import { API_LIST_CONSULT } from "../api";
import moment from "moment";
import { getCategory, nl2br } from "../utils/helpers";
import CDialog from "../../../core/components/CDialog";
import closeBtn from "../assets/icon/close.svg"
import { Carousel } from "react-responsive-carousel";
import iconNext from "../assets/icon/icon_next_carousel.svg"
import iconPrev from "../assets/icon/icon_prev_carousel.svg"
import { useHandleHyperlink } from "core/hooks/useHandleHyperlink";
import { API_MARKREAD_NOTIFICATION } from "modules/Notification/api";


const ConsultDetail = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [item, setItem] = useState<any>({})
    const [previewUrl, setPreviewUrl] = useState<any>("")
    const [dialog, setDialog] = useState<any>({
        delete: false
    })
    const handleGoBack = () => {
        if (location?.search.includes("backTo=main")) {
            navigate(RouterName.MAIN);
        }else{
            navigate(-1)
        }

    }

    useHandleHyperlink({
        data: item?.answer || "",
        elId: "text-answer"
    });


    const fetchData = async () => {
        try {
            const res = await API.get(API_LIST_CONSULT + '/' + params.id, {});
            if (res.data.status) {
                setItem(res.data.data)
                setTimeout(()=>{
                    var elementNext = document.querySelector('.control-next');
                    var elementPrev = document.querySelector('.control-prev');
                    if(elementNext){
                        console.log('zozoz');
                        (elementNext as any).style.backgroundImage = `url(${iconNext})`;
                        (elementPrev as any).style.backgroundImage = `url(${iconPrev})`;
                    }
                },500)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleDelete = async () => {
        try {
            const res = await API.delete(API_LIST_CONSULT + '/' + params.id, {});
            if (res.data.status) {
                // handleGoBack(true)
                localStorage.setItem("delete_consultant", "true")
                navigate(RouterName.CONSULT_LIST)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleEdit = () => {
        navigate(RouterName.CONSULT_FORM_UPDATE.replace(/:id/, item.id))
    }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        if (listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(() => {

        countSize()
    }, [item])


    const handleReadNotify = async  (id:any) =>{
        try{
            await API.post(API_MARKREAD_NOTIFICATION, {id: id});
        } catch (e) {
            console.log(e)
        }
    }

    (window as any).backFromDeviceAndroid = function() {
        //code webview
        handleGoBack()
    }

    useEffect(() => {
        (window as any).webViewCallGetListPath(['/consult/detail/'])
        fetchData()
    }, [])
    useEffect(() => {
        console.log(previewUrl)
    }, [previewUrl])

    const renderAttachment = (type: string) => {
        if (item && item[type] && item[type].length > 0) {
            return item[type].map((att: any, k: number) => {
                return <div key={k} className="attachment-item">
                    <img src={att.path_original} data-src={att.path_thumbnail} alt="" onClick={() => {
                        // setPreviewUrl(att.path_original) 
                    }} />
                </div>
            })
        }
        return []
    }

    var body = document.getElementsByTagName('body')[0];
    body.onclick = function (e: any) {
        if (e.srcElement.childNodes.length > 0) {
            if (e.srcElement.childNodes[0].tagName === 'IMG') {
                setPreviewUrl(e.srcElement.childNodes[0].src)
            }
        } else {
            if (e.srcElement.tagName === 'IMG') {
                setPreviewUrl(e.srcElement.src)
            }
        }
    };

    const renderChild = (type: string) => {
        if (item && item[type] && item[type].length > 0) {
            return item[type].map((att: any, k: number) => {
                return <div key={k} className="attachment-item">
                    <img src={att.path_original} data-src={att.path_thumbnail} alt="" onClick={() => {
                        // setPreviewUrl(att.path_original) 
                    }} />
                </div>
            })
        } else {
            return <></>
        }
    }

    const renderImageQuestion = (type: string) => {
        if (item && item[type] && item[type].length > 0) {
            return (
                <Carousel
                    infiniteLoop={false}
                    showIndicators={false}
                    showArrows={true}
                    showStatus={true}
                    statusFormatter={(currentItem: any, total: any) => `${currentItem} / ${total}`}
                    showThumbs={false}
                    stopOnHover={true}
                    selectedItem={0}
                >
                    {renderChild("question_attachments")}
                </Carousel>
            );
        }
        return <></>;
    };


    return <>
        <AppLayout className={"consult-detail"}>
            <IconHeader
                title={'1:1 문의 내역'}
                iconLeft={true}
                handleLeftClick={() => {
                    handleGoBack()
                }}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <main id="list-panel">
                {(item && item.id) ?
                    <div className="question">
                        <div className="question-info">

                            <span className={`status ${item.status == "PENDING" ? 'pending' : ''}`}>{item.status == "PENDING" ? '답변 대기' : '답변 완료'}</span>
                            <div className="category">
                                {getCategory(item.type).label}
                            </div>
                        </div>
                        <div className="content-title">
                            <span>{item?.subject}</span>
                        </div>
                        <div className="time">{moment(item.created_at).format("YYYY-MM-DD H:mm")}</div>
                        <div className={`attachments ${item.question_attachments && item.question_attachments.length == 1 ? ' has-one' : ''}`}>
                            {/* {renderAttachment("question_attachments")} */}
                            {renderImageQuestion("question_attachments")}
                        </div>
                        <div className="content">
                            <div className="" dangerouslySetInnerHTML={{ __html: nl2br(item.question, null, null) }} />
                        </div>
                        {item.status == 'COMPLETED' ? <div className="content-answer">
                            <h4>답변</h4>
                            <div className="text-anser" id="text-answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
                            <div className="contact-company">
                                <strong></strong>
                                <span>살구나무집 도시락 고객센터 <br/> 042-527-0306</span>
                            </div>
                            <span className="time-answer">{moment(item.answered_at).format("YYYY-MM-DD H:mm")}</span>
                        </div> : ''}

                        {item.status == "PENDING" &&
                            <div className="action">
                                <span onClick={handleEdit}>수정</span>
                                <span onClick={() => setDialog({ ...dialog, delete: true })}>삭제</span>
                            </div>
                        }
                    </div> :
                    <>
                        {/* <div style={{ padding: '16px', paddingTop: '190px' }}>
                            <CEmptyIcon icon={EmptyIcon} text={"1:1 문의 내역이 없습니다."} />
                        </div> */}
                    </>
                }
                {item.status == 'COMPLETED' ? <div className=" btn-back-page">
                    <button type="submit" onClick={()=>{
                        navigate(RouterName.CONSULT_LIST)
                    }} className="cbtn btn-primary">확인</button>
                </div> : ''}
            </main>

            <CDialog show={dialog.delete} overlay={true}
                closeBtn={"닫기"} submitBtn={"삭제"}
                className={"delete-dialog"}
                handleSubmit={handleDelete}
                handleClose={() => setDialog({ ...dialog, delete: false })}
            >
                <div>1:1 문의를 삭제 하시겠습니까?</div>
            </CDialog>
            {previewUrl &&
                <div className="preview">
                    <div className="overlay"></div>
                    <div className="image" id="preview-image">
                        <img src={previewUrl} alt={""} />
                        <div className="close-btn" id="preview-closeBtn" onClick={() => { setPreviewUrl("") }} style={{ backgroundImage: `url(${closeBtn})` }}></div>
                    </div>

                </div>
            }
        </AppLayout>
    </>
}

export default ConsultDetail;