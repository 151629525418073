import Sample from "./pages/Sample";

const router =  [
    {
        name: "Sample",
        auth: false,
        path: "sample",
        element: <Sample/>
    },
]
export default router
