// import { call, put, takeLatest } from 'redux-saga/effects';
// import { fetchDataSuccess, fetchDataError } from './actions';
// import { FETCH_DATA } from './actions';
// import { Data } from './types';

// function* fetchData() {
//     try {
//         const response: Response = yield call(fetch, 'https://api.example.com/data');
//         let data: Data[];
//         data = yield call([response, 'json']);
//         yield put(fetchDataSuccess(data));
//     } catch (error) {
//         yield put(fetchDataError(error as Error));
//     }
// }


export default function* DashboardSaga() {

}

