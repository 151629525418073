import SkeletonComponent from "core/components/Skeleton/skeleton";

interface GoodLoading {
  className: string;
}

const OrderHistoryLoadingComponent = ({ className }: GoodLoading) => {
  return (
    <div className={`order-history-list-page__content--loading`}>
      <div className="order-history-list-page__content--loading__item">
        <div>
          <label htmlFor="">상품명</label>
          <SkeletonComponent width={200} height={15}/>
        </div>
        <div>
          <label htmlFor="">주문번호</label>
          <SkeletonComponent width={100} height={15}/>
        </div>
        <div>
          <label htmlFor="">결제방법</label>
          <SkeletonComponent width={100} height={15}/>
        </div>
        <div>
          <label htmlFor="">결제금액</label>
          <SkeletonComponent width={100} height={15}/>
        </div>
        <div>
          <label htmlFor="">주문상태</label>
          <SkeletonComponent width={100} height={15}/>
        </div>  
      </div>
      <div className="list__bottom">
          <div className="shopping-cart-btn">
            <div className="d-grid d-grid--disabled">
              <button
                className="cbtn btn-primary"
                disabled
              >
                장바구니 담기
              </button>
            </div>
          </div>
          <div className="item row">
            <div className="col-6 item__left">
              <div className={`d-grid  d-grid--disabled`} >
                <button
                  className="cbtn btn-primary"
                >
                  배송지 변경
                </button>
              </div>
            </div>
            <div className="col-6 item__right">
            <div className={`d-grid  d-grid--disabled`} >
                <button
                  className="cbtn btn-primary"
                >
                  주문 취소
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default OrderHistoryLoadingComponent;
