import CommonPage from "./pages/CommonPage";
import Common2Page from "./pages/Common2Page";
import Common3Page from "./pages/Common3Page";

const router =  [
    {
        name: "COMMON",
        auth: false,
        path: "commons",
        element: <CommonPage/>
    },
    {
        name: "COMMON2",
        auth: false,
        path: "commons2",
        element: <Common2Page/>
    },
    {
        name: "COMMON3",
        auth: false,
        path: "commons3",
        element: <Common3Page/>
    },
]
export default router
