import CDialog from "../../../core/components/CDialog";
import {useEffect, useState} from "react";
import {formatAccountNumber, numberFormat} from "../../../core/utils/helper";

const AccumulateRequestDialog = (props: any) => {
    const [_props, setProps] = useState<any>({
        data: {
            show: true,
            data: {
                // "status": false,
                // "code": 9000,
                // "msg": "출금 가능액이 부족합니다.",
                // "data": null,
                // "errors": {
                //     "msg": "출금은 {min_amount}원 이상부터, {unit_amount}원 단위로 신청 가능합니다.",
                //     "params": {
                //         "min_amount": 10000,
                //         "unit_amount": 10000
                //     }
                // }

                // "status": false,
                // "code": 9001,
                // "msg": "출금 가능액이 부족합니다.",
                // "data": null,
                // "errors": {
                //     "msg": "출금 가능액이 부족합니다."
                // }

                // "status": false,
                // "code": 9002,
                // "msg": "출금 가능액이 부족합니다.",
                // "data": null,
                // "errors": {
                //     "msg": "일일 3만원을 초과하는 금액을 출금하시는 경우 1:1 문의 게시판을 이용해주세요",
                //     "rest_amount": 1000
                // }

                // "status": false,
                // "code": 9003,
                // "msg": "출금 가능액이 부족합니다.",
                // "data": null,
                // "errors": {
                //     "msg": "서비스 이용 후 출금이 가능합니다. \n 지금 바로 도시락을 주문해보세요."
                // }
            }
        }
    })

    useEffect(()=>{
        setProps(props)
    }, [props])

    const renderPopup9000 = () => {
        const {msg, params}: any = _props.data.data.errors;
        console.log(msg,params, 'msg');
        
        let html: string = msg
        params && Object.keys(params).forEach((key) => {
            html  = html.replace(new RegExp(`{${key}}`, "g"), '<strong>'+numberFormat(params[key])+'원</strong>')
        })
        html  = html.replace(/\n/g, '<br/>')
        return <div dangerouslySetInnerHTML={{__html: html}}></div>
    }
    const renderPopup9001 = () => {
        const {msg, params}: any = _props.data.data.errors
        let html: string = msg
        // Object.keys(params).forEach((key) => {
        //     html  = html.replace(new RegExp(`{${key}}`, "g"), '<strong>'+numberFormat(params[key])+'</strong>')
        // })
        return <div dangerouslySetInnerHTML={{__html: html}}></div>
    }
    const renderPopup9002 = () => {
        const {msg, rest_amount}: any = _props.data.data.errors
        let html: string = msg
        return <>
            <div dangerouslySetInnerHTML={{__html: html}}></div>
            <div className="rest_amount">출금 가능액 : <strong>{numberFormat(rest_amount)}원</strong></div>
        </>
    }

    const renderPopupConfirm = () => {
        const formData = _props.data.data.data || {};
        return <>
            <div className="text-left" style={{textAlign: 'left'}}>
                <div className="title text-left">은행명 : {formData?.bank_label}</div>
                <div className="title text-left">예금주 : {formData?.bank_account_name}</div>
                {/* formatAccountNumber(formData?.bank_account_number) */}
                <div className="title text-left">계좌번호 : {formData?.bank_account_number}</div>
                <div className="title text-left">출금액 : {numberFormat(formData?.amount)}</div>
                <div className="title text-left">입력하신 정보로 출금신청이 완료됩니다.</div>
            </div>
        </>
    }

    const renderDialog = () => {
        switch (_props.data.data.code) {
            case 9000:
                return <CDialog
                    id={"request_withdraw_dialog"}
                    className={"request_withdraw_dialog"}
                    show={_props.data.show}
                    handleSubmit={() => props.handleClose()}
                    title={null}
                    closeBtn={null}
                    submitBtn={'확인'}
                    disableSubmitBtn={null}
                    overlay={true}
                >{renderPopup9000()}</CDialog>
                break;
            case 9001:
                return <CDialog
                    id={"request_withdraw_dialog"}
                    className={"request_withdraw_dialog"}
                    show={_props.data.show}
                    handleSubmit={() => props.handleClose()}
                    title={null}
                    closeBtn={null}
                    submitBtn={'확인'}
                    disableSubmitBtn={null}
                    overlay={true}
                >{renderPopup9001()}</CDialog>
                break;
            case 9002:
                return <CDialog
                    id={"request_withdraw_dialog"}
                    className={"request_withdraw_dialog"}
                    show={_props.data.show}
                    handleSubmit={() => props.handleSubmit(9002)}
                    title={null}
                    closeBtn={"닫기"}
                    submitBtn={"게시판 이동"}
                    handleClose={() => props.handleClose()}
                    disableSubmitBtn={null}
                    overlay={true}
                >{renderPopup9002()}</CDialog>
                break;
            case 9003:
                return <CDialog
                    id={"request_withdraw_dialog"}
                    className={"request_withdraw_dialog"}
                    show={_props.data.show}
                    handleSubmit={() => props.handleSubmit(9003)}
                    title={null}
                    closeBtn={"닫기"}
                    submitBtn={"주문하기"}
                    handleClose={() => props.handleClose()}
                    disableSubmitBtn={null}
                    overlay={true}
                >{renderPopup9000()}</CDialog>
                break
            case 202:
                return <CDialog
                    id={"request_withdraw_dialog"}
                    className={"request_withdraw_dialog"}
                    show={_props.data.show}
                    handleSubmit={() => props.handleSubmit(202)}
                    title={null}
                    closeBtn={"닫기"}
                    submitBtn={"확인"}
                    handleClose={() => props.handleClose()}
                    disableSubmitBtn={null}
                    overlay={true}
                >{renderPopupConfirm()}</CDialog>
                break
           default:
                return <CDialog
                    id={"request_withdraw_dialog"}
                    className={"request_withdraw_dialog"}
                    show={_props.data.show}
                    handleSubmit={() => props.handleSubmit(500)}
                    title={null}
                    closeBtn={"확인"}
                    submitBtn={"1:1 문의"}
                    handleClose={() => props.handleClose()}
                    disableSubmitBtn={null}
                    overlay={true}
                >서버와 통신에 실패하였습니다.<br/>
                    1:1 문의 게시판을 이용하거나
                    전화 문의를 해주세요.
                </CDialog>
        }
    }

    return <>{props.data.show && renderDialog()}</>
}

export default AccumulateRequestDialog;