
import 'modules/Address/assets/scss/address.scss'
import AppLayout from "core/layouts/AppLayout";

import PlusIcon from "core/assets/images/icon/plus.svg"
import EmptyIcon from "modules/Address/assets/icon/empty-addr.svg"
import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useLocation, useNavigate} from "react-router-dom";
import AddressItem from "../components/AddressItem";
import {useEffect, useState} from "react";
import CEmptyIcon from "core/components/CEmptyIcon";
import CDialog from "core/components/CDialog";
import API from "../../../core/networks/API";
import {API_CREATE_ADDRESS, API_DELETE_ADDRESS, API_LIST_ADDRESS, API_UPDATE_ADDRESS} from "../api";
import {toast} from "react-toastify"
import { useUpdateAddress } from 'modules/Products/hooks/useAddress';
import { useUpdateReload } from 'modules/Products/hooks/useLists';
import { MESSAGE } from 'core/constants/Commons';
import { useDataPayment, useUpdateDataPayment } from 'modules/Orders/hooks/usePayment';
import InfinityScrollContainer from 'core/layouts/InfinityScrollContainer';
import SkeletonComponent from 'core/components/Skeleton/skeleton';
const AddressBook = (props: any) => {
    const navigate = useNavigate();
    const updateReload = useUpdateReload();
    const [addressData, setAddressData] = useState<any>({
        loading: true,
        total: 0,
        totalPage: 0,
        list: [],
        statusEmptyData:false
    })
    const [handleUpdate, ] = useUpdateDataPayment({})
    const [, dataOrders] = useDataPayment({});
    const onUpdateAddress = useUpdateAddress({});

    const location = useLocation();

    const [selectedItem, setSelectedItem] = useState<any>(null)

    const [filter, setFilter] = useState<any>({
        order_by: 'id',
        order_dir: 'desc',
        page: 1,
        page_size:10

    })

    const handleGoBack = () => {
        if (location?.state?.back_type) {
            navigate(location?.state?.back_url ,{
                state: "goBack"
            })
        }else{
            navigate(-1)
        }
    }
    
    const handleSelectItem = async (item: any) => {
        console.log(31, item);
        console.log('location?.state', location?.state);
        console.log('RouterName.ORDER_HISTORY_DETAIL', RouterName.ORDER_HISTORY_DETAIL);
        
        
        handleUpdate({...dataOrders, shippingAddress: item})
        if (location?.state?.back_type === RouterName.PAYMENT || 
            location?.state?.back_type === RouterName.ORDER_HISTORY_LIST || 
            location?.state?.back_type == RouterName.ORDER_HISTORY_DETAIL
            ) {
            let _data = {
                address_id : item?.id || null,
                order_id: location.state?.order_id || null
                }
            if (onUpdateAddress) {
                onUpdateAddress(_data);
            }
            console.log('onUpdateAddress');
            
            setTimeout(() => {
                navigate(location?.state?.back_url, {
                    state: 'goBack'
                }) 
            }, 100);
        }else{
            callSetDefaultAddressAPI(item.id)
        }
    }

    const handleDeleteItem = (item: any, dialog: boolean = true) => {
        console.log(item, 'item');
        
        if(dialog){
            // console.log(43)
            setSelectedItem(item)
        }else{
            console.log(46)
            callDeleteAddressAPI(item.id)
            setSelectedItem(null)
            handleUpdate({...dataOrders, shippingAddress: {}})
        }
    }

    const handleUpdateItem = (item: any) => {
        navigate(RouterName.ADDRESS_UPDATE.replace(/:id/g, item.id.toString()), {
            state: {
                ...location.state,
                back_type: location?.state?.back_type,
                back_url: location?.state?.back_url
            }
        })
    }

    const handleCreateItem = (e: any) => {
        navigate(RouterName.ADDRESS_CREATE, {
            state: {
                ...location.state
            }
        })
    }

    const callDeleteAddressAPI = async (id: number) => {
        try{
            const res = await API.delete(API_DELETE_ADDRESS.replace(/{ID}/g, id.toString()), {})
            if(res.data.status) fetchData()
        } catch (e) {
            console.log(e)
        }
    }

    const callSetDefaultAddressAPI = async (id: number) => {
        try{
            const res = await API.patch(API_UPDATE_ADDRESS.replace(/{ID}/g, id.toString()), {
                default: 'Y'
            })
            console.log(res.data, 'res.data');
            
            if(res.data.status) {
                // toast.dismiss();
                toast(MESSAGE.ADDRESS_CREATE_SUCCESS)
                if (location?.state?.back_type === RouterName.MAIN || location?.state?.back_type === RouterName.GOODS_LIST) {
                    navigate(location?.state?.back_url)
                }else{
                    navigate(RouterName.MAIN)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const fetchData = async () => {
        try{
            // setAddressData({...addressData, loading: true})
            const res = await API.get(API_LIST_ADDRESS, filter)
            console.log(res)
            // setAddressData({...addressData, loading: false, list: res.data.data?.data})
            setAddressData({
                ...addressData,
                loading: false,
                list: (addressData.list.length >= filter?.page_size && filter.page>1) ? [...addressData.list, ...res.data.data.data]: res.data.data.data,
                total: res.data.data.total,
                totalPage: res.data.data.last_page,
                statusEmptyData:res.data.data.data.length > 0 ? false : true
            })
            if (res.data.data.data.length < 1) {
                handleUpdate({...dataOrders, shippingAddress: {}})
            }
        } catch (e: any) {
            console.error(e)
            // setAddressData({...addressData, loading: false})
        }
    }

    
    const renderAddressList = () => {
        if(addressData.list.length > 0){
            return <AddressItem data={addressData.list}
                                onClick={handleSelectItem}
                                onDelete={handleDeleteItem}
                                onEdit={handleUpdateItem}
                />
        } 
        if(filter?.page == 1){
            return [1,2,3].map((i, k) => <AddressItem data={[]} key={k}
                              onClick={(e: any)=>{}}
                              onDelete={(e: any)=>{}}
                              onEdit={(e: any)=>{}}
                    />)
        }
    }


    const countSize = () => {
        const viewportHeight = window.innerHeight;
        let viewportHeightPage = 0;
        let viewportHeightFixed = localStorage.getItem('height_viewport')
        if(viewportHeightFixed && (parseInt(viewportHeightFixed) != viewportHeight)){
            viewportHeightPage = parseInt(viewportHeightFixed)
        }else {
            viewportHeightPage = viewportHeight
            localStorage.setItem('height_viewport',viewportHeight.toString())
        }
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeightPage - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(()=>{
        let msg_submit_success = localStorage.getItem('msg_submit_success')
        if(msg_submit_success){
            toast(msg_submit_success)
            localStorage.removeItem('msg_submit_success')
        }

        // toast.dismiss();
        countSize()
        // fetchData()

        /* update redux for products page */
        if (location?.state?.back_type !== RouterName.ORDER_HISTORY_LIST) {
            updateReload({reload: 0})
        }
        /* end */
    }, [])

    useEffect(()=>{
        setAddressData({...addressData, loading: true})
        fetchData()
    }, [filter])

    useEffect(()=>{
        countSize()
    }, [addressData])

    const handleLoadMore = () => {
        console.log('ssss')
        if(filter.page < addressData.totalPage && !addressData.loading){
            setFilter({...filter, page: filter.page+1})
        }
    }

    (window as any).backFromDeviceAndroid = function() {
        //code webview
        handleGoBack()
      }

    return (
        <AppLayout className={"address-page"}>
            <IconHeader
                title={'배송지 관리'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            {addressData.list.length > 0 ?
                    <InfinityScrollContainer
                        className="list scroll-container"
                        id={'list-panel'}
                        filter={filter}
                        data={addressData}
                        fetchMoreData={handleLoadMore}
                        bottom={1}
                        loadingElement={<>
                        <div key={1} className="address-item">
                            <div className="row">
                                <div className="col-9">
                                    <SkeletonComponent width={200} count={1} height={20}/>
                                </div>
                                <div className="col-3 text-end">
                
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SkeletonComponent width={300} count={1} height={20}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SkeletonComponent width={250} count={1} height={13}/>
                                    <SkeletonComponent width={250} count={1} height={13}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SkeletonComponent width={120} count={1} height={20}/>
                                </div>
                            </div>
                        </div>
                        </>}
                    >
                        {renderAddressList()}
                    </InfinityScrollContainer> : (addressData?.statusEmptyData ? <div  className="list scroll-container" id={'list-panel'}>
                        <div style={{padding: '16px', paddingTop: '0px'}}>
                            <CEmptyIcon  icon={EmptyIcon} text={"등록된 배달지가 없습니다."} description={"배송지를 추가해주세요."} />
                        </div>
                    </div> : <></>)
                    
            }
            <div className="bottom-btn">
                <div className="d-grid">
                    <button className="cbtn btn-primary" onClick={handleCreateItem}><img src={PlusIcon} alt={""}/> 새 배송지 추가</button>
                </div>
            </div>
            <CDialog show={selectedItem} overlay={true}
                     closeBtn={"취소"} submitBtn={"삭제"}
                     className={"delete-dialog"}
                     handleSubmit={(e:any) => handleDeleteItem(selectedItem, false)}
                     handleClose={() => setSelectedItem(null)}
            >
                <div>이 배송지를 <span style={{color: "red"}}>삭제</span> 하시겠습니까?</div>
            </CDialog>
        </AppLayout>
    )
}


export default AddressBook
