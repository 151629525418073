import { Action } from 'redux';
import { DataPermission} from './types';


export const PERMISSION_APP_REQUEST = 'PERMISSION_APP_REQUEST';

export interface PermissionAppRequestAction extends Action<typeof PERMISSION_APP_REQUEST> {
    payload: DataPermission[];
}

export const permissionAppRequest = (payload: DataPermission[]): PermissionAppRequestAction => ({
    type: PERMISSION_APP_REQUEST,
    payload
});


export type PermissionActionTypes = PermissionAppRequestAction