
const AppLayout = (props: any) => {
    return <>
            <div className={"app-layout " + (props.className || '')}>
                {props.children}
            </div>
        </>

}

export default AppLayout;