import { useEffect } from "react";
import { useOpenBrowser } from "./useOpenBrowser";

interface HyperLinkProps{
  data: string;
  elId: string;
}

export const useHandleHyperlink = ({data, elId}: HyperLinkProps) => {
  
  const handleDirect = useOpenBrowser();
  
  useEffect(() => {
    const handleClick = (event: any) => {
      const tagA = event.target;
      handleDirect(tagA.getAttribute('data-original-href'))
    };
    const container: any = document.getElementById(elId);
    if (container) {
      const targetA = container.querySelectorAll('a[href]');
      targetA.forEach((tagA: any) => {
        const originalHref = tagA.href;
        console.log(originalHref, 'originalHref');
        
        tagA.addEventListener('click', handleClick);
        tagA.href = '#';
        tagA.setAttribute('data-original-href', originalHref);
      });
  
      return () => {
        targetA.forEach((tagA: any) => {
          tagA.removeEventListener('click', handleClick);
          const originalHref = tagA.getAttribute('data-original-href');
          if (originalHref) {
            tagA.href = originalHref;
          }
        });
      };
    }

  }, [data]);

};