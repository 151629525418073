import { useFetchProps } from "core/interface/useFetch";
import API from "core/networks/API";
import { useState, useEffect } from "react";

export type DataResProps = {
  id: string;
  url?: string;
  title?: string;
  to?: string;
  from?: string;
  infinity?: boolean
};

export const useFetch = ({url, options}: useFetchProps) => {
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [productData, setProductData] = useState<any>({
    loading: true,
    total: 0,
    totalPage: 1,
    list: [],
  });
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const resp = await API.get(url !== undefined ? url : '', options);
        if (isMounted) {
          if (options?.infinity) {
            setProductData((p: any)  => ({
              ...p,
              loading: loading,
              list:  options?.page > 1 ? [...p.list, ...resp?.data?.data?.data] : resp?.data?.data?.data || [],
              total: resp?.data?.data?.total || p.total,
              totalPage: resp?.data?.data?.last_page || p.totalPage,
            }));
          }else{
            setData(resp.data);
          }
        }
        setLoading(false);
      } catch (e:any) {
        if (isMounted) setData([]);
        if (isMounted) setError(e?.response?.data || {});
        setLoading(false);
      }
    };

    let isMounted = true;
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [options, url]);
  if (options.infinity) {
    return { productData, loading, error };
  }
  return { data, loading, error };
}