import "modules/Policy/assets/scss/policy.scss";
import AppLayout from "core/layouts/AppLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const DeliveryAndPayment = (props: any) => {
  const dataText = `살구나무집 도시락 배송 및 결제 안내
 
  배송안내
  - 배송방법 : 직접 방문 배달을 기본으로 합니다.
  - 배송지역 : 대전, 세종, 청주 (단 일부 산간벽지나 도서지방 제외)
  - 배송기간 : 구매자 요청 일시 및 시간에  배달 됩니다.
  - 배송비 : 대전(5만원 이상), 세종(20만원 이상), 청주(30만원 이상) 구매시 배송비 무료

  결제안내
  1) 신용카드결제안내
  신용카드 결제를 누르시면 (http://) 서버를 경유하여 결제하기 위한 신용카드 입력 양식이 나타납니다. 이 입력양식에는 자신의 신용카드 종류, 카드번호, 유효기간, 할부개월수를 입력하고 결제요청을 누르면 결제승인결과와 구입내역이 화면에 나타납니다. 이로써 정상적으로 주문과 결제가 끝나게 됩니다.

  신용카드와 관련된 정보는 PG사에서 관리하므로 쇼핑몰사업자는 열람을 할 수가 없으니 안심하고 결제하셔도 됩니다.
  
  2) 무통장 입금
  무통장입금 결제건의경우 주문서 작성시 기재해주신 입금자명과 실제로 입금해주신 입금자명이 반드시 일치하여야 확인처리가 가능합니다.`

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>
    <div>
      {/* <span dangerouslySetInnerHTML={{__html: dataText.replace(/\n/g, "<br/>")}}></span> */}

      <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <strong>
      <span style={{ fontFamily: '"Malgun Gothic",sans-serif' }}>
        살구나무집 도시락 배송 및 결제 안내
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 12,
          lineHeight: "115%",
          fontFamily: '"Malgun Gothic",sans-serif'
        }}
      >
        배송안내
      </span>
    </strong>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif'
      }}
    >
      <br />
      &nbsp;-&nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#4D4D4D"
      }}
    >
      배송방법 :<strong>&nbsp;</strong>직접 방문 배달을 기본으로 합니다.
      <br /> - 배송지역 :<strong>&nbsp;</strong>대전, 세종, 청주 (단 일부
      산간벽지나 도서지방 제외)
      <br />
      &nbsp;- 배송기간 : 구매자 요청 일시 및 시간에 &nbsp;배달 됩니다.
      <br />
      &nbsp;- 배송비 : 대전(5만원 이상), 세종(20만원 이상), 청주(30만원 이상)
      구매시 배송비 무료
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 12,
          lineHeight: "115%",
          fontFamily: '"Malgun Gothic",sans-serif',
          color: "#4D4D4D"
        }}
      >
        결제안내
      </span>
    </strong>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#4D4D4D"
      }}
    >
      <br />
      &nbsp;1) 신용카드결제안내
      <br />
      &nbsp;신용카드 결제를 누르시면 (http://) 서버를 경유하여 결제하기 위한
      신용카드 입력 양식이 나타납니다. 이 입력양식에는 자신의 신용카드 종류,
      카드번호, 유효기간, 할부개월수를 입력하고 결제요청을 누르면 결제승인결과와
      구입내역이 화면에 나타납니다. 이로써 정상적으로 주문과 결제가 끝나게
      됩니다.
      <br />
      &nbsp;신용카드와 관련된 정보는 PG사에서 관리하므로 쇼핑몰사업자는 열람을
      할 수가 없으니 안심하고 결제하셔도 됩니다.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#4D4D4D"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#4D4D4D"
      }}
    >
      2) 무통장 입금
      <br />
      &nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      무통장입금 결제건의경우 주문서 작성시 기재해주신 입금자명과 실제로
      입금해주신 입금자명이 반드시 일치하여야 확인처리가 가능합니다.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      3) 신용카드(현장 결제)
      <br /> 앱을 통해서 상품 주문시 결제 수단 항목에서 “
      <u>신용카드_현장 결제</u>”를&nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      선택
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      &nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      진행 할 경우 주문건은 가맹점주 확인을&nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      통해
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      &nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      주문이 진행되며, 현장에 배송 상품 확인과 동시에 단말기를 통한 결제가
      이루어집니다.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      lineHeight: "115%",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      marginTop: "12.0pt",
      marginRight: "0in",
      marginBottom: "12.0pt",
      marginLeft: "0in"
    }}
  >
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535"
      }}
    >
      4) 신용카드(수기 결제)
      <br />
      &nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      앱을 통해서 상품 주문시 결제 수단 항목에서 “<u>신용카드_수기 결제</u>
      ”를&nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      선택
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      &nbsp;
    </span>
    <span
      style={{
        fontSize: 12,
        lineHeight: "115%",
        fontFamily: '"Malgun Gothic",sans-serif',
        color: "#353535",
        background: "white"
      }}
    >
      진행 할 경우 결제할 신용카드의 번호와 유효기간을 받아 정보를 입력합니다.
      할부이용 시 할부 개월수를 입력합니다. 구매하기 버튼을 누르면 결제가
      완료됩니다.
    </span>
  </p>
  <p>
    <br />
  </p>

    </div>
  </>
};

export default DeliveryAndPayment;