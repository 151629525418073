import { useEffect, useState } from "react";
import BankSelect from "core/components/BankSelect";
import BottomSwiper from "core/components/BottomSwiper";
import CFormControl from "core/components/form-controls/CFormControl";
import CRadiobox from "core/components/form-controls/CRadiobox";
import CTextArea from "core/components/form-controls/CTextArea";
import DropdownIcon from "../assets/icon/dropdown.svg";
import { NumericFormat } from "react-number-format";
interface OrderCancelProps {
  data: any;
  dataReason: any;
  sendDataStep1: Function;
  orderDetail: any;
}

const OrderCancelStep1 = ({
  data,
  dataReason,
  sendDataStep1,
  orderDetail
}: OrderCancelProps) => {
  const [dataStep1, setDataStep1] = useState<any>({
    reason: data?.reason || "",
    id: data.id,
    content: data.content,
  });
  
  const [infoRefund, setInfoRefund] = useState<any>({
    bank_id: data?.bank_id || "",
    bank_name: data?.bank_name || "",
    bank_account_name: data?.bank_account_name || "",
    bank_account_number: data?.bank_account_number || "",
  })
  const [swipper, setSwipper] = useState({
    bankSelect: false,
    isActive: false,
  });

  const handleChangeCancel = (item: any) => {
    setDataStep1((p: any) => ({
      ...p,
      ...item,
      content: ""
    }));
  };

  const handleChangeContent = (e: any) => {
    let content = e.target.value;
    if (content.length > 500) return;
    setDataStep1((p: any) => ({
      ...p,
      content,
    }));
  };

  useEffect(() => {
    if (sendDataStep1) {
      sendDataStep1({...dataStep1, ...infoRefund});
    }
  }, [dataStep1, infoRefund]);

  const handleChange = (e: any) => {
    if (+e.target.value.length > 30) return;
    setInfoRefund({
      ...infoRefund,
      [e.target.name]: e.target.value
    })
  };

  const handleSelectBank = () => {
    setSwipper({ ...swipper, bankSelect: true, isActive: true });
  };

  const handleShow = () => {
    if (swipper.isActive) {
      setSwipper({
          isActive: true,
          bankSelect: false
      })
    }
  };

  useEffect(() => {
    if (swipper.bankSelect) {
      setSwipper({
        ...swipper,
        isActive: true,
      });
    }
  }, [swipper.bankSelect]);

  const handleBankSelect = (item: any) => {
    setInfoRefund({
      ...infoRefund,
      bank_id: item.id,
      bank_name: item.name,
    })
    setSwipper({ ...swipper, bankSelect: false });
  };
  return (
    <div className="step1">
      <div className="reason-cancel">
        <label htmlFor="">취소 사유를 선택해주세요.</label>
        <div className="option-cancel">
          {(dataReason || []).map((item: any) => (
            <div className="option-cancel__item" key={item.id}>
              <CFormControl type={"empty"}>
                <CRadiobox
                  disabled={false}
                  name={"reason"}
                  checked={dataStep1.id === item.id}
                  handleChange={() => handleChangeCancel(item)}
                  // label={item.reason}
                />
                <label
                  htmlFor=""
                  className={`reason-content reason-content--${
                    dataStep1.id === item.id ? "checked" : ""
                  }`}
                  onClick={() => handleChangeCancel(item)}
                >
                  {item.reason}
                </label>
              </CFormControl>
            </div>
          ))}
        </div>
      </div>
      {dataStep1.reason === "직접입력" && (
        <div className="reason-detail">
          <label htmlFor="" className="title">
            내용 <span>*</span>
          </label>
          <CFormControl type={"empty"}>
            <CTextArea
              rows={6}
              placeholder={"문의 내용을 입력해주세요."}
              maxLength={500}
              name={"content"}
              disabled={dataStep1.reason !== "직접입력"}
              value={dataStep1.content}
              handleChange={handleChangeContent}
            />
          </CFormControl>
          
        </div>
      )}

      {
        orderDetail?.payment_status === "COMPLETED" && orderDetail?.payment_method === "MONEY" && <div className="refund-account">
        <p className="refund-account__title">
          <label htmlFor="">환불 계좌를 선택해주세요.</label>
        </p>

        <div className="row account-info">
          <div className="col-12">
            <label htmlFor="">
              은행명<span>*</span>
            </label>
            <div className="c-form-control">
              <div className="c-input-wrapper dropdown-bank-custom">
                <input
                  placeholder={"은행 선택"}
                  type="text"
                  maxLength={30}
                  value={infoRefund.bank_name || ''}
                  name={"bankName"}
                  readOnly
                  onClick={() => handleSelectBank()}
                />
                <img className="icon-dropdown" src={DropdownIcon} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="row account-info">
          <div className="col-12">
            <label htmlFor="">
              예금주<span>*</span>
            </label>
            <div className="c-form-control">
              <div className="c-input-wrapper">
                <input
                  placeholder={"예금주 명을 입력 하세요."}
                  type="text"
                  maxLength={30}
                  value={infoRefund?.bank_account_name || ''}
                  name={"bank_account_name"}
                  onChange={handleChange}
                  autoComplete={"one-time-code"}
                  autoCorrect={"one-time-code"}
                />
              </div>
            </div>
          </div>
        </div>
{/* 
        <div className="row account-info">
          <div className="col-12">
            <label htmlFor="">
              계좌번호<span>*</span>
            </label>
            <CFormControl
              placeholder={"계좌번호를 - 없이 입력하세요."}
              type={"number"} required={true} disabled={false} readOnly={false}
              name={'bank_account_number'} className={""}
              pattern={"[0-9]"}
              // error={formData.errors['bank_account_number']}
              maxLength={30}
              value={infoRefund.bank_account_number || ''}
              handleChange={handleChange}
          />
          </div>
        </div> */}

          <div className="c-form-control">
            <label className="c-label">계좌번호 <span className="required">*</span></label>
            <div className="c-input-wrapper">
                <NumericFormat 
                    inputMode="decimal"
                    allowLeadingZeros
                    placeholder="계좌번호를 – 없이 입력하세요."
                    allowNegative={false}
                    value={infoRefund.bank_account_number || null}
                    isAllowed={(values: any) => {
                        const { formattedValue, floatValue }: any = values;
                        if (formattedValue.includes(".")) {
                          return false; 
                        }
                        return formattedValue === "" || formattedValue.length <= 30;
                    }}  
                    name="bank_account_number"  
                    onChange={handleChange}
                    decimalSeparator=''
                />
            </div>
        </div>

      </div>
      }

      <BottomSwiper
        title={"은행을 선택해주세요"}
        suggest={"입금 받을 은행명을 선택해주세요."}
        className={"bottom-swiper--bank-select"}
        isActive={swipper.isActive}
        isOpen={swipper.bankSelect}
        onSlideDown={handleShow}
      >
        <BankSelect onSelect={handleBankSelect} className={""} key={""} />
      </BottomSwiper>
    </div>
  );
};

export default OrderCancelStep1;
