
import SettingMenuPage from "./pages/SettingMenuPage";
import SettingPushNoticePage from "./pages/SettingPushNoticePage";
import DeleteMemberPage from "./pages/DeleteMemberPage";
import React from "react";

const router =  [
    {
        name: "SETTING_MENU",
        auth: true,
        path: "/setting-menu",
        element: <SettingMenuPage/>
    },
    {
        name: "TERMS_POLICY",
        auth: false,
        path: "/terms-policy",
        element: <SettingMenuPage />,
    },
    {
        name: "SETTING_PUSH_NOTICE",
        auth: false,
        path: "/setting-push-notice",
        element: <SettingPushNoticePage />,
    },
    {
        name: "DELETE_MEMBER",
        auth: true,
        path: "/delete-member",
        text:'회원 탈퇴',
        element: <DeleteMemberPage />
    }
]
export default router
