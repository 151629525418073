import CEmptyIcon from "core/components/CEmptyIcon";
import { RouterName } from "core/router";
import { formatDateTime, getPaymentStatus, getProductNamePayment, numberFormat } from "core/utils/helper";
import { useNavigate } from "react-router-dom";
import OrderHistoryEmptyIcon from "../assets/icon/order_history_empty.svg";
import ArrowRight from "./../assets/icon/arrow_right.svg";
import { OrderButtonGroupt } from "./OrderButtonGroupt";
import { PAYMENT_METHOD } from "core/constants/Commons";

interface ListProps {
  filter: any;
  data: any;
  handleUpdateAddress: Function,
  goDetail: Function
}

const OrderHistoryListComponent = ({ data, handleUpdateAddress, filter, goDetail }: ListProps) => {
  const navigate = useNavigate();

  const goToOrderDetail = (item: any) => {
    goDetail(item)
  };


  const getPaymentMethod: any = (data: any) => {
    let method: any;
     PAYMENT_METHOD.filter((item: any) => {
        if (item.value === data) {
            method = item.label;
        }
    })
    return method
  }

  // CARD
  // BANK
  // MONEY


  // chờ thanh toán => status=NEW, 
  // chờ chuyển tiền => payment_status=PENDING, 
  // hoàn tất thanh toán, 
  // tiếp nhận order, 
  // chuẩn bị giao hàng, 
  // đang giao hàng, 
  // hoàn tất giao hàng, 
  // hủy order


// - Chờ thanh toán|chờ chuyển tiền  => status=NEW và payment_status=PENDING
// - Hoàn tất thanh toán => status=NEW và payment_status=COMPLETED
// - Huỷ order => status=CANCELED_COMPLETED hoặc CANCELED_BY_USER hoặc CANCELED_BY_SYSTEM
// - tiếp nhận order => status=APPROVED
// - Chuẩn bị giao hàng => status=PROCESSED
// - đang giao hàng => status=DELIVERING
// - Hoàn tất => COMPLETED

  
  const renderOrderHistory = (data: any) => {
    if (data && data?.data && data?.data.length > 0) {
      return data?.data.map((item: any, idx: number) => (
        <div className="list" key={idx}>
          <div className="list__header" onClick={() => goToOrderDetail(item)}>
            <div className="list__header__left">
              <span className="date-time">{formatDateTime(item.created_at, 'Y-MM-DD HH:mm')}</span>
            </div>
            <div
              className="list__header__right"
              
            >
              <span className="order-detail-text">주문상세</span>
              <img src={ArrowRight} alt="" />
            </div>
          </div>
          <div className="list__body" onClick={() => goToOrderDetail(item)}>
            <div className="item">
              <div className="item__left">
                <span className="title">상품명</span>
              </div>
              <div className="item__right item__right--update-product">
                <label className="content content--update-product" dangerouslySetInnerHTML={{__html: getProductNamePayment(item?.items)}}></label>
              </div>
            </div>
            <div className="item">
              <div className="item__left">
                <span className="title">주문번호</span>
              </div>
              <div className="item__right">
                <span className="content">{item?.id}</span>
              </div>
            </div>
            <div className="item">
              <div className="item__left">
                <span className="title">결제방법</span>
              </div>
              <div className="item__right">
                <span className="content">{getPaymentMethod(item.payment_method)}</span>
              </div>
            </div>
            <div className="item">
              <div className="item__left">
                <span className="title">결제금액</span>
              </div>
              <div className="item__right">
                <span className="amount">{`${numberFormat(item.amount)} 원`}</span>
              </div>
            </div>
            <div className="item">
              <div className="item__left">
                <span className="title">주문상태</span>
              </div>
              <div className="item__right">
                <div className={`button-status button-status--${getPaymentStatus(item.payment_method, item.payment_status, item.status, 'classname')}`}>
                  <span>{getPaymentStatus(item.payment_method, item.payment_status, item.status)}</span>
                </div>
              </div>
            </div>
          </div>
          <OrderButtonGroupt data={item || []} handleUpdateAddress={handleUpdateAddress} url={RouterName.ORDER_HISTORY_LIST}/>
        </div>
      ));
    } else {
      if (data.totalPage === 1) {
        return (
          <div className="order-history-empty">
            <div>
            <CEmptyIcon
              className={""}
              icon={OrderHistoryEmptyIcon}
              text={""}
              description={`${filter.time_option}간의 상품 주문내역이 없습니다.`}
            />
             <div className="d-grid mt-2">
              <button
                className="cbtn btn-primary"
                onClick={() => navigate(RouterName.GOODS_LIST)}
              >
                상품 보러가기
              </button>
            </div>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <>
      {renderOrderHistory(data)}
    </>
  );
};

export default OrderHistoryListComponent;
