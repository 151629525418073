import CAccoditionItem from "core/components/CAccoditionItem";
import CartItemComponent from "./CartItemComponent";
import { getProductNamePayment } from "core/utils/helper";
import { useDeleteCarts, useUpdateCart } from "../hooks/useCarts";
import ModalOrderComponent from "./ModalOrderComponent";
import { useState } from "react";
import { useUpdateDataPayment } from "../hooks/usePayment";
import { useNavigate } from "react-router-dom";
import { RouterName } from "core/router";

interface OrderInformation {
  dataOrders: any;
  carts: any;
  onIncreaseQuatity?: (data: any) => void;
  onDecreaseQuatity?: (data: any) => void;
  onShowKeyBoard?: (data: any) => void;
  onRemoveProduct?: (data: any) => void;
}

  const OrderListComponent = ({ dataOrders, carts }: OrderInformation) => {
  
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [dataCartSelected, setDataCartSelected] = useState<any>({});
  const [handleUpdate, handleReset] = useUpdateDataPayment({})
  const navigate = useNavigate();
  const dataProducts = dataOrders.type === "DETAIL_PAYMENT" ? dataOrders.dataProduct : carts
  const [, onIncrease, onDecrease, onUpdate] = useUpdateCart({});

  console.log('OrderListComponent');
  
  const _onSuccess = (data: any) => {
    console.log(data, '_onSuccess');
    
    if (dataOrders.type !== 'DETAIL_PAYMENT') {
      if (data.length < 1) {
        if (handleReset) {
          handleReset({})
        }
        navigate(RouterName.CARTS_LIST, {
          state:{
            back_type: RouterName.PAYMENT,
            back_url: RouterName.MAIN
          }
        });
      }else{
        let isSelected = data.filter((item: any) => item.isSelected === true);
        console.log(isSelected, 'isSelected');
        if (isSelected.length <= 0) {
          navigate(RouterName.CARTS_LIST, {
            state:{
              back_type: RouterName.PAYMENT,
              back_url: RouterName.MAIN
            }
          });
        }
      }
    }
  }

  const _onFailed = () => {}

  const [loadingDel, onDelete] = useDeleteCarts({onSuccess: _onSuccess, onFailed: _onFailed});

  const increaseQuantity = (data: any) => {
    if (dataOrders.type === 'DETAIL_PAYMENT') {
      let _data = [
        {
          ...dataOrders.dataProduct[0],
          quantity: +data.quantity,
          qty: +data.quantity,
          priceByQuantity: +data.quantity * dataOrders.dataProduct[0].prod_price
        }
      ]
      // setDataProduct(_data);
      if (handleUpdate) {
        handleUpdate({
          ...dataOrders,
          dataProduct: _data
        })
      }
    }else{
      if (onIncrease) {
        onIncrease(data);
      }
    }
  };

  const decreaseQuantity = (data: any) => {
    if (dataOrders.type === 'DETAIL_PAYMENT') {
      let _data = [
        {
          ...dataOrders.dataProduct[0],
          quantity: +data.quantity,
          qty: +data.quantity,
          priceByQuantity: +data.quantity * +dataOrders.dataProduct[0].prod_price
        }
      ]
      // setDataProduct(_data);
      if (handleUpdate) {
        handleUpdate({
          ...dataOrders,
          dataProduct: _data
        })
      }
    }else{
      if (onDecrease) {
        onDecrease(data);
      }
    }
  };

  const onTypingModal = (data: any, quantity: number) => {
    if (dataOrders.type === 'DETAIL_PAYMENT') {
      let _data = [
        {
          ...dataOrders.dataProduct[0],
          quantity: +quantity,
          qty: +quantity,
          priceByQuantity: +quantity * +dataOrders.dataProduct[0].prod_price
        }
      ]
      // setDataProduct(_data);
      if (handleUpdate) {
        handleUpdate({
          ...dataOrders,
          dataProduct: _data
        })
      }
    }else{
      if (onUpdate) {
        let dataUpdate = { ...data, quantity };
        onUpdate(dataUpdate);
      }
    }
  };

  const handleShowKeyBoard = (data: any) => {
    setDataCartSelected({ ...data.dataItem, index: data.index });
    setIsShowModal(true);
  };

  const removeCart = (value: any) => {
    
    if (dataOrders.type === 'DETAIL_PAYMENT') {
      if (handleReset) {
        handleReset({})
      }
      navigate(RouterName.CARTS_LIST, {
        state:{
          back_type: RouterName.PAYMENT,
          back_url: RouterName.MAIN
        }
      });
    }else{
      if (onDelete) {
        onDelete(value);
      }
    }

  };
  
  const handleStatus = (value: boolean) => {
    setIsShowModal(value);
  };

  const addCart = () => {};

  const contentProducts = <div className="order-list-accodition-content">
    <div className={`list ${dataOrders?.orderId ? 'disabled' : ''}`}>
      <CartItemComponent 
        cartList={dataProducts} 
        isCheckbox={false} 
        removeCart={removeCart}
        increaseQuatity={increaseQuantity}
        decreaseQuatity={decreaseQuantity}
        handleShowKeyBoard={handleShowKeyBoard}
        />
    </div>
  </div>

  return (
    <>
    <CAccoditionItem
      id={2}
      className={"CAccoditionItem order-list-accodition"}
      question={"주문목록"}
      answer={contentProducts}
      key={2}
      isShow={!dataOrders?.orderId}
      type={2}
      title={<label htmlFor="" dangerouslySetInnerHTML={{__html: getProductNamePayment(dataProducts)}}></label>}
      description={"* 배달시간은 당일 전달 상황에 따라 다소 변동이 있을 수 있습니다."}
    />
    
    <ModalOrderComponent
        info={dataCartSelected}
        isShow={isShowModal}
        handleStatusOrder={handleStatus}
        title={dataCartSelected.prod_name}
        addCart={addCart}
        isShowPayment={false}
        onIncreaseModal={increaseQuantity}
        onDecreaseModal={decreaseQuantity}
        onTypingModal={onTypingModal}
        className={"bottom-swiper--keyboard"}
      />
    </>
  );
};

export default OrderListComponent;
