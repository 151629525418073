import {useEffect, useState, FunctionComponent, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import 'modules/Auth/assets/scss/forgot_pw.scss'
import AppLayout from "core/layouts/AppLayout";
import CFormControl from "core/components/form-controls/CFormControl";
import {detectPlatformIos, hideBottomButtonInputFocus, phoneNumberFormat, validateNumber, validatePassword} from "core/utils/helper";
import IconHeader from "core/components/header/IconHeader";
import VerifyInput from "core/components/form-controls/VerifyInput";
import CodeVerifyInput from "core/components/form-controls/CodeVerifyInput";
import API from "core/networks/API";

import {API_CHANGE_PASSWORD, API_REQUEST_OTP, API_VERIFY_OTP, API_VERIFY_PHONE} from "modules/Auth/api";


import {RouterName} from "core/router";
import CToast from "core/components/CToast";
import {toast} from "react-toastify";
import CPassword from "../../../core/components/form-controls/CPassword";
import showIcon from "../../../core/assets/images/icon/show.svg";
import CDialog from "../../../core/components/CDialog";
import moment from "moment";
import { useIdleTime, useTimeResume } from "../hooks";
import { updateIdleTime, updateTimeResume } from "../store/actions";

const ForgotPwPage: FunctionComponent = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [stateForgotPwForm, setStateForgotPwForm] = useState({
        loading:false,
        statusSubmit:false,
        data:{
            phone: "",
            code: "",
            password: "",
            password_confirmation: "",
            referral_code: "",
        },
        stateCountDownAgain:1,
        textBtnVerifyPhone:'인증받기',
        errors:{},
        timeCountDownSendOtp:180
    })

    const [stateError, setStateError] = useState({state: false, text: ''})
    const [stateShowInputCode, setStateShowInputCode] = useState(false)
    const [timeCountDownSendOtp, setTimeCountDonwSendOtp] = useState(180)
    const [stateEnableInputPassword, setStateEnableInputPassword] = useState(false)
    const [stateCountDownEnd, setStateCountDownEnd] = useState(false)
    const [stateInTimeCountDown, setStateInTimeCountDown] = useState(false)
    const [stateDialog, setStateDialog] = useState(false)
    const [timeDefault, setTimeDefault] = useState('03:00')
    const [phoneSubmit, setPhoneSubmit] = useState<any>('')
    const [timestampOffApp, setTimestampOffApp] = useState<any>(null)


    const timeOffDevice = useIdleTime()
    const timeResume = useTimeResume()

    useEffect(() => {
        hideBottomButtonInputFocus()
        dispatch(updateIdleTime(null))
    
    }, [])

    useEffect(() => {
        if(timeResume != null && timeCountDownSendOtp > 0 && timestampOffApp != null && detectPlatformIos()){
        
            let totalSeconds = timeCountDownSendOtp - Math.floor(timeResume?.idleTime / 1000) + 2

            let totalSecondss = convertTimeToSecond(moment(timeResume?.timestamp).format("HH:mm:ss")) - 
            convertTimeToSecond(moment(timestampOffApp).format("HH:mm:ss"))

            if(totalSeconds > 0 && !stateEnableInputPassword && stateShowInputCode){
                let minutes = Math.floor(totalSeconds / 60);
                let seconds = totalSeconds % 60;
                setTimeDefault('0'+minutes+':'+(seconds < 10 ? '0'+seconds : seconds))
                setTimeCountDonwSendOtp(totalSeconds)
                setStateForgotPwForm((p: any) => ({
                    ...p,
                    stateCountDownAgain:stateForgotPwForm.stateCountDownAgain + 1
                }))
            }
            
        }
        
        // @ts-ignore
    }, [timeResume])

    useEffect(() => {
        if(timeOffDevice?.timestamp != undefined){
            // toast.dismiss();
            // toast(timeOffDevice?.timestamp)
            setTimestampOffApp(timeOffDevice?.timestamp)
        }
        


    }, [timeOffDevice])

 // @ts-ignore
    window.nativeWebviewResume = function (data:any) {
        try{
            console.log("nativeWebviewResume", data)
            dispatch(updateTimeResume(data))
        } catch (e) {
            console.log("nativeWebviewResume", e)
        }
    }

    // @ts-ignore
    window.nativeWebviewIdle = function (data:any) {
        try{
            console.log("nativeWebviewResume", data)
            dispatch(updateIdleTime(data))
        } catch (e) {
            console.log("nativeWebviewResume", e)
        }
    }

    useEffect(() => {
        if (stateForgotPwForm.data.phone && stateForgotPwForm.data.password &&
            stateForgotPwForm.data.password_confirmation) {
            setStateForgotPwForm({
                ...stateForgotPwForm,
                statusSubmit:true
            })
        } else {
            setStateForgotPwForm({
                ...stateForgotPwForm,
                statusSubmit:false
            })
        }

    }, [stateForgotPwForm.data])

    useEffect(()=>{
        if(stateError?.state){
            toast.dismiss();
            toast(stateError?.text)
            setStateError({state: false, text: ''})
        }
        // eslint-disable-next-line
    }, [stateError?.state])

    useEffect(()=>{
        if(stateCountDownEnd){
            setStateError({
                state: true, text: '인증번호 시간이 만료되었습니다.\n' +
                    '인증번호를 재 전송 받아주세요.\n'
            })
        }
        // eslint-disable-next-line
    }, [stateCountDownEnd])

    const checkEnabledBtnSubmit = () => {
        let stateCheckError = {state: false, text: ''}
        var patternPassword = /^[a-zA-Z0-9]*$/;
        
        
        if (stateForgotPwForm.data.password.length < 8 || stateForgotPwForm.data.password.length > 15) {
            stateCheckError.state = true
            stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
            return stateCheckError
        }
        if (stateForgotPwForm.data.password.match(patternPassword) == null) {
            stateCheckError.state = true
            stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
            return stateCheckError
        }
        if (patternPassword.test(stateForgotPwForm.data.password)) {
            let patternText = /^[a-zA-Z]*$/;
            let patternNumber = /^[0-9]*$/;
            if(patternText.test(stateForgotPwForm.data.password)){
                stateCheckError.state = true
                stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
                return stateCheckError
            }
            if(patternNumber.test(stateForgotPwForm.data.password)){
                stateCheckError.state = true
                stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
                return stateCheckError
            }
        }
        if (stateForgotPwForm.data.password_confirmation != stateForgotPwForm.data.password) {
            stateCheckError.state = true
            stateCheckError.text = '비밀번호가 일치하지 않습니다.\n' +
                '다시 확인해주세요.\n'
            return stateCheckError
        }
        return stateCheckError
    }

    const handleInputChange = (e: any) => {
        setStateError({state: false, text: ''})
        const target = e.target;
        var value = target.value;
        const name = target.name;
        let valueValidate = value
        switch(name) {
            case 'phone':
                valueValidate = valueValidate.replaceAll('-', '')
                // valueValidate = validateNumber(valueValidate)
                break;
            case 'code':
                valueValidate = validateNumber(valueValidate)
                break;
            default:
                valueValidate = validatePassword(valueValidate)
                break;

        }
        if(!valueValidate){
            if(value != ''){
                return false
            }
        }
        if(name == 'phone'){
            valueValidate = phoneNumberFormat(valueValidate.substring(0, 11))
        }
        setStateForgotPwForm({
            ...stateForgotPwForm,
            data:{
                ...stateForgotPwForm.data,
                [name]:valueValidate
            }
        })

    }
    const handleToPageLogin = (e: any) => {
        navigate('/login')
    }

    const convertTimeToSecond = (time:any) => {
        let seconds = moment(time, 'HH:mm:ss:').diff(moment().startOf('day'), 'seconds');
        return seconds
    };


    const handleSendOTP = async () => {
        let dataRequestOtp = {
            action: 'FIND_PW',
            phone: stateForgotPwForm.data.phone.replaceAll('-', '')
        }
        
        try {
            const {data: response} = await API.post(`${API_REQUEST_OTP}`, dataRequestOtp)
            if(response && response?.code == 200){
                setTimeDefault('03:00')
                setTimeCountDonwSendOtp(180)
                setStateCountDownEnd(false)
                setStateInTimeCountDown(true)
                setStateShowInputCode(true)
                hideBottomButtonInputFocus()
                setStateForgotPwForm((p: any) => ({
                    ...p,
                    textBtnVerifyPhone:'재 발송',
                    stateCountDownAgain:p.stateCountDownAgain + 1,
                    data:{
                        ...p.data,
                        code:''
                    }
                }))
            }
        } catch (e:any) {
            let error = e?.response
            setStateDialog(true)
        }

    }
    
    const handleVerifyPhoneNumber = async () => {

        if(!stateForgotPwForm.data.phone){
            setStateError({
                state: true, text: '휴대전화 번호를 입력해주세요.\n'
            })
            return false
        }
        if(stateForgotPwForm.data.phone &&
            stateForgotPwForm.data.phone.length >= 3 &&
            !stateForgotPwForm.data.phone.substring(0,3).includes('010')){
            setStateError({
                state: true, text: '올바르지 않은 휴대전화 번호입니다.\n' +
                    '‘010’으로 시작되는 번호를 입력해주세요.\n'
            })
            return false
        }
        let dataVerifyPhone = {
            field: 'phone',
            action: 'FIND_PW',
            value: stateForgotPwForm.data.phone.replaceAll('-', '')
        }
        if(stateInTimeCountDown && stateForgotPwForm.data.phone == phoneSubmit){
            setStateError({
                state: true, text: '인증번호 재 전송은 3분 후에 다시 시도해주세요.'
            })
            return false
        }
    
    
        try {
            const {data: response} = await API.post(`${API_VERIFY_PHONE}`, dataVerifyPhone)
            if(response && response?.code == 200){

                setPhoneSubmit(stateForgotPwForm.data.phone)
                handleSendOTP()
                
            }
        } catch (e:any) {
            let error = e?.response
            if(error && error?.status == 422){
                setStateError({state: true, text: '가입되지 않은 휴대전화 번호입니다.\n'})
                setStateForgotPwForm({...stateForgotPwForm,stateCountDownAgain:stateForgotPwForm.stateCountDownAgain+1})
            }
        }

    }

    const handleVerifyOTP = async (e: any) => {
        setStateError({
            state: false, text: ''
        })

        if (stateCountDownEnd) {
            setStateError({
                state: true, text: '인증번호 시간이 만료되었습니다.\n' +
                    '인증번호를 재 전송 받아주세요.\n'
            })
            return false
        }
        if (!stateForgotPwForm.data.code) {
            setStateError({
                state: true, text: '인증번호를 입력해주세요.'
            })
            return false
        }
        let dataVerifyOtp = {
            code: stateForgotPwForm.data.code,
            phone: stateForgotPwForm.data.phone.replaceAll('-', '')
        }
        try {
            const {data: response} = await API.post(`${API_VERIFY_OTP}`, dataVerifyOtp)
            if(response && response?.code == 200){
                setStateEnableInputPassword(true)
            
            }
        } catch (e:any) {
            let error = e?.response;
            if (error && error?.status === 400) {

                setStateError({
                    state: true, text: '잘못된 인증번호입니다.\n' +
                        '인증번호를 다시 확인해주세요.\n'
                })
            }
        }
    }
    const handleSubmitForm = async (e: any) => {
        e.preventDefault();
        if (checkEnabledBtnSubmit().state) {
            setStateError(checkEnabledBtnSubmit())
            return false
        }
        setStateForgotPwForm({
            ...stateForgotPwForm,
            statusSubmit:false
        })
        let dataChangePassword = {
            phone: stateForgotPwForm.data.phone.replaceAll('-', ''),
            password: stateForgotPwForm.data.password,
            password_confirmation: stateForgotPwForm.data.password_confirmation
        }
        try {
            const {data: response} = await API.put(`${API_CHANGE_PASSWORD}`, dataChangePassword)
            if(response && response?.code == 200){
                localStorage.setItem('find-id-success','true');
                navigate(RouterName.LOGIN)
            }

        } catch (e:any) {
            setStateForgotPwForm({
                ...stateForgotPwForm,
                statusSubmit:true
            })
            let error = e?.response;
            if (error && error?.status === 400) {
                console.log('errhandleVerifyOTP')
            }
        }

    }
    const handleTimeEnd = (e: any) => {
        console.log(e, 'handleTimeCountDownEnd',e && e == '00:00')
        if (e && e == '00:00') {
            setStateCountDownEnd(true)
            setStateInTimeCountDown(false)
        }
    }

    const updateTimeCountDown = (data:any) =>{
        // console.log(data,'data')
        setTimeCountDonwSendOtp(data)
    }

    

    return (
        <AppLayout className={'forgot-pw-page'}>
            <IconHeader
                title={'비밀번호 찾기'}
                iconLeft={true}
                handleLeftClick={handleToPageLogin}
                handleRightClick={() => {
                }}
                iconRight={false}
            />
            <div className={'forgot-pw-page__main'}>
                <form className={'forgot-pw-page__main__form'} onSubmit={handleSubmitForm}>
                    <div className={`form-verify-code ${stateEnableInputPassword ? ' disable' : ''}`}>
                        <CFormControl label={"아이디"} desc={""} type={"empty"} required={true}
                                      className={'verify-input-comp'}>
                            <VerifyInput
                                placeholder={"아이디(휴대전화 번호)를 입력해주세요."}
                                button={stateForgotPwForm?.textBtnVerifyPhone}
                                handleChange={handleInputChange}
                                handleSubmit={handleVerifyPhoneNumber}
                                name={'phone'}
                                value={stateForgotPwForm.data?.phone}
                                className={'group-input-phone'}
                                maxLength={13}
                                format={'phone'}
                                buttonDisabled={stateForgotPwForm?.data?.phone && (stateForgotPwForm?.data?.phone.length == 13) ? false : true}
                                
                            />
                            {stateShowInputCode ? (<CodeVerifyInput
                                placeholder={'인증번호를 입력해주세요.'}
                                button={"확인"}
                                countdown={timeCountDownSendOtp}
                                handleChange={handleInputChange}
                                handleSubmit={handleVerifyOTP}
                                value={stateForgotPwForm.data?.code}
                                name={'code'}
                                handleTimeEnd={handleTimeEnd}
                                maxLength={4}
                                // pattern={''}
                                count={stateForgotPwForm?.stateCountDownAgain}
                                timeSetDefault={timeDefault}
                                stopTimer={stateEnableInputPassword}
                                updateTimeCountDown={updateTimeCountDown}
                            />) : ''}
                        </CFormControl>
                    </div>
                    <div className={'form-password'}>
                        <CPassword
                            disabled={!stateEnableInputPassword}
                            label={"새 비밀번호"}
                            desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."}
                            placeholder={"새로운 비밀번호를 입력해주세요."}
                            name={'password'}
                            required={true}
                            handleChange={handleInputChange}
                            showPassword={false}
                            value={stateForgotPwForm.data?.password}
                            maxLength={15}
                            handleShowHideStatePassword={()=>{}}
                            showIcon={false}
                        />
                        <CPassword
                            disabled={!stateEnableInputPassword}
                            label={"비밀번호 확인"}
                            desc={""}
                            placeholder={"비밀번호를 다시 한 번 입력해주세요."}
                            name={'password_confirmation'}
                            required={true}
                            handleChange={handleInputChange}
                            showPassword={false}
                            value={stateForgotPwForm.data?.password_confirmation}
                            maxLength={15}
                            handleShowHideStatePassword={()=>{}}
                            showIcon={false}
                        />
                    </div>
                    <div className="bottom-btn ">
                    <div className="d-grid ">
                        <button type="submit" disabled={!stateForgotPwForm.statusSubmit} className="cbtn btn-primary">완료</button>
                    </div>
                    </div>
                </form>
            </div>
            <CDialog
                id={"MD3"}
                show={stateDialog}
                closeBtn={"확인"}
                submitBtn={''}
                overlay={true}
                handleClose={()=>{
                    setStateDialog(false)
                }}
                className={'dialog-custom-button'}
            >
                <span>인증번호 전송에 실패했습니다.<br/>
                휴대전화 번호를 다시 확인해주세요.
                </span>
            </CDialog>
        </AppLayout>
    )
}


export default ForgotPwPage
