import DecreaseLargeDisabled from "core/assets/images/icon/decrease_large_disabled.svg";
import DecreaseLargeEnabled from "core/assets/images/icon/decrease_large_enabled.svg";
import IncreaseLargeDisabled from "core/assets/images/icon/increase_large_disabled.svg";
import IncreaseLargeEnabled from "core/assets/images/icon/increase_large_enabled.svg";
import BottomSwiper from "core/components/BottomSwiper";
import CKeyBoard from "core/components/CKeyBoard";
import CSpinNumber from "core/components/CSpinNumber";
import useDebounce from "core/hooks/useDebounce";
import { minMaxValidate, stringLimit } from "core/utils/helper";
import { useUser } from "modules/Auth/hooks";
import { useEffect, useState } from "react";
import { useUpdateQuantity } from "../hooks/useUpdateQuantity";
import OrderInfoModalComponent from "./OrderInfoModalComponent";
import PaymentAmountModalComponent from "./PaymentAmountModalComponent";
import { useCheckQuality } from "modules/Orders/hooks/useCheckQuantity";
import { quantityMinMax } from "modules/Products/constants/QuantityMinMax";
import { isIOS } from "react-device-detect";

interface dataDetailProps {
  title: any;
  info: any;
  isShow: boolean;
  hasDialog?: boolean; 
  handleStatusOrder: (value: boolean) => void;
  addCart: (value: any) => void;
  payment?: (value: any) => void;
  isShowPayment: boolean;
  onIncreaseModal?: (value: any) => void;
  onDecreaseModal?: (value: any) => void;
  onTypingModal?: (index: any, value: any) => void;
  dataInCart?: any;
  quantityExceed?: (quantity: any) => void;
  className?: string;
  isAddCartSuccess?: boolean;
  clearIsAddCartSuccess?: (value: boolean) => void;
}

const ModalOrderComponent = ({
  info,
  isShow,
  hasDialog,
  handleStatusOrder,
  title,
  addCart,
  payment,
  isShowPayment,
  onIncreaseModal,
  onDecreaseModal,
  onTypingModal,
  dataInCart,
  quantityExceed,
  className,
  isAddCartSuccess,
  clearIsAddCartSuccess
}: dataDetailProps) => {
  const user = useUser();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isShowOrder, setIsShowOrder] = useState<boolean>(false);
  const [isShowKeyboard, setIsShowKeyboard] = useState<boolean>(false);
  const [min] = useState<number>(quantityMinMax.MIN);
  const [max] = useState<number>(quantityMinMax.MAX);
  const [quantityNumberArr, setQuantityNumberArr] = useState<string[]>(["1"]);
  const [dataAddCart, setDataAddCart] = useState<any>(info);
  const [quantity, setQuantity] = useState<any>(info.quantity);
  const debouncedQuantity = useDebounce(quantity, 300);
  const [isExceed, setIsExceed] = useState<any>(false);
  const [isShowMsgCartSuccess, setIsShowMsgCartSuccess] = useState<any>(false);
  const [timeError, setTimeError] = useState<any>();
  const [timeSuccess, setTimeSuccess] = useState<any>("");

  const _onSuccess = () => {
    setIsExceed(false);
    // setIsAddCartSuccess(true);
    // setTimeSuccess(new Date());
    let data = {
      ...dataAddCart,
      quantity: quantity,
      qty: quantity,
      priceByQuantity: +quantity * +dataAddCart.prod_price
    }
    addCart(data);
    // if (!hasDialog) {
    //   setIsShowOrder(false);
    //   handleStatusOrder(false);
    // }
    // setIsShowOrder(false);
  }

  const _onFailed = () => {
    setTimeError(new Date());
    setIsExceed(true);
  }
  useEffect(() => {
    
    if (isAddCartSuccess) {
      setIsShowMsgCartSuccess(true)
      setTimeSuccess(new Date());
    }else{
      setTimeSuccess("")
    }
  }, [isAddCartSuccess])
  
  useEffect(() => {
    let timeoutID: any;
    if (timeSuccess !== "" && isShowMsgCartSuccess) {
      timeoutID = setTimeout(() => {
        setIsShowMsgCartSuccess(false)
        if (clearIsAddCartSuccess) {
          clearIsAddCartSuccess(false);
        }
      }, 1500);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [timeSuccess])



  useEffect(() => {
    let timeoutID: any;
    timeoutID = setTimeout(() => {
      setIsExceed(false)
    }, 5000);
    return () => {
      clearTimeout(timeoutID);
    };

  }, [timeError])

	const onCheckExceed = useCheckQuality({ onSuccess: _onSuccess, onFailed: _onFailed});

  const [quantityUpdate, _onIncrease, _onDecrease, _onTyping, _onRemove, _onReset] = useUpdateQuantity(quantity);
  
  useEffect(() => {
    if (!isShowPayment) {
      setDataAddCart({... info, isSelected: true})
      setQuantity(info.quantity || min)
    }else{
      setDataAddCart({
        id: null,
        prod_id: info?.id,
        quantity: min,
        qty: min,
        prod_name: `[${info?.category?.name || ''}] ${info?.name || ''}`,
        prod_image: info?.images && info?.images.length > 0 ? info?.images[0]?.path_medium : '',
        prod_price: +info?.price,
        priceByQuantity: +info?.price,
        uid: user?.userInfo?.id,
        featured_image: info?.featured_image || '',
        isSelected: true,
        cat_id: info?.cat_id,
      })
    }
  }, [info]) 
  

  const handleTouchMove = (e:any) => {
    // console.log('handleTouchMove', e);
    e.preventDefault();
  };

  useEffect(() => {
    setIsShowOrder(isShow);
    if (isShow) {
      if (document && document.getElementById('list-panel')) {
        // document.body.style.overflowY = "hidden";  
        let bodyEl: any = document.querySelector('body');
        bodyEl.style.overflowY = "hidden";
        if (isIOS) {
          document.addEventListener('touchmove', handleTouchMove, { passive: false });
          // bodyEl.style.position = 'fixed';
          // bodyEl.style.bottom = '0px';
            
          // bodyEl.style.WebkitOverflowScrolling = 'touch';
        }
        // window.scroll(0, bodyEl.offsetHeight);
      }
      setIsShowOrder(true);
    }else{
      if (isShowPayment) {
        setQuantity(min);
      }
    }
    
    return () => {
      // setIsShowOrder(false);
      // document.body.style.overflowY = "scroll";  
      let bodyEl: any = document.querySelector('body');
      bodyEl.style.overflowY = "scroll";
      document.removeEventListener('touchmove', handleTouchMove);
      if (isIOS) {
        document.removeEventListener('touchmove', handleTouchMove);
        // bodyEl.style.position = 'static';
        // bodyEl.style.bottom = '0px';
        // bodyEl.style.WebkitOverflowScrolling = 'touch';
      }
      // bodyEl.style.position = 'static';
      // bodyEl.style.bottom = '0px';
      // window.scroll(0, bodyEl.offsetHeight);
    //   bodyEl.animate({
    //     scrollTop: bodyEl.offsetHeight,
    //  }, 500)
      setTimeSuccess("");
      setIsShowMsgCartSuccess(false)
    };
  }, [isShow]);


  //modal order
  useEffect(() => {
    if (isShow) {
      setIsActive(true);
    }
  }, [isShowOrder]);

  const handleShow = (isShow: boolean) => {
    if (!hasDialog) {
      setIsShowOrder(isShow);
      handleStatusOrder(isShow);
    }
  };

  const onStartSwipeUp = () => {};
  const onEndSwipeUp = () => {};
  const onStartSwipeDown = () => {};
  const onEndSwipeDown = () => {};

  useEffect(() => {
    if (isExceed) {
      if (quantity === '') {
        resetQuantityNumber();
      }
      setIsShowKeyboard(false)
    }
  }, [isExceed])

  const onIncrease = (quantity: number) => {
    console.log(quantity, 'quantity');
    
    setQuantity(+quantity);
    if (onIncreaseModal && !isExceed) {
      onIncreaseModal({...info, quantity})
    }
  };

  const onDecrease = (quantity: number) => {
    setQuantity(+quantity);
    if (onDecreaseModal && !isExceed) {
      onDecreaseModal({...info, debouncedQuantity})
    }
  };

  const onClickInput = () => {
    // setIsShowOrder(true)
    setIsShowKeyboard(true);
    // setIsExceed(false);
  };

  useEffect(() => {
    if (quantity === '' && !isShowKeyboard) {
      resetQuantityNumber();
    }
  }, [isShowKeyboard])

  const handleHideKeyboard = () => {
    if (quantity === '') {
      resetQuantityNumber();
    }
    setIsShowKeyboard(false);
  };

  const handleTypingNumber = (item: string) => {
    // if (_onTyping) {
    //   _onTyping(item)
    // }
    // setActionName('onTyping')
    let arrOO = [];
    if (item === "00") {
      arrOO = ["0", "0"];
    } else {
      arrOO = [item];
    }
    let arr = quantity.toString().split("");
    let mergedArr = [...arr, ...arrOO];
    let numberMegered = +mergedArr.join("");
    if (minMaxValidate(numberMegered, min, max)) {
      setQuantityNumberArr([...mergedArr]);
      setQuantity(+mergedArr.join(""))
      if (onTypingModal) {
        onTypingModal(info, +mergedArr.join(""))
      }
    }else{
      if (numberMegered > max) {
        setQuantityNumberArr(['100']);
        setQuantity(max)
        if (onTypingModal) {
          onTypingModal(info, max)
        }
      }else{
        resetQuantityNumber()
      }
    }
  };

  const resetQuantityNumber = () => {
    // setQuantityNumber(min);
    setQuantity(min)
    setQuantityNumberArr(["1"]);
    if (onTypingModal) {
      onTypingModal(info, min)
    }
  };

  const handleRemoveNumber = () => {
    // if (_onRemove) {
    //   _onRemove()
    // }
    if (quantity === '') {
      return;
    }
    let quantityNumber = +quantityNumberArr.join("");
    if (minMaxValidate(quantityNumber, min, max)) {
      let arr = quantity.toString().split("");
      let _removedArr = [...arr];
      if (_removedArr.length > 1) {
        _removedArr.splice(_removedArr.length - 1);
        setQuantityNumberArr([..._removedArr]);
        setQuantity(+_removedArr.join(""))
        if (onTypingModal) {
          onTypingModal(info, +_removedArr.join(""))
        }
      } else {
        setQuantityNumberArr(['']);
        setQuantity('')
        if (onTypingModal) {
          onTypingModal(info, min)
        }
      }
    } else {
      setQuantityNumberArr(['']);
      setQuantity('')
      if (onTypingModal) {
        onTypingModal(info, min)
      }
    }
  };

  useEffect(() => {
    let numbers = +quantityNumberArr.join("");
    if (minMaxValidate(numbers, min, max)) {
      setQuantity(numbers)
    }
  }, [max, min, quantityNumberArr]);


  const handleAddCart = () => {
    if (onCheckExceed) {
      onCheckExceed({
        quantity: quantity, 
        prod_id: info?.id, 
        max: quantityMinMax.MAX, 
        type: !isShowPayment && 'cart', 
      })
    }
    // setTime(new Date());
    if (!isExceed) {
      // let data = {
      //   ...dataAddCart,
      //   quantity: quantity,
      //   qty: quantity,
      //   priceByQuantity: +quantity * +dataAddCart.prod_price
      // }
      // addCart(data);
      // if (!hasDialog) {
      //   setIsShowOrder(false);
      //   handleStatusOrder(false);
      // }
      // setIsShowOrder(false);
    }
  } 

  const handlePayment = () => {
    console.log('handlePayment', dataAddCart);
    if (payment) {
      let _dataAddCart = {
        ...dataAddCart,
        quantity: quantity,
        qty: quantity,
        priceByQuantity: +quantity * +dataAddCart.prod_price
      }
      payment(_dataAddCart);
    }
  } 
  
  return (
    <BottomSwiper
      isOpen={isShowOrder}
      isActive={isActive}
      title={stringLimit(title, 50)}
      onSlideDown={handleShow}
      className={`${className} ${!isShowPayment || isShowKeyboard ? 'bottom-swiper--keyboard-order' : ''} `}
      isShowHeader={true}
      onStartSwipeUp={onStartSwipeUp}
      onEndSwipeUp={onEndSwipeUp}
      onStartSwipeDown={onStartSwipeDown}
      onEndSwipeDown={onEndSwipeDown}
      isExceed={isExceed}
    >
      <CSpinNumber
        className={""}
        value={quantity}
        min={min}
        max={max}
        label={"수량 선택"}
        onIncrease={onIncrease}
        onDecrease={onDecrease}
        onClickInput={onClickInput}
        increaseIconEnabled={IncreaseLargeEnabled}
        decreaseIconEnabled={DecreaseLargeEnabled}
        increaseIconDisabled={IncreaseLargeDisabled}
        decreaseIconDisabled={DecreaseLargeDisabled}
        isExceed={isExceed} // don't need check isExceed
        isAddCartSuccess={isShowMsgCartSuccess}
      />
      {
        isShowPayment && <>
          <OrderInfoModalComponent info={[]} isOpen={!isShowKeyboard} isExceed={isExceed} />
            <PaymentAmountModalComponent 
              amount={dataAddCart.prod_price * quantity} 
              isOpen={!isShowKeyboard}
              handleAddCart={handleAddCart} 
              handlePayment={handlePayment} 
              isExceed={false} // don't need check isExceed
              isAddCartSuccess={isShowMsgCartSuccess}
            />
        </>
      }
      <CKeyBoard
        className={""}
        onClickNumber={handleTypingNumber}
        onHideKeyboard={handleHideKeyboard}
        onRemoveNumber={handleRemoveNumber}
        isOpen={!isShowPayment || isShowKeyboard }
      />
    </BottomSwiper>
  );
};

export default ModalOrderComponent;
