import {memo, useEffect, useState} from "react";

const CTextArea = (props: any) => {
    const [_props, setProps] = useState({
        name: props.name,
        value: props.value || '',
        className: props.className || '',
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        handleChange: props.handleChange || '',
        placeholder: props.placeholder || '',
        maxLength: props.max || props.maxLength || 0,
        rows: props.rows || 5
    })

    useEffect(()=>{
        setProps(props)
    }, [props])

    const handleChange = (e: any) => {
        let value = _props.value
        if(_props.maxLength > 0){
            if(e.target.value.length <= _props.maxLength) value = e.target.value
        }else{
            console.log(25, _props.maxLength)
        }

        if(value != _props.value){
            setProps({..._props, value})
            if(_props.handleChange)
                _props.handleChange(e)
        }
    }

    const countCharacter = (content = '') => {
        return content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1 ').length
    }

    return <>
        <textarea
            rows={_props.rows}
            name={_props.name}
            className={_props.className}
            onChange={handleChange}
            value={_props.value}
            placeholder={_props.placeholder}
            readOnly={_props.readOnly}
            disabled={_props.disabled}
            // maxLength={_props.maxLength}
        />
        <div className="count-chacter">
            <label className="length">{countCharacter(_props.value)}/{_props.maxLength}</label>
        </div>
    </>
}

export default memo(CTextArea)