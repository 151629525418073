import NotificationPage from "./pages/NotificationPage";

const router =  [
    {
        name: "NOTIFICATION_LIST",
        auth: true,
        path: "/notification",
        element: <NotificationPage/>
    },
]
export default router
