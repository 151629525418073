import {useEffect, useRef, useState} from "react";
import AppLayout from "core/layouts/AppLayout";
import BottomSwiper from "core/components/BottomSwiper";
import IconHeader from "core/components/header/IconHeader";
import SingleHeader from "core/components/header/SingleHeader";
import MainHeader from "core/components/header/MainHeader";
import CFloatButton from "core/components/CFloatButton";
import CSwitcher from "core/components/CSwitcher";
import CEmptyIcon from "core/components/CEmptyIcon";
import Empty from "core/assets/images/icon_empty.svg";
import CAccoditionItem from "core/components/CAccoditionItem";
import CListItem from "core/components/CListItem";
import CTabs from "core/components/CTabs";
import CDialog from "core/components/CDialog";
import ProductListItem from "core/components/ProductListItem";
import ProductImage from 'core/assets/images/img_product.png';
import AccordionItem from "core/components/CAccoditionItem/accordition";

const CommonPage = (props: any) => {
	const refPush = useRef();
	const [statePushDefault, setStatePushDefault] = useState(true)
	const [stateDialog, setStateDialog] = useState(false)

	const [active, setActive] = useState<any>(null);

    const handleToggle = (index:any) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

	const listAccodition = [
		{
			id:1,
			question:'아직 등록된 ',
			answer:'친구에게 추천하고 적립금 혜택을 받아보세요!',
		},
		{
			id:2,
			question:'아직 등록된 ',
			answer:'살구나무집 도시락 앱은 고객님의 전화번호를 \n' +
				'수집하고 있습니다. 이에 동의 하시면 가입이 진행 됩니다.'
		}
	]
	const listItem = [
		{
			id:1,
			title:'아직 등록된 추천인이 없습니다.',
			description:'친구에게 추천하고 적립금 혜택을 받아보세요!',
			time:'2023-01-21 14:52',
			price:800000,
			quantity:1,
		},
		{
			id:2,
			title:'[정식] 소불고기 도시락 20건',
			description:'살구나무집 도시락 앱은 고객님의 전화번호를 \n' +
				'수집하고 있습니다. 이에 동의 하시면 가입이 진행 됩니다.',
			time:'2023-01-21 14:52',
			price:200000,
			quantity:100,
		}
	]

	const faqs = [
		{
			id: 1,
			header: "What is Lorem Ipsum?",
			text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
		},
		{
			id: 2,
			header: "Where does it come from?",
			text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `
		},
		{
			id: 3,
			header: "Why do we use it?",
			text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`
		},
		{
			id: 4,
			header: "Where can I get some?",
			text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
		}
	]


	const contentTab1 = (
		<div id="pages-nav-branch" className="col-xs-12 text-center">
			<ul className="pagination pagination-section pagination-sm">
				1
			</ul>
		</div>
	);
	const contentTab2 = (
		<div id="pages-nav-branch" className="col-xs-12 text-center">
			<ul className="pagination pagination-section pagination-sm">
				2
			</ul>
		</div>
	);
	const contentTab3 = (
		<div id="pages-nav-branch" className="col-xs-12 text-center">
			<ul className="pagination pagination-section pagination-sm">
				3
			</ul>
		</div>
	);
	const handleChangeQuantity = (e: any) => {
	  	console.log(e,'handleChangeQuantity')
	}
	const handChangeItemProduct = (e: any) => {
		console.log(e,'handChangeItemProduct')
	}

	const _handleClickOpen = (data:any) =>{
        
        if (active && active?.id === data?.id) {
            setActive(null);
        } else {
            setActive(data);
        }
    }


  return (
    <AppLayout>
		<div className='test-font'>
			<span>
				세종특별자치시 다솜3로 95 공정거래위원회
3층 시장감시국
			</span>
		</div>
		<IconHeader
			title={'[실속] 제육 도시락'}
			iconLeft={true}
			handleLeftClick={()=>{}}
			handleRightClick={()=>{}}
			iconRight={true}
		/>
		<IconHeader
			title={'[ly test_use] product test hypelink'}
			iconLeft={true}
			handleLeftClick={()=>{}}
			handleRightClick={()=>{}}
			iconRight={true}
			className='limit-text'
			iconCart
			handleCartClick={()=>{console.log('ppppp')}}
		/>
		<SingleHeader
			title={'개인정보 수집/이용 동의'}
		/>
		<MainHeader
			title={'주문하기'}
			handleCallPhoneClick={()=>{}}
			handleOpenKakaoClick={()=>{}}
			handleOpenMarkerClick={()=>{}}
			iconRight={true}
		/>
		<CFloatButton
			icon={true}
			className={'CFloatButton'}
			handleClick={()=>{console.log('CFloatButton')}}
			label={'문의하기'}
		/>
		<CSwitcher
			icon={true}
			value={statePushDefault}
			className={'CSwitcher'}
			handleSwitch={()=>{setStatePushDefault(!statePushDefault)}}
		/>
		<CEmptyIcon
			icon={Empty}
			text={'아직 등록된 추천인이 없습니다.'}
			description={'친구에게 추천하고 적립금 혜택을 받아보세요!'}
		/>

		{/* <div className="container-fluid mt-5 mb-5">
			<div className="row justify-content-center">
				<div className="col-md-8 mt-2">
					<div className="card">
						<div className="card-body">
							{faqs.map((faq, index) => {
								return (
										<AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
										)
								})
							}
						</div>
					</div>
				</div>
            </div>            
        </div> */}

		<div className={'mt-2'}>
			{listAccodition.map((item,key) =>(<CAccoditionItem
				className={'CAccoditionItem'}
				question={item.question}
				answer={item.answer}
				title={'[프리미엄] 돈등갈비 도시락 1건'}
				description={'* 배송시간은 당일 전달 상황에 따라 다소 변동이 있을 수 있습니다.'}
				key={key}
				type={2}
			/>))}
		</div>
		<div className={'mt-2'}>
		<h3 className="mb-2">isShow accordition</h3>
			{listAccodition.map((item,key) =>(<CAccoditionItem
				className={'CAccoditionItem'}
				question={item.question}
				answer={item.answer}
				key={key}
				isShow={true}
			/>))}
		</div>
		<div className={'mt-2'}>
			<h3 className="mb-2">expandSingle accordition</h3>
			{listAccodition.map((item,key) =>(<CAccoditionItem
				className={'CAccoditionItem'}
				question={item.question}
				answer={item.answer}
				key={key}
				item={item}
                expandSingle={true}
                handleClickOpen={_handleClickOpen}
                isShow={active}
			/>))}
		</div>
		<div className={'mt-2'}>
			{listItem.map((item,key) =>(<CListItem
				className={'CAccoditionItem'}
				title={item.title}
				time={item.time}
				icon={true}
				handleClick={()=>{console.log('CListItem')}}
				key={key}
			/>))}
		</div>
		<div className={'mt-2'}>
			{listItem.map((item,key) =>(<CListItem
				className={'CAccoditionItem'}
				title={item.title}
				icon={true}
				handleClick={()=>{console.log('CListItem')}}
				key={key}
			/>))}
		</div>
		<div className={'mt-2'}>
			{listItem.map((item,key) =>(<CListItem
				className={'CAccoditionItem'}
				title={item.title}
				icon={false}
				handleClick={()=>{console.log('CListItem')}}
				key={key}
			/>))}
		</div>
		<div className={'mt-2'}>
			{listItem.map((item,key) =>(<CListItem
				className={'CAccoditionItem'}
				title={item.title}
				icon={false}
				handleClick={()=>{console.log('CListItem')}}
				key={key}
				type={2}
				contentChild={
					<div className={'c-list-item__content d-block c-list-item__content-type'}>
						<div className="list-item-info">
							<label className='list-item-info__user'>psb***</label>
							<label className='list-item-info__time'>2023.01.19 18:00</label>
						</div>
						<div className="list-item-status">
							<span>답변 대기</span>
						</div>
						<p className="list-item-title">아직 등록된 추천인이 없습니다.</p>
					</div>}
			/>))}
		</div>
		<div className={'mt-2'}>
			<CTabs
				tabHeadings={['상품 상세', '상품 정보','배달 및 결제 안내']}
				tabContents={[contentTab1, contentTab2,contentTab3]}
				activeIndex={0}
			/>
		</div>

		<div className={'mt-3'} >
			<button onClick={()=>{
				setStateDialog(true)
			}}>open Dialog</button>
			<CDialog
				id={"MD3"}
				show={stateDialog}
				closeBtn={"닫기"}
				submitBtn={'게시판 이동'}
				overlay={true}
				handleSubmit={()=>{
					setStateDialog(false)
				}}
				handleClose={()=>{
					setStateDialog(false)
				}}
				className={'dialog-custom-button'}
			>
				<span>출금은 <strong>10,500원</strong> 이상부터 가능합니다.</span>
			</CDialog>
		</div>
		<div className={'mt-2'}>

			{listItem.map((item,key) =>(<ProductListItem
				name={item.title}
				price={item.price}
				quantity={item.quantity}
				image={ProductImage}
				handleChange={handChangeItemProduct}
				hasAction={true}
				dataItem={item}
				key={key}
			/>))}
		</div>
		<div className={'mt-2'} style={{height:'250px'}}>
			{listItem.map((item,key) =>(<ProductListItem
				name={item.title}
				price={item.price}
				quantity={item.quantity}
				handleChange={handChangeItemProduct}
				hasAction={false}
				dataItem={item}
				image={ProductImage}
				key={key}
			/>))}
		</div>
		<div className={'mt-2'} style={{height:'250px'}}>
			<div className='list-product-order'>
				{listItem.map((item,key) =>(<ProductListItem
					name={item.title}
					price={item.price}
					quantity={item.quantity}
					handleChange={handChangeItemProduct}
					hasAction={false}
					dataItem={item}
					image={ProductImage}
					key={key}
					type={1}
					handleClick={()=>{}}
				/>))}
			</div>
		</div>
		<div className={'mt-2'}></div>
		<div className={'mt-2'}></div>

    </AppLayout>
  );
};

export default CommonPage;
