import AccumulatePage from "./pages/AccumulatePage";
import AccumulateWithdrawForm from "./pages/AccumulateWithdrawForm";

const router =  [
    {
        name: "ACCUMULATE_LIST",
        auth: true,
        path: "/accumulate",
        element: <AccumulatePage/>
    },
    {
        name: "ACCUMULATE_WITHDRAW_FORM",
        auth: true,
        path: "/accumulate-withdraw",
        element: <AccumulateWithdrawForm/>
    },
]
export default router
