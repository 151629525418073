import CCheckbox from "core/components/form-controls/CCheckbox";
import { useSelectRow } from "core/hooks/useSelectRow";
import { useEffect } from "react";
import ArrowRightIcon from "./../assets/icon/arow_right.svg";
import { useNavigate } from "react-router-dom";
import { RouterName } from "core/router";
import { POLICY_PAYMENT } from "core/constants/Commons";
import { useUpdateDataPayment } from "../hooks/usePayment";

interface PolicyPaymentProps {
  dataOrders: any;
}

const PolicyPaymentComponent = ({
  dataOrders,
}: PolicyPaymentProps) => {
  const policyCheck = POLICY_PAYMENT.filter((item: any) => item.hasCheck);
  const [rowSelected, onSelectRow] = useSelectRow(policyCheck);
  const navigate = useNavigate();
  const [handleUpdate, ] = useUpdateDataPayment({})

  useEffect(() => {
    if (dataOrders.policyPayment || dataOrders?.orderId) {
      onSelectRow({
        id: "all",
        checked: true,
      });
    }
  }, [dataOrders.policyPayment]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      if (rowSelected.length === policyCheck.length) {
        handleUpdate({
          ...dataOrders,
          policyPayment: true
        })
      } else {
        handleUpdate({
          ...dataOrders,
          policyPayment: false
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelected.length]);

  const goDetailPolicy = (id: string) => {
    navigate(RouterName.Policy.replace(":id", id));
  };

  return (
    <div className={`privacy-policy ${dataOrders?.orderId ? 'disabled' : ''}`}>
      <div className="privacy-policy__all">
        <div className="check-all">
          <CCheckbox
            label="전체 동의 하기"
            checked={
              rowSelected.length === policyCheck.length 
            }
            name={"check"}
            handleChange={(e: any) => {
              console.log(e.target.checked);
              
              if (!e.target.checked) {
                handleUpdate({
                  ...dataOrders,
                  policyPayment: false
                })
              }
              onSelectRow({
                id: "all",
                checked: e.target.checked,
              });
            }}
          />
        </div>
      </div>
      {
        POLICY_PAYMENT.map((item: any, idx: number) => {
          return item?.hasCheck ? (
            <div
              className={`privacy-policy__item privacy-policy--${
                idx === policyCheck.length - 1
                  ? "border-bottom"
                  : "product"
              }`}
              key={idx}
            >
              <div className="check-item">
                <CCheckbox
                  label={item.label}
                  checked={rowSelected.find((e: any) => e.id === item.id)}
                  name={`policy-${idx}`}
                  handleChange={() => {
                    onSelectRow(item);
                  }}
                />
              </div>
              <img
                className="img-right"
                onClick={() => goDetailPolicy(item.value)}
                src={ArrowRightIcon}
                alt=""
              />
            </div>
          ) : (
            <div className="privacy-policy__item privacy-policy--border-bottom" key={idx}>
              <label htmlFor="" onClick={() => goDetailPolicy(item.value)}>
                {item.label}
              </label>
              <img
                className="img-right"
                onClick={() => goDetailPolicy(item.value)}
                src={ArrowRightIcon}
                alt=""
              />
            </div>
          );
        })
      }
    </div>
  );
};

export default PolicyPaymentComponent;
