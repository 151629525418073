import { useHandleHyperlink } from "core/hooks/useHandleHyperlink";

// eslint-disable-next-line no-empty-pattern
const ContentSupportComponent = ({data, handleDirectByUrl}: any) => {
  useHandleHyperlink({data, elId: 'content-support'});

  return (
    <>
      <div
        className="pages-nav-branch"
        id="content-support"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </>
  );
};

export default ContentSupportComponent;
