import React, { memo, useEffect, useState } from "react";

const CodeVerifyInput = (props: any) => {
    const [timer, setTimer] = useState("03:00");
    const [intervalId, setIntervalId] = useState<any>(null);
    const [btnState, setBtnState] = useState(true)

    const startTimer = () => {
        let countdown = props.countdown;
        const newInterval = setInterval(() => {
            countdown -= 1;
            const minutes = Math.floor(countdown / 60).toString().padStart(2, "0");
            const seconds = (countdown % 60).toString().padStart(2, "0");
            setTimer(`${minutes}:${seconds}`);

            if (countdown <= 0) {
                clearInterval(newInterval);
                setTimer("00:00");
            }
            if(typeof props.updateTimeCountDown == 'function'){
                props.updateTimeCountDown(countdown)
            }
            
        }, 1000);

        setIntervalId(newInterval);
    };

    const stopTimer = (timeSet = '00:00') => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        setTimer(timeSet);
    };

    const startCountDown = () => {
        if (props.countdown > 0) {
            startTimer();
            return () => {
                stopTimer();
            };
        } else {
            stopTimer();
        }
    }

    // useEffect(() => {
    //     if (props.countdown > 0) {
    //         startTimer();
    //         return () => {
    //             stopTimer();
    //         };
    //     } else {
    //         stopTimer();
    //     }
    // }, [props.countdown]);

    useEffect(()=>{
        stopTimer()
    }, [])

    useEffect(() => {
        if(timer == '00:00'){
            if(typeof props.handleTimeEnd == 'function') {
                props.handleTimeEnd(timer)
            }
        }
    }, [timer]);

    useEffect(() => {
        stopTimer(props?.timeSetDefault || '03:00')
        startCountDown()
        // console.log(props?.count,'props?.count',props?.timeSetDefault,props.countdown )
    }, [props?.count]);

    useEffect(() => {
        if(props?.stopTimer){
            stopTimer('03:00')
        }
    }, [props?.stopTimer]);


    const handleFocus = (e: any) => {
        if(typeof props.onFocus == 'function')
            props.onFocus(e)
    }
    const handleBlur = (e: any) => {
        if(typeof props.onBlur  == 'function')
            props.onBlur(e)
    }

    return (
        <>
            <div className="c-input-button-group verify">
                <div className="input">
                    <input
                        type="text"
                        onChange={props.handleChange}
                        placeholder={props.placeholder}
                        value={props?.value || ''}
                        name={props?.name || ''}
                        maxLength={props?.maxLength || 100}
                        pattern={props?.pattern || null}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputMode="numeric"
                    />
                    <span className="timer">{timer}</span>
                </div>
                <button type={"button"} className="btn btn-primary" onClick={props.handleSubmit}>
                    {props.button}
                </button>
            </div>
        </>
    );
};

export default memo(CodeVerifyInput);
