import API from "core/networks/API";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_CART_FAILED,
  ADD_CART_SUCCESS,
  DELETE_CART_FAILED,
  DELETE_CART_SUCCESS,
} from "../store/types";
import {
  addCart,
  decreaseQuantity,
  deleteCart,
  increaseQuantity,
  listCarts,
  updateOrCreateCart,
  updateQuantity,
} from "../store/actions";
import { API_CART, API_ORDER_HISTORY } from "../api";
import { MESSAGE } from "core/constants/Commons";

export const useGetCarts = (params: any) => {
  const [paramsCarts] = useState<any>({
    page: 0,
    order_by: "created_at",
    order_dir: "desc",
    page_size: 0,
    "relation[]": "product.category",
  });

  const dispatch = useDispatch();
  const carts = useSelector((state: any) => state.Orders.cartReducer?.data || []);
  const handleGetCart = () => {
    dispatch(listCarts(paramsCarts));
  };

  return [carts, handleGetCart];
};

export const useCarts = (params: any) => {
  const carts = useSelector((state: any) => state.Orders.cartReducer?.data || []);
  if (params?.isSelected) {
    return carts.filter((item: any) => item.isSelected);
  }
  if (params && params.length > 0) {
    let arr: any[] = [];
    if (params[0].id === "") {
      return params;
    }
    params.map((item: any) => [arr.push(item.id)]);
    return carts.filter((item: any) => arr.includes(item.id));
  } else {
    return carts;
  }
};

export const useAddCarts = (form: any) => {
  const dispatch = useDispatch();
  let isLoading;
  const carts = useSelector((state: any) => state.Orders.cartReducer || {});
  
  useEffect(() => {
    switch (carts.type) {
      case ADD_CART_SUCCESS:
        if (form.onSuccess) {
          return form.onSuccess(carts.data);
        }
        break;
      case ADD_CART_FAILED:
        if (form.onFailed) {
          return form.onFailed(carts.errors);
        }
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [carts.type]);

  const _handleAddCart = (values: any) => {
    dispatch(addCart(values));
  };

  return [isLoading, _handleAddCart];
};

export const useUpdateCart = (form: any) => {
  const dispatch = useDispatch();
  let isLoading;

  // eslint-disable-next-line no-underscore-dangle
  const _handleIncreaseCart = (values: any) => {
    let _data = {
      id: values?.id || null,
      prod_id: values?.prod_id || null,
      index: values.index,
      quantity: values.quantity,
    };
    dispatch(increaseQuantity(_data));
    // return handleAddCart(data);
  };

  const _handleDecreaseCart = (values: any) => {
    let _data = {
      id: values?.id || null,
      prod_id: values?.prod_id || null,
      index: values.index,
      quantity: values.quantity,
    };
    dispatch(decreaseQuantity(_data));

    // return handleAddCart(data);
  };

  const _handleUpdateCart = (values: any) => {
    let _data = {
      id: values?.id || null,
      prod_id: values?.prod_id || null,
      index: values.index,
      quantity: values.quantity,
    };
    dispatch(updateQuantity(_data));

    // return handleAddCart(data);
  };

  return [
    isLoading,
    _handleIncreaseCart,
    _handleDecreaseCart,
    _handleUpdateCart,
  ];
};

export const useDeleteCarts = (form: any) => {
  const dispatch = useDispatch();
  const carts = useSelector((state: any) => state.Orders.cartReducer || {});
  useEffect(() => {
    switch (carts.type) {
      case DELETE_CART_SUCCESS:
        if (form.onSuccess) {
          return form.onSuccess(carts.data);
        }
        break;
      case DELETE_CART_FAILED:
        if (form.onFailed) {
          return form.onFailed();
        }
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [carts.type]);

  const _handleDeleteCart = (values: any) => {
    let _ids: string[] = [];
    let _prod_ids: string[] = [];
    if (Array.isArray(values)) {
      values.map((item: any) => {
        _ids.push(item.id);
        _prod_ids.push(item.prod_id);
      });
    } else {
      _ids = [values.id];
      _prod_ids = [values.prod_id];
    }
    let _values = {
      cart_id: _ids,
      prod_id: _prod_ids,
      _method: "DELETE",
    };
    dispatch(deleteCart(_values));
  };

  return [carts?.loadingDeleteCart, _handleDeleteCart];
};

export const useUpdateOrCreateCart = (form: any) => {
  const dispatch = useDispatch();
  const carts = useSelector((state: any) => state.Orders.cartReducer?.data || []);
  let isLoading;
  let isCheckMax = false;

  const addCartByOrder = async (values: any) => {
    try {
      let _data = {
        order_id: values.id || null,
      };
      const res = await API.post(API_ORDER_HISTORY.ADD_CART_BY_ORDER, _data);
      if (res.status === 200) {
        dispatch(
          updateOrCreateCart({ dataCarts: values?.items, isSelected: true })
        );
        return form.onSuccess();
      }else{
        return form.onFailed({
          type: 'PRODUCT_NOT_FOUND',
          msg: MESSAGE.PRODUCT_NOT_FOUND
        });
      }
    } catch (error: any) {
      console.log('error.response', error?.response?.data);
      return form.onFailed(error?.response?.data);
    }
  };

  const _handleUpdate = (values: any) => {
    if (values?.items.length > 0) {
      const data = carts.filter(function (obj: any) {
        return values?.items.some(function (obj2: any) {
          if (obj.prod_id === obj2.prod_id) {
            if (obj.quantity + obj2.qty > 100) {
              isCheckMax = true;
            }
          }
          return obj.prod_id === obj2.prod_id;
        });
      });
      console.log(isCheckMax, 'isCheckMax');
      
      if (isCheckMax) {
        if (form.onFailed) {
          return form.onFailed({
            type: 'MAX_EXCEED',
            msg: MESSAGE.MAX_EXCEED
          });
        }
      } else {
        addCartByOrder(values);
        //

        
      }
    } else {
      console.log("data empty");
    }
  };

  return [isLoading, _handleUpdate];
};

export const useProductSelectedInCarts = (form: any) => {
  const carts = useSelector((state: any) => state.Orders.cartReducer?.data || []);
  return carts.filter((item: any) => item.isSelected);
};

export const useCartVerify = (cb: any) => {
  const [loading, setLoading] = useState<any>(false);

  const handleVerify = async (data: any, type = 'order') => {
    console.log('handleVerify', data);
    try {
      const _data = data.map((item: any, idx: number) => ({
        prod_id: item?.prod_id,
        prod_image_path: item?.product?.featured_image || item?.prod_image_path,
        prod_name: item?.prod_name,
        prod_price: item?.prod_price,
        quantity: item?.quantity,
        cat_id: item?.product?.cat_id || item?.cat_id
      }));
      console.log(262, _data);
      
      const res: any = await API.post(API_CART.CART_VERIFY, {items: _data, type});
      if (res.data.code === 200) {
        return cb.onSuccess()
      }
    } catch (error:any) {
      return cb.onFailed(error?.response?.data)
    }
  }

  return [loading, handleVerify]
}