import {memo, useEffect, useState} from "react";
import uuid from "react-uuid";
import {config} from "../../config/app";
import CPhoneInput from "./CPhoneInput";

const VerifyInput = (props: any) => {
    const [_props, setProps] = useState({
        multiple: props.multiple || true,
        className: props.className || '',
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        value: props.value || '',
        placeholder: props.placeholder || '',
        buttonDisabled: props.buttonDisabled || false,
        button: props.button || <></>,
        name: props.name || '',
        maxLength: props.maxLength || 100,
        pattern: props.pattern || '',
        onBlur: props.onBlur || function (e: any){},
        onFocus: props.onFocus || function (e: any){},
        handleChange: props.handleChange || function (e: any){},
    })
    useEffect(()=>{
        setProps(props)
    }, [props])

    const handleFocus = (e: any) => {
        if(_props.onFocus)
            _props.onFocus(e)
    }
    const handleBlur = (e: any) => {
        if(_props.onBlur)
            _props.onBlur(e)
    }
    const _handleChange = (e: any) => {
        if(props.handleChange){
            props.handleChange(e)
        }
    }

    return <>
        <div className={`c-input-button-group ${_props?.className}`}>
            {/*<input*/}
            {/*    type="text"*/}
            {/*    name={_props.name}*/}
            {/*    disabled={_props.disabled}*/}
            {/*    readOnly={_props.readOnly}*/}
            {/*    value={_props.value}*/}
            {/*    onChange={props.handleChange}*/}
            {/*    placeholder={_props.placeholder}*/}
            {/*    maxLength={_props.maxLength}*/}
            {/*    pattern={_props?.pattern}*/}
            {/*    onFocus={handleFocus}*/}
            {/*    onBlur={handleBlur}*/}
            {/*/>*/}
            <CPhoneInput
                label={""} placeholder={_props.placeholder} required={true} disabled={_props.disabled} readonly={_props.readOnly}
                name={_props.name} className={"box-c-phone-number"}
                value={props.value}
                handleChange={_handleChange}
                warning={""}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <button type={"button"} className="cbtn btn-primary btn-outline-primary" onClick={props.handleSubmit} disabled={props.buttonDisabled}>{props.button}</button>
        </div>
    </>
}

export default memo(VerifyInput);
