import { Action } from 'redux';
import { Data } from './types';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export interface FetchDataAction extends Action<typeof FETCH_DATA> {}

export interface FetchDataSuccessAction extends Action<typeof FETCH_DATA_SUCCESS> {
    payload: Data[];
}

export interface FetchDataErrorAction extends Action<typeof FETCH_DATA_ERROR> {
    payload: Error;
}

export const fetchData = (): FetchDataAction => ({
    type: FETCH_DATA,
});

export const fetchDataSuccess = (data: Data[]): FetchDataSuccessAction => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataError = (error: Error): FetchDataErrorAction => ({
    type: FETCH_DATA_ERROR,
    payload: error,
});
