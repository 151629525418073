import SkeletonComponent from "core/components/Skeleton/skeleton";
import { useEffect, useRef, useState } from "react";
import { CategoryProps } from "../interfaces/CategoryProps";
import "./../assets/scss/categorieslist.scss";

interface objectFilters {
  id: number | string;
  name: string;
}

const CategoriesComponent = ({
  categories,
  onSelect,
  active,
  loading
}: CategoryProps) => {
  const scrollref = useRef<any>();
  const selectCategory = (item: objectFilters) => {
    onSelect(item);
  };

    const handleScroll = () => {
      const scrollContainer = scrollref.current;
      if (scrollContainer) {
          window.localStorage.scrollX = scrollContainer.scrollLeft;
      }
  };
  
  useEffect(() => {
    const scrollContainer:any = scrollref.current;
    if (scrollContainer) {
        scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
        if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll);
        }
    };
  }, []);
  
  const renderCategories = (data: any) => {
    if (data.length < 1) return;
    return data.map((item: objectFilters, idx: number) => (
      <li
        className={`categories-list__group__item ${
          +active === item.id
            ? "active"
            : ""
        }`}
        key={idx}
        onClick={() => selectCategory(item)}
      >
        <label htmlFor="">#{item.name}</label>
      </li>
    ));
  };
  
  return (
    <div className="categories-list" id="filter-panel">
      <ul className={`categories-list__group categories-list__group--${loading && 'loading'}`} ref={scrollref} id="category-scroll">
        <li className={`categories-list__group__item ${
          active === 'all'
            ? "active"
            : ""
        }`} onClick={() => selectCategory({id: 'all', name: '전체'})}>
          <label htmlFor="">전체</label>
        </li>
        {
           loading ? <>
           <li className={`loading`}>
              <label htmlFor=""><SkeletonComponent width={80} height={26} /></label>
            </li>
            <li className={`loading`}>
              <label htmlFor=""><SkeletonComponent width={80} height={26} /></label>
            </li>
            <li className={`loading`}>
              <label htmlFor=""><SkeletonComponent width={80} height={26} /></label>
            </li>
          </> : renderCategories(categories)
        }
      </ul>
    </div>
  );
};

export default CategoriesComponent;
