import "./../assets/scss/categorieslist.scss";
import DropdownIcon from "../assets/icon/dropdown.svg";
import { useEffect, useRef, useState } from "react";
import { DropdownProps } from "../interfaces/DropdownProps";
import useOnClickOutside from "core/hooks/useOnClickOutside";

const DropdownComponent = ({ data, onSelect, active }: DropdownProps) => {
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, setIsDropdown);
  const [filterSlelected, setFilterSlelected] = useState(active);

  useEffect(() => {
      let _active = data?.filter((item: any) => item.order_by === active.order_by && item.order_dir === active.order_dir);
      setFilterSlelected(_active[0]);
  }, [active])

  const selectDropdown = (item: any) => {
    setFilterSlelected(item);
    onSelect(item);
    setIsDropdown(false);
  };

  const renderFilters = (data: any) => {
    if (data.length < 1) return;
    return data.map((item: any, idx: number) => (
      <li
        className={`good-filter__group__item ${
          active.order_by === item.order_by &&
          active.order_dir === item.order_dir
            ? "active"
            : ""
        }`}
        key={idx}
        onClick={() => selectDropdown(item)}
      >
        <label htmlFor="">{item.title}</label>
      </li>
    ));
  };

  return (
    <div className="good-filter" ref={wrapperRef}>
      <div
        className="good-filter__title"
        onClick={() => setIsDropdown(!isDropdown)}
      >
        <label htmlFor="">
          {filterSlelected !== undefined && filterSlelected.title}
        </label>
        <img src={DropdownIcon} alt="" />
      </div>

      <ul className={`good-filter__group ${isDropdown ? "show" : "hide"}`}>
        {renderFilters(data)}
      </ul>
    </div>
  );
};

export default DropdownComponent;
