import SkeletonComponent from "core/components/Skeleton/skeleton";

interface GoodLoading {
  className?: string;
}

const OrderHistoryDetailLoadingComponent = ({ className }: GoodLoading) => {
  return (
    <div className="order-detail-page__content">
      <div className={`order-detail-page__content--loading`}>
        <div className="order-detail-page__content--loading__item">
          <div>
            <label htmlFor="">주문번호</label>
            <SkeletonComponent width={'60%'} height={19}/>
          </div>
          <div>
            <label htmlFor="">상품명</label>
            <SkeletonComponent width={'40%'} height={19}/>
          </div>
          <div>
            <label htmlFor="">배달요청 일시</label>
            <SkeletonComponent width={'40%'} height={19}/>
          </div>
        </div>
        <div>
          <hr />
        <div className="product">
              <p className="title">
              <SkeletonComponent width={'100%'} height={19}/>
              <SkeletonComponent width={'60%'} height={19}/>
              </p>
              <p className="amount">
                <SkeletonComponent width={100} height={19}/>
              </p>
            </div>
        </div>
        <div className="list__bottom">
            <div className="shopping-cart-btn">
              <div className="d-grid d-grid--disabled">
                <button
                  className="cbtn btn-primary"
                  disabled
                >
                  장바구니 담기
                </button>
              </div>
            </div>
            <div className="item row">
              <div className="col-6 item__left">
                <div className={`d-grid  d-grid--disabled`} >
                  <button
                    className="cbtn btn-primary"
                  >
                    배송지 변경
                  </button>
                </div>
              </div>
              <div className="col-6 item__right">
              <div className={`d-grid  d-grid--disabled`} >
                  <button
                    className="cbtn btn-primary"
                  >
                    주문 취소
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryDetailLoadingComponent;
