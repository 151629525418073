import { GET_LIST_PRODUCT_FAILED, GET_LIST_PRODUCT_REQUEST, GET_LIST_PRODUCT_SUCCESS, UPDATE_RELOAD_PAGE_LIST } from "./types";

export interface DataState {
  lists?: any;
  type?: string;
  loadingListProduct?: boolean;
	total?: number;
	totalPage?: number;
	listParams?: object
}

const initialState: DataState = {
  lists: {
		data: [],
		total: 0,
		totalPage: 1,
	},
  type: "",
	loadingListProduct: false,
	listParams: {}
};

const ProductReducer = (state: DataState = initialState, action: any): DataState => {
  switch (action.type) {
    case GET_LIST_PRODUCT_REQUEST:
			let listsData = [];
			if (action.payload?.page > 1) {
				listsData = state?.lists?.data;
			}

      return {
        lists: {
					data: listsData,
					total: listsData.total,
					totalPage: listsData.totalPage,
				},
				listParams: {
					order_by: action.payload.order_by,
					order_dir: action.payload.order_dir,
					page: action.payload.page,
					state: action.payload.state,
					category_id: action.payload.category_id,
					query_field: action.payload.query_field,
					query_value: action.payload.query_value,
					reload: 0
				},
				loadingListProduct: true,
        type: action.type,
      };
      break;
		case GET_LIST_PRODUCT_SUCCESS:
			let {data} = action.payload
			let listData:any = [];
			if (data?.current_page === 1) {
				listData = [];
			} else {
				listData = state?.lists?.data;
			}
			
			return {
				lists: {
					data: [...listData, ...data.data],
					total: data.total,
					totalPage: data.last_page,
				},
				listParams: state.listParams,
				loadingListProduct: false,
				type: action.type,
			};
			break;
		case GET_LIST_PRODUCT_FAILED:
			return {
				...state,
				loadingListProduct: false,
				type: action.type,
			};
			break;
		case UPDATE_RELOAD_PAGE_LIST:
			return {
				...state,
				listParams: {
					...state.listParams,
					reload: action.payload.reload,
				},
				loadingListProduct: false,
				type: action.type,
			};
			break;
    default:
      return state;
  }
};

export default ProductReducer;
