import OrderHistoryList from "./pages/OrderHistoryList";
import OrderHistoryDetail from "./pages/OrderHistoryDetail";
import OrderCancel from "./pages/OrderCancel";
import CartList from "./pages/CartList";
import Payment from "./pages/Payment";
import PaymentFailed from "./pages/PaymentFailed";
import PaymentSuccess from "./pages/PaymentSuccess";
import OrderCancelDetail from "./pages/OrderCancelDetail";

const router = [
  {
    name: "CARTS_LIST",
    auth: false,
    path: "/carts-list",
    element: <CartList />,
  },
  {
    name: "ORDER_HISTORY_LIST",
    auth: false,
    path: "/order-history-list",
    element: <OrderHistoryList />,
    errorElement: <>errorr</>
  },
  {
    name: "ORDER_HISTORY_DETAIL",
    auth: false,
    path: "/order-history-detail/:id",
    element: <OrderHistoryDetail />,
  },
  {
    name: "ORDER_CANCEL",
    auth: false,
    path: "/order-cancel/:id",
    element: <OrderCancel />,
  },
  {
    name: "ORDER_CANCEL_DETAIL",
    auth: false,
    path: "/order-cancel-detail/:id",
    element: <OrderCancelDetail />,
  },
  {
    name: "PAYMENT",
    auth: true,
    path: "/payment",
    element: <Payment />,
  },
  {
    name: "PAYMENT_FAILED",
    auth: true,
    path: "/payment-failed",
    element: <PaymentFailed />,
    errorElement: <>errorr</>
  },
  {
    name: "PAYMENT_SUCCESS",
    auth: true,
    path: "/payment-success",
    element: <PaymentSuccess />,
  },
];
export default router;
