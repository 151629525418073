import {memo} from "react";
import CFormControl from "core/components/form-controls/CFormControl";
import VerifyInput from "core/components/form-controls/VerifyInput";
import CodeVerifyInput from "core/components/form-controls/CodeVerifyInput";
import AppLayout from "core/layouts/AppLayout";
import CTextArea from "core/components/form-controls/CTextArea";
import CDropdown from "core/components/form-controls/CDropdown";
import CImagePicker from "core/components/form-controls/CImagePicker";
import CPhoneInput from "../../../core/components/form-controls/CPhoneInput";
import CPassword from "../../../core/components/form-controls/CPassword";

const Common3Page = (props: any) => {
    const dataSet = [
        {id: 1, label: "Option 1"},
        {id: 2, label: "Option 2"},
        {id: 3, label: "Option 3"},
        {id: 4, label: "Option 4"}
    ]

    const ImageDataSet = [{"name": "playpay.consults_1691562992224090.png", "path": "/playpay/consults/playpay.consults_1691562992224090.png", "path_small": "small/playpay/consults/playpay.consults_1691562992224090.png", "origin_name": "LP5-01_온보딩 화면_Other.png", "path_medium": "medium/playpay/consults/playpay.consults_1691562992224090.png", "path_original": "original/playpay/consults/playpay.consults_1691562992224090.png", "path_thumbnail": "product/16/product_1619161391.jpg"}]
    const handleInputChange = (e: any) => {
        console.log(6, e.target.name, e.target.value)
    }

    const handleSubmit = (e: any) => {
        console.log(11, "SUBMIT")
    }

    return <AppLayout>
        <CPhoneInput
            label={"아이디"} placeholder={"비밀번호를 입력해주세요."}required={true} disabled={false} readonly={false}
            name={'phone'} className={""}
            value={'01011111111'}
            handleChange={handleInputChange}
            // error={"This is the error message"}
            warning={"타인의 명의 또는 번호를 동의 없이 입력 시, 추후 발생되는민·형사상의 책임은 본인에게 있음"}
        />
        <CPassword
            label={"비밀번호"}
            placeholder={'비밀번호를 입력해주세요.'}
            name={'password_login'}
            // required={true}
            handleChange={handleInputChange}
            showPassword={true}
            value={'123456'}
            maxLength={15}
            handleShowHideStatePassword={()=>{}}
            showIcon={true}
        />
        <CFormControl
            label={"아이디"} placeholder={"비밀번호를 입력해주세요."}
            type={"input"} required={true} disabled={false} readonly={false}
            name={'password'} className={""}
            handleChange={handleInputChange}
            // error={"This is the error message"}
            warning={"타인의 명의 또는 번호를 동의 없이 입력 시, 추후 발생되는민·형사상의 책임은 본인에게 있음"}
        />
        <hr/>
        <CFormControl label={"비밀번호"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} type={"empty"} required={true}>
            <CDropdown name={'category'} dataSet={dataSet} selected={1} placeholder={"선택해주세요."} handleChange={handleInputChange}/>
        </CFormControl>
        <hr/>
        <CFormControl label={"비밀번호"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} type={"password"} value={"123456"} required={true} handleChange={handleInputChange} showPasswordIcon={true}/>
        <CFormControl label={"비밀번호"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} type={"empty"} required={true} className={'verify-input-comp'}>
            <VerifyInput placeholder={"아이디(휴대전화 번호)를 입력해주세요."} button={"인증받기"} handleChange={handleInputChange} handleSubmit={handleSubmit}/>
            <CodeVerifyInput button={"확인"} countdown={120} handleChange={handleInputChange} handleSubmit={handleSubmit}/>
        </CFormControl>

        <CFormControl disabled={true} label={"비밀번호"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} placeholder={"새로운 비밀번호를 입력해주세요."} type={"password"} value={""} required={true} handleChange={handleInputChange}/>
        <hr/>
        <CFormControl label={"비밀번호"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} type={"empty"} required={true}>
            <CTextArea rows={10} placeholder={"내용은 2,000자 이내로 입력해주세요."} max={0} name={'content'} handleChange={handleInputChange}/>
        </CFormControl>
        <hr/>
        <CFormControl label={"비밀번호"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} type={"empty"} required={true}>
            <CDropdown name={'category'} dataSet={dataSet} placeholder={"선택해주세요."} handleChange={handleInputChange}/>
        </CFormControl>
        <hr/>
        <CFormControl label={"사진"} desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."} type={"empty"} required={true}>
            <CImagePicker
                multiple={true} dataSet={ImageDataSet} readonly={false} disabled={false}
                allows={['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/heic', 'image/heif']} maxSize={1024*1024*10} maxItems={5}
                handleError={(e:any)=>{alert(e.message)}}
                handleChange={()=>{}}
            />
        </CFormControl>
        <hr/>
        <div className="d-grid">
            <button className="cbtn btn-primary">완료</button>
        </div>
    </AppLayout>
}

export default memo(Common3Page);