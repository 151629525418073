import { Data } from './types';
import { FETCH_DATA, FETCH_DATA_SUCCESS, FETCH_DATA_ERROR } from './actions';

export interface DataState {
    data: Data[];
    loading: boolean;
    error: Error | null;
}

const initialState: DataState = {
    data: [],
    loading: false,
    error: null,
};

const authReducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    switch (action.type) {
        case FETCH_DATA:
            return { ...state, loading: true, error: null };
        case FETCH_DATA_SUCCESS:
            return { ...state, data: action.payload, loading: false, error: null };
        case FETCH_DATA_ERROR:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default authReducer
