import {useEffect, useState} from "react";

const CDialog = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        children: props.children || '',
        show: props.show || '',
        handleSubmit: props.handleSubmit || '',
        title: props.title || '',
        closeBtn: props.closeBtn || '',
        submitBtn: props.submitBtn || '',
        disableSubmitBtn: props.disableSubmitBtn || '',
        overlay : props?.overlay  || true
        // footer: props?.footer || true
    })

    const [isShow, setIsShow] = useState(props.show)
    useEffect(()=>{
        setIsShow(props.isShow);
        setProps(props)
        // if(props.isShow){
        //     document.body.classList.add('overflow-hidden');
        // }else {
        //     document.body.classList.remove('overflow-hidden');
        // }
        // return (() => {
        //     document.body.classList.remove('overflow-hidden');
        // })
    }, [props])

    const _handleClose = () => {
        setIsShow(false)
        if(typeof props.handleClose == 'function') props.handleClose(props.id, isShow)
    }

    const _handleSubmit= () => {
        if(typeof props.handleSubmit == 'function') props.handleSubmit()
    }

    return <>
        <div className={`c-dialog ${_propsState.show ? 'show' : ''} ${_propsState.className}`}>
            <div>
                <h3>{_propsState.title}</h3>
            </div>
            <div className="c-dialog-body">
                {_propsState.children}
            </div>
            <div className={`c-dialog-footer ${_propsState.closeBtn && _propsState.submitBtn ? ' full-btn' : ''}`}>
                {(_propsState.closeBtn) &&
                <button onClick={_handleClose} type="button" className="btn btn-block  m-0 cancel">{_propsState.closeBtn}</button>
                }
                {(_propsState.submitBtn) &&
                <button onClick={_handleSubmit} type="button" className="btn btn-block m-0 submit" disabled={_propsState.disableSubmitBtn}>
                    {_propsState.submitBtn}
                </button>
                }
            </div>
        </div>
        {_propsState.overlay ? <div className={'c-dialog-overlay'}></div> : ''}

    </>
}

export default CDialog