import React, {useEffect, useState, FunctionComponent} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'modules/AppSettings/assets/scss/list.scss'
import AppLayout from "core/layouts/AppLayout";
import IconHeader from "core/components/header/IconHeader";
import {RouterName} from "core/router";
import CListItem from "core/components/CListItem";





const SettingMenuPage: FunctionComponent = (props: any) => {

    const menuItems = [
        {
            name: "SETTING_PUSH_NOTICE",
            auth: true,
            path: RouterName.SETTING_PUSH_NOTICE,
            text:'Push 알림 설정',
            element: ''
        },
        {
            name: "TERMS_POLICY",
            auth: true,
            path: RouterName.TERMS_POLICY,
            text:'이용약관 및 정책',
            element: ''
        },
        {
            name: "DELETE_MEMBER",
            auth: true,
            path: "/delete-member",
            text:'회원 탈퇴',
            element: ''
        }
    ];

    const menuItemsTermsPolicy = [
        {
            name: "TERMS_OF_SERVICE",
            auth: true,
            path: RouterName.TERMS_OF_SERVICE,
            text:'이용약관',
            element: ''
        },
        {
            name: "POLICY_USE",
            auth: true,
            path: RouterName.POLICY_USE,
            text:'개인정보 처리방침',
            element: ''
        },

    ];

    const navigate = useNavigate();
    const [listMenu, setListMenu] = useState(menuItems)
    const [titlePage, setTitlePage] = useState('앱 설정')
    const pathName = window.location.pathname


    useEffect(() => {
        if(pathName == RouterName.TERMS_POLICY){
            setListMenu(menuItemsTermsPolicy)
            setTitlePage('이용약관 및 정책')
        }else {
            setListMenu(menuItems)
            setTitlePage('앱 설정')
        }
    }, [pathName])

    const goBack = () => {
        navigate(-1)
    }


    return (
        <AppLayout className={'settings-page'}>
            <IconHeader
                title={titlePage}
                iconLeft={true}
                handleLeftClick={goBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <div className={`settings-page__main`}>
                <div className={'settings-page__main__content'}>

                    <div className='box-content'>
                        {listMenu.map((item,key) =>(<CListItem
                            className={'CAccoditionItem'}
                            title={item?.text}
                            time={''}
                            icon={false}
                            type={1}
                            handleClick={()=>{navigate(item.path)}}
                            key={key}
                        />)) }
                    </div>

                </div>
            </div>
        </AppLayout>
    )
}


export default SettingMenuPage
