import { call, put, takeLatest } from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import {FETCH_DATA, LOGOUT_REQUEST} from './types';
import {fetchMeError, fetchMeSucess, logOutFaild, logOutSuccess} from "./actions";
import API from "core/networks/API";
import {API_FETCH_ME, API_LOGOUT} from "../api";
import {removeAllStorage} from "core/utils/helper";

function* fetchMe() {
    try {
        const res: AxiosResponse = yield call(API.get, API_FETCH_ME, {});
        yield put(fetchMeSucess(res.data.data));
    } catch (error) {
        console.log(14, error)
        yield put(fetchMeError());
    }
}

function* logOut() {
    try {
        removeAllStorage();
        let data = {
        };
        const response:AxiosResponse = yield call(API.post, API_LOGOUT, data);
        if (response.status == 200) {
            yield put(logOutSuccess(response));
        }else{
            yield put(logOutFaild(response));
        }

    } catch (error) {

    }
}


export default function* AuthSaga() {
    yield takeLatest(FETCH_DATA, fetchMe);
    yield takeLatest(LOGOUT_REQUEST, logOut);
}
