//carts
export const GET_LIST_CART = "GET_LIST_CART";
export const GET_LIST_CART_SUCCESS = "GET_LIST_CART_SUCCESS";
export const GET_LIST_CART_FAILED = "GET_LIST_CART_FAILED";

export const ADD_CART = "ADD_CART";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAILED = "ADD_CART_FAILED";

export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const UPDATE_CART_FAILED = "UPDATE_CART_FAILED";

export const UPDATE_OR_CREATE_CART = "UPDATE_OR_CREATE_CART";

export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILED = "DELETE_CART_FAILED";

export const GET_NUMBER_CART = "GET_NUMBER_CART";
export const UPDATE_STATUS_PRODUCT = "UPDATE_STATUS_PRODUCT";
export const RESET_CART_ERRORS = "RESET_CART_ERRORS";

// order history
export const GET_LIST_ORDER_HISTORY_REQUEST = 'GET_LIST_ORDER_HISTORY_REQUEST';
export const GET_LIST_ORDER_HISTORY_SUCCESS = 'GET_LIST_ORDER_HISTORY_SUCCESS';
export const GET_LIST_ORDER_HISTORY_FAILED = 'GET_LIST_ORDER_HISTORY_FAILED';
export const UPDATE_RELOAD_PAGE_LIST = 'UPDATE_RELOAD_PAGE_LIST';
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILED = 'CANCEL_ORDER_FAILED';

//payment
export const UPDATE_DATA_PAYMENT = 'UPDATE_DATA_PAYMENT';
export const RESET_DATA_PAYMENT = 'RESET_DATA_PAYMENT';
export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILED = 'GET_STORES_FAILED';
export const GET_LAST_ORDER = 'GET_LAST_ORDER';
export const GET_LAST_ORDER_SUCCESS = 'GET_LAST_ORDER_SUCCESS';
export const GET_LAST_ORDER_FAILED = 'GET_LAST_ORDER_FAILED';