import { numberFormat } from "core/utils/helper";
import '../assets/scss/cartlistpage.scss'
import { MINIMUM_PAYMENT } from "core/constants/Commons";

interface CartPaymentTotal {
  carts: any;
  productSelectedInCarts: any;
  orderPayment: () => void;
}

const CartPaymentTotalComponent = ({
  carts,
  productSelectedInCarts,
  orderPayment,
}: CartPaymentTotal) => {
  const handleClick = () => {
    console.log('verify current cart with admin');
    orderPayment();
  };

  const renderTotal = (data: any) => {
    let totalValue = 0;
    if (data && data.length > 0) {
      totalValue = data
      .filter((item: any) => +item?.priceByQuantity)
      .map((item: any) => +item?.priceByQuantity)
      .reduce((sum: any, current: any) => sum + current);
    }
    return totalValue
  }

  return (
    <div className="cart-payment-total">
      <div className="cart-payment-total__info">
        <label htmlFor="">선택된 {productSelectedInCarts.length}개 상품</label>
        <div className="payment-total">
          <label className="title" htmlFor="">
            총 결제 금액
          </label>
          <label className="total" htmlFor="">
            {productSelectedInCarts.length > 0 ? numberFormat(renderTotal(productSelectedInCarts)) : 0}원
          </label>
        </div>
        <div className="d-grid">
          <button className="cbtn btn-primary" disabled={productSelectedInCarts.length === 0 || renderTotal(productSelectedInCarts) < MINIMUM_PAYMENT} onClick={() => handleClick()}>
            구매하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartPaymentTotalComponent;
