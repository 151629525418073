import AddressBook from "./pages/AddressBook";
import AddressCreateForm from "./pages/AddressCreateForm";

const router =  [
    {
        name: "ADDRESS_BOOK",
        auth: true,
        path: "/address",
        element: <AddressBook/>
    },
    {
        name: "ADDRESS_CREATE",
        auth: true,
        path: "/address-create",
        element: <AddressCreateForm/>
    },
    {
        name: "ADDRESS_UPDATE",
        auth: true,
        path: "/address-update/:id",
        element: <AddressCreateForm/>
    },
]
export default router
