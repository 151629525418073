import IconHeader from "core/components/header/IconHeader";
import AppLayout from "core/layouts/AppLayout";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import  '../assets/scss/paymentSuccess.scss';
import paymentSucessImg from '../assets/icon/payment_success.svg'
import { RouterName } from "core/router";
import { useDataPayment, useUpdateDataPayment } from "../hooks/usePayment";
import { useEffect, useState } from "react";
import { useCarts, useDeleteCarts } from "../hooks/useCarts";
import { updateStatusProduct } from "../store/actions";
import { useDispatch } from "react-redux";
import { useFetch } from "core/hooks/useFetch";
import { API_ORDER_HISTORY } from "../api";
import { numberFormat } from "core/utils/helper";
import { useUpdateReload } from "../hooks/useLists";
import { installmentMonth } from "../constants/Time";
import SkeletonComponent from "core/components/Skeleton/skeleton";
const PaymentSuccess = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [, dataPayment] = useDataPayment({});
  const carts = useCarts({isSelected: true});
  const dataCarts = useCarts({});
  const [handleUpdate, handleReset] = useUpdateDataPayment({});
  const [paramsDetail, setParamsDetail] = useState<any>({
    id: searchParams.get('order_id') || null,
    'relation[]': ['creator', 'items', 'shippingApp']
  });
  const updateReload = useUpdateReload();
  const {
    data: orderDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({ url: API_ORDER_HISTORY.GET_DETAIL, options: paramsDetail });

  console.log('loadingDetail', loadingDetail);
  
  const dispatch = useDispatch();
  const _onSuccess = (data: any) => {
    if (dataPayment.type !== 'DETAIL_PAYMENT') {
      if (data.length < 1) {
        if (handleReset) {
          handleReset({})
        }
        navigate(RouterName.CARTS_LIST);
      }
    }
  }
  const _onFailed = () => {}
  const [loadingDel, onDelete] = useDeleteCarts({onSuccess: _onSuccess, onFailed: _onFailed});
  const handleClose = () => {
    navigate(RouterName.MAIN)
  }

  const goOrderHistory = () => {
    navigate(RouterName.ORDER_HISTORY_LIST, {
      state: {
        back_type: RouterName.PAYMENT_SUCCESS, 
        back_url: RouterName.MAIN
      }
    })
  }

  (window as any).backFromDeviceAndroid = function() {
    handleClose()
  }

  useEffect(() => {
    if (handleReset) {
      handleReset({})
    }
    if (updateReload) {
      updateReload({
        reload: 0
      })
    }
    if (dataPayment.type === 'CART_PAYMENT') {
      if (onDelete) {
        onDelete(carts);
      }
      dispatch(updateStatusProduct({dataCarts, isSelected: true}));
    }
  }, [])



  const renderTypeCardContent = () => {
    return <>
            <div className="content__reason">
              <div className="img text-center mb-5">
                <img src={paymentSucessImg} alt="" />
              </div>
              <p className="top">주문해 주셔서 감사합니다.</p>
              <p className="bottom">결제가 완료 되었습니다.</p>
            </div>
            <div className="content__method">
              <p className="mb-5">주문번호 {orderDetail.data?.id || ''}</p>
            </div>
            <div className="info">
              <p>
                <label htmlFor="">결제 금액</label>
                {
                  loadingDetail ? <SkeletonComponent width={90} height={23}/> : <span className="payment-amount">{numberFormat(orderDetail.data?.amount || 0)} 원</span>
                }
                
              </p>
              <p>
                <label htmlFor="">결제 일시</label>
                <span>{orderDetail.data?.paid_at}</span>
              </p>
              <p className="payment-method">
                <label htmlFor="">결제 방법</label>
                {
                  loadingDetail ? <SkeletonComponent width={90} height={21}/> : <span>{orderDetail.data?.payment_data?.card_number} </span>
                }
                
              </p>
              <p className="payment-method">
                <label htmlFor=""></label>

                {
                   loadingDetail ? <SkeletonComponent width={90} height={21}/> : <span>
                   {
                    orderDetail?.data?.payment_method === 'CARD_MANUAL' ? '신용카드 (수기결제)' : '신용카드'
                   } 
                   {orderDetail?.data?.payment_data?.install_month && (Object.keys(installmentMonth)).includes(orderDetail?.data?.payment_data?.install_month) ? 
                       <><span> ({installmentMonth[orderDetail?.data?.payment_data?.install_month].label})</span></>
                       : ""
                     }
                 </span>
                }
              </p>
            </div>
    </>
  }

  const renderTypeMoneyContent = () => {
    return <>
            <div className="content__reason content__reason--money">
              <div className="img text-center mb-5">
                <img src={paymentSucessImg} alt="" />
              </div>
              <p className="bottom">주문해 주셔서 감사합니다.</p>
              <p className="top">입금이 완료되어야 주문접수 됩니다.</p>
            </div>
            <div className="content__method content__method--money">
              <p className="mb-3">주문번호 {orderDetail.data?.id || ''}</p>
            </div>
            <div className="notes">
              <p>주문 후, <span>2시간 이내 미 입금시 주문 취소</span> 될 수 있습니다.</p>
            </div>
            <div className="info">
            <p>
              <label htmlFor="">결제 금액</label>
              {
                loadingDetail ? <SkeletonComponent width={90} height={23}/> : <span className="text-highlight">{numberFormat(orderDetail.data?.payment_data?.amount || 0)} 원</span>
              }
              
            </p>
            <p>
              <label htmlFor="">계좌번호</label>
              {
                loadingDetail ? <SkeletonComponent width={150} height={21}/> : <span>{orderDetail.data?.payment_data?.receiver_account_number}</span>
              }
              
            </p>
            <p>
              <label htmlFor="">예금주명</label>
              {
                loadingDetail ? <SkeletonComponent width={150} height={21}/> : <span>{orderDetail.data?.payment_data?.receiver_account_name}</span>
              }
              
            </p>
            </div>
    </>
  }

  const renderTypeCODContent = () => {
    return <>
            <div className="content__reason content__reason--money">
              <div className="img text-center mb-5">
                <img src={paymentSucessImg} alt="" />
              </div>
              <p className="bottom">주문해주셔서 감사합니다.</p>
              <p className="top">신용카드_현장 결제 요청이 완료되었습니다.</p>
            </div>
            <div className="content__method content__method--money">
              <p className="mb-3">주문번호 {orderDetail.data?.id || ''}</p>
            </div>
            <div className="notes">
              <p>현장 결제 요청건은 <span>가맹점 확인 후 주문 접수가 완료</span>됩니다.</p>
            </div>
            <div className="info">
            <p>
              <label htmlFor="">결제 금액</label>
              {
                loadingDetail ? <SkeletonComponent width={90} height={23}/> : <span className="text-highlight">{numberFormat(orderDetail.data?.payment_data?.amount || 0)} 원</span>
              }
              
            </p>
            <p>
              <label htmlFor="">결제 일시</label>
              {
                loadingDetail ? <SkeletonComponent width={150} height={21}/> : <span>{orderDetail.data?.created_at}</span>
              }
              
            </p>
            <p>
              <label htmlFor="">결제 방법</label>
              {
                loadingDetail ? <SkeletonComponent width={150} height={21}/> : <span>신용카드 (현장 결제)</span>
              }
              
            </p>
            </div>
    </>
  }

  const renderPaymentContent = (data: any) => {
    switch (data?.payment_method) {
      case 'CARD':
        return renderTypeCardContent();
      case 'MONEY':
        return renderTypeMoneyContent();
      case 'COD':
        return renderTypeCODContent();
      default:
        return renderTypeCardContent();
    }
  }

  return (
		<AppLayout className={"payment-success"}>
			<main>
        <div className="content">
          {renderPaymentContent(orderDetail.data)}
          <div className="content__button">
            <div className="item row">
                <div className="col-6 item__left">
                  <div className={`d-grid disabled`} >
                    <button
                      disabled={loadingDetail}
                      className="cbtn btn-primary"
                      onClick={() => handleClose()}
                    >
                      닫기
                    </button>
                  </div>
                </div>
                <div className="col-6 item__right">
                  <div className={`d-grid`}>
                    <button
                      disabled={loadingDetail}
                      className="cbtn btn-primary"
                      onClick={() => goOrderHistory()}
                    >
                      구매 내역보기
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
			</main>
	</AppLayout>
  )
}

export default PaymentSuccess;