import IconHeader from "core/components/header/IconHeader";
import AppLayout from "core/layouts/AppLayout";
import { RouterName } from "core/router";
import "modules/Policy/assets/scss/policy.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TermsOfService = (props: any) => {
  const navigate = useNavigate();
  const params = window.location.search;
  const [stateHeaderRight, setStateHeaderRight] = useState(false)

  useEffect(() => {
    if (params && params.includes('sign-up=true')) {
    } else {
      setStateHeaderRight(true)
    }
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (e: any) => {
    navigate(-1)
    if (typeof props?.handleClick == 'function') {
      props?.handleClick(e)
    }
  }


  return (
    <AppLayout className={'terms-of-service'}>
      <IconHeader
        title={'이용약관'}
        iconLeft={true}
        handleLeftClick={handleClick}
        handleRightClick={() => {
          navigate(RouterName.MAIN)
        }}
        iconRight={stateHeaderRight}
      />
      <div className={'terms-of-service__main'}>

        <div>
          <div>
            <p><strong>이용약관</strong></p>
            <p><strong>제1조 목적</strong></p>
            <p><span style={{ fontWeight: 400 }}>이 약관은 ㈜살구나무집(이하 “회사”라 한다)가 운영하는 스마트폰 및 이동통신기기를 통해 도시락 배달 모바일 애플리케이션(이하 “모바일 App”이라 한다)을 통해 제공되는 서비스(이하 “서비스”라 한다)의 이용에 관련하여 권리 의무 및 관련 절차 등을 규정하고 상호 발전을 도모하는 것을 그 목적으로 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제2조 용어의 설명</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- '살구나무집 도시락'이라 함은 회사에서 제공하는 서비스를 통해 이용자가 원하는 재화 등을 주문하면, 주문이 완료된 재화 등을 가게가 이용자에게 배달하는 서비스로 회사와 계약한 적립금을 제공하는 모바일 서비스를 말합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- '회원'이라 함은 회원제로 운영하는 서비스를 이용하는 이용자를 의미합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- '이용계약'이라 함은 서비스 이용과 관련하여 회사와 이용고객 간에 체결 하는 계약을 말합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- '이름'이라 함은 회원식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 본인의 실명을 말합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- '핸드폰번호'라 함은 이용고객이 부여 받은 이용자이름과 일치된 이용고객 임을 확인하고 이용고객의 권익보호를 위하여 이용고객이 선정한 숫자를 말합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- '추천인번호'라 함은 회사가 이용자에게 임의의 적립을 목적으로 회원을 구별하기위한 번호를 말합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>-&nbsp; ‘적립금‘이라함은 회사가 제공하는 서비스를 이용할 때 현금처럼 사용할 수 있는 회사 전용의 사이버 화폐를 말합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 안내에서 정하는 바에 의합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제3조 적립금</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 서비스를 이용하고 완료 건에 해당하는 수만큼의 회사와 계약된 적립금을 부여받게 됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 적립금 1점은 1원으로 환산함을 원칙으로 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 적립금은 회사가 정한 방식에 따라 회원이 지급받을 수 있으며, 이 경우 회사는 회원이 지정하는 은행계좌에 상기 금액을 입금함으로써 적법하게 지급한 것으로 봅니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 회원은 적립금을 타인에게 양도하거나 대여 또는 담보의 목적으로 이용할 수 없습니다. 다만, 회사가 인정하는 적법한 절차를 따른 경우는 예외로 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>5. 적립금의 현금이체에 따른 수수료는 이체한건당 500원이며 회원이 부담합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>6. 회원은 서비스의 문제로 누락,소멸되는 적립금을 회사에 제시하면 보상 받을 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>7. 회사는 회원의 적립금을 관리하고 운영하는 역할을 담당합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>8. 적립금은 본인이 1회 이상 상품 주문을 이용하신 후 출금하실 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>9. 적립금은 출금시 10,000원당 본인이 1회이상 상품 주문을 이용하고 출금 할 수 있습니다.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}> 예) 20.000원 출금 2회 이상 이용 30,000원 출금 3회이상 이용</span></p>
            <p><span style={{ fontWeight: 400 }}>10. 회원이 상품 주문을 이용하지 아니하고 추천을 목적으로 적립만 하였을때는 지급하지 않을수도 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>11.  적립금은 본인 주문 금액의 3%, 추천인 주문 금액의 3%, 추천인 적립금은 2,000원 입니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>12.  1년이상 본인 이용실적이 없는 경우 그동안의 적립금은 회사로 귀속된다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제4조 약관의 게시와 개정</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 이 약관은 모바일 App에 그 내용을 이용자가 알 수 있도록 게시함으로써 효력을 발생하며 "회사"는 약관의 규제 등에 관한 법률, 전자거래 기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률, 소비자 보호법 등 관련법을 위배하지 않는 범위에서 개정할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 서비스 초기 화면에 상호, 소재지, 대표자의 성명, 사업자등록번호, 연락처 등과 함께 게시합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. "회사"는 약관을 개정할 경우, 적용일자 및 개정 사유를 명시하며 약관에 동의하는 것은 정기적으로 웹을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제5조 약관의 해석</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 해당 약관에서 정하지 아니한 사항 및 이 약관의 해석에 대해서는 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계 법령 또는 상관례에 따릅니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 “약관의 규제에 관한 법률”, “전자거래 기본법”, “전자서명법”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”(이하 “정보통신망법”), 개인정보보호법 등 관련법에 위배하지 않는 법위에서 이 약관을 개정할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 사이트의 초기화면에 적용일자 30일 이전부터 적용일자 전일까지 공지합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제6조 이용계약 성립</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 이용계약은 회원이 되고자 본 약관에 동의한 후 회사가 정한 양식에 따라 회원정보를 기입하여 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 제1항과 같이 회원으로 가입신청 시 다음 각 호에 해당하는 신청에 대하여는 서비스 제공을 거부하거나 제한할 수 있습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 타인 명의의 신청 또는 이름이 실명이 아닌 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 허위 서류를 첨부하거나 허위내용을 기재하여 신청하는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 신용정보의 이용과 보호에 관한 법류에 의한 PC통신, 인터넷 서비스의 신용불량자로 등록되어 있는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청한 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 정보통신 윤리위원회에 PC통신, 인터넷 서비스의 불량 이용자로 등록되어 있는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 기타 회사가 정한 이용신청요건이 만족되지 않았을 경우</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사가 설비의 여유가 없는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사의 기술상 지장이 있는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 기타 회사의 귀책사유로 이용승낙이 곤란한 경우</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 회사는 규정에 의하여 이용신청이 불 승낙되거나 승낙을 제한하는 경우에는 이를 이용신청 고객에게 즉시 알려야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>5. 회사는 이용신청고객이 미성년자인 경우에는 별도로 정하는 바에 따라 승낙을 제한할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>6. 회사는 본 서비스를 이용하는 회원에 대하여 등급별로 구분하여 서비스의 이용에 차등을 둘 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제7조 유의사항</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 살구나무집 도시락 사용을 추천하기 위한 목적이 아닌 판매하거나 상업적인 목적으로 남용하여 적발시에는 즉시 해당포인트는 무효화 처리가 됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 친구 추천 이벤트는 사전에 통지 없이 변경 또는 중지 될 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제8조 회원정보의 변경</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회원은 나의정보 수정화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제9조 개인정보보호 의무</strong></p>
            <p><span style={{ fontWeight: 400 }}>회사는 "정보통신망법 및 개인정보보호법" 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 회사는 이용자의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제10조 회원의 아이디 및 비밀번호의 관리에 대한 의무</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 되며 관리소홀로 인해 발생되는 모든 결과의 책임은 회원 본인에게 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회원은 ID 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제11조 회원의 의무</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회원은 다음 행위를 하여서는 안됩니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 신청 또는 변경 시 허위내용의 등록</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 타인의 정보 도용 / 타회원의 ID 및 비밀번호를 사용하는 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사가 게시한 정보의 변경</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사가 제공하는 서비스를 통하여 얻은 정보를 회사의 사전 승낙 없이 허가용도 이외의 목적으로 사용하거나 복제, 유통, 상업적으로 이용하려는 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 사회질서에 위배되는 외설/폭력적 메시지 유포/범죄행위를 목적으로 하거나 범죄를 교사하는 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 관계법령에 위배되거나 미풍양속을 저해하고자 하는 목적으로 회원가입 신청을 할 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 기타 불법적이거나 부당한 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 정보 서비스를 위해 하거나, 혼란을 일으키는 해킹, 컴퓨터 바이러스 전염, 유포하는 행위</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회원은 이 약관에 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 하며 회사가 공지하거나 별도로 게시한 사항을 준수하여야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회원은 회사의 명시적인 사전 동의가 없이 서비스를 이용하여 영업활동을 할 수 없으며, 이에 위반하여 발생한 결과에 대하여 회사는 책임지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 회원은 이와 같은 영업활동과 관련하여 회사에 대하여 손해배상의무를 집니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>5. 회원은 서비스의 이용약관, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>6. 회원은 회사의 사전 승낙 없이는 서비스의 전부 또는 일부 내용 및 기능을 전용할 수 없습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제12조 회사의 의무</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 회원으로 부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 서면, 전자우편 또는 전화등으로 통보하여야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 회사가 제정한 개인정보보호정책에 따라서 이용고객의 개인정보를 보호할 의무를 가집니다. 단, 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않을 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사가 제 2 항의 규정에도 불구하고 고지 또는 명시한 범위를 초과하여 이용고객의 개인 정보를 이용하거나 제 3 자에게 제공하고자 하는 경우에는 반드시 해당 회원에게 개별적으로 공지하여 동의를 받아야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 지체없이 이를 수리 또는 복구합니다. 다만, 천재지변, 비상사태 또는 그밖에 부득이한 경우에는 그 서비스를 일시 중단하거나 중지할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>5. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관련 절차 및 내용등에 있어 회원에게 편의를 제공해야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>6. 회사는 업무와 관련하여 회원의 사전동의 하에 회원전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있고 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송 할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의한 서비스 이용이 변경되는 것은 회원의 책임입니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>7. 회사는 이용고객이 방문하거나 전자서명 또는 아이디(ID)등을 이용하여 자신의 개인정보에 대한 열람 또는 정정을 요구하는 경우에는 본인 여부를 확인하고 지체없이 필요한 조치를 취하여야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>8. 회사는 이용고객의 대리인이 방문하여 열람 또는 정정을 요구하는 경우에는 대리관계를 나타내는 증표를 제시하도록 요구할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>9. 회사는 개인정보와 관련하여 이용고객의 의견을 수렴하고 불만을 처리하기 위한 절차를 마련하여야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제13조 서비스 이용 시간</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 업무상/운영상/기술상 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 서비스는 연중무휴, 1일 24시간 서비스를 제공함을 원칙으로 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 서비스제공화면에 공지한 바에 따릅니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제14조 개인정보 수집</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 적법하고 공정한 수단에 의하여 서비스이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 서비스 및 계약의 이행에 불필요한 민감정보 및 고유식별정보를 수집하지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제15조 회원탈퇴 및 자격 상실 등</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회원은 언제든지 내 정보 화면을 통하여 이용계약 해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회원이 계약을 해지할 경우, 관련법령에 따른 정보보유 사유에 따라 회사가 관련 정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전 통지 없이 자격을 제한/정지할 수 있습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 타인의 ID및 비밀번호를 도용할 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회원가입 시 이용계약에 위반되는 허위사실을 기입하였을 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 다른 회원이 본 사이트를 이용하는 것을 방해하거나 그 정보를 도용하는 등 거래질서를 위협하는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 웹사이트 및 모바일App을 이용하여 기타 관련 법률과 이 약관이 금지하는 행위를 하거나 미풍양속에 반하는 행위를 하는 경우</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제16조 개인정보 파기 및 제3자 제공의 금지</strong></p>
            <p><span style={{ fontWeight: 400 }}>회사는 회원이 탈퇴하거나 제16조에 따라 동의를 철회하는 경우, 지체 없이 수집된 회원정보를 파기하는 등 필요한 조치를 취합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 관련 법령에 특별한 규정이 있는 경우나 이용자의 별도 동의를 받지 않는 한 개인정보를 제3자에게 제공하지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 고객이 서비스 가입 등을 위해 기재한 개인정보는 회원 탈퇴 등 이용목적이 달성된 후 내부 방침에 따라 지체 없이 파기합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 파기방법</span></p>
            <p><span style={{ fontWeight: 400 }}>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기한다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 조치를 통하여 삭제한다.</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 개인정보 유효기간 제도 도입 (1년/3년/5년)</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 당사는 회원의 경우 장기간(1년 이상) 서비스 미이용자의 개인정보보호를 위하여 2015년 8월 18일부로 다른 이용자의 개인정보와 분리하여 저장, 관리합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 미이용의 기간은 최종 로그인 일자 및 주문이력으로 산정하며 법령에서 정의한 기간 동안 서비스 미이용자에 대하여 분리, 저장, 관리합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 당사는 미이용자 개인정보 분리/저장시점 도래 1개월 이전에 이메일 등을 통해 해당 이용자에게 관련내용을 공지합니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 분리 저장된 미이용자 개인정보는 관련 법령에 따라 1년간 보유하며, 해당 기간 종료 후 파기됩니다. 해당 기간이 지나지 않아 파기되지 않은 개인정보는 해당 회원의 요청에 따라 서비스 이용을 재개하는 시점에 다시 제공됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제17조 개인정보 동의 철회, 열람 또는 정정</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 이용자는 언제든지 개인정보의 수집 및 이용, 목적 외의 사용에 대한 동의를 철회할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회원은 언제든지 나의 정보에서 자신의 개인정보를 열람할 수 있으며, 스스로 정보 및 오류를 수정할 수 있습니다. 그 자세한 방법은 본 사이트의 공지, 서비스이용안내에서 정한 바에 따릅니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제18조 정보의 제공</strong></p>
            <p><span style={{ fontWeight: 400 }}>회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제19조 게시물의 관리</strong></p>
            <p><span style={{ fontWeight: 400 }}>회사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 불법복제 또는 해킹을 조장하는 내용인 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 영리를 목적으로 하는 광고일 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 범죄와 결부된다고 객관적으로 인정되는 내용일 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 기타 관계법령에 위배된다고 판단되는 경우</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제20조 게시물 삭제</strong></p>
            <p><span style={{ fontWeight: 400 }}>회사는 회원이 게시하거나 제공하는 서비스 내용이 다음 각 호에 해당한다고 판단될 경우 사전 통지 없이 삭제할 수 있습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 다량의 정보를 전송하여 서비스의 안정적 운영을 방해하는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 허위 사실 유포 및 수신자의 의사에 반하는 광고성 정보를 전송하는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 공공질서 및 미풍양속에 위반되는 내용인 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 제3자의 지적재산권에 침해하는 내용인 경우</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제21조 게시물에 대한 저작권</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제 13조 각 호에 해당된다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제22조 회원에 대한 통지</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사가 회원에 대한 통지를 하는 경우 회원의 전자우편주소로 발송할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사의 불특정다수 회원에 대하여 통지를 하는 경우, 1주일 이상 회사의 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사는 정보통신설비의 문제로 서비스의 제공을 일시적으로 중단할 경우 또는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제23조 권리의 귀속</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 서비스와 관련된 저작권 등 일체의 지적재산권은 회사에 귀속됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 이용자는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 웹사이트 및 모바일 App에 이용자가 게재한 자료와 게시물에 대한 권리와 책임은 게시 당사자에게 있으며 회사는 이용자의 동의 없이 이를 영리적인 목적으로 사용하지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제24조 광고게재 및 광고주와의 거래</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고게재를 통한 수익으로부터 나옵니다. 회원은 서비스 이용시 노출되는 광고게재에 대해 동의합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제25조 서비스 이용제한</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 회원이 서비스 이용내용에 있어서 본 약관 제11조, 제15조 내용을 위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 미풍양속을 저해하는 비속한 ID 및 별명 사용</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 기타 정상적인 서비스 운영에 방해가 될 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 불법 웹사이트인 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 상용소프트웨어나 크랙파일을 올린 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 정보통신윤리 위원회의 심의 세칙 제 7조에 어긋나는 음란물을 게재한 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 저작권이 있는 글을 무단 복제하거나 mp3를 올린 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>-정보통신 설비의 오작동이나 정보등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램등 을 유포하는 경우</span></p>
            <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>-회원이 당사와 경쟁관계에 있는 동종업계로 사업 진출시에는 당사는 해당 회원을 탈퇴 시킬수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시정지, 정지, 이용계약 해지 등을 불량이용자 처리규정에 따라 취할 수 있습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제26조 손해배상의 범위 및 청구</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 서비스로부터 회원이 받은 손해가 천재지변등 불가항력적이거나 회원의 고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지 아니합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 전자상거래 호스팅 및 일반 호스팅의 경우 이에 준하는 서비스 이용회원일 경우 불가항력적으로 발생한 경우 위 1 항의 규정에 따릅니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회원이 서비스를 이용함에 있어 행한 불법행위로 인하여 회사가 당해 회원 이외에 제 3 자로부터 손해배상 청구, 소송을 비롯한 각종의 이의제기를 받는 경우 당해 회원은 회사의 면책을 위하여 노력하여야 하며, 만일 회사가 면책되지 못한 경우는 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제27조 책임제한</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사는 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>4. 회사는 이용자 간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>5. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><strong>제28조 분쟁 시 준거법과 관할법원</strong></p>
            <p><span style={{ fontWeight: 400 }}>1. 회사와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 한다.</span></p>
            <p><span style={{ fontWeight: 400 }}>2. 전항의 경우에도 불구하고 분쟁으로 인한 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 관할법원으로 하여 해결한다.</span></p>
            <p><span style={{ fontWeight: 400 }}>3. 회사와 이용자 간에 발생하는 분쟁에 관하여는 국내법을 적용한다.</span></p>
            <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight: 400 }}>이 약관은 살구나무집 도시락에서 2023년 8월 23일부터 시행합니다.</span></p>
            <p>&nbsp;</p>
          </div>

        </div>

      </div>
    </AppLayout>
  )

};

export default TermsOfService;