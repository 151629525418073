import {useEffect, useState, FunctionComponent} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'modules/Faq/assets/scss/list.scss'
import AppLayout from "core/layouts/AppLayout";
import IconHeader from "core/components/header/IconHeader";
import API from "core/networks/API";
import {API_LIST_FAQ} from "modules/Faq/api";
import Empty from "core/assets/images/icon_empty_faq.svg";
import CEmptyIcon from "core/components/CEmptyIcon";
import {RouterName} from "core/router";
import CAccoditionItem from "core/components/CAccoditionItem";
import ContentDetailComponent from "modules/Announcement/conponents/ContentDetailComponent";
import { useOpenBrowser } from "core/hooks/useOpenBrowser";
import { toast } from "react-toastify";


const ListFaqPage: FunctionComponent = (props: any) => {
    const navigate = useNavigate();
    const [listData, setListData] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const [stateDataEmpty, setStateDataEmpty] = useState(false)
    const [active, setActive] =  useState<any>(null)


    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - 10
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(() => {
        countSize()
        fetchData()
    }, [])

    const goBack = () => {
        navigate(RouterName.MY_INFO)
    }


    const fetchData = async () => {
        let dataRequest = {
            page:0,
            order_by:'created_at',
            order_dir:'desc'
        }
        try {
            const {data: response} = await API.get(`${API_LIST_FAQ}`, dataRequest)
            if(response && response?.code == 200){
                setListData(response?.data)
                setLoading(false)
                if(Object.keys(response?.data || []).length == 0){
                    setStateDataEmpty(true)
                }
                
            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response
            setLoading(true)
        }

    }

    const _handleClickOpen = (data:any) =>{
        
        if(data?.state){
            setTimeout(()=>{
                handleClickTagLink()
            },300)
        }
        if (active && active?.id === data?.id) {
            setActive(null);
        } else {
            setActive(data);
        }
    }



    const textTypeFaq = (type:any) => {
        let typeText = ''
        switch(type) {
            case 'ACCUMULATE_MONEY':
                typeText = '적립금'
                break;
            case 'FAIL':
                typeText = '오류'
                break;
            case 'DELIVERY_ORDER':
                typeText = '배달/주문'
                break;
            case 'WITHDRAW':
                typeText = '출금'
                break;
            default:
                typeText = '기타'
        }
        return typeText
    }

    const renderItemFaq = (data:any,type:any) => {
        
        return <div className='box-content' id="box-content">
                <div className={'box-content__type'}>
                    <h4>{textTypeFaq(type)}</h4>
                </div>
                {data && data.length > 0 && data.map((item:any,key:number)=>{
                    // <ContentDetailComponent data={item?.content} id='box-content' />
                    return (<CAccoditionItem
                        className={'CAccoditionItem'}
                        question={item.title}
                        answer={<div
                        dangerouslySetInnerHTML={{__html: item.content}}
                      />}
                        key={key}
                        item={item}
                        expandSingle={true}
                        handleClickOpen={_handleClickOpen}
                        isShow={active}
                    />)
                })}
            </div>
    }

    

    const handleDirect = useOpenBrowser();

    const handleHref = (link:any) => {
        if(link?.target?.getAttribute('data-original-href')){
            handleDirect(link?.target?.getAttribute('data-original-href'))
        }
    }

    const handleClickTagLink = () =>{
        const container: any = document.getElementById('list-panel');
        if(container){
            const targetA = container.querySelectorAll('a[href]');
            targetA.forEach((tagA: any) => {
                const originalHref = tagA.href;
                tagA.addEventListener('click', handleHref);
                tagA.href = '#';
                tagA.setAttribute('data-original-href', originalHref);
            });
        }
    }

    return (
        <AppLayout className={'faq-page'}>
            <IconHeader
                title={'자주하는 질문'}
                iconLeft={true}
                handleLeftClick={goBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
                id={'main-header'}
            />
            <div className={`faq-page__main  ${stateDataEmpty ? ' empty-main' : ''}`} id='list-panel'>
                <div className={'faq-page__main__content'}>

                    {stateDataEmpty?  <CEmptyIcon
                        icon={Empty}
                        text={'아직 등록된 자주하는 질문이 없습니다.'}
                        description={''}
                    /> : ''}

                    {
                        !stateDataEmpty && Object.keys(listData) && Object.keys(listData).length > 0 &&
                        Object.keys(listData).map((item:any,key:number)=>(
                            renderItemFaq(Object.values(listData)[key],item)
                        ))
                    }

                </div>
            </div>
        </AppLayout>
    )
}


export default ListFaqPage
