
import 'modules/Accumulate/assets/scss/accumulate.scss'
import AppLayout from "core/layouts/AppLayout";
import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {numberFormat, phoneNumberFormat} from "core/utils/helper";
import moment from "moment";
import CEmptyIcon from "core/components/CEmptyIcon";
import PlusIcon from "core/assets/images/icon/plus.svg";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';

import ACMICON from 'modules/Accumulate/assets/icon/income.svg'
import WDRICON from 'modules/Accumulate/assets/icon/withdraw.svg'
import RQSICON from 'modules/Accumulate/assets/icon/request.svg'
import EMPTYICON from 'modules/Accumulate/assets/icon/empty.svg'
import ARROW_DOWN_ICON from 'modules/Accumulate/assets/icon/icon_arrow_down_gray.svg'
import SkeletonComponent from "core/components/Skeleton/skeleton";
import API from "core/networks/API";
import {API_ACCUMULATE_TRANSACTION, API_OWN_BALANCE} from "../api";
import InfinityScrollContainer from "../../../core/layouts/InfinityScrollContainer";
import CFormControl from 'core/components/form-controls/CFormControl';
import CDropdown from 'core/components/form-controls/CDropdown';


const ACCUMULATE_STATE = {
    GRANT: {
      SIGNUP_CREDITS: {
        title: "회원가입",
        icon: ACMICON,
        style: "plus",
        label: "적립"
      },
      SIGNUP_REFERRAL_CREDITS: {
        title: "추천인 가입",
        icon: ACMICON,
        style: "plus",
        label: "적립" 
      },
      PURCHASE_CREDITS: {
        title: "사용 적립",
        icon: ACMICON,
        style: "plus",
        label: "적립"
      },
      PURCHASE_REFERRAL_CREDITS: {
        title: "추천인 구매 적립",
        icon: ACMICON,
        style: "plus",
        label: "적립"
      },
      MANUAL_CREDITS: {
        title: "관리자 지급",
        icon: ACMICON,
        style: "plus",
        label: "적립"
      }
    },
    REVOKE: {
      SIGNUP_CREDITS: {
        title: "회원가입",
        icon: WDRICON,
        style: "minus",
        label: "적립" 
      },
      SIGNUP_REFERRAL_CREDITS: {
        title: "추천인 가입",
        icon: WDRICON,
        style: "minus",
        label: "적립" 
      },
      PURCHASE_CREDITS: {
        title: "사용 적립",
        icon: WDRICON,
        style: "minus",
        label: "적립"
      },
      PURCHASE_REFERRAL_CREDITS: {
        title: "추천인 구매 적립",
        icon: WDRICON,
        style: "minus",
        label: "적립"
      },
      MANUAL_CREDITS: {
        title: "관리자 회수",
        icon: WDRICON,
        style: "minus",
        label: "적립"
      },
      REQUEST_WITHDRAW_CREDITS: {
        title: "출금 신청",
        icon: RQSICON,
        style: "minus",
        label: "신청"
      },
      PAY_WITHDRAW_CREDITS: {
        title: "출금 지급",
        icon: WDRICON,
        style: "minus",
        label: "출금"
      }
    },
  } as any;
  

const AccumulatePage = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const startDateInputRef = useRef<HTMLInputElement | null>(null);
    const endDateInputRef = useRef<HTMLInputElement | null>(null);
    const [sumaryData, setSummaryData] = useState({
        loading: true,
        total: 200000,
        withdrawable_total: 0
    })
    const [transactionData, setTransactionData] = useState<any>({
        loading: true,
        total: 0,
        totalPage: 0,
        list: []
    })
    const [datepicker, setDatepicker] = useState({
        startOpen: false,
        endOpen: false
    })
    const [filter, setFilter] = useState<any>({
        infinity: true,
        page: 1,
        page_size: 10,
        time_option: 0,
        startDate: new Date(moment().subtract(6, 'days').format('YYYY-MM-DD')),
        endDate: new Date(),
        transaction_type: "ALL"
    })

    const handleGoBack = () => {
        if (location?.search.includes("backTo=main")) {
            navigate(RouterName.MAIN);
        }else{
            navigate(-1)
        }
    }
    const handleInvite = (e:any) => {}

    const checkHasProductName = (text:any) =>{
        let status =  false
        if(text && text.includes('[') && text.includes(']')){
            return true
        }
        return status
    }
    const formatProductName = (text:any) =>{
        let textFormat =  text
        if(text && text.includes('[') && text.includes(']')){
            return text.split(' 외')[0]
        }
        return textFormat
    }
    const getQuantityProduct = (text:any) =>{
        let valueText =  ''
        if(text && text.includes('[') && text.includes(']')){
            if(text.length <= 14){
                return valueText
            }
            if(text.includes('외 ')){
                return text.split('외 ')[1]
            }else {
                let textCheck = text.split('건')[0]
                textCheck = textCheck.split('').reverse().join('')
                let textFormat = textCheck.substring(0, 3)
                if(textFormat && textFormat.includes(' ')){
                    return textFormat.split(' ')[0]+'건'
                }else {
                    return textFormat+'건'
                }
            }
            
        }
        return valueText
    }

    const renderList = () => {
        // const type: any = {
        //     "ACM": {icon: ACMICON, style: "plus", label: "적립"},
        //     "WDR": {icon: WDRICON, style: "minus", label: "출금"},
        //     "RQS": {icon: RQSICON, style: "minus", label: "신청"},
        // }

        if(transactionData.loading && filter.page == 1){
            return [1,2,3,4,5].map((item: any, k: number) => {
                return <div key={k} className="acm-item">
                    <div className="date">
                        <SkeletonComponent width={40} height={16} count={1}/>
                    </div>
                    <div className="icon" style={{paddingLeft: '10px'}}>
                        <SkeletonComponent count={1} width={40} height={40} circle={true}/>
                    </div>
                    <div className="content">
                        <h3><SkeletonComponent width={70} height={16} count={1}/></h3>
                        <p><SkeletonComponent width={150} height={16} count={1}/></p>
                    </div>
                    <div className="info">
                        <SkeletonComponent width={40} height={16} count={1}/>
                        <SkeletonComponent width={20} height={16} count={1}/>
                    </div>
                </div>

            })
        }else {
            if (transactionData.list.length > 0) {
                return transactionData.list.map((item: any, k: number) => {
                    return <div key={k} className="acm-item">
                        <div className="date">{moment(item.created_at).format("MM.DD")}</div>
                        <div className="icon"><img src={ACCUMULATE_STATE[item.state][item.type].icon} alt={""}/></div>
                        <div className="content position-relative">
                            <h3>{ACCUMULATE_STATE[item.state][item.type].title}</h3>
                            <p className={`${checkHasProductName(item?.object_app_content?.text) ? ' limit-text-name' : ''}`}>_ {formatProductName(item?.object_app_content?.text) || "CONTENT"}</p>
                            {/* <p style={{right:(getQuantityProduct(item.app_content).length == 4 ? '-18px' : '-5px')}} className={`${checkHasProductName(item.app_content) ? ' has-text-price' : ''}`}>{getQuantityProduct(item.app_content)}</p> */}
                            {
                                item?.object_app_content?.totalProduct > 0 && item?.object_app_content?.totalQuantity > 0 && 
                                <p className='has-text-price'>{item?.object_app_content?.totalProduct > 1 ? '외' : ''} {item?.object_app_content?.totalQuantity}건</p>
                            }
                        </div>
                        <div className="info">
                            <div
                                className={`amount ${ACCUMULATE_STATE[item.state][item.type].style}`}> 
                                {/* {item.type === "REQUEST_WITHDRAW_CREDITS" || (item.state === "REVOKE" && item.type === "PURCHASE_REFERRAL_CREDITS") ? '-' : ''}  */}
                                {ACCUMULATE_STATE[item.state][item.type].style == 'minus' ? '-' : ''} 
                                {numberFormat(item.amount)}
                            </div>
                            <div>{ACCUMULATE_STATE[item.state][item.type].label}</div>
                        </div>
                    </div>

                })
            }
        }
        return []
    }

    const handleStartDateChange = (date: Date | null) => {
        let data = {
            startDate: date,
            endDate: filter.endDate
        }
        // @ts-ignore
        if(filter.endDate < date){
            data = {
                startDate: date,
                endDate: date
            }
        }
        setFilter({...filter, ...data,  page: 1})
        // setSelectedDateRange(prevRange => ({ ...prevRange, startDate: date }));
        openStartDatePicker(false)
    };

    const handleEndDateChange = (date: Date | null) => {
        // setSelectedDateRange(prevRange => ({ ...prevRange, endDate: date }));
        let data = {
            startDate: filter.startDate,
            endDate: date
        }
        // @ts-ignore
        if(filter.startDate > date){
            data = {
                startDate: date,
                endDate: date
            }
        }
        setFilter({...filter, ...data,  page: 1})
        openEndDatePicker(false)
    };


    const handleChangeTimeOption = (e: any) => {
        let data: any = {
            time_option: parseInt(e.target.value)
        }
        if(parseInt(e.target.value) === 0)
            data = {
                time_option: parseInt(e.target.value),
                startDate: new Date(moment().subtract(6, 'days').format('YYYY-MM-DD')),
                endDate: new Date()
            }
        if(parseInt(e.target.value) === 1)
            data = {
                time_option: parseInt(e.target.value),
                startDate: new Date( moment().endOf("month").format('DD') === '31'
                ? moment().subtract(1, 'days').subtract(1, 'months').format('YYYY-MM-DD')
                : moment().subtract(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')),
                endDate: new Date()
            }
            
        if(parseInt(e.target.value) === 2)
            data = {
                time_option: parseInt(e.target.value),
                startDate: new Date(moment().format('MM-DD') === '12-01'
                ? moment().subtract(3, 'months').subtract(1, 'days').format('YYYY-MM-DD')
                : moment().subtract(1, 'days').subtract(3, 'months').format('YYYY-MM-DD'),),
                endDate: new Date()
            }
        if(parseInt(e.target.value) === 3)
            data = {
                time_option: parseInt(e.target.value),
                startDate: new Date(moment().subtract(6, 'days').format('YYYY-MM-DD')),
                endDate: new Date()
            }

        setFilter({...filter, ...data, page: 1})
    }

    const openStartDatePicker = (x: boolean) => {
        if((x && filter.time_option == 3) || !x){
            setDatepicker({
                ...datepicker,
                startOpen: x
            })
        }

    }

    const openEndDatePicker = (x: boolean) => {
        if((x && filter.time_option == 3) || !x){
            setDatepicker({
                ...datepicker,
                endOpen: x
            })
        }
    }

    const handleChangeType = (e: any) => {
        setFilter({...filter, transaction_type: e.id, infinity: false, page: 1 })
    }

    const fetchBalance = async () => {
        try{
            const res = await API.get(API_OWN_BALANCE, {});
            if(res?.data?.data) setSummaryData({...sumaryData, loading: false, ...res.data.data})
        } catch (e: any) {
            console.log(e)
        }
    }

    const fetchTransaction = async () => {
        try{
            const params = {
                start_date: moment(filter.startDate).format("Y-MM-DD"),
                end_date:  moment(filter.endDate).format("Y-MM-DD"),
                page: filter.page,
                page_size: filter.page_size,
                transaction_type: filter.transaction_type,
                order_by: 'created_at',
                order_dir: 'desc',
            }
            const res = await API.get(API_ACCUMULATE_TRANSACTION, params);
            
            if(res?.data?.data?.data) {
                setTransactionData({
                    ...transactionData,
                    loading: false,
                    list: (transactionData.list.length >= filter?.page_size && filter.page>1) ? [...transactionData.list, ...res.data.data.data]: res.data.data.data,
                    total: res.data.data.total,
                    totalPage: res.data.data.last_page
                })
                
            }
        } catch (e: any) {
            console.log(e)
        }
    }
    
    const handleLoadMore = () => {
        if(filter.page < transactionData.totalPage && !transactionData.loading){
            setFilter({...filter, page: filter.page+1})
        }
    }

    useEffect(()=>{
    }, [transactionData, filter])

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 16
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    (window as any).backFromDeviceAndroid = function() {
    //code webview
        handleGoBack()
    }

    useEffect(()=>{
        (window as any).webViewCallGetListPath(['/accumulate'])
        countSize()
        fetchBalance()
        // fetchTransaction()
    }, [])
    useEffect(()=>{
        setTransactionData({...transactionData, loading: true})
        fetchTransaction()
    }, [filter])
    useEffect(()=>{
        countSize()
    }, [transactionData, sumaryData])

    
    const dataSet = [
        {id: 'ALL', label: "전체"},
        {id: 'ACCUMULATE', label: "적립"},
        {id: 'WITHDRAW', label: "출금"},
        {id: 'REQUEST_WITHDRAW', label: "신청"}
    ]



    
    return (
        <AppLayout className={"accumulate-page"}>
            <IconHeader
                id={"main-header"}
                title={'적립 내역'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <div className="summary" id={"summary-panel"}>
            <div className="benefit-information" onClick={()=>{
                    navigate(RouterName.PROMOTION)
                }}>
                    <label>!</label>
                    <span>혜택 안내</span>
                </div>

                보유 적립금<br />

                {sumaryData.loading ? <SkeletonComponent count={1} width={200} height={20}/> : <h1>{numberFormat(sumaryData.total,0,true)}원</h1>}
                {sumaryData.loading ? <SkeletonComponent count={1} width={200} height={20}/> : <p>출금 가능액 : {numberFormat(sumaryData.withdrawable_total,0,true)}원</p>}
                {sumaryData.loading ? <><br/></> : <button className="cbtn btn btn-primary" onClick={()=>navigate(RouterName.ACCUMULATE_WITHDRAW_FORM)}>출금신청</button>}
            </div>

            <main>
                <div className="filter" id={"filter-panel"}>
                    <div className="time-option" onChange={handleChangeTimeOption}>
                        <label><input type="radio" checked={filter.time_option === 0} value={0}/><span>일주일</span></label>
                        <label><input type="radio" checked={filter.time_option === 1} value={1}/><span>1개월</span></label>
                        <label><input type="radio" checked={filter.time_option === 2} value={2}/><span>3개월</span></label>
                        <label><input type="radio" checked={filter.time_option === 3} value={3}/><span>조건검색</span></label>
                    </div>
                    <div className="date-ranger-filter">
                        <DatePicker
                            selected={filter.startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            dateFormat={"Y-MM-dd"}
                            startDate={filter.startDate}
                            endDate={filter.endDate}
                            maxDate={filter.endDate}
                            placeholderText="Start Date"
                            locale={ko}
                            readOnly={true}
                            open={datepicker.startOpen}
                            disabled={filter.time_option !== 3}
                            onInputClick={()=>openStartDatePicker(true)}
                            onClickOutside={()=>openStartDatePicker(false)}
                        />
                        <div className="division"></div>
                        <DatePicker
                            selected={filter.endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            dateFormat={"Y-MM-dd"}
                            startDate={filter.startDate}
                            endDate={filter.endDate}
                            minDate={filter.startDate}
                            maxDate={new Date()}
                            placeholderText="End Date"
                            locale={ko}
                            readOnly={true}
                            disabled={filter.time_option !== 3}
                            open={datepicker.endOpen}
                            onInputClick={()=>openEndDatePicker(true)}
                            onClickOutside={()=>openEndDatePicker(false)}
                        />
                    </div>
                </div>
                <div className="display" id={"display-panel"}>
                    <div className="row">
                        <div className="col-6">
                            {/* <select onChange={handleChangeType}>
                                <option value="ALL">전체</option>
                                <option value="ACCUMULATE">적립</option>
                                <option value="WITHDRAW">출금</option>
                                <option value="REQUEST_WITHDRAW">신청</option>
                            </select> */}
                            <CFormControl label={""} desc={""} type={"empty"} required={false} className='dropdown-status'>
                                <CDropdown name={'category'} dataSet={dataSet} selected={1} placeholder={"전체"} iconArrow={ARROW_DOWN_ICON}
                                handleChange={handleChangeType}/>
                            </CFormControl>
                        </div>
                        <div className="col-6 text-end text-total">
                            단위 : 원
                        </div>
                    </div>
                </div>
                {transactionData.list.length > 0 ?
                    <InfinityScrollContainer
                        className="list scroll-container"
                        id={'list-panel'}
                        filter={filter}
                        data={transactionData}
                        fetchMoreData={handleLoadMore}
                        bottom={1}
                        loadingElement={<>
                            <div key={1} className="acm-item">
                                <div className="date">
                                    <SkeletonComponent width={40} height={16} count={1}/>
                                </div>
                                <div className="icon" style={{paddingLeft: '10px'}}>
                                    <SkeletonComponent count={1} width={40} height={40} circle={true}/>
                                </div>
                                <div className="content">
                                    <h3><SkeletonComponent width={70} height={16} count={1}/></h3>
                                    <p><SkeletonComponent width={150} height={16} count={1}/></p>
                                </div>
                                <div className="info">
                                    <SkeletonComponent width={40} height={16} count={1}/>
                                    <SkeletonComponent width={20} height={16} count={1}/>
                                </div>
                            </div>
                        </>}
                    >
                        {renderList()}
                    </InfinityScrollContainer>:
                    <div  className="list scroll-container" id={'list-panel'}>
                        <div style={{padding: '16px', paddingTop: '0px'}}>
                            <CEmptyIcon icon={EMPTYICON} text={"적립 내역이 없습니다."}/>
                        </div>
                    </div>
                    }
            </main>
        </AppLayout>
    )
}


export default AccumulatePage
