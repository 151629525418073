
import 'modules/Announcement/assets/scss/list.scss'
import AppLayout from "core/layouts/AppLayout";
import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import moment from "moment";
import CEmptyIcon from "core/components/CEmptyIcon";
import EmptyIcon from "modules/Announcement/assets/icons/empty.svg";
import CListItem from "core/components/CListItem";

import SkeletonComponent from "../../../core/components/Skeleton/skeleton";
import InfinityScrollContainer from "../../../core/layouts/InfinityScrollContainer";
import API from "../../../core/networks/API";
import {API_LIST_ANNOUCEMENT } from "../api";
import {toast} from "react-toastify";
import { API_MARKREAD_NOTIFICATION } from 'modules/Notification/api';


const AnnoucementPage = (props: any) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>({
        loading: true,
        total: 0,
        totalPage: 0,
        list: []
    })

    const [filter, setFilter] = useState<any>({
        infinity: true,
        page: 1,
        page_size: 20,
        order_by: "id",
        order_dir: "desc"
    })
    const handleGoBack = () => {
        navigate(-1)
    }

    const handleGotoDetail = async (item: any) => {
        navigate(RouterName.ANNOUCEMENT_DETAIL.replace(/:id/g, item.id))
        await API.post(API_MARKREAD_NOTIFICATION, {id: item.id});
    }

    const fetchData = async () => {
        try{
            const res = await API.get(API_LIST_ANNOUCEMENT, filter);
            if(res?.data?.data?.data) {
                setData({
                    ...data,
                    loading: false,
                    list: (filter.infinity && filter.page>1) ? [...data.list, ...res.data.data.data]: res.data.data.data,
                    total: res.data.data.total,
                    totalPage: res.data.data.last_page
                })
                
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    const renderList = () => {
        if (data.list.length > 0) {
                return data.list.map((item: any, k: number) => {
                    return <CListItem
                        className={(item.read == 'Y') ? 'read' : ''}
                        title={item.title}
                        time={moment(item.created_at).format("YYYY-MM-DD HH:mm")}
                        icon={true}
                        handleClick={() => handleGotoDetail(item)}
                        key={k}
                    />
                })
            }
        if(filter.page == 1  && data.loading){
            return [1,2,3].map((i, k) => <CListItem
            className={''}
            title={<SkeletonComponent count={1} height={20} width={350}/>}
            time={<SkeletonComponent count={1} height={13} width={100}/>}
            icon={false}
            handleClick={() => {}}
            key={k}
        />)
        }
    }


    const handleLoadMore = () => {
        console.log(99)
        if(filter.page < data.totalPage && !data.loading){
            console.log(100)
            setFilter((p: any) => ({
                ...p,
                page: filter.page+1,
            }))
            setData({
                ...data,
                loading: true,
            })
        }
    }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(()=>{
        fetchData()
    }, [filter.page])

    useEffect(()=>{
        countSize()
    }, [data])

    useEffect(()=>{
        // fetchData()
        countSize()
    }, [])

    console.log(data.loading,'loading',filter.infinity)

    return (
        <AppLayout className={"notification-page annoucement-page"}>
            <IconHeader
                title={'공지사항'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />

                {!data.loading && data.list.length == 0 ?
                    <div style={{padding: '16px', paddingTop: '190px'}}>
                        <CEmptyIcon icon={EmptyIcon} text={"아직 등록된 공지사항이 없습니다."}/>
                    </div>:
                    ''
                }
                <InfinityScrollContainer
                        className={`list scroll-container ${data.list.length == 0 && !data.loading ? 'empty-data' : ''}`}
                        id={'list-panel'}
                        filter={filter}
                        data={data}
                        fetchMoreData={handleLoadMore}
                        bottom={1}
                        loadingElement={<>
                            <CListItem
                                className={``}
                                title={<SkeletonComponent count={1} height={20} width={350}/>}
                                time={<SkeletonComponent count={1} height={13} width={100}/>}
                                icon={false}
                                handleClick={() => {}}
                                key={1}
                            />
                        </>}
                    >
                        {renderList()}
                </InfinityScrollContainer>
        </AppLayout>
    )
}


export default AnnoucementPage
