import {Action} from 'redux';
import {
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOGIN,
    LOGOUT, LOGOUT_FAILD, LOGOUT_REQUEST, LOGOUT_SUCCESS, UPDATE_FORM_SIGNUP, UPDATE_IDLE_TIME, UPDATE_TIME_RESUME
} from './types';
import {removeAxiosToken, setAxiosToken} from "core/networks/API";
import {config} from "../../../core/config/app";
import {deviceStorage} from "../../../core/utils/devicestorage";


// export interface FetchDataAction extends Action<typeof FETCH_DATA> {}
//
// export interface FetchDataSuccessAction extends Action<typeof FETCH_DATA_SUCCESS> {
//     payload: Data[];
// }
//
// export interface FetchDataErrorAction extends Action<typeof FETCH_DATA_ERROR> {
//     payload: Error;
// }
//
// export const fetchData = (): FetchDataAction => ({
//     type: FETCH_DATA,
// });
//
// export const fetchDataSuccess = (data: Data[]): FetchDataSuccessAction => ({
//     type: FETCH_DATA_SUCCESS,
//     payload: data,
// });
//
// export const fetchDataError = (error: Error): FetchDataErrorAction => ({
//     type: FETCH_DATA_ERROR,
//     payload: error,
// });


export interface  LoginAction extends Action<typeof LOGIN>{}
export const login = (payload: any): { payload: any; type: string } => {
    if(payload.token) {
        deviceStorage[localStorage.getItem("storage_driver")|| "session"].setItem(config.appPackage+'_X_TOKEN', payload.token)
    }
    return {
        type: LOGIN,
            payload
    }
}

export interface  FetchMeAction extends Action<typeof FETCH_DATA>{}
export const fetchMe = (): { type: string } => {
    const token = deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(config.appPackage+'_X_TOKEN')
    if(token) setAxiosToken(token)
    return {
        type: FETCH_DATA
    }
}


export interface  FetchMeSuccessAction extends Action<typeof FETCH_DATA_SUCCESS>{}
export const fetchMeSucess = (payload: any): { payload: any; type: string } => {

    return {
        type: FETCH_DATA_SUCCESS,
        payload
    }
}

export const fetchMeError = (): { type: string } => {
    return {
        type: FETCH_DATA_ERROR
    }
}

export interface  logoutAction extends Action<typeof LOGOUT>{}
export const logout = (): { type: string } => {
    removeAxiosToken()
    return {
        type: LOGOUT
    }
}

export const updateFormSignUp = (payload: any): { payload: any; type: string } => {
    return {
        type: UPDATE_FORM_SIGNUP,
        payload
    }
}

export const logOutRequest = (payload: any): { payload: any; type: string } => {
    return {
        type: LOGOUT_REQUEST,
        payload,
    };
};

export const logOutSuccess = (payload: any): { payload: any; type: string } => {
    // console.log('logOutSuccess action', payload);
    return {
        type: LOGOUT_SUCCESS,
        payload,
    };
};

export const logOutFaild = (payload: any): { payload: any; type: string } => {
    // console.log('logOutFaild action', payload);
    return {
        type: LOGOUT_FAILD,
        payload,
    };
};

export const updateIdleTime = (payload: any): { payload: any; type: string } => {
    return {
        type: UPDATE_IDLE_TIME,
        payload
    }
}
export const updateTimeResume = (payload: any): { payload: any; type: string } => {
    return {
        type: UPDATE_TIME_RESUME,
        payload
    }
}