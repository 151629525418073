import { numberFormat } from "core/utils/helper";

interface TotalPaymentProps {
  dataOrders: any;
  carts: any;
  loadingOrder: boolean
}

const TotalPaymentAmountComponent = ({ dataOrders, carts, loadingOrder }: TotalPaymentProps) => {

  const renderTotal = (data: any) => {
    let totalValue = 0;
    if (data && data.length > 0) {
      totalValue = data
        .filter((item: any) => item.priceByQuantity)
        .map((item: any) => +item.priceByQuantity)
        .reduce((sum: any, current: any) => sum + current);
    }
    return totalValue;
  };

  const amount = renderTotal(
    dataOrders.type === "DETAIL_PAYMENT"
      ? dataOrders.dataProduct
      : carts
  )

  return (
    <div className="total-payment-amount">
      <div className="group">
        <h2 className="title">총 결제 금액</h2>
        <span className="amount">{numberFormat(amount)}원</span>
      </div>
      <div className="note">
        <label htmlFor="" className="">
          {
            dataOrders.paymentMethod?.value === 'COD' ? '* 적립금은 결제금액의 5%가 적립되며, 결제 완료 후 익일 지급됩니다.' : '* 적립금은 결제금액의 5%가 적립되며, 배달 완료 후 익일 지급됩니다.'
          }
        </label>
      </div>
    </div>
  );
};

export default TotalPaymentAmountComponent;
