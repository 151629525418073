import { useHandleHyperlink } from "core/hooks/useHandleHyperlink";
import { useEffect } from "react";

// eslint-disable-next-line no-empty-pattern
const ContentInfoComponent = ({data, handleDirectByUrl}: any) => {
  useHandleHyperlink({data, elId: 'content-info'});

  return (
    <>
      <div
        className="pages-nav-branch"
        id="content-info"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </>
  );
};

export default ContentInfoComponent;
