import { useFetch } from "core/hooks/useFetch";
import { RouterName } from "core/router";
import { formatDateTime, getPaymentStatus, getProductNamePayment, numberFormat, phoneNumberFormat } from "core/utils/helper";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_ORDER_HISTORY } from "../api";
import AppLayout from "core/layouts/AppLayout";
import API from "core/networks/API";
import IconHeader from "core/components/header/IconHeader";
import "../assets/scss/orderhistorydetail.scss";
import OrderHistoryDetailLoadingComponent from "../components/OrderHistoryDetailLoadingComponent";
import { PAYMENT_METHOD } from "core/constants/Commons";
import { OrderButtonGroupt } from "../components/OrderButtonGroupt";
import { installmentMonth } from "../constants/Time";

const OrderHistoryDetail = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [paramsDetail, setParamsDetail] = useState<any>({
    id: params?.id,
    'relation[]': ['creator', 'items', 'shippingApp']
  });

  const {
    data: orderDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({ url: API_ORDER_HISTORY.GET_DETAIL, options: paramsDetail });

  const updateShippingAddress = async (data: any) => {
    try {
       const res = await API.post(API_ORDER_HISTORY.UPDATE_SHIPPING_ADDRESS, data);
    } catch (error) {
     
    }
   }
 
   useEffect(() => {
     if (location.state?.order_id && location.state?.dataAddress?.id) {
       let _data = {
         address_id : location.state?.dataAddress?.id || null,
         order_id: location.state?.order_id || null
       }
       updateShippingAddress(_data)
     }
   }, [location.state?.dataAddress])
  
  const updateAddress = (data: any) => {
    navigate(RouterName.ADDRESS_BOOK, {
      state: {
        order_id: orderDetail?.data?.id || null,
        back_type: RouterName.ORDER_HISTORY_DETAIL,
        back_url: `${RouterName.ORDER_HISTORY_DETAIL.replace(":id", orderDetail?.data?.id)}`
      }
    })
  }

  const handleGoBack = () => {
    //
    if (location.search.includes("backTo=main")) {
      navigate(RouterName.MAIN);
    }else{
      console.log(location, 'location');
      if (location?.state) {
        navigate(RouterName.ORDER_HISTORY_LIST, {
          state: {
            back_type: RouterName.MY_INFO,
            back_url: `${RouterName.MY_INFO}`
          }
        })
      }else{
        navigate(-1);
      }


      // if (location.state && location.state?.back_type !== '') {
      //   switch (location?.state?.back_type) {
      //     case RouterName.PAYMENT_SUCCESS:
      //       navigate(RouterName.MAIN);
      //       break;
      //     default:
      //       navigate(location.state?.back_url);
      //       break;
      //   }
      // }else{
      //   navigate(-1);
      // }
    }
    
    
  }

  (window as any).backFromDeviceAndroid = function() {
    //code webview
    handleGoBack()
  }
  
  return (
    <AppLayout className={"order-detail-page"}>
      <IconHeader
        className="order-detail-page__custom-header"
        title={"주문상세"}
        iconLeft={true}
        handleLeftClick={() => handleGoBack()}
        handleRightClick={() => {
          navigate(RouterName.MAIN);
        }}
        iconRight={true}
      />
      {
        loadingDetail ? <>
          <OrderHistoryDetailLoadingComponent className="" />
        </> : 
        <div className="order-detail-page__content">
        <div className="order-info-box">
          <div className="order-info-box__header">
            <div className="order-info-box__header__left">
              <span className="date-time">{formatDateTime(orderDetail?.data?.created_at, 'Y-MM-DD HH:mm')}</span>
            </div>
            <div className="order-info-box__header__right">
              <div className={`button-status button-status--${getPaymentStatus(orderDetail?.data?.payment_method, orderDetail?.data?.payment_status, orderDetail?.data?.status, 'classname')}`}>
                <span>
                  {
                    getPaymentStatus(orderDetail?.data?.payment_method, orderDetail?.data?.payment_status, orderDetail?.data?.status, '') === "결제대기(입금)" ? "입금 대기" : 
                    getPaymentStatus(orderDetail?.data?.payment_method, orderDetail?.data?.payment_status, orderDetail?.data?.status, '')
                  }
                  {/* <span>{getPaymentStatus(orderDetail?.data?.payment_method, orderDetail?.data?.payment_status, orderDetail?.data?.status)}</span> */}
                </span>
              </div>
            </div>
          </div>
          <div className="order-info-box__body">
            <div className="info">
              <div className="item">
                <div className="item__left">
                  <span className="title">주문번호</span>
                </div>
                <div className="item__right">
                  <span className="content">{orderDetail?.data?.id}</span>
                </div>
              </div>
              <div className="item">
                <div className="item__left">
                  <span className="title">상품명</span>
                </div>
                <div className="item__right item__right--update-product">
                  <label className="content content--update-product" dangerouslySetInnerHTML={{__html: getProductNamePayment(orderDetail?.data?.items || [])}}></label>
                </div>
              </div>
              <div className="item">
                <div className="item__left">
                  <span className="title">배달요청 일시</span>
                </div>
                <div className="item__right">
                  <span className="content">{formatDateTime(orderDetail?.data?.shipping_app?.shipping_time, 'Y-MM-DD HH:mm')}</span>
                </div>
              </div>
            </div>
            <div className="product">
              {
                orderDetail?.data?.items.map((item: any) => <div className="item">
                  <p className="title">
                    {item.prod_name} {item.qty+"개"}
                  </p>
                  <label htmlFor="" className="product-price">{numberFormat(+item.prod_price * +item.qty)}원</label>
                </div>)
              }

              {/* <p className="title" dangerouslySetInnerHTML={{__html: getProductNamePayment(orderDetail?.data?.items || [])}}></p> */}
              {/* <span>옵션 없음</span> */}
              {/* <p className="amount">{`${numberFormat(orderDetail?.data?.amount || 0)} 원`}</p> */}
            </div>
          </div>
          <div className="btn-groups">
            <OrderButtonGroupt data={orderDetail?.data || []} handleUpdateAddress={updateAddress} url={RouterName.ORDER_HISTORY_DETAIL.replace(':id', paramsDetail.id)}/>
          </div>
        </div>
        <div className="payment-info-box">
          <div className="payment-info-box__header">
            <div className="payment-info-box__header__left">
              <span className="date-time">결제정보</span>
            </div>
          </div>
          <div className="payment-info-box__body">
            <div className="info">
              <div className="item">
                <div className="item__left">
                  <span className="title">결제금액</span>
                </div>
                <div className="item__right">
                  <span className="content">{`${numberFormat(orderDetail?.data?.amount || 0)} 원`}</span>
                </div>
              </div>
              <div className="item item--method">
                <div className="item__left">
                  <span className="title">결제방법</span>
                </div>
                <div className="item__right">
                  <span className="content">
                  {
                    (PAYMENT_METHOD.find((item: any) => item.value === orderDetail?.data?.payment_method))?.label || ''
                  }
                  </span>
                  { 
                    orderDetail?.data?.payment_method === 'MONEY' && 
                    // (!['CANCELED_BY_USER', 'CANCELED_BY_SYSTEM'].includes(orderDetail?.data?.status))) && 
                    <span className="content"> ({orderDetail?.data?.payment_data?.receiver_bank_name})</span>
                  }
                </div>
              </div>
              {
                (!['CANCELED_BY_USER', 'CANCELED_BY_SYSTEM'].includes(orderDetail?.data?.status)) && orderDetail?.data?.payment_method === 'MONEY' &&
                <div className="item item--bank">
                  <div className="item__left">
                    <span className="title">입금계좌</span>
                  </div>
                  <div className="item__right item__right--money">
                    <span className="content">{orderDetail?.data?.payment_data?.receiver_account_number}</span> 
                    {/* <span className="space">|</span> <span>{orderDetail?.data?.payment_data?.sender_account_name}</span> */}
                  </div>
                </div>
              }

              {
                ((!['CANCELED_BY_USER', 'CANCELED_BY_SYSTEM'].includes(orderDetail?.data?.status)) && orderDetail?.data?.payment_method === 'CARD') || orderDetail?.data?.payment_method === 'CARD_MANUAL' ?
                <div className="item item--bank">
                  <div className="item__left">
                    <span className="title"></span>
                  </div>
                  <div className="item__right item__right--card">
                    <span className="content">{orderDetail?.data?.payment_data?.card_number}</span> 
                    {/* <span className="space">|</span> <span>{orderDetail?.data?.payment_data?.sender_account_name}</span> */}
                    <span className="content"> {orderDetail?.data?.payment_data?.install_month && (Object.keys(installmentMonth)).includes(orderDetail?.data?.payment_data?.install_month) ? 
                      <><span className="space">|</span> <span>{installmentMonth[orderDetail?.data?.payment_data?.install_month].label}</span></>
                      : ""
                    }</span>
                  </div>
                </div> : ''
              }
              
            </div>
          </div>
        </div>
        <div className="shipping-address-box">
          <div className="shipping-address-box__header">
            <div className="shipping-address-box__header__left">
              <span className="date-time">배송지</span>
            </div>
          </div>
          <div className="shipping-address-box__body">
            <div className="info">
              <div className="item address-default">
                <div className="item__left">
                  <span className="title bold">{orderDetail?.data?.shipping_app?.shipping_address_name}</span>
                </div>
                {
                  orderDetail?.data?.shipping_app?.shipping_default === 'Y' && 
                  <div className="item__right">
                    <span className="content">기본배송지</span>
                  </div>
                }
              </div>
              
              <div className="item">
                <div className="item__left">
                  <span className="title">받으시는 분</span>
                </div>
                <div className="item__right">
                  <span className="content">{orderDetail?.data?.shipping_app?.shipping_name || ''}</span>
                </div>
              </div>
              <div className="item item--address">
                <div className="item__left">
                  <span className="title code">주소</span>
                </div>
                <div className="item__right">
                  <div className="address-detail">
                    <p className="title-detail">({orderDetail?.data?.shipping_app?.zipcode || '07080'}) {orderDetail?.data?.shipping_app?.shipping_address} <br />
                    {orderDetail?.data?.shipping_app?.shipping_detail_address} </p>
                  </div>
                </div>
              </div>
              <div className="item ">
                <div className="item__left">
                  <span className="title">휴대폰번호</span>
                </div>
                <div className="item__right">
                  <div className="address-detail">
                    <p className="title-detail">{phoneNumberFormat(orderDetail?.data?.shipping_app?.shipping_phone)}</p>
                  </div>
                </div>
              </div>
              {
                orderDetail?.data?.shipping_app?.shipping_contact && <div className="item item--address">
                  <div className="item__left">
                    <span className="title">추가 연락처</span>
                  </div>
                  <div className="item__right">
                    <div className="address-detail">
                    <p className="title-detail">{phoneNumberFormat(orderDetail?.data?.shipping_app?.shipping_contact)}</p>
                    </div>
                  </div>
                </div>
              }
              
            </div>
          </div>
        </div>
        <div className="delivery-request-box">
          <div className="delivery-request-box__header">
            <div className="delivery-request-box__header__left">
              <span className="date-time">배달 요청 사항</span>
            </div>
          </div>
          <div className="delivery-request-box__body">
            <div className="info">
              <div className="note-detail">
                <p className="title">{orderDetail?.data?.shipping_app?.shipping_note || ''}</p>
              </div>
            </div>
          </div>
        </div>
			</div>
        
      }
			
    </AppLayout>
  );
};

export default OrderHistoryDetail;
