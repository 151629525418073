import {memo, useEffect, useRef, useState} from "react";
import uuid from "react-uuid";
import {config} from "../../config/app";

import RemoveIcon from 'core/assets/images/icon/picker-remove.svg'
import PickerIcon from 'core/assets/images/icon/add-file.svg'
const CImagePicker = (props: any) => {
    const [_props, setProps] = useState({
        multiple: props.multiple || true,
        className: props.className || '',
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        handleChange: props.handleChange || undefined,
        handleError: props.handleError || undefined,
        maxItems: props.maxItems || 0,
        maxSize: props.maxSize || 0,
        allows: props.allows || ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/tiff', 'image/heic', 'image/heif'],
        description: ""
    })

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [images, setImages] = useState<any>([])

    const parseImageSet = () => {
        if(props.dataSet?.length>0){
            const _images = props.dataSet.map((item: any, k:number)=>{
                console.log(28, item.path_thumbnail, config.s3Url)
                return {id: uuid().toString(), type: 'path', data: item, path: item.path_thumbnail.includes(config.s3Url) ? item.path_thumbnail: config.s3Url+'/images/'+item.path_thumbnail}
            })
            setImages(_images)
        }
    }

    useEffect(()=>{
        setProps(props)
        parseImageSet()
        // @ts-ignore
        if(!window._APP_CAMERA_PERMISSION || !window._APP_LIBRARY_PERMISSION){
            console.log("Permissions request")
            // @ts-ignore
            window.webViewRequestPermission(["camera","library","notification"])
            // window.nativeResponsePermission({camera: true, library: true, notification: true})
        }
    }, [])

    useEffect(()=>{
        parseImageSet()
    }, [props.dataSet])

    useEffect(()=>{
        try {
            const propsHash = (props.dataSet.map((item:any) => item.id)).join('-')
            const stateHash = (images.map((item:any) => item.id)).join('-')
            console.log(45, propsHash !== stateHash, propsHash, stateHash)
            if (typeof props.handleChange == 'function' && propsHash !== stateHash) {
                console.log(39, "UPDATE")
                props.handleChange(images)
            }
        }catch (e) {
            console.log(e)
        }
    }, [images])

    const handleSelectFile = () => {
        if((window as any)._APP_PLATFORM) {
            // @ts-ignore
            if (window._APP_CAMERA_PERMISSION && window._APP_LIBRARY_PERMISSION) {
                console.log("Permissions are granted")
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                    fileInputRef.current.click(); // Trigger the file input click event
                }
            } else {
                console.log("Permissions request")
                // @ts-ignore
                window.webViewRequestPermission(["camera", "library", "notification"])
                // window.nativeResponsePermission({camera: true, library: true, notification: true})
            }
        }else{
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
                fileInputRef.current.click(); // Trigger the file input click event
            }
        }
    }

    /*
    const handleChangeFile = (e: any) => {
        try {
            const selectedImage = e.target.files && e.target.files[0];
            console.log(70, selectedImage, _props)
            let allow = true;
            if (_props.maxSize > 0 && Math.round(selectedImage.size/1024/1024) > Math.round(_props.maxSize/1024/1024)) throw new Error(`Reach the limit file size, allow ${Math.round(_props.maxSize/1024/1024)} MB`)
            if (_props.maxItems > 0 && _props <= images.length) throw new Error("Reach the file limit")
            if (_props.allows?.length > 0 && !_props.allows.includes(selectedImage.type.toLowerCase())) throw new Error(`File type is not allowed`)

            if (allow && selectedImage) {
                const reader = new FileReader();

                reader.onload = () => {
                    const base64 = reader.result as string;
                    let _images = [...images]
                    _images.push({
                        id: uuid().toString(),
                        type: 'file',
                        data: selectedImage,
                        path: base64
                    })
                    setImages(_images)
                };

                reader.readAsDataURL(selectedImage);
            }
        } catch (e: any) {
            if(typeof props.handleError == 'function'){
                props.handleError(e)
            }
            console.log(e)
        }
    }
    */
    const handleChangeFile = (e: any) => {
        try {
          const selectedImage = e.target.files && e.target.files[0];
            console.log(108, selectedImage);
          // Check if the selected file type is allowed
          let allow = true;
          if (
            _props.allows?.length > 0 &&
            !_props.allows.includes(selectedImage.type.toLowerCase())
          )
            throw new Error(`jpg, gif, bmp, png, jpeg 파일만 업로드 가능합니다.\n 파일의 형식을 확인해주세요.`);

        //   if(Math.round(selectedImage.size/1024/1024) > Math.round(_props.maxSize/1024/1024)) throw new Error(`${Math.round(_props.maxSize/1024/1024)}MB 미만 파일만 업로드 가능합니다.\n업로드 파일의 용량 확인 부탁드립니다.`)
          if(selectedImage.size/1024/1024 > _props.maxSize/1024/1024) throw new Error(`${Math.round(_props.maxSize/1024/1024)}MB 미만 파일만 업로드 가능합니다.\n업로드 파일의 용량 확인 부탁드립니다.`)
          // ... (other validation checks)
            console.log(images.length >= _props.maxItems)
            if(images.length >= _props.maxItems) {
                allow = false;
                throw new Error(`Reaching max files`)
            }
    
          if (allow && selectedImage) {
            const reader = new FileReader();
    
            reader.onload = () => {
              const base64 = reader.result as string;
              let _images = [...images];
              _images.push({
                id: uuid().toString(),
                type: 'file',
                data: selectedImage,
                path: base64
              });
              setImages(_images);
            };
    
            if (
              selectedImage.type.toLowerCase() === 'image/heic' ||
              selectedImage.type.toLowerCase() === 'image/heif'
            ) {
              // If the selected file is HEIC or HEIF, convert it to JPEG for preview
              const blob = selectedImage.slice(0, selectedImage.size, 'image/jpeg');
              console.log(140, blob)
              const newFile = new File([blob], selectedImage.name, {
                type: 'image/jpeg'
              });
              console.log(144, newFile)
              reader.readAsDataURL(newFile);
            } else {
              // For other image formats, just read as is
              reader.readAsDataURL(selectedImage);
            }
          }
        } catch (e) {
          if (typeof props.handleError == 'function') {
            props.handleError(e);
          }
          console.log(e);
        }
      };
    

    const handleRemoveFileItem = (id: string) => {
        let _images = [...images]
        try{
            _images = _images.filter(item => item.id !== id);
            setImages(_images)
            const propsHash = (props.dataSet.map((item:any) => item.id)).join('-')
            if (typeof props.handleChange == 'function' && _images.length == propsHash) {
                console.log(39, "UPDATE")
                props.handleChange(_images)
            }
        } catch (e) {
            console.log(e)
        }
    }


    const renderAddFile = () => {

        return <div className={`${props.disabled ? ' disabled-add' : ''}`}>
            <div className="picker" onClick={handleSelectFile}>
                <img src={PickerIcon} alt={""}/>
            </div>
            <input 
            style={{display: "none"}}
            accept="image/*"
            ref={fileInputRef} onChange={handleChangeFile}
            type="file"/>
        </div>
    }

    const renderMultiFileItem = () => {
        if(images?.length > 0){
            return images.map((item: any, k: number) => {
                return <div className="image-item" key={k}>
                    <div className="thumb">
                        <img src={item.path} alt=""/>
                    </div>
                    <div className="remove-icon" onClick={()=>handleRemoveFileItem(item.id)}>
                        <img src={RemoveIcon} alt=""/>
                    </div>
                </div>
            })
        }
        return <></>
    }

    const renderFileItem = () => {
        return <>FileItem</>
    }

    const renderSingleFileItem = () => {
        return <>SINGLE</>
    }

    return <div className="c-file-picker">
        { !_props.multiple ? <>
            {renderSingleFileItem()}
        </> : <>
            {renderAddFile()}
            {renderMultiFileItem()}
        </>}
        {_props.description && <div className="description">{_props.description}</div>}
    </div>
}
export default CImagePicker;