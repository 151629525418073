
import ListFaqPage from "./pages/ListFaqPage";
import React from "react";

const router =  [
    {
        name: "FAQ",
        auth: true,
        path: "/faq",
        element: <ListFaqPage/>
    }
]
export default router
