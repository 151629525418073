import { GetListProductRequestAction, UpdateReloadPageListAction } from "../interfaces/Products";
import { GET_LIST_PRODUCT_REQUEST, UPDATE_RELOAD_PAGE_LIST } from "./types";


export const getListProductsRequest = (payload: any): GetListProductRequestAction => ({
    type: GET_LIST_PRODUCT_REQUEST,
    payload
});

export const updateReloadPageListRequest = (payload: any): UpdateReloadPageListAction => ({
    type: UPDATE_RELOAD_PAGE_LIST,
    payload
});


export type ProductActionTypes = 
            | GetListProductRequestAction
            | UpdateReloadPageListAction