import "modules/Policy/assets/scss/policy.scss";
import AppLayout from "core/layouts/AppLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const CancellationRefund = (props: any) => {
  const dataText = `취소/환불에 관한 기준 및 상세 정보
 
  1. 주문취소 가능 기간
  - 주문취소는 입금대기, 주문대기 상태에서 가능하며, [내 정보 > 주문내역 및 배달 조회>주문 상세] 를 통해 직접 취소할 수 있습니다.
  - 상품의 주문 접수가 시작된 이후부터는 주문취소가 불가하며, 상품 수령 후 교환/반품 신청이 가능합니다.
   
  2. 환불
  1) 환불 절차
  - 상품 수령 후 반품을 통한 환불 시 상품회수가 필요한 반품의 경우, 해당 판매사에서 회수한 상품의 검수를 진행한 다음 환불승인이 이뤄집니다.
  - 환불승인 후 결제수단별 평균 환불 소요기간에 따라 결제한 수단으로 환불이 진행됩니다.
   2) 결제 수단별 평균 환불 소요 기간
  가. 신용카드
  1) 회사에서 환불처리가 완료되고 카드사를 통해 결제승인이 취소되면 환불(한도액 복구)가 완료됩니다.
   2) 결제당일 이후 결제취소 시 카드사를 통한 결제 승인 취소 확인은 3~7일 가량 소요될 수 있으며, 이는 카드사마다 다를 수 있습니다.

  나. 무통장입금
  
  1) 환불은 환불 계좌 접수 후 5~7일 내에 접수된 계좌로 입금됩니다.
  2) 접수 계좌 오류, 증빙서류 미접수 등의 사유로 환불이 유보 또는 지연될 수 있으며, 이 경우 유선전화를 통해 별도 안내합니다.
  
  3. 주의사항
  - 판매자가 설정한 교환/반품 정책보다 전자상거래 등에서의 소비자보호에 관한 법률을 우선합니다.
  - 미성년자가 법정 대리인의 동의 없이 구매한 경우, 미성년자 본인 또는 법정 대리인은 구매를 취소할 수 있습니다.
  - 전기용품 및 생활용품 안전관리법의 규정 내용에 따라 안전확인대상상품을 구매하실 경우, 안전확인 관련 정보를 확인하시기 바랍니다.
     또한, 발견하였을 시 [안전거래 위반 신고센터]를 통해 신고접수 부탁드립니다.
  
  4. 소비자 피해 보상의 처리, 상품에 대한 불만 처리와 관련하여 살구나무 고객센터(042-528-0306)로 문의 주시면 관련 법령 및 소비자 분쟁 조정 기준에 부합한 범위 내에서 도움을 드리도록 하겠습니다.
   
  5. 거래에 관한 약관의 내용은 본 상품 상세 설명 화면과 당사 이용 약관에서 확인하실 수 있습니다.
   
  (개정일 : 2023년 04월 28일 )
  `;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        {/* <span dangerouslySetInnerHTML={{__html: dataText.replace(/\n/g, "<br/>")}}></span> */}

        {/* <div>
        <p><strong>취소</strong><strong>/</strong><strong>환불에</strong> <strong>관한</strong> <strong>기준</strong> <strong>및</strong> <strong>상세</strong> <strong>정보</strong></p>
        <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
        <p><strong>1. 주문취소</strong> <strong>가능</strong> <strong>기간</strong></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 주문취소는 입금대기, 주문대기 상태에서 가능하며, [내 정보 &gt; 주문내역 및 배달 조회&gt;주문 상세] 를 통해 직접 취소할 수 있습니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 상품의 주문 접수가 시작된 이후부터는 주문취소가 불가하며, 상품 수령 후 교환/반품 신청이 가능합니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
        <p><strong>2. 환불</strong></p>
        <p><strong>1) </strong><strong>환불</strong> <strong>절차</strong></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 상품 수령 후 반품을 통한 환불 시 상품회수가 필요한 반품의 경우, 해당 판매사에서 회수한 상품의 검수를 진행한 다음 환불승인이 이뤄집니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 환불승인 후 결제수단별 평균 환불 소요기간에 따라 결제한 수단으로 환불이 진행됩니다.</span></p>
        <p><strong>2) </strong><strong>결제</strong> <strong>수단별</strong> <strong>평균</strong> <strong>환불</strong> <strong>소요</strong> <strong>기간</strong></p>
        <p><span style={{ fontWeight: 400 }}>가. 신용카드</span></p>
        <p><span style={{ fontWeight: 400 }}>1) 회사에서 환불처리가 완료되고 카드사를 통해 결제승인이 취소되면 환불(한도액 복구)가 완료됩니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>2) 결제당일 이후 결제취소 시 카드사를 통한 결제 승인 취소 확인은 3~7일 가량 소요될 수 있으며, 이는 카드사마다 다를 수 있습니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>나. 무통장입금</span></p>
        <p><span style={{ fontWeight: 400 }}>1) 환불은 환불 계좌 접수 후 5~7일 내에 접수된 계좌로 입금됩니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>2) 접수 계좌 오류, 증빙서류 미접수 등의 사유로 환불이 유보 또는 지연될 수 있으며, 이 경우 유선전화를 통해 별도 안내합니다.</span></p>
        <p>&nbsp;</p>
        <p><strong>3. 주의사항</strong></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 판매자가 설정한 교환/반품 정책보다 전자상거래 등에서의 소비자보호에 관한 법률을 우선합니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 미성년자가 법정 대리인의 동의 없이 구매한 경우, 미성년자 본인 또는 법정 대리인은 구매를 취소할 수 있습니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>- 전기용품 및 생활용품 안전관리법의 규정 내용에 따라 안전확인대상상품을 구매하실 경우, 안전확인 관련 정보를 확인하시기 바랍니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;또한, 발견하였을 시 [안전거래 위반 신고센터]를 통해 신고접수 부탁드립니다.</span></p>
        <p>&nbsp;</p>
        <p><strong>4. 소비자</strong> <strong>피해</strong> <strong>보상의</strong> <strong>처리</strong><strong>, </strong><strong>상품에</strong> <strong>대한</strong> <strong>불만</strong> <strong>처리와</strong> <strong>관련하여</strong> <strong>살구나무</strong> <strong>고객센터</strong><strong>(042-528-0306)</strong><strong>로</strong> <strong>문의</strong> <strong>주시면</strong> <strong>관련</strong> <strong>법령</strong> <strong>및</strong> <strong>소비자</strong> <strong>분쟁</strong> <strong>조정</strong> <strong>기준에</strong> <strong>부합한</strong> <strong>범위</strong> <strong>내에서</strong> <strong>도움을</strong> <strong>드리도록</strong> <strong>하겠습니다.</strong></p>
        <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
        <p><strong>5. 거래에</strong> <strong>관한</strong> <strong>약관의</strong> <strong>내용은</strong> <strong>본</strong> <strong>상품</strong> <strong>상세</strong> <strong>설명</strong> <strong>화면과</strong> <strong>당사</strong> <strong>이용</strong> <strong>약관에서</strong> <strong>확인하실</strong> <strong>수</strong> <strong>있습니다.</strong></p>
        <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
        <p><span style={{ fontWeight: 400 }}>(개정일 : 2023년 04월 28일 )</span></p>
        <p><br /><br /></p>
      </div> */}
        <>
          <p>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>취소</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>/</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>환불에</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>&nbsp;</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>관한</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>&nbsp;</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>기준</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>&nbsp;</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>및</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>&nbsp;</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>상세</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>&nbsp;</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "11pt" }}>정보</span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                1.&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                주문취소
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                가능
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                기간
              </span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 주문취소는 입금대기, 주문대기 상태에서 가능하며, [내 정보 &gt;
              주문내역 및 배달 조회&gt;주문 상세] 를 통해 직접 취소할 수
              있습니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 상품의 주문 접수가 시작된 이후부터는 주문취소가 불가하며, 상품
              수령 후 교환/반품 신청이 가능합니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                2.&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                환불
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                1)&nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>환불</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>절차</span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 상품 수령 후 반품을 통한 환불 시 상품회수가 필요한 반품의 경우,
              해당 판매사에서 회수한 상품의 검수를 진행한 다음 환불승인이
              이뤄집니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 환불승인 후 결제수단별 평균 환불 소요기간에 따라 결제한 수단으로
              환불이 진행됩니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>&nbsp;</span>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                2)&nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>결제</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                수단별
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>평균</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>환불</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>소요</span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
            </strong>
            <strong>
              <span style={{ color: "#333333", fontSize: "10.5pt" }}>기간</span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
            가. 신용카드/신용카드(수기 결제)
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              1) 회사에서 환불처리가 완료되고 카드사를 통해 결제승인이 취소되면
              환불(한도액 복구)가 완료됩니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              &nbsp;2) 결제당일 이후 결제취소 시 카드사를 통한 결제 승인 취소
              확인은 3~7일 가량 소요될 수 있으며, 이는 카드사마다 다를 수
              있습니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              나. 무통장입금
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              1) 환불은 환불 계좌 접수 후 5~7일 내에 접수된 계좌로 입금됩니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              2) 접수 계좌 오류, 증빙서류 미접수 등의 사유로 환불이 유보 또는
              지연될 수 있으며, 이 경우 유선전화를 통해 별도 안내합니다.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                3.&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                주의사항
              </span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 판매자가 설정한 교환/반품 정책보다 전자상거래 등에서의
              소비자보호에 관한 법률을 우선합니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 미성년자가 법정 대리인의 동의 없이 구매한 경우, 미성년자 본인
              또는 법정 대리인은 구매를 취소할 수 있습니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              - 전기용품 및 생활용품 안전관리법의 규정 내용에 따라
              안전확인대상상품을 구매하실 경우, 안전확인 관련 정보를 확인하시기
              바랍니다.
            </span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              &nbsp; &nbsp;또한, 발견하였을 시 [안전거래 위반 신고센터]를 통해
              신고접수 부탁드립니다.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                4.&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                소비자
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                피해
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                보상의
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                처리
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                ,&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                상품에
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                대한
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                불만
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                처리와
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                관련하여
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                살구나무
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                고객센터
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                (042-528-0306)
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                로
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                문의
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                주시면
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                관련
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                법령
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                및
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                소비자
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                분쟁
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                조정
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                기준에
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                부합한
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                범위
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                내에서
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                도움을
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                드리도록
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                하겠습니다.
              </span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                5.&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                거래에
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                관한
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                약관의
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                내용은
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                본
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                상품
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                상세
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                설명
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                화면과
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                당사
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                이용
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                약관에서
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                확인하실
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                수
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                &nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#333333",
                  backgroundColor: "#f1f1f1",
                  fontSize: "10.5pt",
                }}
              >
                있습니다.
              </span>
            </strong>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ color: "#333333", fontSize: "10.5pt" }}>
              (개정일 : 2023년 04월 28일 )
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </>
      </div>
    </>
  );
};

export default CancellationRefund;
