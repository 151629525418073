import {useEffect, useState, FunctionComponent} from "react";
import {Alert, Button} from 'react-bootstrap';
import {Link, useNavigate, useParams,useSearchParams } from "react-router-dom";
import {useSelector} from "react-redux";
import { useFetchMe, useLogin } from "../hooks";
import {FETCH_DATA_SUCCESS, LOGIN} from "../store/types";

import 'modules/Auth/assets/scss/login.scss'
import AppLayout from "core/layouts/AppLayout";
import Logo from 'core/components/Logo'
import CCheckbox from "core/components/form-controls/CCheckbox";
import CFormControl from "core/components/form-controls/CFormControl";
import {detectPlatformIos, hideBottomButtonInputFocus, phoneNumberFormat, validateNumber, validatePassword} from "core/utils/helper";
import {RouterName} from "core/router";
import CToast from "core/components/CToast";
import {toast} from "react-toastify";
import CPhoneInput from "../../../core/components/form-controls/CPhoneInput";
import CPassword from "../../../core/components/form-controls/CPassword";
import { useFetchAppSetting } from "modules/AppSettings/hooks";

interface IUser {
  phone: string;
  password: string;
}

interface IPageAlert {
  login: boolean;
  message: string;
  dataError:any;
}

const LoginPage: FunctionComponent = (props: any) => {
    const redux = useSelector((state: any) => state)
    const navigate = useNavigate();
    

    const [pageAlert, setPageAlert] = useState<IPageAlert>({
        login: false,
        message: "",
        dataError:''
    })

    const [stateLoginForm, setStateLoginForm] = useState({
        loading:false,
        statusSubmit:false,
        data:{
            phone : "",
            password: "",
            auto_login:false
        },
        errors:{}
    })
    const [stateSubmit, setStateSubmit] = useState(false)
    const [stateError, setStateError] = useState({state:false,text:''})
    const [showPassword, setShowPassword] = useState(true)
    const [stateFocusInput, setStateFocusInput] = useState(false)
    const getAppSetting = useFetchAppSetting({});

    // useFetchMe()


    const loginSuccess = (res: any) => {
        setPageAlert({
            login: true,
            message: res.data.msg,
            dataError:res
        })

    }

    const loginFail = (res: any) => setPageAlert({
        login: true,
        message: res.message,
        dataError:res
    })

    const [ handleSubmitLogin ] = useLogin(loginSuccess, loginFail)

    useEffect(()=>{
        (window as any).webViewCallGetListPath(['/signup'])
        let findIdSuccess = localStorage.getItem('find-id-success')
        if(findIdSuccess){
            setStateError(
                {state:true,
                    text:'새로운 비밀번호 설정이 완료되었습니다.\n'}
            )
            localStorage.removeItem('find-id-success')
        }
        
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        switch (redux?.Auth?.type) {
            case FETCH_DATA_SUCCESS:
            case LOGIN:
                if(stateSubmit){
                    if (getAppSetting) {
                        getAppSetting();
                    }
                    navigate(RouterName.MAIN);
                }else {
                    navigate(-1);
                }
                break;
            default: break;
        }
    // eslint-disable-next-line
    }, [redux?.Auth?.type])
    //
    useEffect(()=>{
        if(stateLoginForm.data.phone && stateLoginForm.data.password){
            setStateLoginForm({
                ...stateLoginForm,
                statusSubmit:true
            })
        }else {
            setStateLoginForm({
                ...stateLoginForm,
                statusSubmit:false
            })
        }
    }, [stateLoginForm.data])

    useEffect(()=>{
        if(pageAlert?.dataError?.response?.data?.msg){
            setStateError({state:true,text:pageAlert?.dataError?.response?.data?.msg})
        }
        // if(pageAlert?.dataError?.response?.data?.code && pageAlert?.dataError?.response?.data?.code == 401){
        //     setStateError({state:true,text:'비밀번호가 일치하지 않습니다.\n' +
        //             '다시 확인해주세요.\n'})
        // }
        // if(pageAlert?.dataError?.response?.data?.code && pageAlert?.dataError?.response?.data?.code == 400){
        //     setStateError({state:true,text:'가입되지 않은 휴대전화 번호입니다.'})
        // }
        if(stateLoginForm.data.phone && stateLoginForm.data.password){
            setStateLoginForm({
                ...stateLoginForm,
                statusSubmit:true
            })
        }else {
            setStateLoginForm({
                ...stateLoginForm,
                statusSubmit:false
            })
        }
        setStateSubmit(false)
    }, [pageAlert?.dataError])


    useEffect(()=>{
        if(stateError?.state){
            toast.dismiss();
            toast(stateError?.text)
            setStateError({state: false, text: ''})
        }
        // eslint-disable-next-line
    }, [stateError?.state])


    

    const handleInputChange = (e: any) => {
        console.log(e.target.name)
        setStateError({state:false,text:''})
        const target = e.target;
        const value = target.value;
        var name = target.name;
        let valueValidate = value
        name = name.replaceAll('_login','')
        switch(name) {
            case 'phone':
                valueValidate = valueValidate.replaceAll('-', '')
                valueValidate = validateNumber(valueValidate)
                break;
            default:
                valueValidate = validatePassword(valueValidate)
                break;
        }
        if(!valueValidate){
            if(value != ''){
                return false
            }
        }
        if(name == 'phone'){
            valueValidate = phoneNumberFormat(valueValidate.substring(0, 11))
        }
        setStateLoginForm({
            ...stateLoginForm,
            data:{
                ...stateLoginForm.data,
                [name]:valueValidate
            }
        })

    }
    const handleToPage = (e:any) => {
        navigate('/'+e);
    }
    const handleLogin = (e:any) => {
        e.preventDefault();
        let stateLoginFormSubmit = {...stateLoginForm}
        let dataFormSubmit = {phone:'',auto_login:false,password:''}

        dataFormSubmit.phone =  stateLoginFormSubmit.data.phone.replaceAll('-', '')
        dataFormSubmit.auto_login =  stateLoginFormSubmit.data.auto_login
        dataFormSubmit.password =  stateLoginFormSubmit.data.password

        console.log(176, dataFormSubmit.auto_login, stateLoginFormSubmit.data.auto_login)
        if(dataFormSubmit.auto_login) localStorage.setItem("storage_driver", "cookie")
        else localStorage.setItem("storage_driver", "session")

        handleSubmitLogin(e, dataFormSubmit)
        setStateSubmit(true)
    }
    const handleShowHideStatePassword = (state:any) => {
        setShowPassword(state)
    }
    const formatTypePhone = (data:any) => {
        let value =  data
        if(value && value.length >= 10 && !value.includes('-')){
            value = phoneNumberFormat(value)
        }
        return value
    }



    useEffect(()=>{
        setTimeout(() => {
            hideBottomButtonInputFocus()
        }, 200);
    }, [])
    
    const handleOnFocus = (e:any) => {
        setStateFocusInput(true)
        const idInputPw = document.getElementById(`password-login`);
        const mainForm = document.getElementById(`main-form`);
        (mainForm as any).style.marginBottom = '200px';
        (mainForm as any).style.paddingBottom = '60px';
        if(idInputPw && !detectPlatformIos()){
            idInputPw.scrollIntoView();
        }
    }
    const handleonBlur = (e:any) => {
        setStateFocusInput(false)
        const mainForm = document.getElementById(`main-form`);
        if(!detectPlatformIos()){
            (mainForm as any).style.marginBottom = '0px';
            (mainForm as any).style.paddingBottom = '0px';
        }
    }

    return (
        <AppLayout className={'login-page'}>
            <div className={'login-page__main'}>
                <Logo/>
                <form className={'login-page__main__form'} onSubmit={handleLogin} id='main-form'>
                    <CPhoneInput
                        label={"아이디"} placeholder={"아이디(휴대전화 번호)를 입력해주세요."}
                        required={false} 
                        disabled={false} 
                        readonly={false}
                        name={'phone_login'} className={""}
                        value={stateLoginForm.data?.phone}
                        handleChange={handleInputChange}
                        warning={""}
                        // onFocus={()=>{setStateFocusInput(true)}}
                        // onBlur={()=>{setStateFocusInput(false)}}
                    />
                    <CPassword
                        label={"비밀번호"}
                        placeholder={'비밀번호를 입력해주세요.'}
                        name={'password_login'}
                        // required={true}
                        handleChange={handleInputChange}
                        showPassword={!showPassword}
                        value={stateLoginForm.data?.password}
                        maxLength={15}
                        handleShowHideStatePassword={handleShowHideStatePassword}
                        // onFocus={handleOnFocus}
                        // onBlur={handleonBlur}
                        showIcon={true}
                        id='password-login'
                    />
                    <div className={'group-form-action'}>
                        <div className={'form__auto-login'}>
                            <CCheckbox
                                name={'autologin'}
                                label={"자동로그인"}
                                checked={stateLoginForm.data?.auto_login}
                                handleChange={()=>setStateLoginForm({...stateLoginForm, data: {...stateLoginForm.data, auto_login: !stateLoginForm.data.auto_login}})}
                            />
                        </div>
                        <div className={'form__to-page'}>
                            <button type="button" onClick={(e)=>handleToPage('forgot-password')}>비밀번호 찾기</button>
                            <span className='box-line-top' />
                            <button type="button" onClick={(e)=>handleToPage('signup')}>회원가입</button>
                        </div>
                    </div>

                    <div className="bottom-btn ">
                        <div className="d-grid">
                            <button type="submit" disabled={!stateLoginForm.statusSubmit} className="cbtn btn-primary">로그인</button>
                        </div>
                    </div>

                </form>
            </div>
        </AppLayout>
    )
}


export default LoginPage
