import { forwardRef, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'core/hooks/useOnClickOutside';
import useDebounce from 'core/hooks/useDebounce';
import { AutocompleteProps } from 'core/interface/Autocomplete';
import goodSearchIcon from "core/assets/images/icon/goodSearchIcon.svg";
import CFormControl from '../form-controls/CFormControl';
import SkeletonComponent from '../Skeleton/skeleton';

const AutoComplete = forwardRef(({
  type = 'text',
  onChange,
  onSelected,
  placeholder,
  name,
  errors,
  value,
  className,
  disabled,
  loadOptions,
  handleInputChange,
  dataArr,
  loading,
  handleClickSearch
}: AutocompleteProps, ref) => {

  const dropdownRef = useRef<HTMLHeadingElement>(null);

  const [inputValue, setInputValue] = useState<string | undefined>(value || '')
  const [data, setData] = useState<any>(dataArr)
  const [show, setShow] = useState(false)
  const [isDitry, setIsDitry] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [selecting, setSelecting] = useState(-1)


  const debouncedInputValue = useDebounce(inputValue, 350)

  useOnClickOutside((dropdownRef), () => {
    setShow(false)
  });

  useEffect(() => {
    setInputValue(value)
  }, [value])


  useEffect(() => {
    const disableSubmitEvent = (e:any) => {
      e.preventDefault()
      return false;
    }
    const keyEvent = (event: any) => {
      if (show) {
        if (event.keyCode == 38) { // up
          if (selecting < 1) {
            setSelecting(data.length - 1)
          } else {
            setSelecting(p => p - 1)
          }
        }
        if (event.keyCode == 40) { // down
          if (selecting === data.length - 1) {
            setSelecting(0)
          } else {
            setSelecting(p => p + 1)
          }
        }
      }
    }

    document.addEventListener("keydown", keyEvent);

    return () => {
      document.removeEventListener("keydown", keyEvent)
    };
  // eslint-disable-next-line
  }, [selecting, show])

  useEffect(() => {
    if (show) {
      setSelecting(-1)
    }
  // eslint-disable-next-line
  }, [show])

  const _handleInputChange = (e:any) => {
    setInputValue(e)
    handleInputChange(e)
    setIsDitry(true)
  }

  const successCallback = (res: any) => {
    setIsloading(false)
    setShowResult(true)
    setData(res)
  }

  useEffect(() => {
    if (debouncedInputValue) {
      setIsloading(true)
      loadOptions(debouncedInputValue, successCallback)
      onChange(debouncedInputValue)
    } else {
      setData([])
      setIsDitry(false)
      setShowResult(false)
      onChange('')
    }
  // eslint-disable-next-line
  }, [debouncedInputValue])

  useEffect(() => {
    // if (value) {
    setInputValue(value)
    // }
  }, [value])

  const selectName = (e: any) => {
    setData([])
    onSelected(e)
    setIsDitry(false)
  }

  const handleClickIconSearch = () => {
    if (value) {
      setShow(false)
      handleClickSearch(inputValue)
    }
  }

  const renderLoadingAutocomplete = () => {
    return <>
      <li>
        <SkeletonComponent height={15} width={100}/>
      </li> 
      <li>
        <SkeletonComponent height={15} width={100}/>
      </li> 
      <li>
        <SkeletonComponent height={15} width={100}/>
      </li> 
    </>
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    console.log('You clicked submit.');
    if (value) {
      setShow(false)
      handleClickSearch(inputValue)
    }
  }

  return (
    <div
      className="position-relative input-autocomplete form-control p-0 border-0"
      ref={dropdownRef}
    >
      <form onSubmit={handleSubmit}>
        <CFormControl
          placeholder={placeholder}
          type={"input"}
          disabled={false}
          readonly={false}
          name={name}
          value={inputValue}
          className={"goods-search"}
          onClick={() => setShow(true)}
          onFocus={() => setShow(true)}
          handleChange={(e:any) => _handleInputChange(e.target.value)}
        />
        <button type='submit' className='search-icon'>
          <img className="" src={goodSearchIcon} alt="" onClick={() => handleClickIconSearch()}/>
        </button>
      </form>
      {isDitry && show ? <div className="autocomplete-box">
        <div className="autocomplete-box__child">
        <ul>
          {loading ? renderLoadingAutocomplete() : (
            !dataArr.length ? <li>검색 결과가 없습니다. 메뉴명을 확인해주세요.</li> : null
          )}
          {!loading && dataArr.map((item:any, key: number) => 
            <li key={key} onClick={() => selectName(item)} >
              {/* className={selecting === key ? 'selecting' : ''} */}
              {/* {item.name} */}
              {`[${item?.category?.name || ''}] ${item?.name || ''}`}
            </li>
          )}
          </ul>
        </div>
      </div> : null}
    </div>
  )
});

export default AutoComplete
