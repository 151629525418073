import React, {useEffect, useState, FunctionComponent, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import 'modules/MyInfo/assets/scss/edit_profile.scss'
import AppLayout from "core/layouts/AppLayout";
import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import CFormControl from "core/components/form-controls/CFormControl";
import PlusAddIcon from "core/assets/images/icon_plus_add.svg";
import CloseIcon from "core/assets/images/icon_close_x.svg";
import {detectPlatformIos, hideBottomButtonInputFocus, phoneNumberFormat, validateNumber} from "core/utils/helper";
import API from "core/networks/API";
import { API_PROFILE} from "../api";
import CPhoneInput from "../../../core/components/form-controls/CPhoneInput";
import {toast} from "react-toastify";
import Lottie from "lottie-react";
import LottieLoadingJson from 'core/assets/lotties/loading.json'


const EditProfilePage: FunctionComponent = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [stateForm, setStateForm] = useState<any>({
        loading: true,
        valid: false,
        data: {
            phone: "",
            referral_number: "",
            name:''
        },
        contacts: [],
        statusFetchData:true,
        disabledReferalNumber:false,
        errors: {}
    })
    const [stateError, setStateError] = useState({state: false, text: ''})

    const fetchData = async () => {
        let dataRequest = {
        }
        try {
            const {data: response} = await API.get(`${API_PROFILE}`, dataRequest)
            if(response && response?.code == 200){
                console.log(response,'response')
                let contactList: boolean[] = [];
                if(response.data.contacts && response.data.contacts.length > 0){
                    contactList = response.data.contacts
                    contactList.map((item:any,index:number) =>{
                        item.is_change = false
                    })
                }
                setStateForm((prevState: any) => ({
                    ...prevState,
                    data: {
                        phone:response.data.user.phone,
                        referral_number:response.data.user.referrer,
                        name:response.data.user.name
                    },
                    contacts:contactList,
                    statusFetchData:true,
                    disabledReferalNumber:(response?.data?.user?.referrer ? true: false),
                    loading:false
                }))
            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response
            setStateForm((prevState: any) => ({
                ...prevState,
                loading:false
            }))
        }

    }



    const formValidate = () => {
        let valid = true
        // if(stateForm.contacts.length > 0){
        //     stateForm.contacts.map((item:any,index:number)=>{
        //         if(!item.name || !item.phone){
        //             valid = false
        //         }else {
        //             valid = true
        //         }
        //     })
        // }
        setStateForm({...stateForm, valid: valid})
    }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const boxMain = document.getElementById('box-main-body');
        const contentHeight = viewportHeight - headerHeight + 86
        const body = document.getElementsByTagName('body');
        if(boxMain) {
            boxMain.style.height = contentHeight.toString() + 'px'
            boxMain.style.paddingTop = (headerHeight+10) + 'px'
            boxMain.style.background = '#ebebeb'
        }
    }

    useEffect(() => {
        countSize()
        fetchData()
        hideBottomButtonInputFocus()
    }, [])

    useEffect(()=>{
        if(stateError?.state){
            toast.dismiss();
            toast(stateError?.text)
            setStateError({state: false, text: ''})
        }
        // eslint-disable-next-line
    }, [stateError?.state])

    useEffect(() => {
        if(!stateForm.statusFetchData){
            formValidate()
        }
    }, [stateForm.contacts,stateForm.data])

    const handleAddMoreContact = (e: any) => {
        let addMore = {name: '', phone: '', id:null,is_change:false}
        setStateForm({...stateForm,
            contacts:[addMore,...stateForm.contacts],
            statusFetchData:false
        })
        const boxAddContactWarning = document.getElementById('box-add-contact-warning');
        if(boxAddContactWarning){
            // setTimeout(()=>{
            //     boxAddContactWarning.scrollIntoView({
            //         behavior: 'smooth'
            //     });
            // },350)
        }
        hideBottomButtonInputFocus()
    }

    const handleRemoveContact = (item:any,index:number) => {
        let data = [...stateForm.contacts];
        data.splice(index, 1)
        setStateForm({...stateForm,
            contacts:data,
            statusFetchData:false
        })
    }

    const validateInputPhone = (valueValidate:any) => {
        let valueInput = valueValidate
        valueValidate = valueValidate.replaceAll('-', '')
        valueValidate = validateNumber(valueValidate)
        if(!valueValidate){
            if(valueInput != ''){
                let pattern = /[^\d]/g;
                valueValidate = valueInput.replace(pattern, '');
            }
        }
        return valueValidate
    }

    const handleInputChange = (e: any,item:any) => {
        console.log(e.target.value, 'e');
        
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let indexInput = 0
        let dataList = [...stateForm.contacts];
        let addMoreData = {}
        let valueValidate = value
        if(name.includes('name_')){
            // addMoreData.name = value
            indexInput = name.replaceAll('name_','')
            let dataInputContact = dataList[indexInput]
            if(('name' in dataInputContact) && ('phone' in dataInputContact)){
                var regex = /[!@#$%^&*(),.?\":{}|<>]/g;
                dataInputContact.name = valueValidate.replace(regex, '').slice(0,10)
                dataInputContact.is_change = dataInputContact.id ? true :false
            }
            addMoreData = dataInputContact
        }
        if(name.includes('phone_')){
            // addMoreData.phone = value
            indexInput = name.replaceAll('phone_','')
            let dataInputContact = dataList[indexInput]
            if(('name' in dataInputContact) && ('phone' in dataInputContact)){
                valueValidate = phoneNumberFormat(validateInputPhone(valueValidate))
                dataInputContact.phone = valueValidate
                dataInputContact.is_change = dataInputContact.id ? true :false
            }
            addMoreData = dataInputContact
        }
        dataList.map((item:object,index:number) =>{
            if (index == +indexInput) {
                dataList[index] = addMoreData
            }
        })
        if(name.includes('referral_number')){
            valueValidate = validateInputPhone(valueValidate)
            valueValidate = phoneNumberFormat(valueValidate)
            setStateForm({...stateForm,
                data:{...stateForm.data,referral_number:valueValidate},
                statusFetchData:false
            })
        }else {
            setStateForm({...stateForm,
                contacts:dataList,
                statusFetchData:false
            })
        }

    }

    const handleOnFocus =(e:any) => {
        console.log(e.target.inputMode,'handleOnFocus',e.target.id)
        let str = e.target.id;
        let n = str.lastIndexOf('_');
        let result = str.substring(n + 1);
        // const idInput = document.getElementById(e.target.id);
        const idInput = document.getElementById(`box-item-contact-add-${result}`);
        
        if(idInput && !e.target.inputMode && !detectPlatformIos()){
            // setTimeout(() => {
            //     idInput.scrollIntoView({
            //         // block: "nearest", inline: "nearest",
            //         // behavior: 'smooth'
            //     });
            // }, 400);
        }
    }

    // console.log(stateForm.data,'stateForm.data66')
    const addItemContact = (item: object,index:number) => {

        if (('name' in item) && ('phone' in item)) {
            return (<div className={'box-add-contact__list__item'} key={index} id={`box-item-contact-add-${index}`}>
                <div className={'add-contact-item'}>
                    <CFormControl
                        disabled={false}
                        label={"연락처 명"}
                        desc={""}
                        placeholder={"연락처 명을 입력해주세요."}
                        type={"input"}
                        value={item?.name}
                        required={false}
                        handleChange={handleInputChange}
                        name={`name_${index}`}
                        maxLength={10}
                        format='text_not_special_characters'
                        id={`input_contact_name_${index}`}
                        onFocus={handleOnFocus}
                    />
                    <CPhoneInput
                        label={"연락처 전화번호"}
                        placeholder={"-없이 숫자만 입력해주세요."}
                        required={false} disabled={false} readonly={false}
                        name={`phone_${index}`} className={""} id={`input_phone_${index}`}
                        value={item?.phone}
                        handleChange={handleInputChange}
                        warning={""}
                        // allPhoneFormat={true}
                        maxLength={13}
                        onFocus={handleOnFocus}
                    />
                    <div className={'add-contact-item__close'}>
                        <img className={`add-contact-item__close__icon `} onClick={()=>handleRemoveContact(item,index)} src={CloseIcon}
                             alt={''}/>
                    </div>
                </div>
            </div>)
        }
    }

    const handleSubmit = async (e:any) =>{
        e.preventDefault();
        if(stateForm.data.referral_number && (stateForm.data.referral_number.replaceAll('-','') == stateForm.data?.phone)){
            setStateError({
                state: true, text: '자신의 번호는 추천인 번호로 등록할 수 없습니다.\n'
            })
            return false
        }
        let statusError = false
        let contactList: boolean[] = [];
        let listContactExist: boolean[] = [];
        stateForm.contacts.map((item:any,index:number) => {
            stateForm.contacts[index].phone = item?.phone.replaceAll('-','')
            if(!stateForm.contacts[index].name){
                setStateError({
                    state: true, text: '연락처 명을 입력해주세요.\n'
                })
                statusError = true
                return false
            }
            if(!stateForm.contacts[index].phone){
                setStateError({
                    state: true, text: '연락처 전화번호를 입력해주세요.\n'
                })
                statusError = true
                return false
            }
            if(stateForm.contacts[index].phone.length < 9){
                setStateError({
                    state: true, text: '올바른 형식의 전화번호가 아닙니다.\n'
                })
                statusError = true
                return false
            }
            if(stateForm.contacts[index].phone && stateForm.contacts[index].phone == stateForm.data?.phone){
                setStateError({
                    state: true, text: '올바른 형식의 전화번호가 아닙니다.\n'
                })
                statusError = true
                return false
            }
            if(stateForm.contacts[index].phone && stateForm.contacts[index].phone == stateForm.data?.phone){
                setStateError({
                    state: true, text: '올바른 형식의 전화번호가 아닙니다.\n'
                })
                statusError = true
                return false
            }
            if(stateForm.contacts[index].phone && listContactExist.includes(stateForm.contacts[index].phone)){
                setStateError({
                    state: true, text: '이미 등록된 연락처입니다.\n'+
                    '연락처 전화번호를 다시 확인해주세요\n'
                })
                statusError = true
                return false
            }
            listContactExist.push(stateForm.contacts[index].phone)
            contactList.push(stateForm.contacts[index])
        })
        if(statusError){
            return
        }
        let dataRequest = {
            referral_code:stateForm.data.referral_number ? stateForm.data.referral_number.replaceAll('-','') : '',
            contacts:contactList
        }
        setStateForm((prevState: any) => ({
            ...prevState,
            loading:true
        }))
        try {
            const {data: response} = await API.put(`${API_PROFILE}`,dataRequest)
            if(response && response?.code == 200){
                console.log(response,'response')
                navigate(-1)
                setStateForm((prevState: any) => ({
                    ...prevState,
                    loading:false
                }))
            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response?.data?.errors
            let code = e?.response?.data?.code
            setStateForm((prevState: any) => ({
                ...prevState,
                loading:false
            }))
            if(code && code == 9004) {
                let msgError = e?.response?.data?.msg
                msgError =  msgError.replaceAll('{referral_code}',stateForm.data.referral_number)
                setStateError({
                    state: true, text: msgError
                })
                return false
            }
            if(error && error.length > 0){
                setStateError({
                    state: true, text: error[0].error
                })
            }else {
                let msgError = e?.response?.data?.msg
                setStateError({
                    state: true, text: msgError
                })
            }
        }
    }

    const formatTypePhone = (data:any) => {
        let value =  data
        if(value && value.length >= 3 && !value.includes('-')){
            value = phoneNumberFormat(value)
        }
        return value
    }
    const _handleChangeStateShow = (state:any) => {
        setStateError({state: state, text: ''})
    }

    // console.log('stateForm',stateForm)

    return (
        <AppLayout className={'edit-profile-page'}>
            <IconHeader
                title={'회원 정보 수정'}
                iconLeft={true}
                handleLeftClick={() => {
                    navigate(-1)
                }}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
                id={'main-header'}
                fixed={true}
            />
            <div  id={'box-main-body'}>
                <form className={`edit-profile-page__main`} onSubmit={handleSubmit}>
                    <div className={`edit-profile-page__main__title`}>
                        <label>회원 정보</label>
                    </div>
                    <div className={`edit-profile-page__main__form`}>
                        <CFormControl
                            disabled={true}
                            label={"이름"}
                            desc={""}
                            placeholder={"연락처 명을 입력해주세요."}
                            type={"input"}
                            value={stateForm.data?.name}
                            required={false}
                            handleChange={() => {
                            }}
                            name={`name_user`}
                            maxLength={20}
                        />
                        <CPhoneInput
                            label={"휴대전화 번호"}
                            placeholder={""}
                            required={false} disabled={true} readonly={false}
                            name={`phone_edit`} 
                            className={""}
                            value={stateForm.data?.phone}
                            warning={""}
                            allPhoneFormat={true}
                            maxLength={13}
                            handleChange={() => {
                            }}
                        />
                        {!stateForm.loading ? <CPhoneInput
                            label={"추천인 번호"}
                            placeholder={""}
                            required={false} disabled={true} readonly={true}
                            name={'referral_number'} className={""}
                            value={stateForm.data?.referral_number}
                            handleChange={handleInputChange}
                            warning={"추천인 번호는 한 번 입력하면 변경할 수 없습니다."}
                            maxLength={13}
                        /> : ''}
                    </div>
                    <div className={`edit-profile-page__main__add-contact`}>
                        <div className={'box-add-contact'}>
                            <div className={'box-add-contact__title'}>
                                <label className={'box-add-contact__title__text'}>
                                    연락처 추가(선택)
                                </label>
                                <img className={`box-add-contact__title__icon `} onClick={handleAddMoreContact}
                                     src={PlusAddIcon} alt={''}/>
                            </div>
                            <p className={'box-add-contact__note'}>(지역 번호를 꼭 입력해주세요)</p>
                            <div className={'box-add-contact__list'}>
                                {stateForm.contacts.map((input: object, index: number) => {
                                    return addItemContact(input,index)
                                })}
                            </div>
                            <div className={'box-add-contact__warning'} id='box-add-contact-warning'>
                                <label>* 타인의 명의 또는 번호를 동의 없이 입력 시, 추후 발생되는</label>
                                <label style={{paddingLeft:'9px'}}>민·형사상의 책임은 본인에게 있음</label>
                            </div>
                        </div>
                    </div>
                    <div className=" edit-profile-page__main__submit bottom-btn">
                        <div className={'box-submit'}>
                            <button type="button" onClick={()=>{navigate(-1)}} className="cbtn btn-default">취소</button>
                            <button disabled={!stateForm.valid || stateForm.loading} type="submit" className="cbtn btn-primary">확인</button>
                        </div>
                    </div>
                </form>
            </div>
            {stateForm.loading && <div className="loading-overlay"><Lottie height={120} animationData={LottieLoadingJson} loop={true} /></div>}
        </AppLayout>
    )
}


export default EditProfilePage
