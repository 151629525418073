import ProductListItem from "core/components/ProductListItem";
import { numberFormat } from "core/utils/helper";

interface CartTotal {
  isCheckbox?: boolean;
  cartList?: [] | undefined;
  selectItem?: (value: any | undefined) => void;
  removeCart?: (value: any | undefined) => void;
  increaseQuatity?: (value: any | undefined) => void;
  decreaseQuatity?: (value: any | undefined) => void;
  handleShowKeyBoard?: (value: any | undefined) => void;
  hasAction?: boolean;
  productSelectedInCarts?: any
}

const CartItemComponent = ({ 
  isCheckbox, 
  cartList, 
  selectItem, 
  removeCart,
  increaseQuatity, 
  decreaseQuatity, 
  handleShowKeyBoard, 
  hasAction=true,
  productSelectedInCarts
}: CartTotal) => {

  const handleSelected = (item:any) => {
    if (selectItem) {
      selectItem({...item.dataItem, isSelected: item.checked})
    }
  }

  const handleRemove = (item: any) => {
    if (removeCart) {
      removeCart(item.dataItem);
    }
  }

  const handleOnIncrease = (quantity: number, data: any) => {
    let values = {
      id: data?.dataItem?.id || null,
      prod_id: data?.dataItem?.prod_id || null,
      index: data.index,
      quantity: quantity || '',
    }
    if (increaseQuatity) {
      increaseQuatity(values);
    }
  }
  const handleOnDecrease = (quantity: number, data: any) => {
    let values = {
      id: data?.dataItem?.id || null,
      prod_id: data?.dataItem?.prod_id || null,
      index: data.index,
      quantity: quantity || '',
    }
    if (decreaseQuatity) {
      decreaseQuatity(values);
    }
    
  }
  const handleOnClickInput = (value: any) => {
    if (handleShowKeyBoard) {
      handleShowKeyBoard(value)
    }
  }

  
  return (
    <div className="cart-item"  id="list-panel">
      {cartList && cartList.map((item: any, key: number) => (
        <ProductListItem
          name={item.prod_name}
          price={numberFormat(item?.priceByQuantity || 0)}
          quantity={item.quantity}
          handleChange={(item:any) => handleSelected(item)}
          handleRemove={(item:any) => handleRemove(item)}
          hasAction={hasAction}
          isCheckbox={isCheckbox}
          checked={item.isSelected}
          dataItem={item}
          // eslint-disable-next-line no-mixed-operators
          image={item?.product?.images[0]?.path_original ? item?.product?.images[0]?.path_original : item.prod_image}
          index={key}
          handleOnIncrease={handleOnIncrease}
          handleOnDecrease={handleOnDecrease}
          onClickInput={handleOnClickInput}
        />
      ))}

    </div>
  );
};

export default CartItemComponent;
