import { useEffect } from "react";
import CTabs from "core/components/CTabs";
import { useHandleHyperlink } from "core/hooks/useHandleHyperlink";
// eslint-disable-next-line no-empty-pattern

const ContentDetailComponent = ({data, handleDirectByUrl}: any) => {
  useHandleHyperlink({data, elId: 'content-detail'});
  return (
    <>
      <div
          className="pages-nav-branch"
          id="content-detail"
          dangerouslySetInnerHTML={{ __html: data }}
        />
    </>
  );
};

export default ContentDetailComponent;
