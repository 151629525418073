import {useEffect, useState} from "react";
import hideIcon from "../../assets/images/icon/hide.svg";
import showIcon from "../../assets/images/icon/show.svg";
import CKeyBoard from "../CKeyBoard";
import { phoneNumberFormat } from "core/utils/helper";

const CPhoneInput = (props: any) => {
    const [_props, setProps] = useState<any>({
        name: props.name,
        value: props.value || '',
        label: props.label || '',
        desc: props.desc || '',
        className: props.className || '',
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        required: props.required || false,
        error: props.error || '',
        warning: props.warning || '',
        handleChange: props.handleChange || function (e: any){},
        handlePhoneChange: props.onPhoneChange || function (e: any){},
        placeholder: props.placeholder || '',
        maxLength: props.maxLength || 100,
        pattern: props.pattern || '',
        onClick: props.onClick || function (e: any){},
        onFocus: props.onFocus || function (e: any){},
        onBlur: props.onBlur || function (e: any){},
        allPhoneFormat: props.allPhoneFormat || false,
        id: props.id || ''
    })


    const formatPhoneValue = (str: string) => {
        if(str) {
            const cleanedInput = str.replace(/[^0-9]/g, '');
            // console.log(cleanedInput,'cleanedInput', cleanedInput.slice(0, 2))
            //Neu dau so 02
            if(cleanedInput.slice(0, 2) == '02'){
                // Kiểm tra độ dài của chuỗi đã làm sạch
                if (cleanedInput.length <= 2) {
                    // Nếu chuỗi có 2 ký tự hoặc ít hơn, không cần định dạng
                    return cleanedInput;
                } else if (cleanedInput.length <= 5) {
                    // Nếu chuỗi có từ 2 đến 5 ký tự, định dạng là "xx-xxx"
                    return cleanedInput.slice(0, 2) + '-' + cleanedInput.slice(2);
                }else if (cleanedInput.length <= 9) {
                    // Nếu chuỗi có từ 5 đến 9 ký tự, định dạng là "xx-xxx-xxx"
                    return (
                        cleanedInput.slice(0, 2) +
                        '-' +
                        cleanedInput.slice(2, 5) +
                        '-' +
                        cleanedInput.slice(5, 10)
                    );
                } else {
                    // Nếu chuỗi có từ 8 ký tự trở lên, định dạng là "xxx-xxxx-xxxx"
                    return (
                        cleanedInput.slice(0, 2) +
                        '-' +
                        cleanedInput.slice(2, 6) +
                        '-' +
                        cleanedInput.slice(6, 11)
                    );
                }
            } else if(cleanedInput.slice(0, 3) != '010'){

                // Kiểm tra độ dài của chuỗi đã làm sạch
                if (cleanedInput.length <= 3) {
                    // Nếu chuỗi có 3 ký tự hoặc ít hơn, không cần định dạng
                    return cleanedInput;
                } else if (cleanedInput.length <= 6) {
                    // Nếu chuỗi có từ 4 đến 6 ký tự, định dạng là "xxx-xxx"
                    return cleanedInput.slice(0, 3) + '-' + cleanedInput.slice(3);
                } else if (cleanedInput.length <= 10) {
                    // Nếu chuỗi có từ 4 đến 7 ký tự, định dạng là "xxx-xxx"
                    return (
                        cleanedInput.slice(0, 3) +
                        '-' +
                        cleanedInput.slice(3, 6) +
                        '-' +
                        cleanedInput.slice(6, 10)
                    );
                } else {
                    // Nếu chuỗi có từ 8 ký tự trở lên, định dạng là "xxx-xxxx-xxxx"
                    return (
                        cleanedInput.slice(0, 3) +
                        '-' +
                        cleanedInput.slice(3, 7) +
                        '-' +
                        cleanedInput.slice(7, 11)
                    );
                }

            }else {
                
                // Kiểm tra độ dài của chuỗi đã làm sạch
                if (cleanedInput.length <= 3) {
                    // Nếu chuỗi có 3 ký tự hoặc ít hơn, không cần định dạng
                    return cleanedInput;
                } else if (cleanedInput.length <= 7) {
                    // Nếu chuỗi có từ 4 đến 7 ký tự, định dạng là "xxx-xxx"
                    return cleanedInput.slice(0, 3) + '-' + cleanedInput.slice(3);
                } else if (cleanedInput.length <= 10 && _props.allPhoneFormat) {
                    // Nếu chuỗi có từ 4 đến 7 ký tự, định dạng là "xxx-xxx"
                    return (
                        cleanedInput.slice(0, 3) +
                        '-' +
                        cleanedInput.slice(3, 6) +
                        '-' +
                        cleanedInput.slice(6, 10)
                    );
                } else {
                    // Nếu chuỗi có từ 8 ký tự trở lên, định dạng là "xxx-xxxx-xxxx"
                    return (
                        cleanedInput.slice(0, 3) +
                        '-' +
                        cleanedInput.slice(3, 7) +
                        '-' +
                        cleanedInput.slice(7, 11)
                    );
                }

            }

            
        }
        return ""
    }

    useEffect(()=>{
        setProps({..._props, value: phoneNumberFormat(props.value)})
    }, [props])

    const handleChange = (e: any) => {
        const input = e.target;
        const currentValue = input.value;
        const caretPosition = input.selectionStart; // Lấy vị trí con trỏ trước khi xử lý

        let formattedValue = phoneNumberFormat(currentValue);
        const formattedCaretPosition =
            formattedValue.length > currentValue.length
                ? caretPosition + 1
                : caretPosition;

        let value = phoneNumberFormat(e.target.value)

        setProps({..._props, value })
        // const x = Object.assign({}, e);
        // x.target.value = value.replace(/-/g, '')
        e.target.value = value
        props.handleChange(e)
        // Xử lý để giữ vị trí con trỏ
        input.value = formattedValue;
        input.setSelectionRange(formattedCaretPosition, formattedCaretPosition);


    }

    const handleClick = (e: any) => {
        if(_props.onClick)
            _props.onClick()
    }

    const handleFocus = (e: any) => {
        if(_props.onFocus)
            _props.onFocus(e)
    }

    const handleBlur = (e: any) => {
        if(_props.onBlur)
            _props.onBlur(e)
    }


    const renderControl = () => {
        let className = ["c-input-wrapper"]

        return <div className={className.join(' ')}>
            <input
                name={_props.name}
                type={'text'}
                value={_props.value}
                inputMode="numeric"
                placeholder={_props.placeholder}
                readOnly={_props.readOnly}
                disabled={_props.disabled}
                onChange={handleChange}
                autoComplete={"one-time-code"}
                autoCorrect={"one-time-code"}
                maxLength={_props.maxLength}
                pattern={props.pattern}
                onClick={handleClick}
                onFocus={handleFocus}
                onBlur={handleBlur}
                id={_props.id}
            />
        </div>
    }

    return <>
        <div className={_props.className}>
            <div className="c-form-control">
                {_props.label && <label className="c-label">{_props.label} {_props.required && <span className="required">*</span>}</label>}
                {_props.desc && <p className="c-desc">{_props.desc}</p>}
                {renderControl()}
                {_props.error && <p className="c-form-error">{_props.error}</p>}
                {_props.warning && <p className="c-form-error"><span>*</span><span>{_props.warning}</span></p>}
            </div>
        </div>
        {/*<CKeyBoard*/}
        {/*    className={''}*/}
        {/*    onClickNumber={()=>{}}*/}
        {/*    isOpen={true}*/}
        {/*    onRemoveNumber={()=>{}}*/}
        {/*    onHideKeyboard={()=> {}}*/}
        {/*/>*/}
    </>
}

export default CPhoneInput;