import { PermissionActionTypes, PERMISSION_APP_REQUEST } from "./actions";
import {  DataPermission, DataState } from "./types";


const initialState: DataState = {
	data: {} as DataPermission,
  type: "",
};

const Device = (
	state: DataState = initialState,
	action: PermissionActionTypes,
): DataState => {
	switch (action.type) {
			case PERMISSION_APP_REQUEST:
					return { 
						data: action.payload[0],
						type: action.type
					};
			default:
					return state;
	}
};

export default Device
