import Menu1 from 'core/assets/images/bottom_nav/1.svg';
import Menu1Active from 'core/assets/images/bottom_nav/1-active.svg';
import Menu2 from 'core/assets/images/bottom_nav/2.svg';
import Menu2Active from 'core/assets/images/bottom_nav/2-active.svg';
import Menu3 from 'core/assets/images/bottom_nav/3.svg';
import Menu3Active from 'core/assets/images/bottom_nav/3-active.svg';
import Menu4 from 'core/assets/images/bottom_nav/4.svg';
import Menu4Active from 'core/assets/images/bottom_nav/4-active.svg';
import {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {RouterName} from "../router";

const BottomNavigation = (props: any) => {
    const [index, setIndex] = useState(1)
    const MenuItems = [
        {link: RouterName?.MAIN, menu: {default: Menu1, active: Menu1Active}},
        {link: RouterName?.GOODS_LIST, menu: {default: Menu2, active: Menu2Active}},
        {link: RouterName?.CARTS_LIST, menu: {default: Menu3, active: Menu3Active}},
        {link: RouterName?.MY_INFO, menu: {default: Menu4, active: Menu4Active}}
    ]
    useEffect(()=>{
        setIndex(props.activeIndex)
    }, [props])

    const renderMenuItem = ()  => {
        return MenuItems.map((item, k) => {
            if(k == index-1) return <li key={k}><Link to={item.link}><img src={item.menu.active} alt={''}/></Link></li>;
            return <li key={k}><Link to={item.link}><img src={item.menu.default} alt={''}/></Link></li>;
        })
    }

    return <ul id="bottom_navigation" className="bottom_navigation">
        {renderMenuItem()}
    </ul>
}

export default BottomNavigation