import ReferrerPage from "./pages/ReferrerPage";

const router =  [
    {
        name: "REFERER_LIST",
        auth: true,
        path: "/referrer",
        element: <ReferrerPage/>
    },
]
export default router
