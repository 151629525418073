import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import PreviewIcon from "core/assets/images/icons/preview_icon.svg";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useEffect, useRef, useState } from "react";
import BackIcon from "core/assets/images/icons/close_fancybox.svg"
import FancyboxComponent from "./FancyboxComponent";

interface Type {
  thumbnail: string,
  original: string
}

interface CFancyboxProps {
  cssClass?: string;
  dataSet?: Array<Type>
}

const CFancybox = ({ cssClass, dataSet }: CFancyboxProps) => {
  console.log(dataSet, 'dataSet');
  
  const fancyRef = useRef<any>();
  const fancyRefS = useRef<any>([]);
  const [indexSlide, setIndexSlide] = useState(0);

  return (
    <>
      <div className={`fancybox ${cssClass}`} id="gallery-wrap">
        {/* <Carousel
          infiniteLoop={true}
          showIndicators={false}
          showArrows={false}
          showStatus={true}
          statusFormatter={(currentItem: any, total: any) =>
            {
              var parentGallery = document.getElementById('gallery-wrap');
              if(parentGallery){
                var countSlide = parentGallery.querySelector('.carousel-status');
                if(countSlide && typeof countSlide == 'object'){
                  countSlide.innerHTML = `<strong>${currentItem}</strong>/<span>${total}</span>`
                }
              }
              return `${currentItem}/${total}`
            }
          }
          showThumbs={false}
          stopOnHover={true}
          selectedItem={0}
        >
          {itemsImg?.map((item: any, index: any) => (
            <div key={index} className="">
              <img className="rounded" src={item} />

              <a hidden data-fancybox="gallery" href={item} ref={fancyRef} />
            </div>
          ))}
        </Carousel> */}

    <FancyboxComponent
        options={{
          Carousel: {
            infinite: true
          },
          Toolbar: {
            items: {
              close: {
                tpl: `<button class="f-button" title="{{CLOSE}}" data-fancybox-close>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path stroke="#fff" stroke-width="2" stroke-linecap="round" d="M6 12h14"/>
                    <path d="m10 7-5 4.762L9.5 17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>`,
              },
              prev: {
                tpl: ""
              }
            },
            display: {
              left: ["close"],
              middle: [],
              right: [],
            },
          },
          width: "auto",
          Thumbs: {
            type: "classic",
          },
          closeButton: false,
          mainClass: "fancybox-custom",
          Hash: false,
        }}
      >
        <Carousel
          infiniteLoop={true}
          showIndicators={false}
          showArrows={false}
          showStatus={true}
          statusFormatter={(currentItem: any, total: any) =>
            {
              var parentGallery = document.getElementById('gallery-wrap');
              setIndexSlide(currentItem - 1)
              if(parentGallery){
                var countSlide = parentGallery.querySelector('.carousel-status');
                if(countSlide && typeof countSlide == 'object'){
                  countSlide.innerHTML = `<strong>${currentItem}</strong>/<span>${total}</span>`
                }
              }
              return `${currentItem}/${total}`
            }
          }
          showThumbs={false}
          stopOnHover={true}
          selectedItem={0}
        >
          {dataSet?.map((item: any, index: any) => (
            <div key={index} className="" onClick={() => {fancyRefS.current[indexSlide].click()}}>
              <img className="rounded" src={item.path_medium} />
              
            </div>
          ))}
        </Carousel>
          {dataSet?.map((item: any, index: any) => (
            <div key={index} className="" >
              <a hidden data-fancybox="gallery" href={item.path_original} ref={(el) => (fancyRefS.current[index] = el)} />
            </div>
          ))}
      </FancyboxComponent>

        <div className="preview-icon" onClick={() => {fancyRefS.current[indexSlide].click()}}>
          {/* <img src={PreviewIcon} alt="" /> */}
        </div>
      </div>
    </>
  );
};

export default CFancybox;
