import { useCarts } from 'modules/Orders/hooks/useCarts';
import { useState } from 'react';

export const useCheckQuality = (form: any) => {
  const [isExceed, setIsExceed] = useState<boolean>(false);
  const getCarts = useCarts(null)
 
  const checkMax = (values: any) => {
    let quantityInCart = getCarts.find((item: any) => {
			if (values.prod_id !== undefined) {
				return item.prod_id === +values.prod_id
			}
		})
    let quantityTotal;

    if (values.type && values.type === 'cart') {
      quantityTotal = values.quantity
    }else{
      quantityTotal = values.quantity + quantityInCart?.quantity || 0
    }
   
    if (quantityTotal > values.max) {
      if (form.onFailed) {
        return form.onFailed()
      }
    }else{
      if (form.onSuccess) {
        return form.onSuccess()
      }
     
    }
  };

  // useEffect(() => {
  //   checkMax();
  // }, [quantity, id, time]);

  const _onCheckExceed = (values: any) => {
    checkMax(values);
  }

  return _onCheckExceed
}