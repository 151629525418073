
import { call, put, takeLatest } from "redux-saga/effects";
import API from "core/networks/API";
import { API_PRODUCT } from "../api";
import { GET_LIST_PRODUCT_SUCCESS, GET_LIST_PRODUCT_FAILED, GET_LIST_PRODUCT_REQUEST } from "./types";

function* getListProducts(_action: any): any{
  try {
    const response = yield call(() => {
      let url = API_PRODUCT.GET_PRODUCT;
      return API.get(url, _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put({
        type: GET_LIST_PRODUCT_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: GET_LIST_PRODUCT_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: GET_LIST_PRODUCT_FAILED,
      payload: error || {},
    });
  }

}


function* ProductsSaga() {
  yield takeLatest(GET_LIST_PRODUCT_REQUEST, getListProducts);
}

export default ProductsSaga;
