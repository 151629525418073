import React, {useEffect, useState, FunctionComponent} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import 'modules/Announcement/assets/scss/list.scss'
import AppLayout from "core/layouts/AppLayout";
import IconHeader from "core/components/header/IconHeader";
import API from "core/networks/API";
import {API_LIST_ANNOUCEMENT,API_DETAIL_ANNOUCEMENT} from "../api";
import {RouterName} from "core/router";
import CEmptyIcon from "../../../core/components/CEmptyIcon";
import EmptyIcon from "../assets/icons/empty.svg";
import moment from "moment";
import { useHandleHyperlink } from "core/hooks/useHandleHyperlink";


const DetailPage: FunctionComponent = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [detailData, setDetailData] = useState<any>({
        loading:true,
        data: null
    })

    useHandleHyperlink({
        data: detailData?.data?.content || "",
        elId: "content-description"
    });
    
    
    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - 20
        const detailEl = document.getElementById('detail-panel');
        if(detailEl) detailEl.style.height = contentHeight.toString() + 'px'
    }

    const goBack = () => {
        if (location?.search.includes("backTo=main")) {
            navigate(RouterName.MAIN);
        }else{
            navigate(-1)
        }
    }

    (window as any).backFromDeviceAndroid = function() {
        //code webview
        goBack()
    }

    useEffect(() => {
        (window as any).webViewCallGetListPath(['/annoucement/detail/'])
        countSize()
        fetchData()
    }, [])




    const fetchData = async () => {
        let pathname =  window.location.pathname
        let urlPathDetail = RouterName.ANNOUCEMENT_DETAIL.replaceAll(':id','')
        let id = pathname.replaceAll(urlPathDetail,'')
        
        try {
            const {data: response} = await API.get(`${API_DETAIL_ANNOUCEMENT.replace(/:id/g, id)}`, '')
            if(response && response?.code == 200){
                setDetailData({
                    loading:false,
                    data:response?.data
                })
                
            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response
            setDetailData({
                loading:false,
                data:null
            })
        }
    }
   
    return (
        <AppLayout className={'annoucement-page annoucement-detail-page'}>
            <IconHeader
                title={'공지사항'}
                iconLeft={true}
                handleLeftClick={goBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
                id='main-header'
            />
                {!detailData.loading && !detailData.data ? <div style={{padding: '16px', paddingTop: '190px'}}>
                    <CEmptyIcon icon={EmptyIcon} text={"해당 알림이 더 이상 존재하지 않습니다."}/>
                </div>:
                    <div className={`annoucement-page__main`} id="detail-panel">
                        <div className={'annoucement-page__main__content'}>
                            <div className={'content-detail'}>
                                <div className={'content-title'}>
                                    <h3 className={'content-title__text'}>{detailData?.data?.title}</h3>
                                    <span className={'content-title__time'}>{moment(detailData?.data?.created_at).format("YYYY-MM-DD HH:mm")}</span>
                                </div>
                                <div className={'content-description'} >
                                    <p id="content-description" dangerouslySetInnerHTML={{__html: detailData?.data?.content}} />
                                    {/* <ContentDetailComponent data={detailData?.data?.content} /> */}
                                </div>
                                {/* <div className="d-grid d-grid-fixed-bottom">
                                    <button onClick={(e) => goBack()} className="cbtn btn-primary">확인</button>
                                </div> */}
                                <div className="bottom-btn ">
                                    <div className="d-grid ">
                                        <button onClick={(e) => goBack()} className="cbtn btn-primary">확인</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                }
        </AppLayout>
    )
}


export default DetailPage
