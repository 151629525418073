import { useEffect } from 'react';

function useHandleError() {
    useEffect(() => {
        const handleError = (event: any) => {
            // setStatus(false)
            // console.log(24, event)
            // dispatch(logout())
            // window.location.reload()
            // Xử lý lỗi theo cách của bạn
            console.error('ERROR_HANDLER_MESSAGE:', event.message);
            console.error('ERROR_HANDLER_OBJECT:', event);
            // @ts-ignore
            // document.getElementById("debugPanel").innerText = JSON.stringify(event)
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    return null
}

export default useHandleError

// usage
// useHandleError()
