import {useEffect, useState} from "react";

const Tabs = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        tabHeadings: props.tabHeadings || [],
        tabContents: props.tabContents || [],
        activeIndex: props.activeIndex || '',
        handleChangeTab: props.handleChangeTab || '',
    })

    const [index, setIndex] = useState(props.activeIndex);
    useEffect(() => {
        setProps({
            ..._propsState,
            tabContents: props.tabContents
        })
    }, [ props]);

    const _handleChangeTab = (e: any) => {

        if (typeof _propsState.handleChangeTab == 'function') {
            props.handleChangeTab(e);
        }
        setIndex(e);
    }
    
    return <>
        <div className="tabs c-tabs">
            <div className="tabs-main">
                <div className="tabs__heading wrap">
                    <ul className="tabs__heading__list">
                        {_propsState.tabHeadings.map((item: any, k: number) => {
                            return (
                                    <li
                                        onClick={() => _handleChangeTab(k)}
                                        key={k}
                                        className={
                                            `tabs__heading__item ` +
                                            (k === index ? 'active' : '')
                                        }
                                    >
                                        {item}
                                    </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="tabs__content">
                    {_propsState.tabContents.map((item: any, kContent: number) => {
                        return (
                            <div
                                key={kContent}
                                className={
                                    'tabs__content__section ' + (kContent === index ? 'active' : '')
                                }
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    </>
}

export default Tabs