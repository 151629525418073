import {
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOGIN,
    LOGOUT, LOGOUT_FAILD,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    UPDATE_FORM_SIGNUP,
    UPDATE_IDLE_TIME,
    UPDATE_TIME_RESUME
} from './types';
import {removeAxiosToken, setAxiosToken} from "core/networks/API";
import {io} from "socket.io-client";
import {config} from "../../../core/config/app";
import {deviceStorage} from "../../../core/utils/devicestorage";

export interface DataState {
    user: object,
    token: string,
    login: boolean,
    type: string,
    userInfo:object,
    isProcessing:boolean,
    statusCode:any,
    isProcessingUpdate:boolean,
    errors:object,
    dataFormSignUp:any,
    idleTime:any,
    timeResume:any,
}

const initialState: DataState = {
    user: {},
    token: '',
    login: false,
    type: '',
    userInfo: {} ,
    isProcessing: false,
    statusCode: null,
    isProcessingUpdate: false,
    errors : {},
    dataFormSignUp:'',
    idleTime:null,
    timeResume:null,
};

const initSocket = (token: string) => {
    // @ts-ignore
    if(!window.socket && process.env.REACT_APP_ENABLE_SOCKET && process.env.REACT_APP_ENABLE_SOCKET.toLowerCase() === 'true') {
        //@ts-ignore
        window.socket = io(process.env.REACT_APP_SOCKET_URL, {
            transports: ['websocket'],
            secure: true,
            reconnectionDelayMax: 10000,
            extraHeaders: {
                Authorization: token
            },
            query: {
                token: token
            }
        });

    }
}

const Reducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    switch (action.type) {
        case LOGIN:
            setAxiosToken(action.payload.token)
            // @ts-ignore
            initSocket(action.payload.token,action)
            console.log('LOGIN',state,'__',action)
            return { ...state, ...action.payload, login:true, type: action.type,userInfo:action.payload?.user };
        case FETCH_DATA_SUCCESS:
            // @ts-ignore
            initSocket(deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(config.appPackage+config.appPackage+'_X_TOKEN'))
            return { ...state, ...action.payload, login:true, type: action.type };
        case FETCH_DATA_ERROR:
            // @ts-ignore
            if(window.socket)
                // @ts-ignore
                window.socket.disconnect()
            return {...initialState, type: action.type};
        case UPDATE_FORM_SIGNUP:
            return {...initialState, type: action.type,dataFormSignUp:action.payload};
        case UPDATE_IDLE_TIME:
            return {...state, type: action.type,idleTime:action.payload};
        case UPDATE_TIME_RESUME:
            return {...initialState, type: action.type,timeResume:action.payload};          
        case LOGOUT_REQUEST:
            return {
                ...state,
                isProcessing: true,
                type: action.type
            };
        case LOGOUT_SUCCESS:
            return {
                ...initialState,
                isProcessing: false,
                token:'',
                statusCode: 400,
                userInfo: {},
                type: action.type,
            };
        case LOGOUT_FAILD:
            return {
                ...state,
                isProcessing: false,
                token:'',
                statusCode: 400,
                userInfo: {},
                type: action.type,
            };
        case LOGOUT:
            removeAxiosToken()
            // @ts-ignore
            if(window.socket){
                // @ts-ignore
                window.socket.disconnect()
            }


            return {...initialState, type: action.type};
        default:
            return state;
    }
};

export default Reducer
