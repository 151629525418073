import CTabs from "core/components/CTabs";
import SkeletonComponent from "core/components/Skeleton/skeleton";
import ContentDetailComponent from "./ContentDetailComponent";
import ContentInfoComponent from "./ContentInfoComponent";
import ContentSupportComponent from "./ContentSupportComponent";
import { useOpenBrowser } from "core/hooks/useOpenBrowser";

interface dataTabProps {
  info?: any;
  loading?: boolean;
}

const GoodTabComponent = ({ info, loading }: dataTabProps) => {
  const handleDirect = useOpenBrowser();
  const loadingComponent = (
    <>
      <SkeletonComponent width={"50%"} height={18} />
      <SkeletonComponent width={"100%"} height={16} />
      <SkeletonComponent width={"100%"} height={16} />
      <SkeletonComponent width={"100%"} height={16} />
      <br />
      <SkeletonComponent width={"50%"} height={18} />
      <SkeletonComponent width={"100%"} height={16} />
      <SkeletonComponent width={"100%"} height={16} />
      <SkeletonComponent width={"100%"} height={16} />
      <br />
      <SkeletonComponent width={"50%"} height={18} />
      <SkeletonComponent width={"100%"} height={16} />
      <SkeletonComponent width={"100%"} height={16} />
      <SkeletonComponent width={"100%"} height={16} />
    </>
  );

  const handleDirectByUrl = (link: string) => {
    handleDirect(link);
  }

  let contentDetail = () =>
    loading ? (
      loadingComponent
    ) : (
      <ContentDetailComponent data={info?.data?.content_detail} type={'content_detail'} handleDirectByUrl={handleDirectByUrl}/>
    );
  
  let contentInfo = () =>
    loading ? (
      loadingComponent
    ) : (
      <ContentInfoComponent data={info?.data?.content_info} type={'content_info'} handleDirectByUrl={handleDirectByUrl}/>
    );
  let contentSupport = () =>
    loading ? (
      loadingComponent
    ) : (
      <ContentSupportComponent data={info?.data?.content_support} type={'content_support'} handleDirectByUrl={handleDirectByUrl}/>
    );

  return (
    <div className="good-tab">
      <CTabs
        tabHeadings={["상품 상세", "상품 정보", "배달 및 결제 안내"]}
        tabContents={[contentDetail(), contentInfo(), contentSupport()]}
        activeIndex={0}
      />
    </div>
  );
};

export default GoodTabComponent;
