import { MESSAGE } from 'core/constants/Commons';
import { useFetch } from 'core/hooks/useFetch';
import API from 'core/networks/API';
import { API_LIST_ADDRESS } from 'modules/Address/api';
import { API_ORDER_HISTORY } from 'modules/Orders/api';
import { toast } from 'react-toastify';

export const useAddress = (params: any) => {

  const {
    data: addressLists,
    loading: loadingAddressList,
    error: errorAddressList,
  } = useFetch({ url: API_LIST_ADDRESS, options: params });
  
  return [loadingAddressList, addressLists?.data || []]
}

export const useUpdateAddress = (form: any) => {

  const updateShippingAddress = async (data: any) => {
    try {
      
       const res = await API.post(API_ORDER_HISTORY.UPDATE_SHIPPING_ADDRESS, data);
       if (res.status === 200) {
         toast.dismiss();
         toast(MESSAGE.UPDATE_ADDRESS_SUCCESS);
       }else{
         toast.dismiss();
         toast(MESSAGE.UPDATE_ADDRESS_ERROR, {
           type: 'error'
         });
       }
    } catch (error) {
       toast.dismiss();
       toast(MESSAGE.UPDATE_ADDRESS_ERROR, {
         type: 'error'
       });
    }
   }

   const _handleUpdate = async (data: any) => {
    if (data.order_id) {
      return await updateShippingAddress(data)
    }
   }

 
  return _handleUpdate
}