import { all } from 'redux-saga/effects';

let sagas: any = []
const sagaModules = require.context('./../../modules/', true, /([^/]+)\/saga.ts$/);
const modules = require.context('./../../modules/', true, /module.ts$/)

modules.keys().forEach((mpath: any) => {
    const moduleJson = modules(mpath).default
    if(moduleJson?.status?.redux && mpath.includes('modules/')){
        const key = mpath.toString().replace('module.ts', 'store/saga.ts');
        const saga = sagaModules(key);
        sagas = [...sagas, (saga.default || saga)];
    }
})


export default function* rootSaga() {
    yield all([
        ...(sagas.map((pageSaga: any) => {
            return pageSaga()
        }))
    ]);
}
