
import LoginPage from "./pages/LoginPage";
import ForgotPwPage from "./pages/ForgotPwPage";
import SignUpPage from "./pages/SignUpPage";
import TermsOfService from "../StaticPage/pages/TermsOfService";
import PolicyUse from "../StaticPage/pages/PrivacyPolicy";
import React from "react";

const router =  [
    {
        name: "LOGIN",
        auth: false,
        path: "/login",
        element: <LoginPage/>
    },
    {
        name: "FORGOT_PASSWORD",
        auth: false,
        path: `/forgot-password`,
        element: <ForgotPwPage/>
    },
    {
        name: "SIGNUP",
        auth: false,
        path: `/signup`,
        element: <SignUpPage/>
    },
    {
        name: "TERMS_OF_SERVICE",
        auth: false,
        path: `/terms-of-service`,
        element: <TermsOfService/>
    },
    {
        name: "POLICY_USE",
        auth: false,
        path: `/policy-use`,
        element: <PolicyUse/>
    },
]
export default router
