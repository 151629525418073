import { useEffect } from "react";


export const useCountSize = (params: any) => {
  const countSize = () => {
    const viewportHeight = window.innerHeight;
    const headerElement = document.getElementById("main-header");
    const headerHeight = headerElement ? headerElement.clientHeight : 0;
    const submaryEl = document.getElementById("summary-panel");
    const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
    const filterEl = document.getElementById("filter-panel");
    const filterHeight = filterEl ? filterEl.clientHeight : 0;
    const displayEl = document.getElementById("display-panel");
    const displayHeight = displayEl ? displayEl.clientHeight : 0;
    const contentHeight =
      viewportHeight -
      headerHeight -
      submaryHeight -
      filterHeight -
      displayHeight;
    const listEl = document.getElementById("list-panel");
    if (listEl) listEl.style.height = contentHeight.toString() + "px";
    return contentHeight.toString() + "px";
  }

  useEffect(() => {
    countSize();
  }, [params])
};