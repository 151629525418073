import * as yup from "yup";
import { MESSAGE } from "core/constants/Commons";

export const checkIsCardNumber = {
  name: 'card_number',
  message: MESSAGE.CARD_NUMBER_REQUIRED,
  test: (value) => {
    let length = value.length;
    return length === 16;
  }
}

export const checkIsExpiryDate = {
  name: 'expiry_date',
  message: MESSAGE.EXPIRY_REQUIRED,
  test: (value) => {
    let length = value.length;
    return length === 4;
  }
}


const orderValidationSchema = yup.object().shape({
  policy_payment: yup.bool().oneOf([true], MESSAGE.POLICY_PAYMENT_REQUIRED),
  sender_account_name: yup.string().when("payment_method", (val, schema) => {
    if (val[0] === "MONEY") {
      return schema.required(MESSAGE.BANK_ACCOUNT_REQUIRED);
    }
    return schema;
  }),
  install_month: yup.string().when("payment_method", (val, schema) => {
    if (val[0] === "CARD_MANUAL") {
      return schema.required(MESSAGE.INSTALL_MONTH_REQUIRED);
    }
    return schema;
  }),
  expiry_date: yup.string()
    .when("payment_method", (val, schema) => {
      if (val[0] === "CARD_MANUAL") {
        return schema.required(MESSAGE.EXPIRY_REQUIRED).test(checkIsExpiryDate);
      }
      return schema;
    }),
  card_number: yup.string()
    .when("payment_method", (val, schema) => {
      if (val[0] === "CARD_MANUAL") {
        return schema.required(MESSAGE.CARD_NUMBER_REQUIRED).test(checkIsCardNumber);
      }
      return schema;
      }),
  store_id: yup.string().when("payment_method", (val, schema) => {
    if (val[0] === "MONEY") {
      return schema.required(MESSAGE.BANK_ID_REQUIRED);
    }
    return schema;
  }),
  payment_method: yup.string().required(MESSAGE.PAYMENT_METHOD_REQUIRED),
  shipping_time_minute: yup.string().required(MESSAGE.SHIPPING_TIME_REQUIRED),
  shipping_time_hour: yup.string().required(MESSAGE.SHIPPING_TIME_REQUIRED),
  shipping_time_day: yup.string().required(MESSAGE.SHIPPING_TIME_REQUIRED),
  // order_name: yup
  //   .string()
  //   .required(MESSAGE.ORDER_NAME_REQUIRED)
  //   .max(20, MESSAGE.ORDER_NAME_MAX_LENGTH),
  address_id: yup.number().required(MESSAGE.ADDRESS_REQUIRED),
});

export default orderValidationSchema;
