import { useEffect, useState } from 'react';
import {
  RouterProvider
} from "react-router-dom";
import useHandleError from './core/hooks/useHandleError';
import API, { setUpUUID, setupAxios } from "./core/networks/API";
import { AppRouter } from './core/router';
import useAccountSocket from "./modules/Auth/hooks/useSocket";

import './App.scss';
import { config } from "./core/config/app";

import Loading from 'core/components/Loading';
import { readNotifyFromPush } from 'core/utils/helper';
import { useLastOrder } from 'modules/Orders/hooks/useOrder';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from "react-toastify";
import { useFetchMe } from "./modules/Auth/hooks";
import { API_APP_VERSION, API_SYSTEM_SETTING } from 'modules/AppSettings/api';
import CDialog from 'core/components/CDialog';
import { deviceStorage } from 'core/utils/devicestorage';
// import {fetchLanguage} from "./modules/Locale/store/actions";
import { isAndroid, isIOS } from 'react-device-detect';
const router = AppRouter;

setupAxios()

const App = () => {
  const appPackage = config.appPackage;
  const [status, setStatus] = useState(true);
  const [isOpenMarket, setIsOpenMarket] = useState<any>(false);
  const [dataVersion, setDataVersion] = useState<any>({});
  const appVersion = deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(appPackage+'_APP-VERSION');
  useAccountSocket()
  useFetchMe()
  // const [loadingLastOrder] = useLastOrder({});
  useHandleError()
  readNotifyFromPush(window?.location?.search)
  console.log('_APP_NATIVE_VERSION', appVersion);
  // const getAppVersion = async () => {
  //   try {
  //     const res = await API.get(API_APP_VERSION, {});
  //     let dataRes = res.data.data;
  //     console.log('_handleGetAppVersion', dataRes);
  //     setDataVersion(dataRes);

  //     if (isAndroid && appVersion !== "") {
  //       if (dataRes?.android_ver_id !== appVersion) {
  //         setIsOpenMarket(true);
  //       }else{
  //         setIsOpenMarket(false);
  //       }
  //     }
  //     if (isIOS && appVersion !== "") {
  //       if (dataRes?.ios_ver_id !== appVersion) {
  //         setIsOpenMarket(true);
  //       }else{
  //         setIsOpenMarket(false);
  //       }
  //     }
  //   }catch (error: any) {
  //     console.log(error);
  //     setIsOpenMarket(false);
  //   }
  // }; 

  useEffect(()=>{
    console.log('App');
    // getAppVersion();
    //need call update status payment when user kill app 
    (window as any).webViewDocumentReady().then(()=>{
      setTimeout(()=> {
        setUpUUID()
        setupAxios()
      }, 500)
    })

    if(config.debug){
      // @ts-ignore
      // document.getElementById("debugPanel").style.display = 'block'

    }else{
      // @ts-ignore
      // document.getElementById("debugPanel").remove()
    }

  }, [])


  const openMarket = () => {
    let url:string = '';
    if (isAndroid) {
      url = dataVersion.android_market_url
    }
    if (isIOS) {
      url = dataVersion.ios_market_url
    }
    (window as any).webViewRequestOpenMarket(url);
  }

  return (<>
    {status ? <RouterProvider router={router} /> : <Loading status={true}/>}
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            // pauseOnHover
            theme="colored"
        />
        <CDialog
          id={"app-version-dialog"}
          className={"app-version-dialog"}
          show={isOpenMarket || false}
          handleSubmit={() => openMarket()}
          title={null}
          closeBtn={null}
          submitBtn={'확인'}
          disableSubmitBtn={null}
          overlay={true}
      >
        {/* APP: {deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(appPackage+'_APP-VERSION')}
      <br />
       api: {dataVersion.android_ver_id} */}
       <div className="content-app-version">
        <label>응용 프로그램에 새 버전이 있습니다.</label>
        <label>새 버전을 업데이트하십시오.</label>
       </div>
      </CDialog>
    </>
  );
};

export default App;
