import {useEffect, useState} from "react";
import hideIcon from "../../assets/images/icon/hide.svg";
import showIcon from "../../assets/images/icon/show.svg";

const CPassword = (props: any) => {
    const [_props, setProps] = useState<any>({
        name: props.name,
        id: props.id || '',
        value: props.value || '',
        label: props.label || '',
        desc: props.desc || '',
        className: props.className || '',
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        required: props.required || false,
        error: props.error || '',
        warning: props.warning || '',
        handleChange: props.handleChange || '',
        placeholder: props.placeholder || '',
        maxLength: props.maxLength || 100,
        pattern: props.pattern || '',
        onClick: props.onClick || function (e: any){},
        onFocus: props.onFocus || function (e: any){},
        onBlur: props.onBlur || function (e: any){},
        showIcon:props.showIcon || false,
    })

    useEffect(()=>{
        setProps(props)
    }, [props])

    const cleanPassword = (str: string) => {
        // Loại bỏ các ký tự không phải số hoặc tiếng Anh
        const cleanedStr = str.replace(/[^0-9a-zA-Z]/g, '');

        // Giới hạn độ dài tối đa
        return cleanedStr.slice(0, _props.maxLength);
    }
    const handleChange = (e: any) => {
        let value = cleanPassword(e.target.value)
        // console.log(value)
        setProps({..._props, value })
        e.target.value = value
        // console.log(e.target.value)
        if(typeof props.handleChange == 'function')
            _props.handleChange(e)
    }

    const handleClick = (e: any) => {
        if(_props.onClick)
            _props.onClick()
    }

    const handleFocus = (e: any) => {
        if(_props.onFocus)
            _props.onFocus(e)
    }

    const handleBlur = (e: any) => {
        if(_props.onBlur)
            _props.onBlur(e)
    }

    const handleShowHidePassword = () => {
        setProps({..._props, showPassword: !_props.showPassword})
        if(typeof props.handleShowHideStatePassword == 'function') props.handleShowHideStatePassword(_props.showPassword)
    }
    const renderControl = () => {
        let className = ["c-input-wrapper"]
        // if(!_props.showPassword) className.push("show-hide-password")
        // console.log(52, className, _props.showPassword)
        return <div className={className.join(' ')}>
            {/*{(!_props.showPassword) && <div className="markup">{secretPassword(_props.value)}</div>}*/}
            <input
                name={_props.name}
                id={_props.id}
                type={_props.showPassword ? 'text' : 'password'}
                value={_props.value}
                placeholder={_props.placeholder}
                readOnly={_props.readOnly}
                disabled={_props.disabled}
                onChange={handleChange}
                autoComplete={"one-time-code"}
                autoCorrect={"one-time-code"}
                maxLength={_props.maxLength}
                pattern={_props.pattern}
                onClick={handleClick}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {_props.showIcon ? <span className={'password-icon'} onClick={handleShowHidePassword}><img src={_props.showPassword ? hideIcon: showIcon} alt={""}/> </span> : ''}

        </div>
    }

    return <>
        <div className={_props.className}>
            <div className="c-form-control">
                {_props.label && <label className="c-label">{_props.label} {_props.required && <span className="required">*</span>}</label>}
                {_props.desc && <p className="c-desc">{_props.desc}</p>}
                {renderControl()}
                {_props.error && <p className="c-form-error">{_props.error}</p>}
                {_props.warning && <p className="c-form-error"><span>*</span><span>{_props.warning}</span></p>}
            </div>
        </div>
    </>
}

export default CPassword;