import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchMe, login, updateFormSignUp} from "../store/actions";
import API from "core/networks/API";
import {API_LOGIN} from "../api";
import Reducer from "../store/reducers";
import {config} from "../../../core/config/app";
import {deviceStorage} from "../../../core/utils/devicestorage";
import { useGetCarts } from 'modules/Orders/hooks/useCarts';

interface IUser {
  phone: string;
  password: string;
}

export function useFetchMe() {
    const dispatch = useDispatch();
    const token = deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(config.appPackage+'_X_TOKEN')
    const [, handleGetCart] = useGetCarts({});
    useEffect(() => {
        if (token) {
            dispatch(fetchMe())
            handleGetCart()
        }
    // eslint-disable-next-line
    }, [])
}
// usage
// useFetchMe()

export function useLogin(onSuccess: any, onFail: any) {
    const dispatch = useDispatch();

    const handleSubmitLogin = async (e: any, loginCredential: IUser) => {
        e.preventDefault()
        try{
            const res = await API.post(API_LOGIN, loginCredential)
            console.log(!res?.data?.status,'ddddd')
            if (!res?.data?.status) {
                if (onSuccess) {
                    onSuccess(res)
                }
            } else {
                dispatch(login(res.data.data))
            }
        } catch (e) {
            console.log(e)
            if (onFail) {
                onFail(e)
            }
        }
    }

    return [handleSubmitLogin]
}
// usage
// const [ handleSubmitLogin ] = useLogin()
// handleSubmitLogin()

export function useUser(){
    const auth = useSelector((state:any) => state.Auth);
    const user = {userInfo:auth?.user,setting:auth?.setting}
    return user;
};

export function useUpdateFormSignUp(data:any) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateFormSignUp(data))
    }, [data])
}
export function useFormSignUp() {
    const dataSignUp = useSelector((state:any) => state.Auth);
    let dataForm = dataSignUp?.dataFormSignUp
    return dataForm
}

export function useIdleTime() {
    const data = useSelector((state:any) => state.Auth);
    let dataTime = data?.idleTime
    return dataTime
}

export function useTimeResume() {
    const data = useSelector((state:any) => state.Auth);
    let dataTime = data?.timeResume
    return dataTime
}