import Policy from "./pages/Policy";

const router =  [
    {
        name: "Policy",
        auth: false,
        path: "/policy/:id",
        element: <Policy />
    },
]

export default router
