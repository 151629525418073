import SkeletonComponent from "core/components/Skeleton/skeleton";
import { numberFormat } from "core/utils/helper";
import { Carousel } from "react-responsive-carousel";
import FeaturedProductDetail from "../assets/images/featured_product_detail.svg"
import CFancybox from "core/components/CFancybox";
interface dataDetailProps {
  info: any;
  loading: boolean
}

const GoodInfoComponent = ({ info, loading }: dataDetailProps) => {
  const renderChild  = () => {
    if (info?.data?.images.length > 0) {
      return info?.data?.images.map((item:any, k:number) => {
        return (
          <div key={k}>
            <img src={item.path_original} alt={""} />
          </div>
        );
      });
    }else{
      return <></>
    }
  }

  const renderImageProduct = () => {
    console.log('renderImageProduct', info?.data);
    
    if (info?.data?.images.length > 0) {
      return (
        <CFancybox cssClass="" dataSet={info?.data?.images.map((e: any) => e)} />
        // <Carousel
        //   infiniteLoop={false}
        //   showIndicators={false}
        //   showArrows={false}
        //   showStatus={true}
        //   statusFormatter={(currentItem: any, total: any) => `${currentItem}/${total}`}
        //   showThumbs={false}
        //   stopOnHover={true}
        //   selectedItem={0}
        // >
        //   {renderChild()}
        // </Carousel>
      );
    }
    return <></>;
  };

  return (
    <div className="good-info">

      <div className="img-swiper">
        {
          loading ? <SkeletonComponent width={324} height={200}/> : renderImageProduct()
        }
        {
          info?.data?.featured_state === 'Y' &&
          <div className="featured-product-detail">
            <img src={FeaturedProductDetail} alt="" />
          </div>
        }
      </div>

      <div className="good-info-detail">
        <h1 className={`good-info-detail__title ${info?.data?.featured_state === 'Y' ? 'space-name' : ''}`}>
        {
          loading ? <SkeletonComponent width={250} height={20}/>
          : `[${info?.data?.category?.name || ''}] ${info?.data?.name || ''}`
        }

        </h1>
        <p className="good-info-detail__description">
        {
          loading ? <SkeletonComponent width={350} height={18}/>
          : info?.data?.description || ""
        }
        </p>
        <span className="good-info-detail__price">
        {
          loading ? <SkeletonComponent width={70} height={19}/>
          : <>
            {numberFormat(info?.data?.price || 0)}원
          </>
        }
        </span>
      </div>
    </div>
  );
};

export default GoodInfoComponent;
