import ConsultPage from "./pages/ConsultPage";
import ConsultForm from "./pages/ConsultForm";
import ConsultDetail from "./pages/ConsultDetail";

const router =  [
    {
        name: "CONSULT_LIST",
        auth: true,
        path: "/consult",
        element: <ConsultPage/>
    },
    {
        name: "CONSULT_DETAIL",
        auth: true,
        path: "/consult/detail/:id",
        element: <ConsultDetail/>
    },
    {
        name: "CONSULT_FORM",
        auth: true,
        path: "/consult/create",
        element: <ConsultForm/>
    },
    {
        name: "CONSULT_FORM_UPDATE",
        auth: true,
        path: "/consult/edit/:id",
        element: <ConsultForm/>
    },
]
export default router
