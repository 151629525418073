
interface TimeSettingProps {
  filter?: any,
  data?: any;
  setDateFilter?: Function;
  customClass?: string;
}

const TimeSettingFilterComponent = ({ filter, data, setDateFilter, customClass }: TimeSettingProps) => {

  const handleChangeTimeOption = (e: any) => {
      if (setDateFilter) {
        setDateFilter(e)
      }
  };

  return (
    <div className={`${customClass ? customClass : 'order-history-list-page__time-filter'}`}>
      {/* <div>
        {filter.start_date} ~ {filter.end_date}
      </div> */}
      <ul className="time-setting">
        {
          (Object.values(data)).map((item: any) => 
            <li className={`time-setting__item time-setting__item--${item.label === filter.time_option ? 'active' : ''}`} 
              onClick={() => handleChangeTimeOption(item)}
              key={item.label}
            >
              <span>{item.label}</span>
            </li>
          )
        }
      </ul>
    </div>
  );
};

export default TimeSettingFilterComponent;
