import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FETCH_DATA_SUCCESS, LOGIN} from "../store/types";
import {emitPrivate, emitPublic} from "../../SocketNotification/store/actions";
import {config} from "core/config/app";
import {deviceStorage} from "../../../core/utils/devicestorage";
function useAccountSocket() {
    const dispatch = useDispatch()
    const redux = useSelector((state: any) => state)
    
    useEffect(()=>{
        // @ts-ignore
        switch (redux?.Auth?.type) {
            case LOGIN:
            case FETCH_DATA_SUCCESS:
                // @ts-ignore
                if(window.socket) {
                    // @ts-ignore
                    window.socket.on(config.appPackage+'_private_' + redux.Auth.user.id, (e) => {
                        // console.log(config.appPackage+'_private_'+redux.Auth.user.id, e)
                        dispatch(emitPrivate(e))
                    })
                    // @ts-ignore
                    window.socket.on('connect', () => {
                        console.log('connect')
                        let token = deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(config.appPackage+'_X_TOKEN')
                        // @ts-ignore
                        window.socket.emit('authenticate', { token: token });
                    })
                    // @ts-ignore
                    window.socket.on(config.appPackage+'_public', (e) => {
                        // console.log(config.appPackage+'_public', e)
                        dispatch(emitPublic(e))
                    })
                }
                break;

            default: break;
        }
    // eslint-disable-next-line
    }, [redux?.Auth?.type])

    return null
}

export default useAccountSocket

// usage
// useAccountSocket()
