import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrderRequest, getListOrderHistoryRequest, updateReloadPageListRequest } from "../store/actions";
import { CANCEL_ORDER_FAILED, CANCEL_ORDER_SUCCESS } from "../store/types";

export const useLists = (data: any) => {
  const dispatch = useDispatch();
  const [param] = useState<any>({
    order_by: "created_at",
    order_dir: "desc",
    infinity: true,
    page_size: 5,
    "relation[]": ["creator", "items", "shippingApp"],
  });
  const orderHistory = useSelector((state: any) => state.Orders.orderHistoryReducer || {});
  
  const loadingLists = orderHistory.loadingListOrderHistory;
  const lists = orderHistory?.lists || {data: [], total: 0, totalPage: 1};
  const listParams = orderHistory.listParams;
  const errors = {};
  const params = {
    ...param,
    ...data,
  }
  
  useEffect(() => {
    if (+data.reload === 0) {
      dispatch(getListOrderHistoryRequest(params));
    }
  }, [dispatch, getListOrderHistoryRequest, data]);


  return [loadingLists, lists, listParams, errors];
};

export const useUpdateReload = () => {
  const dispatch = useDispatch();

  const handleUpdate = (value: any) => {
    dispatch(updateReloadPageListRequest(value));
  }

  return handleUpdate
}

export const useCancelOrder = (form: any) => {
  const dispatch = useDispatch();
  const orders = useSelector((state: any) => state.Orders.orderHistoryReducer || {});
  useEffect(() => {
    switch (orders.type) {
      case CANCEL_ORDER_SUCCESS:
        if (form.onSuccess) {
          return form.onSuccess(orders.data);
        }
        break;
      case CANCEL_ORDER_FAILED:
        if (form.onFailed) {
          return form.onFailed();
        }
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [orders.type]);
  
  const handleCancel = (values: any) => {
    let formData = {};
    if (values.isCheckBank === "PAID") {
      formData = {
        reason: values.reason,
        order_id: values.order_id,
        type: values.type,
        bank_id: values.bank_id,
        payment_method: values.payment_method,
        bank_account_name: values.bank_account_name,
        bank_account_number: values.bank_account_number,
      }
    }else{
      formData = {
        reason: values.reason,
        order_id: values.order_id,
        type: values.type,
        payment_method: values.payment_method,
      }
    }
    dispatch(cancelOrderRequest(formData));
  }

  return handleCancel
}