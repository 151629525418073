import MainPage from "./pages/MainPage";

const router =  [
    {
        name: "MAIN",
        auth: true,
        exact: true,
        path: "/main",
        element: <MainPage/>
    },
]
export default router
