import { CKeyBoardProps } from 'core/interface/CKeyBoard';
import React, { memo, useRef } from 'react';
import useOnClickOutside from 'core/hooks/useOnClickOutside';
import numberOne from 'core/assets/images/keyboard/1.svg';
import numberTwo from 'core/assets/images/keyboard/2.webp';
import numberThree from 'core/assets/images/keyboard/3.webp';
import numberFour from 'core/assets/images/keyboard/4.webp';
import numberFive from 'core/assets/images/keyboard/5.webp';
import numberSix from 'core/assets/images/keyboard/6.webp';
import numberSeven from 'core/assets/images/keyboard/7.webp';
import numberEight from 'core/assets/images/keyboard/8.webp';
import numberNight from 'core/assets/images/keyboard/9.webp';
import numberZero from 'core/assets/images/keyboard/0.webp';
import numberZeroZero from 'core/assets/images/keyboard/00.webp';
import iconDelete from 'core/assets/images/keyboard/del.svg';

const keyBoardList = [
  {
    id: 1,
    icon: numberOne,
    value: '1'
  },
  {
    id: 2,
    icon: numberTwo,
    value: '2'
  },
  {
    id: 3,
    icon: numberThree,
    value: '3'
  },
  {
    id: 4,
    icon: numberFour,
    value: '4'
  },
  {
    id: 5,
    icon: numberFive,
    value: '5'
  },
  {
    id: 6,
    icon: numberSix,
    value: '6'
  },
  {
    id: 7,
    icon: numberSeven,
    value: '7'
  },
  {
    id: 8,
    icon: numberEight,
    value: '8'
  },
  {
    id: 9,
    icon: numberNight,
    value: '9'
  },
  {
    id: 10,
    icon: numberZeroZero,
    value: '00'
  },
  {
    id: 11,
    icon: numberZero,
    value: '0'
  },
  {
    id: 12,
    icon: iconDelete,
    value: 'delete'
  },
]

const CKeyBoard = ({
  className,
  onClickNumber,
  isOpen,
  onRemoveNumber,
  onHideKeyboard
}: CKeyBoardProps) => {
  
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, onHideKeyboard);

  const onClick = (value:string) => {
    
    if (value === 'delete') {
      onRemoveNumber();
    }else{
      onClickNumber(value);
    }
  }

  const renderKeyBoard = () => {
    return keyBoardList.map((item, idx) => 
      <div className="ckey-board__group__item" key={idx} onClick={() => onClick(item.value)}>
        <div className="img number-key">
          {/* <img src={item.icon} alt="" /> */}
          {
            item.value === 'delete' ? <img src={item.icon} alt="" /> : 
            <span className='number'>{item.value}</span>
          }
        </div>
      </div>
    )
  }


  return (
    <>
      {
        isOpen && <div className={`ckey-board ${className}`} ref={wrapperRef}>
        <div className="ckey-board__group">
          {renderKeyBoard()}
        </div>
      </div>
      }
    </>
  )
};

export default memo(CKeyBoard);