import IconHeader from "core/components/header/IconHeader";
import {RouterName} from "core/router";

import AppLayout from "core/layouts/AppLayout";
import React, {memo, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CDropdown from "core/components/form-controls/CDropdown";
import CFormControl from "core/components/form-controls/CFormControl";
import CTextArea from "core/components/form-controls/CTextArea";
import CImagePicker from "core/components/form-controls/CImagePicker";
import uuid from "react-uuid";
import {toast} from "react-toastify";
import API from "core/networks/API";
import {API_LIST_CONSULT} from "../api";
import CDialog from "core/components/CDialog";
import Lottie from "lottie-react";
import LottieLoadingJson from 'core/assets/lotties/loading.json'
import {CategoryDataSet} from "../utils/constants";
import { hideBottomButtonInputFocus } from "core/utils/helper";
const ConsultForm = (props: any) => {
    const params = useParams();
    const navigate = useNavigate();
    
    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }



    const [formData, setFormData] = useState<any>({
        loading: false,
        dirty: false,
        valid: false,
        editId: null,
        urlBack:-1,
        payload: {
            type: "",
            subject: "",
            question: "",
            attachments: []
        },
        errors: {

        }
    })
    

    const handleGoBack = () => {
        navigate(formData.urlBack)
    }

    const [dialog, setDialog] = useState({
        cancel: false
    })

    const [ImageDataSet, setImageDataSet] = useState<any>([])

    const validateItem = (field: string) => {
        let valid = true;
        switch (field) {
            case "type": if(!formData.payload.type) valid = false; break;
            case "subject": if(formData.payload.subject.length === 0 || formData.payload.subject.length > 50) valid = false; break;
            case "question": if(formData.payload.question.length === 0 || formData.payload.question.length > 2000) valid = false; break;
            case "attachments": if(formData.payload.attachments.length === 0 || formData.payload.attachments.length > 10) valid = false; break;
        }
        return valid;
    }

    const validateForm = (field = '') => {
        let valid = true;
        ['type', 'subject', 'question'].forEach((field) => {
            if(!validateItem(field)) {
                valid = false;
                console.log(field)
            }
        })
        return valid;
    }

    const handleInputChange = (e: any) => {
        try{
            const valid = validateForm(e.target.name);
            setFormData({...formData,
                dirty: true,
                valid,
                payload: {
                    ...formData.payload,
                    [e.target.name]: e.target.value
                }
            })
        } catch (e: Error|any) {
            console.log(e)
        }
    }

    const handleCategoryChange = (item: any) => {
        try{
            const valid = validateForm('type');
            setFormData({...formData,
                dirty: true,
                valid,
                payload: {
                    ...formData.payload,
                    type: item.id
                }
            })
        } catch (e: Error|any) {
            console.log(e)
        }
    }

    const handleFileChange = (images: any) => {
        try{
            const attachments = images.map((item: any)=>{
                return item.data
            })
            console.log(attachments,'attachments',images,ImageDataSet,JSON.stringify(attachments) === JSON.stringify(ImageDataSet))
            if(attachments.length > 0) {
                let checkAttactmentFirstFetchData = JSON.stringify(attachments) === JSON.stringify(ImageDataSet)
                setFormData({
                    ...formData,
                    payload: {
                        ...formData.payload,
                        attachments: attachments
                    },
                    dirty: checkAttactmentFirstFetchData ? false : true
                })
            } else{
                setFormData({
                    ...formData,
                    payload: {
                        ...formData.payload,
                        attachments: attachments
                    },
                    dirty: true
                })
            }

        } catch (e: Error|any) {
            console.log(e)
        }
    }

    const handleSubmit = async (e: any) => {
        try{
            console.log(formData)
            setFormData({...formData, loading: true})
            const data = new FormData()
            Object.keys(formData.payload).forEach(k=>{
                if(k == "attachments"){
                    formData.payload.attachments.forEach((file: any) =>{
                        // console.log(155, file?.path)
                        if(file?.path){
                            data.append("path_attachments[]", file.path)
                        }else{
                            data.append("attachments[]", file)
                        }

                    })
                }else if(k == 'question'){
                    // formData.payload[k] =   formData.payload[k].replace(/\n/g, " ");
                    data.append(k, formData.payload[k])
                }
                else{
                    data.append(k, formData.payload[k])
                }
            })
            
            console.log(formData.editId,162, data)
            if(formData.editId){
                data.append('_method', 'patch')
                const res = await API.post(API_LIST_CONSULT+'/'+formData.editId, data)
            }
            else{
                const res = await API.post(API_LIST_CONSULT, data)
            }
            // console.log(res)
            setFormData({...formData, loading: false})
            localStorage.setItem("create_update_consultant", "true")
            navigate(RouterName.CONSULT_LIST)
        } catch (e: Error | any) {
            console.log(e)
            setFormData({...formData, loading: false})
        }
    }

    const fetchData = async ()=>{
        try{
            if(params.id){
                setFormData({...formData, loading: true})
                console.log(172, params.id)
                const res = await API.get(API_LIST_CONSULT+'/'+params.id, {})
                console.log(176, res.data.data)
                setFormData({...formData, payload: {
                        type: res.data.data.type,
                        subject: res.data.data.subject,
                        question: res.data.data.question,
                        attachments: []
                    },
                    loading: false,
                    editId: params.id,
                    dirty: false,
                })
                setImageDataSet(res.data.data.question_attachments || [])
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(()=>{
        const valid = validateForm('attachments');
        setFormData({
            ...formData,
            valid
        })
    }, [formData.payload])

    useEffect(()=>{
        console.log(formData)
    }, [formData])

    useEffect(()=>{
        countSize()
        fetchData()
        hideBottomButtonInputFocus();
        (window as any).webViewCallGetListPath(['/consult/create', '/consult/edit/','/consult'])
    }, [])

    const handleCancle = (toMain:any = null) => {
        
        if(formData.dirty){
            setDialog({...dialog, cancel: true})
        }else {
            if(typeof toMain == 'boolean'){
                navigate(RouterName.MAIN)
            }else {
                handleGoBack()
            }
        }
        
    }

    //handle go back from native
    (window as any).backFromDeviceAndroid = function() {
        //code webview
        handleCancle()
    }

    return <>
        <AppLayout className={"consult-page"}>
            <IconHeader
                title={'1:1 문의 작성'}
                iconLeft={true}
                handleLeftClick={handleCancle}
                handleRightClick={() => {
                    // navigate(RouterName.MAIN)
                    setFormData({
                        ...formData,
                        urlBack:RouterName.MAIN
                    })
                    handleCancle(true)
                }}
                iconRight={true}
            />

            <main id="list-panel">
                <CFormControl label={"문의 유형"} rightDesc={"필수 입력 사항"} type={"empty"} required={true}>
                    <CDropdown name={'type'} dataSet={CategoryDataSet} selected={formData.payload.type} placeholder={"문의하실 내용을 선택해주세요."} handleChange={handleCategoryChange}/>
                </CFormControl>
               <div>
                    <CFormControl name={'subject'} maxLength={50} label={"제목"} placeholder={"제목을 입력해주세요."} type={"input"} value={formData.payload.subject} required={true} handleChange={handleInputChange}/>
                    <div className="count-chacter">
                        <label>{formData.payload.subject.length}/50</label>
                    </div>
               </div>
                <CFormControl label={"내용"} type={"empty"} required={true}>
                    <CTextArea maxLength={2000} rows={5} placeholder={"문의 내용을 입력해주세요."} value={formData.payload.question} name={'question'} handleChange={handleInputChange}/>
                    {/* <div className="count-chacter">
                        <label>{formData.payload.question.length}/2000</label>
                    </div> */}
                </CFormControl>
                <CFormControl label={"사진"} type={"empty"} required={false}>
                    <CImagePicker
                        multiple={true} dataSet={ImageDataSet} readonly={false} disabled={formData.payload.attachments.length == 10 ? true : false}
                        allows={['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp','image/x-ms-bmp']} maxSize={1024*1024*10} maxItems={10}
                        handleError={(e:any)=>{toast(e.message)}}
                        handleChange={handleFileChange}
                        description={"- 총 10MB미만의 jpg, gif, bmp, png, jpeg 파일만 업로드 가능"}
                    />
                </CFormControl>
                <div className="bottom-btn">
                    <div className="row">
                        <div className="col-6 pe-2">
                            <div className="d-grid">
                                <button disabled={formData.loading} className="cbtn btn-outline-primary" onClick={handleCancle}>취소</button>
                            </div>
                        </div>
                        <div className="col-6 ps-2">
                            <div className="d-grid">
                                <button disabled={!formData.valid || formData.loading} className="cbtn btn-primary" onClick={handleSubmit}>작성하기</button>
                            </div>
                        </div>
                    </div>

                </div>
                {formData.loading && <div className="loading-overlay"><Lottie height={120} animationData={LottieLoadingJson} loop={true} /></div>}

                <CDialog show={dialog.cancel} overlay={dialog.cancel}
                         closeBtn={"닫기"} submitBtn={"작성취소"}
                         className={"cancel-dialog"}
                         handleSubmit={(e:any) => handleGoBack()}
                         handleClose={() => {
                            setDialog({...dialog, cancel: false})
                            setFormData({
                                ...formData,
                                urlBack:-1
                            })
                         }}
                >
                    <div>작성을 취소 하시겠습니까?</div>
                </CDialog>
            </main>

        </AppLayout>
    </>
}

export default ConsultForm