import { combineReducers } from 'redux';

let reducers = {}

const reducerModules = require.context('./../../modules/', true, /reducers.ts$/);
const modules = require.context('./../../modules/', true, /module.ts$/)
modules.keys().forEach((mpath: any) => {
    const moduleJson = modules(mpath).default
    if(moduleJson?.status?.redux){
        const key = mpath.toString().replace('module.ts', 'store/reducers.ts');
        const reducer = reducerModules(key);
        reducers = {...reducers, [moduleJson.name] : (reducer.default || reducer)};
    }
})



const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
