import {useEffect, useState, FunctionComponent} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import 'modules/MyInfo/assets/scss/list.scss'
import AppLayout from "core/layouts/AppLayout";
import BottomNavigation from "core/layouts/BottomNavigation";
import MainHeader from "core/components/header/MainHeader";
import * as action from "modules/Auth/store/actions";
import {phoneNumberFormat, removeAllStorage} from "core/utils/helper";
import {RouterName} from "core/router";
import BellIcon from "core/assets/images/icon_bell.svg";
import ArrowRightIcon from "core/assets/images/icon_arrow_right.svg";
import SettingIcon from "core/assets/images/icon_setting.svg";
import HistoryPointIcon from "core/assets/images/icon_history_point.png";
import RefererMemberIcon from "core/assets/images/icon_referer_member.png";
import WithdrawalMoneyIcon from "core/assets/images/icon_withdrawal_money.png";
import InviteFriendIcon from "core/assets/images/icon_invite_friend.png";
import WaitingPaymentIcon from "core/assets/images/icon_waiting_payment.svg";
import SuccessPaymentIcon from "core/assets/images/icon_success_payment.svg";
import ReadyDeliveryIcon from "core/assets/images/icon_ready_delivery.svg";
import DeliveringIcon from "core/assets/images/icon_delivering.svg";
import ClockIcon from "core/assets/images/icon_clock.svg";
import CListItem from "core/components/CListItem";
import {useUser} from "modules/Auth/hooks";
import API from "../../../core/networks/API";
import {API_PROFILE, API_STATISTIC_ORDER} from "../api";
import {ShareMessage} from "../../Referrer/utils/constants";
import { toast } from "react-toastify";
import { useUpdateReload } from "modules/Products/hooks/useLists";

const MainMyInfoPage: FunctionComponent = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const updateReload = useUpdateReload();
    const user = useUser()
    const [stateData, setStateData] = useState<any>({
        loading: false,
        statistic:{
            completed:'',
            delivering:'',
            payment_completed:'',
            payment_pending:'',
            prepare_deliver:''
        },
    })

    
    const listMenuMyinfo = [
        {
            name: "HISTORY_ORDER",
            path: RouterName.ORDER_HISTORY_LIST,
            text:'주문내역 및 배달조회',
        },
        {
            name: "MANAGEMENT_ADDRESS",
            path: RouterName.ADDRESS_BOOK,
            text:'배송지관리',
        },
        {
            name: "ANNOUCEMENT_LIST",
            path: RouterName.ANNOUCEMENT_LIST,
            text:'공지사항',
        },
        {
            name: "FAQ",
            path: RouterName.FAQ,
            text:'자주하는 질문',
        },
        {
            name: "CONSULTANT",
            path: RouterName.CONSULT_LIST,
            text:'1:1 문의',
        }
    ]

    const fetchDataStatisticOrder = async () => {
        let dataRequest = {
        }
        try {
            const {data: response} = await API.get(`${API_STATISTIC_ORDER}`, dataRequest)
            if(response && response?.code == 200){
                console.log(response,'response')
                setStateData({...stateData,statistic:response.data})

            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response
        }

    }

    const countSize = () => {
        let viewportHeightPage = 0;
        const viewportHeight = window.innerHeight;
        let viewportHeightFixed = localStorage.getItem('height_viewport_myinfo')
        if(viewportHeightFixed && (parseInt(viewportHeightFixed) != viewportHeight)){
            viewportHeightPage = parseInt(viewportHeightFixed)
        }else {
            viewportHeightPage = viewportHeight
        }
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const boxInfoMember = document.getElementById('box-info-member');
        const boxInfoMemberHeight = boxInfoMember ? boxInfoMember.clientHeight : 0;
        const boxLine = document.getElementById('box-line');
        const boxLineHeight = boxLine ? boxLine.clientHeight : 0;
        const contentHeight = viewportHeightPage - headerHeight - boxInfoMemberHeight - boxLineHeight - 80
        const listEl = document.getElementById('box-list-panel');
        const body = document.getElementsByTagName('body');
        
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(() => {
        countSize()
        fetchDataStatisticOrder()
        updateReload({reload: 0})
    }, [])

    const handleLogout = async (e: any) => {
        removeAllStorage();
        dispatch(action.logOutRequest(e));
        navigate(RouterName.LOGIN)
    }
    const handleToSettingAppPage = (e:any) => {
        navigate(RouterName.SETTING_MENU)
    }
    const handleToNotificationPage = (e:any) => {
        navigate(RouterName.NOTIFICATION_LIST)
    }

    const handleInviteFriend = () => {
        (window as any).webViewRequestShare(ShareMessage)
    }

    const toEditProfilePage = () =>{
        const innerHeight = window.innerHeight;
        localStorage.setItem('height_viewport_myinfo',innerHeight.toString())
        navigate(RouterName.EDIT_PROFILE)
    }

    return (
        <AppLayout className={'my-info-page'}>
            <MainHeader
                title={'내정보'}
                iconRight={true}
                id='main-header'
            />
            <div className={`my-info-page__main `}>
                <div className={`my-info-page__main__info-member`} id='box-info-member'>
                    <div className={'box-info'}>
                        <div className={'box-info__data'} onClick={toEditProfilePage}>
                            <span className={'box-info__data__phone'}>{phoneNumberFormat(user?.userInfo?.phone)}</span>
                            <label className={'box-info__data__gender'}>님</label>
                            <img className={`box-info__data__icon `} src={ArrowRightIcon} alt={''}/>
                        </div>
                        <div className={'box-info__action'}>
                            <img className={`box-info__action__icon `} onClick={handleToNotificationPage} src={BellIcon} alt={''}/>
                            <img className={`box-info__action__icon `} onClick={handleToSettingAppPage} src={SettingIcon} alt={''}/>
                        </div>
                    </div>
                    <div className={'box-btn-action'}>
                        <div className={`box-btn-action__icon `} onClick={()=>{navigate(RouterName.REFERER_LIST)}}>
                            <img  src={RefererMemberIcon} alt={''}/>
                        </div>
                        <div className={`box-btn-action__icon `} onClick={()=>{navigate(RouterName.ACCUMULATE_LIST)}}>
                            <img  src={HistoryPointIcon} alt={''}/>
                        </div>
                        <div className={`box-btn-action__icon `} onClick={()=>{navigate(RouterName.ACCUMULATE_WITHDRAW_FORM)}}>
                            <img src={WithdrawalMoneyIcon} alt={''}/>
                        </div>
                        <div className={`box-btn-action__icon `} onClick={handleInviteFriend}>
                            <img src={InviteFriendIcon} alt={''}/>
                        </div>

                    </div>
                </div>
                <div id={'box-line'} className={'box-line'} />

                <div className="box-list-panel" id='box-list-panel'>
                    <div className={`my-info-page__main__info-order`}>
                        <h4 className={`info-order__title`}>
                            주문현황
                        </h4>
                        <div className={'info-order__status'}>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={WaitingPaymentIcon} alt={''}/>
                                <p>결제대기<br/>
                                    (입금)</p>
                                <strong>{stateData.statistic?.payment_pending || 0}</strong>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={ArrowRightIcon} alt={''}/>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={SuccessPaymentIcon} alt={''}/>
                                <p>결제완료</p>
                                <strong>{stateData.statistic?.payment_completed || 0}</strong>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={ArrowRightIcon} alt={''}/>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={ReadyDeliveryIcon} alt={''}/>
                                <p>배달 <br/>
                                    준비</p>
                                <strong>{stateData.statistic?.prepare_deliver || 0}</strong>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={ArrowRightIcon} alt={''}/>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={DeliveringIcon} alt={''}/>
                                <p>배달중</p>
                                <strong>{stateData.statistic?.delivering || 0}</strong>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={ArrowRightIcon} alt={''}/>
                            </div>
                            <div className={'info-order__status__item'}>
                                <img className={`box-info__data__icon `} src={ClockIcon} alt={''}/>
                                <p>배달 <br/>
                                    완료</p>
                                <strong>{stateData.statistic?.completed || 0}</strong>
                            </div>
                        </div>
                    </div>
                    <div className={`my-info-page__main__info-menu`}>
                        {listMenuMyinfo.map((item,key) =>(<CListItem
                            className={'CAccoditionItem'}
                            title={item.text}
                            time={''}
                            icon={true}
                            handleClick={()=>{navigate(item.path)}}
                            key={key}
                        />))}
                    </div>
                    <div className="d-grid my-info-page__main__info-btn">
                        <button onClick={(e) => handleLogout(e)}  className="cbtn btn-primary">로그아웃</button>
                    </div>
                    
                </div>
                
            </div>
            <BottomNavigation activeIndex={4}/>
        </AppLayout>
    )
}


export default MainMyInfoPage
