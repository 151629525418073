
import 'modules/StaticPage/assets/scss/static-page.scss'
import AppLayout from "core/layouts/AppLayout";
import Logo from "core/components/Logo";

import ComIntro from 'modules/StaticPage/assets/images/comintro.png';
import QuoteIcon from 'modules/StaticPage/assets/images/quote.png';

import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const CompanyIntroductionPage = (props: any) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(RouterName.MAIN)
    }


    const timeLineData = [
        {time: "2013년", content: <div>한정식 도시락의 <span>품격에 맞는 용기를 <br/>최초 개발</span>하였습니다.</div>},
        {time: "2015년", content: <div>살구나무집 대전 유성점을 오픈하여,<br/> <span>자체개발한 도시락 용기를 특허등록</span> <br/>(제30-08249847) 하였습니다.</div>},
        {time: "2017년", content: <div>주식회사 살구나무집 법인을 설립하여 <br/><span>청년벤처 기업에 선정</span>되었습니다.</div>}
    ]
    const renderTimeLine = () => {
        return timeLineData.map((item: any, k:number)=>{
            return <li key={k}>
                <div className="timeline-item">
                    <div className="left"><div className="time">{item.time}</div></div>
                    <div className="right">{item.content}</div>
                </div>
            </li>
        })
    }

    useEffect(()=>{
        window.scrollTo(0,0)
    }, [])

    return (
        <AppLayout className={"static-page company-introduction-page"}>
            <IconHeader
                title={'회사 소개'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <main>
                <Logo width={'160px'}/>
                <div className="com-intro-img">
                    <LazyLoadImage
                        alt={""}
                        effect={"blur"}
                        src={ComIntro} />
                    {/*<img src={ComIntro} alt={""}/>*/}
                </div>
                <div className="section-1">
                    살구나무집 도시락은 대전 용문동에서<br/>
                    1993년 한정식을 시작으로 시작하여
                </div>
                <div className="section-2">
                    <ul>{renderTimeLine()}</ul>
                </div>
                <div className="section-3">
                    살구나무집 도시락은 대전에서<br/>
                    10년 이상 장수한 기업으로<br/>
                    다른 도시락 전문과 차별화를 두고 있으며<br/>
                    고객의 만족도가 가장 높은 맛과<br/>
                    서비스를 제공하고있습니다.
                </div>
                <div className="quote">
                    <div className="quote-icon">
                        <img src={QuoteIcon} alt=""/>
                    </div>
                    음식을 만드는 정성과 담는 정성을 함께<br/>
                    연구하고  있는 살구나무집 도시락 입니다.
                </div>
            </main>
        </AppLayout>
    )
}


export default CompanyIntroductionPage
