// eslint-disable-next-line
export const config = {
  // prefix for all route
  appPackage: "apricot_app",
  basename: process.env.REACT_APP_PREFIX,
  apiUrl: process.env.REACT_APP_BASEAPI,
  version: process.env.REACT_APP_VERSION || '1.0.0',
  apiVersion: process.env.REACT_API_VERSION || '1.0',
  debug: process.env.REACT_APP_DEBUG === "TRUE" || process.env.REACT_APP_DEBUG === true,
  s3Url: process.env.REACT_APP_AWS_BASE_URL,
  buildId: "20230929001",
  apiKsPay: "http://kspay.ksnet.to/store/KSPayMobileV1.4/KSPayPWeb.jsp",
  KSPAYForm: {
    sndReply: "", // url callback
    sndOrdernumber: "", // order_id 
    sndGoodname: "", // product name -  상품명
    sndAmount: "", //price - 가격
    sndOrdername: "", // order name - 성명,
    sndMobile: "", //phone number - 이동전화
    sndPaymethod: "1000000000", //Credit card - 신용카드
    sndStoreid: "2008000204", // Store ID - 상점아이디 ---- 2999199999 ---- 2008000185
    sndCurrencytype: "WON", //currency unit - 화폐단위
    sndAllregid: "", // Social Security Number - 주민번호
    sndInstallmenttype: "0:2:3:4:5:6:7:8:9:10:11:12", // Installment Months - 할부개월수
    sndInteresttype: "NONE", // Interest-free classification - 무이자구분
    sndShowcard: "C", // Credit card display classification - 신용카드표시구분
    sndEmail: "", // e-mail - 전자우편
    sndCharSet: "utf-8", // Affiliate CharSet setting variable - 가맹점 CharSet 설정변수
    sndEscrow: "0", // Whether to apply escrow-- 0: Do not apply, 1: Apply - 에스크로적용여부-- 0: 적용안함, 1: 적용함
    sndVirExpDt: "", //  Due date -   마감일시
    sndVirExpTm: "", //  deadline - 마감시간
    sndStoreName: "케이에스페이(주)", // company name in Korean - 회사명을 한글로 넣어주세요(최대20byte)
    sndStoreNameEng: "kspay", // 회사명을 영어로 넣어주세요(최대20byte)
    sndStoreDomain: "http://www.kspay_test.co.kr", // 
    sndGoodType: "1", // Physical (1) / Digital (2) - 실물(1) / 디지털(2)
    sndUseBonusPoint: "", // 포인트거래시 60
    sndRtApp: "", //  Variables used when developing in the form of a hybrid APP - 하이브리드APP 형태로 개발시 사용하는 변수 
    sndStoreCeoName: "", //    Store representative name for Kakao Pay -  카카오페이용 상점대표자명 
    sndStorePhoneNo: "", //    Kakao Pay contact information -  카카오페이 연락처 
    sndStoreAddress: "", //    Kakao Pay address -  카카오페이 주소 
  }
}