import React, { memo, useState } from 'react';
import { BankSelectProps } from 'core/interface/BankSelect';
import { useFetch } from 'core/hooks/useFetch';
import SkeletonComponent from '../Skeleton/skeleton';
import { config } from "core/config/app"
const api_version = config.apiVersion;

const GET_BANK = `/app/${api_version}/banks`
const BankSelect = ({
  className,
  onSelect,
}: BankSelectProps) => {

  const [filter, ] = useState<any>({
    order_by: "id",
    order_dir: "asc",
    page: 0,
  });

  const {
    data: bankLists,
    loading: loadingList,
    error: errorList,
  } = useFetch({ url: GET_BANK, options: filter });

  
  const renderBank = () => {
    if (bankLists.data && bankLists.data.length > 0) {
      return bankLists.data.map((item: any, idx: number) => 
        <div className="bank-select__group__item" onClick={() => onSelect(item)} key={idx}>
          <div className="img">
            <img src={item.logo} alt="" />
          </div>
          <span className="bank-name">{item.short_name}</span>
        </div>
      )
    }else{
      return <>
        NO BANK
      </>
    }
  }

  return (
    <div className={`bank-select ${className}`}>
      <div className="bank-select__group">
        {
          loadingList ? <>
            <div className="bank-select__group__item">
              <div className="img">
                <SkeletonComponent className='img-loading' width={34} height={34} circle/>
              </div>
              <SkeletonComponent className='name-loading' width={40} height={14}/>
            </div>
            <div className="bank-select__group__item">
              <div className="img">
                <SkeletonComponent className='img-loading' width={34} height={34} circle/>
              </div>
              <SkeletonComponent className='name-loading' width={40} height={14}/>
            </div>
          </> : renderBank()
        }
      </div>
    </div>
  )
};

export default memo(BankSelect);