import { useState } from 'react';

export const useSelectRow = (data: any, key = 'id') => {
  const [ rowSelected, setRowSelected ] = useState<any>([])

  const onSelectRow = (e:any) => {
    let value = e;
    if (Array.isArray(e)) {
      setRowSelected(e)
    }else{
      if (value.id === 'all') {
        if (e.checked) {
          setRowSelected(data.map((item:any) => item))
        } else {
          setRowSelected([])
        }
      } else {
        if (rowSelected.find((e:any) => e.id === value.id)) {
          setRowSelected([...rowSelected].filter(e => e.id !== value.id))
        } else {
          setRowSelected((p:any) => [...p, e])
        }
      }
    }
  }

  return [ rowSelected, onSelectRow ]
}

// Usage
// const [ rowSelected, onSelectRow ] = useSelectRow(dataList)
