import CDialog from "core/components/CDialog";
import SkeletonComponent from "core/components/Skeleton/skeleton";
import IconHeader from "core/components/header/IconHeader";
import { useFetch } from "core/hooks/useFetch";
import AppLayout from "core/layouts/AppLayout";
import { RouterName } from "core/router";
import { stringLimit } from "core/utils/helper";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GoodInfoComponent from "../components/GoodInfoComponent";
import GoodTabComponent from "../components/GoodTabComponent";
import { useAddress } from "../hooks/useAddress";
import { FetchDetailProps } from "../interfaces/FetchProductProps";
import "./../assets/scss/gooddetailpage.scss";
import { useCountSize } from "core/hooks/useCountSize";
import { API_PRODUCT } from "../api";
import { useAddCarts } from "modules/Orders/hooks/useCarts";
import { useDataPayment, useUpdateDataPayment } from "modules/Orders/hooks/usePayment";
import ModalOrderComponent from "modules/Orders/components/ModalOrderComponent";
import { resetCartError } from "modules/Orders/store/actions";
import { useLastOrder, useStores } from "modules/Orders/hooks/useOrder";
import { useCheckIsExistProduct, useUpdateReload } from "../hooks/useLists";
import { MESSAGE } from "core/constants/Commons";

const GoodList = (props: any) => {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isAddCartSuccess, setIsAddCartSuccess] = useState<boolean>(false);
  const [paramsCountSize, setParamsCountSize] = useState<any>(false);
  // const [loadingLastOrder] = useLastOrder({});
  const [loadingStores] = useStores({});
  // useCountSize(paramsCountSize)
  const [paramsAddress] = useState<any>({
    default: "Y",
    first: true,
  });
  const [loadingAddress, dataAddress] = useAddress(paramsAddress);
  const [paramDetail, setParamDetail] = useState<FetchDetailProps>({
    id: param?.id,
    "relation[]": "category",
  });

  const [modalDialog, setModalDialog] = useState<any>({
    isShow: false,
    title: "",
    content: "",
    type: "",
    submitBtn: "주소 설정",
    closeBtn: "닫기",
  });

  const {
    data: dataDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({
    url: `${API_PRODUCT.GET_PRODUCT}/${param?.id}`,
    options: paramDetail,
  });

  const [isShowOrder, setIsShowOrder] = useState<boolean>(false);
  const updateReload = useUpdateReload();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (errorDetail && errorDetail?.code === 404) {
      setModalDialog((p: any) => ({
        ...p,
        isShow: true,
        title: "",
        content: "상품이 삭제되었습니다", //MESSAGE.PRODUCT_NOT_FOUND
        type: "NO_PRODUCT",
        submitBtn: "확인",
        closeBtn: "",
      }));
    }
    console.log('errorDetail', errorDetail);
    
    if (errorDetail && errorDetail?.code === 422) {
      setModalDialog((p: any) => ({
        ...p,
        isShow: true,
        title: "",
        content: errorDetail?.msg || MESSAGE.PRODUCT_NOT_FOUND , //MESSAGE.PRODUCT_NOT_FOUND
        type: "NO_PRODUCT",
        submitBtn: "확인",
        closeBtn: "",
      }));
    }
  }, [errorDetail?.code]);

  const handleOrder = (value: boolean) => {
    if (modalDialog?.isShow) {
    } else {
      setIsShowOrder(value);
    }
  };

  const handleShowModal = () => {
    setIsShowOrder(true);
  };

  const renderName = (name: string) => {
    if (loadingDetail) {
      return <SkeletonComponent width={204} height={20} />;
    }
    return stringLimit(name, 30);
  };

  const [, onAddCart] = useAddCarts({
    onSuccess: (data: any) => {
      console.log(data, 'success');
      setIsAddCartSuccess(true);
    },
    onFailed: (errors: any) => {
      console.log(errors, 'onFailed add carts');
      let err = errors?.errors[0]?.error;
      setIsAddCartSuccess(false) 
      setModalDialog((p: any) => ({
        ...p,
        isShow: true,
        title: "",
        content: err, //MESSAGE.PRODUCT_NOT_FOUND
        type: "NO_PRODUCT",
        submitBtn: "확인",
        closeBtn: "",
      }));
    },
  });

  const handleAddCart = (data: any | undefined) => {
    if (dataAddress && dataAddress?.id) {
      if (onAddCart) {
        onAddCart(data);
        /* hide modal */
        // setIsShowOrder(false);
      }
    } else {
      setModalDialog((p: any) => ({
        ...p,
        isShow: true,
        title: "",
        content: "주소를 설정해 주세요.",
        type: "NO_ADDRESS",
        submitBtn: "주소 설정",
        closeBtn: "닫기",
      }));
    }
    // dispatch(addCart(cart));
  };

  const [handleUpdate] = useUpdateDataPayment({});
  const [, dataPayment] = useDataPayment({});

  const _onCheckSuccess = (data: any) => {
    if (dataAddress && dataAddress?.id) {
      handleUpdate({
        ...dataPayment,
        dataProduct: [data],
        type: "DETAIL_PAYMENT",
      });
      navigate(RouterName.PAYMENT);
    } else {
      setModalDialog({
        isShow: true,
        title: "",
        content: "주소를 설정해 주세요.",
        type: "NO_ADDRESS",
        submitBtn: "주소 설정",
        closeBtn: "닫기",
      });
    }
  }

  const _onCheckFailed = (err:any) => {
    setModalDialog((p: any) => ({
      ...p,
      isShow: true,
      title: "",
      content: err?.msg || MESSAGE.PRODUCT_NOT_FOUND,
      type: "NO_PRODUCT",
      submitBtn: "확인",
      closeBtn: "",
    }));
  }

  const [loadingCheckProduct, handleCheck] = useCheckIsExistProduct({onSuccess: _onCheckSuccess, onFailed: _onCheckFailed})
  
  const payment = (data: any) => {
    handleCheck(data);
  };

  const onIncreaseModal = () => {};
  const onDecreaseModal = () => {};
  const onTypingModal = () => {};

  const quantityExceed = (quantity: any) => {};

  const handleConfirm = () => {
    switch (modalDialog.type) {
      case "NO_ADDRESS":
        navigate(RouterName.ADDRESS_BOOK, {
          state: {
            back_type: RouterName.GOODS_DETAIL,
            back_url: RouterName.GOODS_DETAIL.replace(
              ":id",
              dataDetail?.data?.id
            ),
          },
        });
        break;
      case "NO_PRODUCT":
        /* update redux for products page */
        updateReload({reload: 0})
        /* end */
        //clear errors redux cartReducer
        dispatch(resetCartError({}));
        navigate(RouterName.GOODS_LIST);
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    setModalDialog((p: any) => ({
      ...p,
      isShow: false,
      title: "",
      content: "",
      type: "",
    }));
  };

  const goback = () => {
    dispatch(resetCartError({}));
    navigate(location?.state?.back_url || RouterName.GOODS_LIST);
  };

  const clearIsAddCartSuccess = () => {
    setIsAddCartSuccess(false) 
    setIsShowOrder(false)
  }

  //when back device call clearIsAddCartSuccess

  (window as any).backFromDeviceAndroid = function() {
    clearIsAddCartSuccess()
    goback()
  }

  return (
    <AppLayout className={"good-detail-page"}>
      <IconHeader
        title={""}
        iconLeft={true}
        handleLeftClick={() => goback()}
        handleRightClick={() => {
          navigate(RouterName.MAIN);
        }}
        iconCart
        handleCartClick={() => {
          navigate(RouterName.CARTS_LIST, {
            state: {
              back_type: location?.pathname,
              back_url: location?.pathname
            }
          });
        }}
        iconRight={true}
				className='limit-text'
        id={"main-header"}
      />
      <div className="good-detail-page__content" id="list-panel">
        <GoodInfoComponent info={dataDetail} loading={loadingDetail} />
        <GoodTabComponent
          info={dataDetail}
          // handleOrder={handleOrder}
          loading={loadingDetail}
        />
        <div className="good-order">
          <div className="d-grid">
            <button
              className="cbtn btn-primary"
              onClick={() => handleShowModal()}
              disabled={loadingDetail}
            >
              구매하기
            </button>
          </div>
        </div>
      </div>
      <div className="good-detail-page__bottom-swiper">
        <ModalOrderComponent
          title={`[${dataDetail?.data?.category?.name || ""}] ${
            dataDetail?.data?.name || ""
          }`}
          handleStatusOrder={handleOrder}
          isShow={isShowOrder}
          hasDialog={modalDialog?.isShow}
          info={dataDetail?.data || []}
          addCart={handleAddCart}
          payment={payment}
          isShowPayment={true}
          className={"bottom-swiper--order-detail"}
          // dataInCart={dataInCart}
          onIncreaseModal={onIncreaseModal}
          onDecreaseModal={onDecreaseModal}
          onTypingModal={onTypingModal}
          quantityExceed={quantityExceed}
          isAddCartSuccess={isAddCartSuccess}
          clearIsAddCartSuccess={clearIsAddCartSuccess}
        />
      </div>

      <CDialog
        id={"js-error-quantity"}
        className={"modal-dialog-no-address"}
        show={modalDialog.isShow || false}
        title={modalDialog.title}
        submitBtn={modalDialog.submitBtn}
        closeBtn={modalDialog.closeBtn}
        overlay
        handleSubmit={() => handleConfirm()}
        handleClose={() => handleCancel()}
      >
        <div className="content-dialog">
          <label htmlFor="">{modalDialog.content || ""}</label>
        </div>
      </CDialog>
    </AppLayout>
  );
};

export default GoodList;
