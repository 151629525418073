
import "./../assets/scss/mainpage.scss"
import React, {useEffect, useState} from "react";
import {documentTitle, numberFormat, phoneNumberFormat} from "core/utils/helper";
import AppLayout from "core/layouts/AppLayout";
import BottomNavigation from "core/layouts/BottomNavigation";

import 'modules/Main/assets/scss/mainpage.scss'
import PeachIcon from 'modules/Main/assets/images/peach.svg'
import Logo from "core/components/Logo";
import {Link, useNavigate} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from 'react-responsive-carousel';

import MarkerIcon from 'modules/Main/assets/icon/marker.svg'
import BellIcon from 'modules/Main/assets/icon/bell.svg'
import BellIconActive from 'modules/Main/assets/icon/bell-active.svg'
import RightIcon from 'modules/Main/assets/icon/right.svg'
import AngleRightIcon from 'modules/Main/assets/icon/angle-right.svg'
import CEmptyIcon from "core/components/CEmptyIcon";
import Empty from "modules/Main/assets/icon/empty-activity.svg";
import IntroBg from 'modules/Main/assets/images/introbg.png'
import TreeIcon from 'modules/Main/assets/images/tree.svg'
import RefIcon from 'modules/Main/assets/icon/referrer.png'
import RanksIcon from 'modules/Main/assets/icon/ranks.png'
import PromotionIcon from 'modules/Main/assets/icon/promotion.png'
import InviteIcon from 'modules/Main/assets/icon/invite.png'
import KakaoIcon from 'modules/Main/assets/icon/main_kakao.svg'
import PhoneIcon from 'modules/Main/assets/icon/main_phone.svg'
import moment from "moment";
import {RouterName} from "core/router";
import {handlePushGoto} from "../../Notification/utils/Helpers";
import SkeletonComponent from "../../../core/components/Skeleton/skeleton";
import {useDispatch, useSelector} from "react-redux";
import API from "../../../core/networks/API";
import {API_OWN_BALANCE} from "../../Accumulate/api";
import {API_BANNER, API_MAIN_ACTIVITIES} from "../api";
import {ShareMessage} from "../../Referrer/utils/constants";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from "react-toastify";
import { useAppSetting, useFetchAppSetting } from "modules/AppSettings/hooks";
import { useUpdateReload } from "modules/Products/hooks/useLists";
import { useOpenBrowser } from "core/hooks/useOpenBrowser";
import { API_MARKREAD_NOTIFICATION } from "modules/Notification/api";
import { useDataPayment, useUpdateDataPayment, useUpdatePaymentStatus } from "modules/Orders/hooks/usePayment";
import { useLastOrder } from "modules/Orders/hooks/useOrder";
import { useGetCarts } from "modules/Orders/hooks/useCarts";
import { updateStatusProduct } from "modules/Orders/store/actions";


const MainPage = (props: any) => {
    const dispatch = useDispatch();
    const [loadingLastOrder] = useLastOrder({});
    const navigate = useNavigate();
    const redux = useSelector((state: any) => state);
    const [dataCarts, handleGetCarts] = useGetCarts({});
    const updateReload = useUpdateReload();
    const appSettings = useAppSetting({});
    const handleDirect = useOpenBrowser();
    const getAppSetting = useFetchAppSetting({});
    const [, handleReset] = useUpdateDataPayment({});
    const [loading, handleUpdatePaymentStatus] = useUpdatePaymentStatus(); 
    const [, paymentData] = useDataPayment({});
    console.log(dataCarts, 'dataCarts');
    
    const [activities, setActivities] = useState<any>({
        loading: true,
        unread: 0,
        list: []
    })
    const [balance, setBalance] = useState({
        loading: true,
        balance: 15520000
    })
    const [banners, setBanners] = useState({
        loading: true,
        list: []
    })

    const [stateIndexBanner, setStateIndexBanner] = useState(0)

    const handleInviteFriend = () => {
        (window as any).webViewRequestShare(ShareMessage)
    }

    const handlePhoneDial = () => {
        console.log(appSettings, 'appSettings');
        
        (window as any).webViewRequestPhoneDial(appSettings?.consult_kakao_phone || '')
    }
    
    const handleOpenKakao = () => {
        if(appSettings?.chanelId) {
            (window as any).webViewRequestKakaoChannel(appSettings?.chanelId || '')
        }
    }

    
    const handleBannerHref = (link:string,index:any) => {
        setStateIndexBanner(index)
        localStorage.setItem('indexBanner',index)
        handleDirect(link)
    }

    const handleToDetailNotify = async (item:any) => {
        if(item?.read == 'N'){
            handlePushGoto(item)
            await API.post(API_MARKREAD_NOTIFICATION, {id: item.id});
        }else {
            handlePushGoto(item)
        }
    }

    const renderBanner = () => {
        if(banners.loading){
            return <Carousel
                infiniteLoop={true}
                showIndicators={false}
                showArrows={false}
                showStatus={true}
                statusFormatter={(currentItem: any, total: any) => `${currentItem}/${total}`}
                showThumbs={false}
                stopOnHover={true}
                selectedItem={2}>
                <SkeletonComponent count={1} height={115}/>
                <SkeletonComponent count={1} height={115}/>
                <SkeletonComponent count={1} height={115}/>
            </Carousel>

        }else {
            if (banners.list.length > 0) {
                const childEls = banners.list.map((item: any, k) => {
                    return <div key={k} onClick={()=>handleBannerHref(item.link,k)}>
                        {/*<Link to={"/main"}>*/}
                            {/*<LazyLoadImage*/}
                            {/*    alt={""}*/}
                            {/*    effect={"blur"}*/}
                            {/*    src={item.image_path.path_original} />*/}
                            <img src={item.image_path.path_original} alt={""}/>
                        {/*</Link>*/}
                    </div>
                })
                return <Carousel
                    infiniteLoop={true}
                    showIndicators={false}
                    showArrows={false}
                    showStatus={true}
                    statusFormatter={(currentItem: any, total: any) => `${currentItem}/${total}`}
                    showThumbs={false}
                    stopOnHover={true}
                    selectedItem={stateIndexBanner}>
                    {childEls}
                </Carousel>
            }
        }
        return <></>
    }

    const renderActivities = () => {
        if(activities.loading){
            const childEls = [1,2,3,4,5].map((item: any, k: number) => {
                return <li key={k}>
                    <div className="list-item" style={{padding: "5px 0"}}>
                        <div className="title"><SkeletonComponent count={1} height={18} width={300}/></div>
                        <div className="date"><SkeletonComponent count={1} height={18} width={30}/></div>
                    </div>
                </li>
            })
            return <ul>
                {childEls}
            </ul>
        }else {
            if (activities.list.length > 0) {
                const childEls = activities.list.map((item: any, k: number) => {
                    const date: any = moment().diff(moment(item.created_at), 'days') == 0 ? '오늘': moment(item.created_at).format("MM-DD") //moment(item.date).format('MM-DD')
                    return <li key={k} onClick={() => handleToDetailNotify(item)}>
                        <div className="list-item">
                            <div className="title">{item.content.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                            <div className="date">{date}</div>
                        </div>
                    </li>
                })
                return <ul>
                    {childEls}
                </ul>
            }
            return <CEmptyIcon
                icon={Empty}
                text={'새로운 소식이 없습니다.'}
            />
        }
    }

    const fetchBalance = async () => {
        try{
            const res = await API.get(API_OWN_BALANCE, {});
            console.log(res,'resfetchBalance',res?.data?.data?.total)
            if(res?.data?.data?.total != undefined) setBalance({...balance, loading: false, balance: res.data.data.total})
        } catch (e: any) {
            console.log(e)
        }
    }

    const fetchBanners = async () => {
        try{
            const res = await API.get(API_BANNER, {
                page: 0,
                order_by: 'order',
                order_dir: 'asc',
                state: 'Y'
            });
            if(res?.data?.data) setBanners({...banners, loading: false, list: res.data.data})
        } catch (e: any) {
            setBanners({...banners, loading: false})
            console.log(e)
        }
    }

    const fetchActivities = async () => {
        try{
            const res = await API.get(API_MAIN_ACTIVITIES, {});
            if(res?.data?.data) setActivities({...activities, loading: false, unread: res.data.data.totalUnread, list: res.data.data.newestNotifications})
        } catch (e: any) {
            console.log(e)
        }
    }

    const handleScroll = (event: any): any => {
        // console.log(event.target.getBoundingClientRect())
        // console.log(window.scrollY)
        const header = document.getElementById('main-home-header')
        if(window.scrollY > 0){
            header?.style.setProperty('background', `rgba(253, 234, 225, ${window.scrollY/47})`);
            header?.style.setProperty('box-shadow', `rgba(0, 0, 0, ${window.scrollY/47*0.16}) 0px 1px 4px`);
        }else{
            header?.style.setProperty('background', 'transparent');
            header?.style.setProperty('box-shadow', `none`);
        }

    };

    const requestPermission = () => {
        const NOTIFICATION_PERM = (window as any)._APP_NOTIFICATION_PERMISSION
        const CAMERA_PERM = (window as any)._APP_CAMERA_PERMISSION
        const LIBRARY_PERM = (window as any)._APP_LIBRARY_PERMISSION
        if(!NOTIFICATION_PERM || !CAMERA_PERM || !LIBRARY_PERM) {
            (window as any).webViewRequestPermission(['camera', 'notification', 'library'])
        }
    }

    useEffect(()=>{
        if(redux?.Auth?.user?.phone) requestPermission()
        documentTitle('MainPage')
        fetchBalance()
        fetchBanners()
        fetchActivities()
        window.onscroll = handleScroll;

        /* update redux for products page */
        updateReload({reload: 0})
        /* end */

        if (getAppSetting) {
            getAppSetting();
        }
        window.scrollTo(0, 0);
        if (handleReset) {
            handleReset({})
        }
        let indexBaner = localStorage.getItem('indexBanner')
        if(indexBaner){
            setStateIndexBanner(+indexBaner)
            localStorage.removeItem('indexBanner')
        }
        dispatch(updateStatusProduct({dataCarts, isSelected: true}));
    }, [])

    useEffect(() => {
        const {lastOrder} = paymentData;
        if (lastOrder?.id && lastOrder?.payment_method === "CARD" && lastOrder?.payment_status === "PENDING" ) {
          handleUpdatePaymentStatus({
            order_id: lastOrder?.id,
            status: "FAILED"
          })
        }
      }, [paymentData.lastOrder])


    const handleCacheClear = () => {
        (window as any).webViewRequestClearCache();
        toast('Cache clear success!');
        setTimeout(() => {
            toast.dismiss()
        }, 500);
    }

    console.log('process.env.NODE_ENV', process.env.REACT_APP_NODE_ENV);
    

    return (
        <AppLayout className={"main-page"}>
            <div className="peach-icon"><img src={PeachIcon} alt=""/></div>
            <div className="main-header" id="main-home-header">
                <div className="row align-items-center">
                    <div className="col-6">
                        <Link to={"/main"}><Logo/></Link>
                    </div>
                    <div className="col-6 text-end">
                        <Link className={"top-icon"} to={RouterName.ADDRESS_BOOK} state={{ back_type: RouterName.MAIN, back_url: RouterName.MAIN }}><img src={MarkerIcon} alt={""}/></Link>
                        {activities.unread == 0 ?
                            <Link className={"top-icon"} to={RouterName.NOTIFICATION_LIST}><img src={BellIcon} alt={""}/></Link>
                            : <Link className={"top-icon"} to={RouterName.NOTIFICATION_LIST}><img src={BellIconActive} alt={""}/></Link> }

                    </div>
                </div>
            </div>
            <div className="info-area">
                <div className="info-section name bg-white-transparent">
                    <div className="row">
                        <div className="col-6">{redux?.Auth?.user?.display_role || "일반 회원"}</div>
                        <div className="col-6 text-end"><strong>{phoneNumberFormat(redux?.Auth?.user?.phone)}</strong>님</div>
                    </div>
                </div>
                <div className="info-section balance bg-white-transparent">
                    <div className="div1">
                        <div className="clickable" onClick={()=>navigate(RouterName.ACCUMULATE_LIST)}></div>
                        <h3 onClick={()=>navigate(RouterName.ACCUMULATE_LIST)}>내 적립금</h3>
                        <button onClick={()=>{navigate(RouterName.ACCUMULATE_WITHDRAW_FORM)}}>출금</button>
                        {balance.loading ? <SkeletonComponent width={200} count={1} height={30}/> : <div onClick={()=>navigate(RouterName.ACCUMULATE_LIST)} className="amount">{numberFormat(balance.balance)}원</div>}
                        <div className="angle-right"><img src={AngleRightIcon} alt={""}/></div>
                    </div>
                    <div className="div2" onClick={()=>{navigate(RouterName.REFERER_LIST)}}><img src={RefIcon} alt={""}/></div>
                    <div className="div3" onClick={()=>{navigate(RouterName.MEMBER_RANKING)}}><img src={RanksIcon} alt={""}/></div>
                </div>
                <div className="info-section promotion bg-white-transparent">
                    <div className="left" onClick={()=>{navigate(RouterName.PROMOTION)}}><img src={PromotionIcon} alt={""}/></div>
                    <div className="right" onClick={handleInviteFriend}><img src={InviteIcon} alt={""}/></div>
                    {/* <a href="apricotapp://lunchbox.dev-enjoyworks.com" target="_blank">share</a> */}
                </div>
            </div>
            <div className="content">
                <section className="banner-area">
                    {renderBanner()}
                </section>
                <section className={`activities-area ${banners.list.length == 0 ? ' empty-banner' : ''}`}>
                    <Link to={RouterName.NOTIFICATION_LIST}><h3>최근 메시지 <span><img src={RightIcon} alt={""}/></span></h3></Link>
                    {renderActivities()}
                </section>
                <section className="intro-area" style={{backgroundImage: `url(${IntroBg})`}} onClick={()=>{navigate(RouterName.COMPANY_INTRODUCTION)}}>
                    <div className={"tree"}><img src={TreeIcon} alt={""}/> </div>
                    <Link to={"/"}><h3>살구나무집도시락 <span><img src={RightIcon} alt={""}/></span></h3></Link>
                    <p>살구나무 아래 작은 풍경<br/>
                        행복한 맛의 건강한 도시락을 제공합니다.</p>
                </section>
                <section className="introduce-company" id="introduce-company">
                    <h4>(주)살구나무</h4>
                    <span>대표: <label>이종규</label></span>
                    <span className="bold-value">사업자등록번호: <label >269-81-00423</label></span>
                    <span className="bold-value">주소: <label >대전광역시 유성구 문화원로 6번길 42,1층(장대동)</label></span>
                    <span className="bold-value">TEL: <label >042-528-0306</label></span>
                </section>
            </div>
            <div className="floatCTA">
                {
                    process.env.REACT_APP_NODE_ENV !== 'production' && 
                    <div className="cacheClear"  onClick={() => handleCacheClear()}>
                        <div><label htmlFor="">Cache clear</label></div>
                    </div>
                }
                <div className="phoneBtn" onClick={handlePhoneDial}><img src={PhoneIcon} alt={"phone"}/></div>
                <div className="kakaoBtn" onClick={handleOpenKakao}><img src={KakaoIcon} alt={"kakao"}/></div>
            </div>
            <BottomNavigation activeIndex={1}/>
        </AppLayout>
    )
}


export default MainPage
