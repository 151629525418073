import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './saga';

    declare global {

        interface Window {
            __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
        }
    }

    // // @ts-ignore
    // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const sagaMiddleware = createSagaMiddleware();
let _store
if(process.env.NODE_ENV === 'production'){
    _store = createStore(
        rootReducer,
        compose(
            applyMiddleware(sagaMiddleware)
        )
    )
}else{
    _store = createStore(
        rootReducer,
        compose(
            applyMiddleware(sagaMiddleware),
            // (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
            typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "undefined" ? (a:any) => a : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
        )
    )
}
const store = _store

sagaMiddleware.run(rootSaga);

export default store;
