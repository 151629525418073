interface dataTabProps {
  info: any;
  isOpen: boolean;
  isExceed: boolean;
}

const OrderInfoModalComponent = ({ info, isOpen, isExceed }: dataTabProps) => {
  return (
    <>
      {isOpen && (
        <div className="order-info">
          <h2 className="order-info__title">지역별 최소 주문 금액</h2>
          <div className="order-info__item">
            <label htmlFor="" className="city city--5">
              <span>대전</span>
            </label>
            <label htmlFor="" className="rate">
              5만원 <span className="add">이상</span>
            </label>
            
          </div>
          <div className="order-info__item">
            <label htmlFor="" className="city city--20">
              <span>세종</span>
            </label>
            <label htmlFor="" className="rate">
              20만원 <span className="add">이상</span>
            </label>
          </div>
          <div className="order-info__item">
            <label htmlFor="" className="city city--30">
              <span>청주</span>
            </label>
            <label htmlFor="" className="rate">
              30만원 <span className="add">이상</span>
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderInfoModalComponent;
