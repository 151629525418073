import { useEffect, useState } from "react";
import AppLayout from "core/layouts/AppLayout";
import BottomSwiper from "core/components/BottomSwiper";
import CSpinNumber from "core/components/CSpinNumber";
import CKeyBoard from "core/components/CKeyBoard";
import IncreaseLargeEnabled from 'core/assets/images/icon/increase_large_enabled.svg';
import IncreaseLargeDisabled from 'core/assets/images/icon/increase_large_disabled.svg';

import DecreaseLargeEnabled from 'core/assets/images/icon/decrease_large_enabled.svg';
import DecreaseLargeDisabled from 'core/assets/images/icon/decrease_large_disabled.svg';
import { minMaxValidate } from "core/utils/helper";
import { Router } from "core/router";
import router from "modules/Main/router";

const CommonPage = (props: any) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [quantityNumber, setQuantityNumber] = useState<number>(1 || undefined);
	const [min, ] = useState<number>(1 || undefined);
	const [max, ] = useState<number>(100000 || undefined);
	const [quantityNumberArr, setQuantityNumberArr] = useState<string[]>(['1'])

	useEffect(() => {
		console.log('this is common page');
		// console.log(router[0].path);
		
	}, []);

	const handleShow = (isShow: boolean) => {
		setIsOpen(isShow)
	}
	const handleSelectBank = (item: object) => {
		console.log(42, item);
	}

	useEffect(() => {
		if (isOpen) {
			setIsActive(true);
		}
	}, [isOpen])
	
	const onStartSwipeUp = () => {
	} 
	const onEndSwipeUp = () => {

	} 
	const onStartSwipeDown = () => {

	} 
	const onEndSwipeDown = () => {

	} 

	const onIncrease = (quantity:number) => {
		console.log(quantity, 'onIncrease');
		setQuantityNumber(quantity)
	}

	const onDecrease = (quantity:number) => {
		console.log(quantity, 'onDecrease');
		setQuantityNumber(quantity)
	}

	const onClickInput = () => {
		setIsOpen(true)
	}

	const handleTypingNumber = (item: string) => {
		let arrOO = [];
		if (item === '00') {
			arrOO = ['0','0']
		}else{
			arrOO = [item]
		}
		let arr = quantityNumber.toString().split("");
		let mergedArr = [...arr, ...arrOO];
		let numberMegered = +mergedArr.join('');
		if (minMaxValidate(numberMegered, min, max)){
			setQuantityNumberArr([...mergedArr]);
			setQuantityNumber(+mergedArr.join(''))
		}
	}

	const onHideKeyboard = () => {
		
	}
	const handleRemoveNumber = () => {
		if (minMaxValidate(quantityNumber, min, max)) {
			let arr = quantityNumber.toString().split("");
			let _removedArr = [...arr];
			if (_removedArr.length > 1) {
				_removedArr.splice(_removedArr.length - 1);
				setQuantityNumberArr([..._removedArr]);
				setQuantityNumber(+_removedArr.join(''))
			}
		}else{
			setQuantityNumber(min)
			setQuantityNumberArr(['1'])
		}
	}

	useEffect(() => {	
		let numbers = +quantityNumberArr.join('');
		if (minMaxValidate(numbers, min, max)) {
			setQuantityNumber(numbers)
		}
	}, [max, min, quantityNumberArr])

  return (
    <AppLayout>
			<p onClick={() => setIsOpen(true)}>
				Show BottomSwiper
			</p>

			<BottomSwiper
				isOpen={isOpen}
				isActive={isActive}
				title={'[프리미엄] 돈등갈비 도시락'}
				onSlideDown={handleShow}
				className={'bottom-swiper--order'}
				isShowHeader={true}
				onStartSwipeUp={onStartSwipeUp}
				onEndSwipeUp={onEndSwipeUp}
				onStartSwipeDown={onStartSwipeDown}
				onEndSwipeDown={onEndSwipeDown}
			>

				<CSpinNumber 
					className={''}
					value={quantityNumber || 1}
					min={min}
					max={max}
					onIncrease={onIncrease}
					onDecrease={onDecrease}
					onClickInput={onClickInput}
					increaseIconEnabled={IncreaseLargeEnabled}
					decreaseIconEnabled={DecreaseLargeEnabled}
					increaseIconDisabled={IncreaseLargeDisabled}
					decreaseIconDisabled={DecreaseLargeDisabled}
				/>

				<CKeyBoard 
					onHideKeyboard={onHideKeyboard}
					className={''}
					onClickNumber={handleTypingNumber}
					onRemoveNumber={handleRemoveNumber}
					isOpen={false}
				/>

      </BottomSwiper>

    </AppLayout>
  );
};

export default CommonPage;
