import moment from "moment";
import slugify from "slugify";
import { config } from "../config/app";
import { deviceStorage } from "./devicestorage";
import { API_MARKREAD_NOTIFICATION } from "modules/Notification/api";
import API from "core/networks/API";
import { toast } from "react-toastify";

export const numberFormat = (number: number | string, fixed: number = 0, showFull = false) => {
    let num
    if (typeof number === "string") {
        num = parseFloat(number)
    } else {
        num = number
    }
    if (num < 100000000 || showFull) {
        let formattedNumber = num.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed }); // "123,456,789.12"
        return formattedNumber
    } else {
        let mNum = num / 1000000
        let formattedNumber = mNum.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed }); // "123,456,789.12"
        return formattedNumber + "M"
    }

}

export const documentTitle = (str: string) => {
    document.title = str;
}

export const convertToSlug = (text: string) => {
    return slugify(text, {
        replacement: "-", // Sử dụng dấu gạch ngang thay thế khoảng trắng và các ký tự đặc biệt
        remove: /[*+~.()'"!:@]/g, // Loại bỏ các ký tự đặc biệt
        lower: true, // Chuyển đổi thành chữ thường
    });
}

export function phoneNumberFormatOld(phone: string, encode: boolean = false) {
    var phoneFormat = phone;
    if (typeof phoneFormat == 'string') {
        phoneFormat.replaceAll('', '-')
        var phoneArray = phone.split("");
        if (phone.length > 11) {
            return ''
        }
        if (phone.length >= 8 && phone.length <= 11) {
            phoneArray.map((item: string, index: number) => {
                if (index === 2 || index === 6) {
                    phoneArray[index] = item + '-'
                }
            })

            phoneFormat = phoneArray.join('');
        } else if (phone.length >= 4) {
            phoneArray.map((item: string, index: number) => {
                if (index === 2) {
                    phoneArray[index] = item + '-'
                }
            })
            phoneFormat = phoneArray.join('');
        }
        if (encode) {
            const phoneArr = phoneFormat.split('-');
            const hiddenPart = '**';
            const visiblePart = phoneArr[1].substr(0, phoneArr[1].length - 2);
            return phoneArr[0] + '-' + visiblePart + hiddenPart + '-' + phoneArr[2];
        }
        return phoneFormat;
    }
    return ''
}

export const minMaxValidate = (value: number, min: number, max: number) => {
    if (value >= min && value <= max) {
        return true;
    }
    return false;
}

export const stringLimit = (string: string | undefined, limit: number = 30) => {
    if (string != undefined && string.length > limit) {
        return `${string.substring(0, limit)}...`
    } else {
        return string
    }
}

export const validateNumber = (data: any) => {
    let valueNumber = data;
    const re = /^[0-9\b]+$/;
    let value = false
    if (valueNumber === '' || re.test(valueNumber)) {
        value = valueNumber
    }
    return value
}


export function removeAllStorage() {
    sessionStorage.clear();
    deviceStorage[localStorage.getItem("storage_driver") || "session"].removeItem('persist:root')
    deviceStorage[localStorage.getItem("storage_driver") || "session"].removeItem(config.appPackage + '_X_TOKEN')
    deviceStorage[localStorage.getItem("storage_driver") || "session"].removeItem('remember_me')
}

export const s3Image = (path: string) => {
    return config.s3Url + '/' + path
}

export const validatePassword = (data: any) => {
    let valueNumber = data;
    const re = /^[A-Za-z0-9_.]+$/;
    let value = false
    if (valueNumber === '' || re.test(valueNumber)) {
        value = valueNumber
    }
    return value
}


export const hideBottomButtonInputFocus = (bottomClass = '.bottom-btn', durationLoad = 500) => {
    const windowHeight = window.innerHeight

    const handleWindowResize = () => {
        const btnBottomElements = document.querySelectorAll(bottomClass);

        // Kiểm tra kích thước cửa sổ và ẩn/hiện các phần tử tùy thuộc vào điều kiện của bạn.
        // alert(window.innerHeight + " --- "+ windowHeight)
        // console.log(115, windowHeight > window.innerHeight)
        if (windowHeight > window.innerHeight) {
            // console.log(116, windowHeight, window.innerHeight)
            btnBottomElements.forEach((element: any) => {
                element.style.display = 'none';
            });
        } else {
            // console.log(120, windowHeight, window.innerHeight)
            btnBottomElements.forEach((element: any) => {
                element.style.display = 'block';
            });
        }
    };

    // Đăng ký sự kiện resize của cửa sổ và gọi hàm xử lý khi kích thước thay đổi.
    window.addEventListener('resize', handleWindowResize);

    // Gọi hàm xử lý lần đầu để ẩn/hiện phần tử dựa trên kích thước ban đầu của cửa sổ.
    handleWindowResize();

    // Check riêng cho trường hợp trên IOS. Trên IOS sự kiện resize không hoạt động vì keyboard đang được fixed lên màn hình
    if (detectPlatformIos()) {
        setTimeout(() => {
            const inputElements = document.querySelectorAll('input');
            inputElements.forEach((element: any) => {
                element.addEventListener('focus', function () {
                    document.querySelectorAll(bottomClass).forEach(function (elementBtn: any) {
                        elementBtn.style.opacity = '0';
                        elementBtn.style.pointerEvents = 'none';
                    });
                });
                element.addEventListener('blur', function () {
                    document.querySelectorAll(bottomClass).forEach(function (elementBtn: any) {
                        elementBtn.style.opacity = '1';
                        elementBtn.style.pointerEvents = 'auto';
                    });
                });
            });
        }, durationLoad);
    }

}

export const navigateTo = (routerName: string) => {

}

export const navigateToBack = () => {

}

export function formatDateTime(dateTime: any, format = 'Y-MM-DD H:mm:ss') {
    if (!dateTime) return null;
    return moment(dateTime).format(format);
}

export const getProductNamePayment = (data: any) => {
    let name;
    if (data.length < 1) return '';
    // if (data[0].id === '') {
    //     return stringLimit(data[0]?.prod_name, 26) +  ` ${data[0].quantity}건`
    // }

    const sum = data.reduce((accumulator: any, current: any) => {
        return +accumulator + current.qty;
    }, 0);

    if (data[0].product?.category?.name) {
        name = `<span class="product-name">[${data[0].product?.category?.name}] ${data[0]?.product?.name || ''}</span>` + `<span>${data.length > 1 ? `${' '}외` : ''} ${sum}건</span>`
    } else {
        name = `<span class="product-name">${data[0]?.prod_name}</span>` + `<span>${data.length > 1 ? `${' '}외` : ''} ${sum}건</span>`
    }

    return name;
    // return `<span class="product-name">${data[0]?.prod_name}</span>` +  `<span>${data.length > 1 ? `${' '}외` : ''} ${sum}건</span>`

}


export const getLocalUrl = (path: any) => {
    let myloc = window.location.href;
    return config.apiUrl + path;
}


export const getValidationErrors = (err: any) => {
    const validationErrors: any = {};

    err.inner.forEach((error: any) => {
        if (error.path) {
            validationErrors[error.path] = error.message;
        }
    });

    return validationErrors;
}

export const getPaymentStatus: any = (payment_method: any, payment_status: any, status: any, type: any) => {
    // 입금 대기 => 
    // 결제 대기 => 
    // 결제 완료 => 
    // 주문 취소 => 
    // 환불 완료 => 
    // 배달 완료 => 
    // 주문 접수 => 
    // 배달 시작 => 
    // 취소 완료 => 

    // 주문 대기 c  => 
    let textMethod;
    let textMethodClass;
    let disabled = false
    switch (status) {
        case "NEW":
            if (payment_status === "FAILED") {
                if (payment_method === "CARD") {
                    textMethod = "결제실패"; //(payment-failed)
                    textMethodClass = "payment-failed";
                }
            }
            if (payment_status === "PENDING") {
                if (payment_method === "MONEY") {
                    textMethod = "결제대기(입금)"; //(waiting-deposit) 입금 대기
                    textMethodClass = "waiting-deposit";
                    disabled = true;
                }
                if (payment_method === "COD") {
                    textMethod = "현장결제"; //(waiting-deposit) 입금 대기
                    textMethodClass = "waiting-deposit";
                }
            }
            if (payment_status === "COMPLETED") {
                // textMethod = "주문접수(완료)"; //(waiting-for-order) 주문대기 
                textMethod = "결제완료"; //(waiting-for-order) 주문대기 
                textMethodClass = "waiting-for-order";
                disabled = true;
            }
            if (payment_status === "CANCELED") {
                textMethod = "주문취소"; //(complete-payment)
                textMethodClass = "canceled";
            }
            if (payment_status === "COD_PENDING") {
                textMethod = "현장결제"; //(complete-payment)
                textMethodClass = "cod-payment";
            }
            break;
        case "CANCELED_COMPLETED":
            textMethod = "취소완료"; //(canceled)
            textMethodClass = "canceled-completed";
            break;
        case "CANCELED_BY_USER":
            textMethod = "주문취소"; //(canceled)
            textMethodClass = "canceled";
            break;
        case "CANCELED_BY_SYSTEM":
            textMethod = "주문취소"; //(canceled)
            textMethodClass = "canceled";
            break;
        case "APPROVED":
            textMethod = "주문접수(완료)"; ////(order-reception)
            textMethodClass = "order-reception";
            break;
        case "PROCESSED":
            textMethod = "배달준비"; //(Chuẩn bị giao hàng)
            textMethodClass = "ready-for-delivery";
            break;
        case "DELIVERING":
            textMethod = "배달시작"; //delivery-starts 배달중
            textMethodClass = "delivery-starts";
            break;
        case "COMPLETED":
            textMethod = "배달완료"; //delivery-completed
            textMethodClass = "delivery-completed";
            break;
        case "REFUND":
            textMethod = "환불완료"; //(rút lệnh)
            textMethodClass = "refund-complete";
            break;
        default: 
            break;
    }

    if (type && type === 'classname') {
        return textMethodClass
    }
    if (type && type === 'statusBtn') {
        return disabled
    }
    
    return textMethod;
}

export const detectPlatformIos = () => {

    var userAgent = window.navigator.userAgent;
    // @ts-ignore
    var platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = false;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = true;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = false;
    } else if (/Android/.test(userAgent)) {
        os = false;
    } else if (/Linux/.test(platform)) {
        os = false;
    }

    return os;
}


export const priceFormat = (price: any, n = 0, x = 3, s = ',', c = '.') => {
    if (price) {
        price = +price
        let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
        let num = price.toFixed(Math.max(0, ~~n));

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    }
    return 0
}


export const formatAccountNumber = (accountNumber: any) => {
    const accountString = String(accountNumber);
    const parts = [
        accountString.slice(0, 3),
        accountString.slice(3, 6),
        accountString.slice(6)
    ];
    const formattedAccount = parts.join('-');
    return formattedAccount;
}

export function phoneNumberFormat (str:string, encode: boolean=false) {
    var valueFormat = str;
    if(str && typeof str == 'string') {
        const cleanedInput = str.replace(/[^0-9]/g, '');
        // if(cleanedInput.length > 11) {
        //     return ''
        // }
        //Neu dau so 02
        if(cleanedInput.slice(0, 2) == '02'){
            // Kiểm tra độ dài của chuỗi đã làm sạch
            if (cleanedInput.length <= 2) {
                // Nếu chuỗi có 2 ký tự hoặc ít hơn, không cần định dạng
                valueFormat = cleanedInput;
            } else if (cleanedInput.length <= 5) {
                // Nếu chuỗi có từ 2 đến 5 ký tự, định dạng là "xx-xxx"
                valueFormat = cleanedInput.slice(0, 2) + '-' + cleanedInput.slice(2);
            }else if (cleanedInput.length <= 9) {
                // Nếu chuỗi có từ 5 đến 9 ký tự, định dạng là "xx-xxx-xxx"
                valueFormat = (
                    cleanedInput.slice(0, 2) +
                    '-' +
                    cleanedInput.slice(2, 5) +
                    '-' +
                    cleanedInput.slice(5, 10)
                );
            } else {
                // Nếu chuỗi có từ 8 ký tự trở lên, định dạng là "xxx-xxxx-xxxx"
                valueFormat = (
                    cleanedInput.slice(0, 2) +
                    '-' +
                    cleanedInput.slice(2, 6) +
                    '-' +
                    cleanedInput.slice(6, 10)
                );
            }
        } else if(cleanedInput.slice(0, 3) != '010'){

            // Kiểm tra độ dài của chuỗi đã làm sạch
            if (cleanedInput.length <= 3) {
                // Nếu chuỗi có 3 ký tự hoặc ít hơn, không cần định dạng
                valueFormat = cleanedInput
            } else if (cleanedInput.length <= 6) {
                // Nếu chuỗi có từ 4 đến 6 ký tự, định dạng là "xxx-xxx"
                valueFormat = cleanedInput.slice(0, 3) + '-' + cleanedInput.slice(3);
            } else if (cleanedInput.length <= 10) {
                // Nếu chuỗi có từ 4 đến 7 ký tự, định dạng là "xxx-xxx"
                valueFormat = (
                    cleanedInput.slice(0, 3) +
                    '-' +
                    cleanedInput.slice(3, 6) +
                    '-' +
                    cleanedInput.slice(6, 10)
                );
            } else {
                // Nếu chuỗi có từ 8 ký tự trở lên, định dạng là "xxx-xxxx-xxxx"
                valueFormat = (
                    cleanedInput.slice(0, 3) +
                    '-' +
                    cleanedInput.slice(3, 7) +
                    '-' +
                    cleanedInput.slice(7, 11)
                );
            }

        }else {
            
            // Kiểm tra độ dài của chuỗi đã làm sạch
            if (cleanedInput.length <= 3) {
                // Nếu chuỗi có 3 ký tự hoặc ít hơn, không cần định dạng
                valueFormat = cleanedInput;
            } else if (cleanedInput.length <= 7) {
                // Nếu chuỗi có từ 4 đến 7 ký tự, định dạng là "xxx-xxx"
                valueFormat = cleanedInput.slice(0, 3) + '-' + cleanedInput.slice(3);
            } else {
                valueFormat = (
                    cleanedInput.slice(0, 3) +
                    '-' +
                    cleanedInput.slice(3, 7) +
                    '-' +
                    cleanedInput.slice(7, 11)
                );
            }

        }
        if(encode) {
            const dataPhone = phoneNumberFormat(str);
            const phoneArr: any = dataPhone.split('-');
            const hiddenPart = '**';
            const visiblePart = phoneArr[1].substr(0, phoneArr[1].length - 2);
            return phoneArr[0] + '-' + visiblePart + hiddenPart + '-' + phoneArr[2];
        }
        return valueFormat
    }
    
    return ''
}


export const getTotalQuantity = (data: any) => {
    const sum = data.reduce((accumulator: any, current: any) => {
      return +accumulator + current.qty;
    }, 0);
  
    return sum;
}

export const readNotifyFromPush = (data: any) => {
    const handleReadNotify = async  (id:any) =>{
        try{
            await API.post(API_MARKREAD_NOTIFICATION, {id: id});
        } catch (e) {
            console.log(e)
        }
    }
    let paramActivity = data
    let paramActivityId = ''
    if(typeof paramActivity == 'string' && paramActivity && paramActivity.includes('activity_id=')){
        paramActivityId = paramActivity.split('=')[1]
        handleReadNotify(paramActivityId)
    }
}