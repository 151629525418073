import CAccoditionItem from "core/components/CAccoditionItem";
import { DELIVERY_DATA, PAYMENT_METHOD } from "core/constants/Commons";
import { useEffect, useRef, useState } from "react";
import { useUpdateDataPayment } from "../hooks/usePayment";
import CardIcon from "../assets/icon/card.svg";
import CFormControl from "core/components/form-controls/CFormControl";
import CDropdown from "core/components/form-controls/CDropdown";
import CardLockIcon from "../assets/icon/card_locked.svg";
import { INSTALLMENT_MONTH } from "../constants/Time";
import { NumericFormat } from "react-number-format";
interface PaymentMethodProps {
  dataOrders: any;
  loadingLastOrder: boolean
}

interface ObjectMethod {
  id: number;
  label: string;
  value: string;
}

const PaymentMethodComponent = ({
  dataOrders,
  loadingLastOrder
}: PaymentMethodProps) => {
  const [handleUpdate, ] = useUpdateDataPayment({});
  const [maskMonth, setMaskMonth] = useState('')
  console.log('PaymentMethodComponent');
  const expireMMRef = useRef<HTMLInputElement>();
  const expireYYRef = useRef<HTMLInputElement>();
  useEffect(() => {
    // console.log(dataOrders, 'PAYMENT_METHOD');
      
    // let methodObj;
    // if (dataOrders?.lastOrder?.id) {
    //   methodObj = PAYMENT_METHOD.find((item: any) => item.value === dataOrders?.lastOrder?.payment_method);
    // }else{
    //   methodObj = {}
    // }
    
    // handleUpdate({
    //   ...dataOrders, 
    //   paymentMethod: {
    //   ...methodObj
    // }})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrders?.lastOrder, loadingLastOrder]);

  const handleSelect = (item: ObjectMethod) => {
    if (item.value === 'CARD_MANUAL') {
      handleUpdate({...dataOrders, paymentMethod: item, cardManualInfo: {
        ...dataOrders.cardManualInfo,
        card_number_1: '',
        card_number_2: '',
        card_number_3: '',
        card_number_4: '',
        expire_month: '',
        expire_year: '',
      }})
    }else{
      handleUpdate({...dataOrders, paymentMethod: item})
    }
  };

  const handleChangeNameDepositor = (e: any) => {
    let value1 = e.target.value.replace(/[^a-zA-Z |\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]/g, '');
    // let value = e.target.value.replace(/[^a-zA-Z |\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]/g, '').replace(/\s+/g, ' ');
    let pattern = /[`~!@#$¿%^đêôư&*()_+\-=[\]{}×÷;…':"\\|,.<>/?|0-9]/g;
    let value = value1.replace(pattern, '');
      if (value.length > 20) return value.slice(0, 20);
      handleUpdate({...dataOrders, depositorName: value})
  };


  const renderMethod = () => {
    return PAYMENT_METHOD.map((item, idx) => (
      <div
        key={idx}
        className={`method
            ${dataOrders?.orderId ? 'disabled' : ''} 
            ${idx === 1 ? "" : ""} 
            ${
              dataOrders.paymentMethod?.id !== undefined &&
              dataOrders.paymentMethod?.id === item.id
                ? "selected"
                : ""
            }`}
        onClick={() => handleSelect(item)}
      >
        <span>{item.label}</span>
      </div>
    ));
  };

  const formatExpirationDate = (event: any) => {
    const target = event.target;
    
    if (target.name === "expire_year" && target.value === '') {
      if (expireMMRef.current) {
        expireMMRef.current.focus()
      }
    }
    if (target.name === "expire_month" && target.value.length === 2) {
      if (expireYYRef.current) {
        expireYYRef.current.focus()
      }
    }

    handleUpdate({...dataOrders, cardManualInfo: {
      ...dataOrders.cardManualInfo,
      [target.name]: target.value
    }})
  }

  const handleClickMM = () => {

  }

  const handleClickYY = (e: any) => {
    console.log('handleClickYY', e.target.value);
    if (dataOrders.cardManualInfo.expire_month.length < 1) {
      if (expireMMRef.current) {
        expireMMRef.current.focus()
      }
    }
  }

  const handleKeyDown = (e: any) => {
    console.log(e.target.value, 'handleKeyDown');
    if (e.target.value === '' && (e.key === 'Backspace' || e.key === "KEYCODE_DEL")) {
      if (expireMMRef.current) {
        expireMMRef.current.focus()
      }
    }
  }

  const handleChangeNumberCard = (e: any) => {
    let el = e.target;
    let pattern = /\./g;
    let valuePrice;
    valuePrice = e.target.value.replaceAll(pattern, ''); 

    console.log(valuePrice, 'valuePrice');
    
    handleUpdate({...dataOrders, cardManualInfo: {
      ...dataOrders.cardManualInfo,
      [el.name]: valuePrice
    }})
  }

  const handleChangeInstallment = (e: any) => {
    console.log('handleChangeInstallment', e);
    handleUpdate({...dataOrders, cardManualInfo: {
      ...dataOrders.cardManualInfo,
      installment_month: e
    }})
  }

  const contentPaymentMethod = (
    <div className="payment-method-accodition-content">
      <div className="item">{renderMethod()}</div>
      {dataOrders?.paymentMethod?.value === "MONEY" && (
        <>
          <div className="c-form-control">
            <div className="c-input-wrapper">
              <input
                placeholder={"입금자명을 입력해 주세요."}
                type="text"
                maxLength={20}
                value={dataOrders?.buyerName || ''}
                disabled={true}
                name={"depositorName"}
                onChange={(e) => handleChangeNameDepositor(e)}
              />
            </div>
          </div>
          <div className="info-store">
            <label className="bank-name" htmlFor="">{dataOrders.stores[0]?.bank_name}</label>
            <span className="number-bank">
              {`${dataOrders.stores[0]?.account_number} (${dataOrders.stores[0]?.account_holder})`}
            </span>
            <p className="note-bank">
              <span>주문자와 입금자명이 동일해야 합니다.</span>
            </p>
          </div>
        </>
      )}
      {dataOrders?.paymentMethod?.value === "CARD_MANUAL" && (
        <>
          <div className="card-hand">
            <div className="card-hand__header">
              <h3><img src={CardIcon} alt="" /> 카드 정보를 입력해주세요.</h3>
            </div>
            <div className="card-hand__content">
              <form action="" className="card-hand-form">
                <div className="number-card">
                  <label htmlFor="">카드 번호</label>
                  <div className="number-card__box">
                    <div className="number-card__input">
                      <div className="c-form-control">
                        <div className="c-input-wrapper">
                            <NumericFormat 
                                inputMode="decimal"
                                allowLeadingZeros
                                placeholder="0000"
                                allowNegative={false}
                                value={dataOrders.cardManualInfo?.card_number_1 || null}
                                isAllowed={(values: any) => {
                                    const { formattedValue, floatValue }: any = values;
                                    if (formattedValue.includes(".")) {
                                      return false; 
                                    }
                                    return formattedValue === "" || formattedValue.length <= 4;
                                }}  
                                name={"card_number_1"} 
                                className="phone-payment"
                                onChange={handleChangeNumberCard}
                            />
                        </div>
                    </div>

                    </div>
                    <div className="number-card__input locked">
                      <div className="c-form-control">
                        <div className="c-input-wrapper">
                            <NumericFormat 
                                inputMode="decimal"
                                allowLeadingZeros
                                placeholder="••••"
                                allowNegative={false}
                                value={dataOrders.cardManualInfo?.card_number_2 || null}
                                isAllowed={(values: any) => {
                                    const { formattedValue, floatValue }: any = values;
                                    if (formattedValue.includes(".")) {
                                      return false; 
                                    }
                                    return formattedValue === "" || formattedValue.length <= 4;
                                }}  
                                className={"cart-mask"}
                                name={"card_number_2"} 
                                onChange={handleChangeNumberCard}
                            />
                        </div>
                      </div>

                      <img src={CardLockIcon} alt="" />
                    </div>
                    <div className="number-card__input locked">
                      <div className="c-form-control">
                        <div className="c-input-wrapper">
                            <NumericFormat 
                                inputMode="decimal"
                                allowLeadingZeros
                                placeholder="••••"
                                allowNegative={false}
                                value={dataOrders.cardManualInfo?.card_number_3 || null}
                                isAllowed={(values: any) => {
                                    const { formattedValue, floatValue }: any = values;
                                    if (formattedValue.includes(".")) {
                                      return false; 
                                    }
                                    return formattedValue === "" || formattedValue.length <= 4;
                                }}  
                                className={"cart-mask"}
                                name={"card_number_3"} 
                                onChange={handleChangeNumberCard}
                            />
                        </div>
                      </div>

                      <img src={CardLockIcon} alt="" />
                    </div>
                    <div className="number-card__input">
                      <div className="c-form-control">
                        <div className="c-input-wrapper">
                            <NumericFormat 
                                inputMode="decimal"
                                allowLeadingZeros
                                placeholder="0000"
                                allowNegative={false}
                                value={dataOrders.cardManualInfo?.card_number_4 || null}
                                isAllowed={(values: any) => {
                                    const { formattedValue, floatValue }: any = values;
                                    if (formattedValue.includes(".")) {
                                      return false; 
                                    }
                                    return formattedValue === "" || formattedValue.length <= 4;
                                }}  
                                name={"card_number_4"} 
                                onChange={handleChangeNumberCard}
                            />
                        </div>
                    </div>
                    </div>
                  </div>
                  
                </div>
                <div className="card-info">
                  <div className="card-expired">
                      <label htmlFor="">유효기간</label>
                      <div className="input-group-expire">
                        <NumericFormat 
                          inputMode="decimal"
                          allowLeadingZeros
                          placeholder="MM"
                          allowNegative={false}
                          value={dataOrders?.cardManualInfo?.expire_month}
                          isAllowed={(values: any) => {
                              const { formattedValue, floatValue }: any = values;
                              if (formattedValue.includes(".")) {
                                return false; 
                              }
                              return formattedValue === "" || formattedValue.length <= 2;
                          }}  
                          name={"expire_month"} 
                          onChange={formatExpirationDate}
                          onClick={handleClickMM}
                          getInputRef={expireMMRef}
                          className="mm cart-mask"
                        />
                        <span>/</span>
                        <NumericFormat 
                          inputMode="decimal"
                          allowLeadingZeros
                          placeholder="YY"
                          allowNegative={false}
                          value={dataOrders?.cardManualInfo?.expire_year}
                          isAllowed={(values: any) => {
                              const { formattedValue, floatValue }: any = values;
                              if (formattedValue.includes(".")) {
                                return false; 
                              }
                              return formattedValue === "" || formattedValue.length <= 2;
                          }}  
                          name={"expire_year"} 
                          onChange={formatExpirationDate}
                          onClick={handleClickYY}
                          onKeyDown={handleKeyDown}
                          getInputRef={expireYYRef}
                          className="yy cart-mask"
                        />
                      </div>
                  </div>
                  <div className="card-expired">
                    <label htmlFor="">할부개월</label>
                    <CFormControl type={"empty"} className="delivery-select">
                      <CDropdown
                        name={"installment_month"}
                        dataSet={INSTALLMENT_MONTH}
                        handleChange={handleChangeInstallment}
                        selected={dataOrders.cardManualInfo?.installment_month}
                        placeholder={"일시불"}
                      />
                    </CFormControl>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <CAccoditionItem
      id={3}
      className={"payment-method-accodition"}
      question={"결제 수단"}
      answer={contentPaymentMethod}
      key={3}
      isShow={!dataOrders?.orderId}
      type={1}
      title={""}
      description={""}
    />
  );
};

export default PaymentMethodComponent;
