export const deviceStorage: any = {
    "cookie": {
        getItem: (key: string): string => {
            const cookies = document.cookie.split(";").map(cookie => cookie.trim());
            for (const cookie of cookies) {
                const [cookieName, cookieValue] = cookie.split("=");
                if (cookieName === key) {
                    return decodeURIComponent(cookieValue);
                }
            }
            return ""
        },
        setItem: (key: string, value: string, daysToExpire: number = 30): string => {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + daysToExpire);

            const cookieValue = encodeURIComponent(value) + (daysToExpire ? `; expires=${expirationDate.toUTCString()}` : '');
            document.cookie = `${key}=${cookieValue}; path=/`;
            return key;
        },
        removeItem: (key: string): string => {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() - 1); // Đặt hạn sử dụng là hôm qua

            const cookieValue = encodeURIComponent("") + "; expires=" + expirationDate.toUTCString();
            document.cookie = key + "=" + cookieValue + "; path=/";
            return key;
        }
    },
    "session": {
        getItem: (key: string): string => {
            return sessionStorage.getItem(key) || "";
        },
        setItem: (key: string, value: string, daysToExpire: number = 30): string => {
            sessionStorage.setItem(key, value)
            return key;
        },
        removeItem: (key: string): string => {
            sessionStorage.removeItem(key)
            return key;
        }
    }
}