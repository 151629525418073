import {useEffect, useState} from "react";

import ArrowLeftIcon from 'core/assets/images/header/icon_arrow_left.svg';
import HomeIcon from 'core/assets/images/header/icon_home.svg';
const SingleHeader = (props: any) => {
    const [_props, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        title: props.title || '',
    })

    const handleRightClick = (e: any) => {
        props.handleRightClick(e)
    }
    const handleLeftClick = (e: any) => {
        props.handleLeftClick(e)
    }

    useEffect(()=>{
        setProps(props)
    }, [props])

    return <>
        <header className={`${_props.className} single-header`} id={_props.id}>
            <div className="header-wrapper">
                <div className="header-content">
                    <div className="center">
                        <h2>{_props.title}</h2>
                    </div>
                </div>
            </div>
        </header>
    </>
}

export default SingleHeader