import moment from "moment"

const DATE_KEY = {
  '7day': 0,
  '15day': 1,
  '1month': 2,
  '3month': 3,
  '6month': 4,
  'search': 5,
}

// export const DATE_RANGES = {
//   [DATE_KEY['15day']]: {
//     label: '15일',
//     value: [
//       moment().subtract(14, 'days').format('YYYY-MM-DD'),
//       moment().format('YYYY-MM-DD')
//     ],
//     disabled: false,
//   },
//   [DATE_KEY['1month']]: {
//     label: '1개월',
//     value: [
//       moment().subtract(1, 'months').subtract(1, 'days').format('YYYY-MM-DD'),
//       moment().format('YYYY-MM-DD')
//     ],
//     disabled: false,
//   },
//   [DATE_KEY['3month']]: {
//     label: '3개월',
//     value: [
//       // ngoại lệ ngày 1 tháng 12 là bị sai
//       // nên cần phải trừ 3 tháng - 1 ngày mới đúng
//       moment().format('MM-DD') === '12-01'
//         ? moment().subtract(3, 'months').subtract(1, 'days').format('YYYY-MM-DD')
//         : moment().subtract(1, 'days').subtract(3, 'months').format('YYYY-MM-DD'),
//       moment().format('YYYY-MM-DD')
//     ],
//     disabled: false,
//   },
//   [DATE_KEY['6month']]: {
//     label: '6개월',
//     value: [
//       // ngoại lệ ngày 1 tháng 12 là bị sai
//       // nên cần phải trừ 3 tháng - 1 ngày mới đúng
//       moment().format('MM-DD') === '12-01'
//         ? moment().subtract(6, 'months').subtract(1, 'days').format('YYYY-MM-DD')
//         : moment().subtract(1, 'days').subtract(6, 'months').format('YYYY-MM-DD'),
//       moment().format('YYYY-MM-DD')
//     ],
//     disabled: false,
//   },
// }

// const DATE_KEY = {
//   '15day': 1,
//   '1month': 2,
//   '3month': 3,
//   '6month': 4,
// }

export const DATE_RANGES = {
  [DATE_KEY['7day']]: {
    label: '일주일',
    value: [
      moment().subtract(7, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ],
    disabled: false,
    type: ["accumulate"]
  },
  [DATE_KEY['15day']]: {
    label: '15일',
    value: [
      moment().subtract(14, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ],
    disabled: false,
    type: ["history"]
  },
  [DATE_KEY['1month']]: {
    label: '1개월',
    value: [
       // ngoại lệ ngày 31
      // nên cần phải trừ 1 ngày - 1 tháng mới đúng
      moment().endOf("month").format('DD') === '31'
        ? moment().subtract(1, 'days').subtract(1, 'months').format('YYYY-MM-DD')
        : moment().subtract(1, 'months').subtract(1, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ],
    disabled: false,
    type: ["accumulate", "history"]
  },
  [DATE_KEY['3month']]: {
    label: '3개월',
    value: [
            // ngoại lệ ngày 1 tháng 12 là bị sai
      // nên cần phải trừ 3 tháng - 1 ngày mới đúng
      moment().format('MM-DD') === '12-01'
        ? moment().subtract(3, 'months').subtract(1, 'days').format('YYYY-MM-DD')
        : moment().subtract(1, 'days').subtract(3, 'months').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ],
    disabled: false,
    type: ["accumulate", "history"]
  },
  [DATE_KEY['6month']]: {
    label: '6개월',
    value: [
      // ngoại lệ ngày 1 tháng 12 là bị sai
      // nên cần phải trừ 6 tháng - 1 ngày mới đúng
      moment().format('MM-DD') === '12-01'
        ? moment().subtract(6, 'months').subtract(1, 'days').format('YYYY-MM-DD')
        : moment().subtract(1, 'days').subtract(6, 'months').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ],
    disabled: false,
    type: ["history"]
  },
  [DATE_KEY['search']]: {
    label: '조건검색',
    value: '',
    disabled: false,
    type: ["accumulate"]
  },
}