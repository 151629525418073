import CDialog from "core/components/CDialog";
import { RouterName } from "core/router";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateReload } from "../hooks/useLists";
import { useUpdateOrCreateCart } from "../hooks/useCarts";
import { getPaymentStatus } from "core/utils/helper";
import { MESSAGE } from "core/constants/Commons";

interface OrderButtonGrouptProps {
  data: any;
  handleUpdateAddress: Function;
  url: string;
}

export const OrderButtonGroupt = ({data, handleUpdateAddress, url}: OrderButtonGrouptProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [modalDialog, setModalDialog] = useState<any>({
    isShow: false,
    title: "",
    content: "",
    type: "",
    btnClose: "닫기",
    btnSubmit: "주소 설정",
  });

  const _onSuccess = () => {
    toast.dismiss();
    // toast(MESSAGE.ADD_CART_BY_ORDER_SUCCESS);
    setModalDialog({
      isShow: true,
      title: "",
      content: "같은 메뉴를 장바구니에 담았습니다.",
      type: "ADD_CART_SUCCESS",
      btnClose: "닫기",
      btnSubmit: "장바구니 이동",
    })
  }

  const _onFailed = (error: any) => {
    //two case validate
    console.log('error', error);
    
    if (error.code === 422) {
      setModalDialog({
        isShow: true,
        title: '',
        content: error?.msg || MESSAGE.PRODUCT_NOT_FOUND, //MESSAGE.PRODUCT_NOT_FOUNDO
        type: error.type,
        btnSubmit: "확인",
        btnClose: ""
      })
    }else if(error.type === 'MAX_EXCEED'){
      toast(error.msg)
    }else{
      toast("ERRORS")
    }
  }

  const [, onUpdateOrCreate] = useUpdateOrCreateCart({ onSuccess: _onSuccess, onFailed: _onFailed});
  
  const handleAddCarts = (data: any) => {
    if (onUpdateOrCreate) {
      onUpdateOrCreate(data || [], );   
    }
  };

  const handleChangeAddress = (order: any) => {
    handleUpdateAddress(order);
  }
  
  const updateReloadPage = useUpdateReload();

  const handleCancelOrder = (order: any) => {
    if (updateReloadPage) {
      updateReloadPage({
        reload: 0
      })
    }
    navigate(`${RouterName.ORDER_CANCEL.replace(":id", order.id)}?step=1&from=${url}`);
  };

  const closePopup = () => {
    setModalDialog({
      isShow: false,
      title: "",
      content: "",
      type: "",
      btnClose: "닫기",
      btnSubmit: "주소 설정",
    })
  }

  const handleConfirm = (data: any) => {
    if (data.type === "ADD_CART_SUCCESS") {
      navigate(RouterName.CARTS_LIST, {
        state: {
          back_type: url, 
          back_url: url,
        }
      })
    }else{
      closePopup();
    }
  }
  const updateReload = useUpdateReload();
  const handleCancelOrderDetail = (data: any) => {
    if (updateReload) {
      updateReload({
        reload: 1
      })
    }
    navigate(`${RouterName.ORDER_CANCEL_DETAIL.replace(":id", data.id)}`, {
      state: {
        back_type: location?.pathname || '', 
        back_url: location?.pathname || '',
      }
    })
  } 

  return <>
          {
           getPaymentStatus(data.payment_method, data.payment_status, data.status) === '주문취소' ? 
           <div className="bottom-groupt">
            <div className="item row">
              <div className="col-6 item__left ">
                <div className={`d-grid`} style={{backgroundColor: "#ff773a"}}>
                  <button
                    className="cbtn btn-primary "
                    style={{backgroundColor: "transparent", color: '#fff'}}
                    onClick={() => handleAddCarts(data)}
                  >
                    장바구니 담기
                  </button>
                </div>
              </div>
              <div className="col-6 item__right">
                <div className={`d-grid `}>
                  <button
                    className="cbtn btn-primary"
                    onClick={() => handleCancelOrderDetail(data)}
                  >
                    취소 상세
                  </button>
                </div>
              </div>
            </div>
          </div> : 
           <div className="bottom-groupt">
            <div className="shopping-cart-btn">
              <div className="d-grid" style={{border: "none"}}>
                <button
                  className="cbtn btn-primary"
                  onClick={() => handleAddCarts(data)}
                >
                  장바구니 담기
                </button>
              </div>
            </div>
            <div className="item row">
              <div className="col-6 item__left">
                <div className={`d-grid  d-grid--${!getPaymentStatus(data.payment_method, data.payment_status, data.status, 'statusBtn') ? 'disabled' : ''}`} >
                  <button
                    disabled={!getPaymentStatus(data.payment_method, data.payment_status, data.status, 'statusBtn')}
                    className="cbtn btn-primary"
                    onClick={() => handleChangeAddress(data)}
                  >
                    배송지 변경
                  </button>
                </div>
              </div>
              <div className="col-6 item__right">
                <div className={`d-grid  d-grid--${!getPaymentStatus(data.payment_method, data.payment_status, data.status, 'statusBtn') ? 'disabled' : ''}`}>
                  <button
                    disabled={!getPaymentStatus(data.payment_method, data.payment_status, data.status, 'statusBtn')}
                    className="cbtn btn-primary"
                    onClick={() => handleCancelOrder(data)}
                  >
                    주문 취소
                  </button>
                </div>
              </div>
            </div>
          </div>
          }


          <CDialog
            id={"js-error-quantity"}
            className={"modal-dialog-no-address"}
            show={modalDialog.isShow || false}
            title={modalDialog.title}
            submitBtn={modalDialog.btnSubmit}
            closeBtn={modalDialog?.btnClose}
            overlay
            handleClose={() => closePopup()}
            handleSubmit={() => handleConfirm(modalDialog)}
          >
            <div className="content-dialog">{modalDialog.content || ""}</div>
          </CDialog>
  </>
}