import React, {useEffect, useState, FunctionComponent} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'modules/AppSettings/assets/scss/setting_push.scss'
import AppLayout from "core/layouts/AppLayout";
import IconHeader from "core/components/header/IconHeader";
import {RouterName} from "core/router";
import CSwitcher from "core/components/CSwitcher";
import {toast} from "react-toastify";
import API from "../../../core/networks/API";
import {API_SETTING_PUSH} from "../api";
import {useFetchMe, useUser} from "../../Auth/hooks";
import {useDispatch} from "react-redux";
import {fetchMe} from "../../Auth/store/actions";


const SettingPushNoticePage: FunctionComponent = (props: any) => {

    const user = useUser()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // @ts-ignore
    // const [statePushDefault, setStatePushDefault] = useState(window._APP_NOTIFICATION_PERMISSION)
    const [statePushDefault, setStatePushDefault] = useState((user?.setting?.allow_push == 'Y' ? true : false))
    const [stateError, setStateError] = useState({state: false, text: ''})
    const [stateUpdateSettingPush, setStateUpdateSettingPush] = useState(false)

    const saveSettingPush = async (statue:any) => {
        let dataRequest = {
            allow_push:(statue ? 'Y' : 'N')
        }
        try {
            const {data: response} = await API.post(`${API_SETTING_PUSH}`, dataRequest)
            if(response && response?.code == 200){
                console.log(response,'response')
                setStateUpdateSettingPush(true)
            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response
        }
    }


    useEffect(() => {
        dispatch(fetchMe())
    }, [stateUpdateSettingPush])

    useEffect(()=>{
        if(stateError?.state){
            toast.dismiss();
            toast(stateError?.text)
        }
        // eslint-disable-next-line
    }, [stateError?.state])
    const goBack = () => {
        navigate(-1)
    }
    const handleChangePush = (state:any) => {

        if(state){
            setStateError({
                state: false, text: ''
            })
        }else {
            setStateError({state: true,text:'푸시 알림이 해제되었습니다.'})
        }
        // @ts-ignore
        window.webViewRequestPermission(["notification"])
        setStatePushDefault(state)
        saveSettingPush(state)
    }


    return (
        <AppLayout className={'setting-push-page'}>
            <IconHeader
                title={'Push 알림 설정'}
                iconLeft={true}
                handleLeftClick={goBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <div className={`setting-push-page__main`}>
                <div className={'setting-push-page__main__content'}>

                    <div className='box-content'>
                        <div className={'box-content__action'}>
                            <label>Push 알림 설정</label>
                            <CSwitcher
                                icon={true}
                                value={statePushDefault}
                                className={'CSwitcher'}
                                handleSwitch={handleChangePush}
                            />
                        </div>
                        <div className={'box-content__description'}>
                            <p>- 결제 및 포인트 내역에 대한 Push 메세지 등 부가적인 <br/>
                                알림 수신 여부를 설정할 수 있습니다</p>
                            <p>- 디바이스 내 설정에서 알림을 끄셨을 경우 알림이 수신 <br/>
                                되지 않습니다.</p>
                        </div>
                    </div>

                </div>
            </div>
        </AppLayout>
    )
}


export default SettingPushNoticePage
