import { BottomSwiperProps } from "core/interface/BottomSwiper";
import React, { memo, useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const BottomSwiper = (props: any) => {
  const {
    title,
    children,
    isOpen,
    onSlideDown,
    className,
    isShowHeader = true,
    suggest,
    isActive,
    onStartSwipeUp,
    onEndSwipeUp,
    onStartSwipeDown,
    onEndSwipeDown
  } = props;
  let startY: number;
  let endY: number;
  const wrapperRef = useRef(null);
  
  useOnClickOutside(wrapperRef, onSlideDown);

  const [isOverlay, setIsOverlay] = useState(false);

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    let timeEnd: NodeJS.Timeout;
    if (!isOpen) {
      if(typeof onStartSwipeDown == 'function') onStartSwipeDown(true);
      timeOut = setTimeout(() => {
        if(typeof onEndSwipeDown == 'function') onEndSwipeDown(true);
        setIsOverlay(false);
      }, 500);
    } else {
      if(typeof onStartSwipeUp == 'function') onStartSwipeUp(true);
      setIsOverlay(true);
      timeEnd = setTimeout(() => {
        if(typeof onEndSwipeUp == 'function') onEndSwipeUp(true);
      }, 600);
    }
    return () => {
      clearTimeout(timeOut)
      clearTimeout(timeEnd)
    };
  }, [isOpen]);

  const handelOnTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    startY = event?.changedTouches[0]?.clientY;
  };

  const handelOnTouchMove = () => {
    if(typeof onSlideDown == 'function') onSlideDown(true);
  };

  const handelOnTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    endY = event?.changedTouches[0]?.clientY;
    if (startY - endY < -30 && startY !== endY) {
      if(typeof onSlideDown == 'function') onSlideDown(false);
    }
  };
  
  return isActive ? (
    <div className={`bottom-swiper ${isOverlay ? "overlay" : ""} ${className}`}>
      <div
        className={`bottom-swiper__main ${isOpen ? "touchend" : "back"}`} //${isOpen ? !isExceed ? "touchend" : 'touchend-error' : isExceed ? 'back-error' : "back"}
        ref={wrapperRef}
      >
        <div className="bottom-swiper__main__header">
          <div
            className="bottom-swiper__main__swipe-down"
            onTouchStart={(e) => handelOnTouchStart(e)}
            onTouchMove={() => handelOnTouchMove()}
            onTouchEnd={(e) => handelOnTouchEnd(e)}
          ></div>
          {isShowHeader && (
            <>
              {title && (
                <div className="bottom-swiper__main__title">
                  <h2>{title}</h2>
                </div>
              )}
              {suggest && (
                <div className="bottom-swiper__main__suggest">{suggest}</div>
              )}
            </>
          )}
        </div>

        <div className="bottom-swiper__main__body">{children}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default memo(BottomSwiper);
