export const filterProduct = [
  {
    id: 1,
    title: "추천 판매순",
    order_by: "featured_state",
    order_dir: "asc",
  },
  {
    id: 2,
    title: "최신 등록순",
    order_by: "created_at",
    order_dir: "desc",
  },
  {
    id: 3,
    title: "높은 가격순",
    order_by: "price",
    order_dir: "desc",
  },
  {
    id: 4,
    title: "낮은 가격순",
    order_by: "price",
    order_dir: "asc",
  },
];