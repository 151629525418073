import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RouterName} from "../../router";
import {config} from "../../config/app";
import {deviceStorage} from "../../utils/devicestorage";

const PrivateRouter = (props: any) => {
    const navigate = useNavigate();
    const authRedux = useSelector((state: any) => state.Auth);
    const [authData, setAuthData] = useState<any>(authRedux)

    useEffect(()=>{
        // if(!authData.user.id) navigate(RouterName.LOGIN)
    }, [authData])

    useEffect(()=>{
        setAuthData(authData)
    }, [authRedux])

    useEffect(()=>{
        const token = deviceStorage[localStorage.getItem("storage_driver")|| "session"].getItem(config.appPackage+'_X_TOKEN')
        if(!token) navigate(RouterName.LOGIN)
    }, [])


    return <>{props.children}</>
}

export default PrivateRouter