import { MESSAGE, MINIMUM_PAYMENT } from "core/constants/Commons";
import { numberFormat } from "core/utils/helper";

interface ButtonPaymentProps {
  dataOrders: any;
  carts: any;
  loadingOrder: boolean
}

const ButtonPaymentComponent = ({dataOrders, carts, loadingOrder }: ButtonPaymentProps) => {
  
  const renderTotal = (data: any) => {
    let totalValue = 0;
    if (data && data.length > 0) {
      totalValue = data
        .filter((item: any) => item.priceByQuantity)
        .map((item: any) => +item.priceByQuantity)
        .reduce((sum: any, current: any) => sum + current);
    }
    return totalValue;
  };

  const total = numberFormat(
    renderTotal(
      dataOrders.type === "DETAIL_PAYMENT"
        ? dataOrders.dataProduct
        : carts
    )
  )

    const disabled = renderTotal(
      dataOrders.type === "DETAIL_PAYMENT"
        ? dataOrders.dataProduct
        : carts
    ) < MINIMUM_PAYMENT

  return (
    // ${true ? 'payment--accodition-close' : ''}`
    <div className={`payment ${disabled ? 'disable' : ''}`}>
      {
        disabled && !loadingOrder ? <div className="box-err">
        <label htmlFor="" className="text-err">{MESSAGE.PAYMENT_NOT_LESS_50}</label>
        <label htmlFor="" className="total-err">{total}원</label>
      </div> : <></>
      }

      <div className="d-grid">
        <button className="cbtn btn-primary" type="submit" disabled={disabled || loadingOrder}>
          구매하기
        </button>
      </div>
    </div>
  );
};

export default ButtonPaymentComponent;
