import {useEffect, useState} from "react";

import CreateIcon from 'core/assets/images/header/icon_kakao.svg';
const CFloatButton = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        icon: props.icon || true,
        label: props.label || '문의하기',
        handleClick: props.handleClick || {},
    })

    const handleClick = (e: any) => {
        props.handleClick(e)
    }

    return <>
        <div className={`c-float-button ${_propsState.className}`} onClick={(e) => handleClick(e)}>
            <div className='content-float-button'>
                {_propsState.icon ? <img className='content-float-button__icon' src={CreateIcon} alt={''}/> : ''}
                <p className='content-float-button__text'>{_propsState.label}</p>
            </div>
        </div>
    </>
}

export default CFloatButton