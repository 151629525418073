import {useEffect, useState} from "react";

import EmptyIcon from 'core/assets/images/icon_empty.svg';
const CEmptyIcon = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        icon: props.icon || EmptyIcon,
        text: props.text || '',
        description: props.description || '',
    })


    return <>
        <div className={`c-empty-icon ${_propsState.className}`}>
            <div className='box-content'>
                <img className='box-content__icon' src={_propsState.icon} alt={''}/>
                <p className='box-content__text'>{_propsState.text}</p>
                {_propsState.description ? <p className='box-content__description'>{_propsState.description}</p> : ''}
            </div>
        </div>
    </>
}

export default CEmptyIcon