import {
  GET_APP_SETTING,
  GET_APP_SETTING_SUCCESS,
  GET_APP_SETTING_FAILED,
} from "./types";

export interface DataState {
  data: any;
  loadingAppSetting: boolean;
  type: any;
}

const initialState: DataState = {
  data: [],
  loadingAppSetting: false,
  type: "",
};

const cartReducer = (
  state: DataState = initialState,
  action: any
): DataState => {
  switch (action.type) {
    case GET_APP_SETTING:
      return {
        ...state,
        loadingAppSetting: true,
        type: action.type,
      };
    case GET_APP_SETTING_SUCCESS:
      return {
        ...state,
				data: action.payload.data || [],
        loadingAppSetting: false,
        type: action.type,
      };
		case GET_APP_SETTING_FAILED:
			return {
				...state,
				loadingAppSetting: false,
				type: action.type,
			};
    default:
      return state;
  }
};

export default cartReducer;
