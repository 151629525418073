import {CategoryDataSet} from "./constants";

export const getCategory = (key: string) => {
    const [item] = CategoryDataSet.filter(item=> item.id == key)
    return item;
}

export const nl2br = (str:any, replaceMode:any, isXhtml:any) => {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export const insertParam = (key:any, value:any) => {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);
    // Lấy danh sách các tham số từ URL
    var kvp = document.location.search.substr(1).split('&');
    let i = 0;
    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }
    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join('=');
    }
    // Tạo chuỗi tham số mới
    let params = kvp.join('&');
    // Tải lại trang với tham số mới
    document.location.search = params;
}