import {SOCKET_EMIT_PRIVATE, SOCKET_EMIT_PUBLIC} from './types';
import uuid from "react-uuid";

export const emitPublic = (payload: any): { payload: any; type: string; uuid: string } => {
    return {
        type: SOCKET_EMIT_PUBLIC,
        uuid: uuid(),
        payload
    }
}

export const emitPrivate = (payload: any): { payload: any; type: string; uuid: string } => {
    return {
        type: SOCKET_EMIT_PRIVATE,
        uuid: uuid(),
        payload
    }
}
