import CDropdown from "core/components/form-controls/CDropdown";
import CFormControl from "core/components/form-controls/CFormControl";
import React, { memo, useEffect, useState } from "react";
import { dataHours, dataMinus } from "../constants/Time";

interface DateOption {
  id: number;
  label: string;
  value: string;
}

interface DateTime {
  date: string;
  hour: string;
  minute: string;
}

interface HoursOption {
  id: number;
  label: string;
  disable: boolean;
}

interface MinutesOption {
  id: number;
  label: string;
  disable: boolean;
}

interface DateTimeInfomation {
  dateTimeSelected: Function,
  dataShippingTime: any,
  dataOrders: any
}

const GenerateDateTimeComponent = ({dateTimeSelected, dataShippingTime, dataOrders}: DateTimeInfomation) => {
  let endYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const endDate = endYear.toISOString().substr(0, 10);
  const dateOptions: DateOption[] = [];
  const [hourOptions, setHoursOption] = useState<any>(dataHours)
  const [minutesOptions, setMinutesOptions] = useState<any>(dataMinus)
  const start = new Date();
  const end = new Date(endDate);

  const [dateSelected, setDateSelected] = useState<DateTime>({
    date: dataShippingTime?.date || '',
    hour: dataShippingTime?.hour || '',
    minute: dataShippingTime?.minute || '',
  });

  console.log('dataShippingTime11', dataShippingTime);
  

  let idx = 0;
  while (start <= end) {
    idx++;
    //ko-kr
    // const formattedDate = start.toLocaleDateString("ko-kr", {
    //   // year: 'numeric',
    //   month: "long",
    //   day: "numeric",
    //   weekday: "short",
    // });
    // // "Mon, January 16, 2023"
    // //"2023년 1월 16일 (월)"
    // const dateOption: DateOption = {
    //   id: idx,
    //   label: formattedDate,
    //   value: start.toISOString().substr(0, 10),
    // };
    const formattedDate = start.toLocaleString("ko-KR", {
      timeZone: "Asia/Seoul",
      month: "long",
      day: "numeric",
      weekday: "short",
    })


  
    const dateOption: DateOption = {
      id: idx,
      label: formattedDate,
      value: start.toLocaleDateString("en-ZA", {
        timeZone: "Asia/Seoul",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).split('/').reverse().join('-')
    };
    // console.log(dateOption, 'dateOption');
    
    dateOptions.push(dateOption);
    start.setDate(start.getDate() + 1);
  }

  console.log(minutesOptions, 'minutesOptions');
  

  useEffect(() => {
  
    setHoursOption(dataHours);
    setMinutesOptions(dataMinus);
    
    if (dataShippingTime?.date) {
      let dateSelected:any = dateOptions.filter((item:any) => item.value === dataShippingTime?.date)
      setDateSelected({
        date: dateSelected?.value || dataShippingTime?.date,
        hour: dataShippingTime?.hour || '00',
        minute: dataShippingTime?.minute || '00',
      })
    }else{
      setDateSelected({
        date: "",
        hour: "",
        minute: "",
      })
    }
  }, [])

  const handleDateTimeChange = (item: DateOption, name: string) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("ko-kr", {
      timeZone: "Asia/Seoul",
      month: "long",
      day: "numeric",
      weekday: "short",
    });

    console.log(name, item, formattedDate);
    //current day
    if (formattedDate === item.label && name === 'date') {
      console.log(currentDate.getHours(), 'currentDate.getHours()');
      console.log(dateSelected, 'dateSelected');
      
      if (currentDate.getHours() < 12) {
        const _dataHours = [...dataHours].map((item: any, idx: number) => {
          if (+item.value < 12) {
            return {
              ...item,
              disable: true,
            }
          }else{
            return {
              ...item
            }
          }
        })
        setHoursOption(_dataHours);
        // let _dataMinus = [...dataMinus];
        // _dataMinus[0].disable = true
        // setMinutesOptions(_dataMinus)

        //check if date.hour !== "" &&  date.hour < 12
        if (dateSelected.hour !== "" && +dateSelected.hour < 12)  {
          setDateSelected({
            ...dateSelected,
            date: item.value,
            hour: "12"
          })
        }else{
          setDateSelected({
            ...dateSelected,
            [name]: item.value
          })
        }
      }else{
        let timeSetting = +currentDate.getHours() + 3 <= 23 ? +currentDate.getHours() + 3 : 23;
        const _dataHours = [...dataHours].map((item: any, idx: number) => {
          
          if (+item.value < timeSetting) {
            return {
              ...item,
              disable: true,
            }
          }else{
            return {
              ...item
            }
          }
        })
        setHoursOption(_dataHours);
        //check if date.hour !== "" &&  date.hour < 12
        if (dateSelected.hour !== "" && +dateSelected.hour < timeSetting)  {
          setDateSelected({
            ...dateSelected,
            date: item.value,
            hour: `${timeSetting}`
          })
        }else{
          setDateSelected({
            ...dateSelected,
            [name]: item.value
          })
        }
        // let _dataMinus = [...dataMinus];
        // _dataMinus[0].disable = true
        // setMinutesOptions(_dataMinus)
      }
    }else{
      console.log('else');
      if (name !== 'date') {
        setDateSelected({
          ...dateSelected,
          [name]: item.value
        })
      }else{
        setHoursOption(dataHours);
        setMinutesOptions(dataMinus)
        setDateSelected({
          ...dateSelected,
          [name]: item.value
        })
      }
    }

  };

  useEffect(() => {
    console.log(dateSelected, 'dateSelected');
    dateTimeSelected(dateSelected);

  }, [dateSelected])

  console.log(dateOptions, 'dateOptionsdateOptionsdateOptions');
  
  console.log(dateOptions.find((item:any) => item?.value === dataShippingTime?.date), dataShippingTime?.date, 'pppp');

  const isValidDateFormat = (dateString: any) => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
  }

  const formattedDateString = (date: any) => {
    // console.log(date, 'datedatedate');
    
    // console.log(isValidDateFormat(date), '000000');
    if (isValidDateFormat(date)) {
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    }else{
      return date
    }
  }
  return (
    <div className="date-time row">
      <div className="month-select col-6 m-0 p-0">
        <CFormControl type={"empty"} className="date-time-select">
          <CDropdown
            name={"date"}
            dataSet={dateOptions}
            selected={dateOptions.find((item:any) => item?.value === formattedDateString(dataOrders.shippingTime?.date || ''))}
            placeholder={dateOptions[0]?.label}
            disabled={dataOrders?.orderId}
            handleChange={(e:any) => handleDateTimeChange(e, 'date')}
          />
        </CFormControl>
      </div>
      <div className="hour-select col-3 m-0 p-0">
        <CFormControl type={"empty"} className="date-time-select">
          <CDropdown
            name={"hour"}
            dataSet={hourOptions}
            selected={hourOptions.find((item:any) => item?.value === dataOrders.shippingTime?.hour)}
            placeholder={"12 시"}
            disabled={dateSelected.date === "" || dataOrders?.orderId}
            handleChange={(e:any) => handleDateTimeChange(e, 'hour')}
          />
        </CFormControl>
      </div>
      <div className="hour-select col-3 m-0 p-0">
        <CFormControl type={"empty"} className="date-time-select">
          <CDropdown
            name={"minute"}
            dataSet={minutesOptions}
            selected={minutesOptions.find((item:any) => item?.value === dataOrders.shippingTime?.minute)}
            placeholder={"10 분"}
            disabled={dateSelected.date === "" || dateSelected.hour === "" || dataOrders?.orderId}
            handleChange={(e:any) => handleDateTimeChange(e, 'minute')}
          />
        </CFormControl>
      </div>
    </div>
  );
};

export default memo(GenerateDateTimeComponent);
