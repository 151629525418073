import {AppRouter, RouterName} from "core/router";

export const handlePushGoto = (item: any) => {

    switch (item.action) {
        case "ORDER_PAY_COMPLETE": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;
        case "ORDER_RECEIVE": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;
        case "ORDER_START_DELIVERY": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;
        case "ORDER_COMPLETE_DELIVERY": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;
        case "ORDER_CANCEL_DUE_TRANSFER_FAIL": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;
        case "ORDER_CANCEL": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;
        case "ORDER_INSTRUCT_DELIVERY": AppRouter.navigate(RouterName.ORDER_HISTORY_LIST); break;

        case "POINT_ACCUMULATE_USER": AppRouter.navigate(RouterName.ACCUMULATE_LIST); break;
        case "POINT_ACCUMULATE_REFERRER": AppRouter.navigate(RouterName.ACCUMULATE_LIST); break;
        case "POINT_WITHDRAW_PAID": AppRouter.navigate(RouterName.ACCUMULATE_LIST); break;
        case "POINT_WITHDRAW_CANCEL": AppRouter.navigate(RouterName.ACCUMULATE_LIST); break;
        case "REFERRER_SIGNUP": AppRouter.navigate(RouterName.REFERER_LIST); break;
        case "CONSULT_REPLY": AppRouter.navigate(RouterName.CONSULT_DETAIL.replace(/:id/g, item?.data?.consult_id)); break;
        case "ANNOUNCEMENT": AppRouter.navigate(RouterName.ANNOUCEMENT_DETAIL.replace(/:id/g, item?.data?.announcement_id)); break;
        case "ORDER_PUSH_BY_ADMIN": AppRouter.navigate(RouterName.ORDER_HISTORY_DETAIL.replace(/:id/g, item?.data?.order_id)); break;
        default: break;
    }
}