import {useEffect, useState} from "react";

import ArrowLeftIcon from 'core/assets/images/header/icon_arrow_left.svg';
import HomeIcon from 'core/assets/images/header/icon_home.svg';
import CartIcon from 'core/assets/images/icon/icon_cart_header.svg';
const IconHeader = (props: any) => {
    const [_props, setProps] = useState({
        id: props.id || '',
        className: props?.className || '',
        iconLeft: props.iconLeft || true,
        title: props.title || '',
        handleLeftClick: props.handleLeftClick || {},
        iconRight: props.iconRight || true,
        handleRightClick: props.handleRightClick || {},
        fixed: props?.fixed || true,
        iconCart: props.iconCart || true,
        handleCartClick: props.handleRightClick || {},
    })

    const handleRightClick = (e: any) => {
        props.handleRightClick(e)
    }
    const handleLeftClick = (e: any) => {
        props.handleLeftClick(e)
    }
    const handleCartClick = (e: any) => {
        props.handleCartClick(e)
    }

    useEffect(()=>{
        setProps(props)
    }, [props])

    return <>
        <header className={`${_props?.className} icon-header ${_props?.fixed ? ' fixed-header' : ''}`} id={_props.id}>
            <div className="header-wrapper">
                <div className="header-content">
                    <div className="left" onClick={(e) => handleLeftClick(e)}>
                        {_props.iconLeft ? <a title="" href={void(0)}
                                              >
                            <img src={ArrowLeftIcon} alt={''}/>
                        </a> : ''}
                        {_props.title ? <h2>{_props.title}</h2> : ''}
                    </div>
                    <div className="right">
                        
                        {
                            props.iconRight ?
                                (
                                    <a title="" href={void(0)}
                                       onClick={(e) => handleRightClick(e)}>
                                        <img src={HomeIcon} alt={''}/>
                                    </a>
                                )
                                : ''
                        }
                        {
                            props.iconCart ?
                                (
                                    <a style={{marginLeft:'17px'}} title="" href={void(0)}
                                       onClick={(e) => handleCartClick(e)}>
                                        <img src={CartIcon} alt={''}/>
                                    </a>
                                )
                                : ''
                        }
                    </div>
                </div>
            </div>
        </header>
    </>
}

export default IconHeader