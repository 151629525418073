import GoodList from "./pages/GoodList";
import GoodDetail from "./pages/GoodDetail";

const router = [
  {
    name: "GOODS_LIST",
    auth: false,
    path: "/goods-list",
    element: <GoodList />,
  },
  {
    name: "GOODS_DETAIL",
    auth: false,
    path: "/goods-detail/:id",
    element: <GoodDetail />,
  },
];
export default router;
