import { getProductNamePayment, numberFormat } from "core/utils/helper";
import CartItemComponent from "./CartItemComponent";
import { PAYMENT_METHOD } from "core/constants/Commons";
import ShowMoreLessComponent from "./ShowMoreLessComponent";
import { useEffect, useRef, useState } from "react";
import { installmentMonth } from "../constants/Time";

interface OrderCancelProps {
  // step: string | null;
  data: any;
  dataStep1: any;
}

const OrderCancelStep2 = ({ data, dataStep1 }: OrderCancelProps) => {
  const textContainerRef = useRef<any>(null);
  const [maxCharacters, setMaxCharacters] = useState(17);

  const mapped = (data?.items || []).map((element: any) => ({
    ...element,
    priceByQuantity: +element.qty * +element.prod_price,
    prod_name: (
      // <div className="product">
      //   <span className="product-name">{element.prod_name}</span>
      //   <span className="quantity">{element.qty}건</span>
      // </div>
      <div className="product">
        <span className="product-info">
          <span className="product-name">
            {element.prod_name.length > maxCharacters * 2
              ? `${element.prod_name.slice(0, maxCharacters * 2)}...`
              : element.prod_name}{" "}
          </span>
          <span className="quantity">{element.qty}건</span>
        </span>
      </div>
    ),
  }));

  const mappedName = (data?.items || []).map((element: any) => ({
    ...element,
    priceByQuantity: +element.qty * +element.prod_price,
  }));

  const [isExpanded, setIsExpanded] = useState(false);
  const [firstLine, setFirstLine] = useState<any>("");

  useEffect(() => {
    // const _first = text && text !== "" ? text.split("\n")[0] : ""
    // if (_first !== "") {
    //   setFirstLine(_first);
    // }
    const calculateMaxCharacters = () => {
      if (textContainerRef.current) {
        const containerWidth = textContainerRef.current.offsetWidth - 150;
        console.log(
          "textContainerRef.current.offsetWidth",
          textContainerRef.current.offsetWidth
        );

        console.log("containerWidth", containerWidth);

        const sampleText = "림";
        const sampleElement = document.createElement("span");

        sampleElement.style.position = "absolute";
        sampleElement.style.visibility = "hidden";
        sampleElement.textContent = sampleText;
        document.body.appendChild(sampleElement);

        let totalWidth = 0;
        let charCount = 0;

        while (totalWidth <= containerWidth) {
          totalWidth += sampleElement.offsetWidth;
          charCount++;
        }

        document.body.removeChild(sampleElement);
        console.log("charCount", charCount);

        setMaxCharacters(charCount - 2);
      }
    };

    window.addEventListener("resize", calculateMaxCharacters);

    calculateMaxCharacters();

    return () => {
      window.removeEventListener("resize", calculateMaxCharacters);
    };
  }, []);

  return (
    <div className="step2">
      <div className="cart-list">
        <div className="title">
          <label
            dangerouslySetInnerHTML={{
              __html: getProductNamePayment(mappedName),
            }}
          ></label>
        </div>
        <div className="list" ref={textContainerRef}>
          <CartItemComponent
            cartList={mapped || []}
            isCheckbox={false}
            hasAction={false}
          />
        </div>
      </div>

      <div className="reason-cancel">
        <div className="content">
          <ShowMoreLessComponent
            lengthRemove={4}
            text={dataStep1.id === 3 ? dataStep1?.content : dataStep1.reason}
          />
        </div>
      </div>
      <div className="order-cancel-info">
        <div className="content">
          <label htmlFor="">주문 취소 정보</label>
          <div className="item">
            <div className="item__left">주문 최소 금액:</div>
            <div className="item__right">{numberFormat(data.amount)}원</div>
          </div>
          <div className="item">
            <div className="item__left">기존 결제 방법:</div>
            <div className="item__right">
              {PAYMENT_METHOD.find(
                (item: any) => item.value === data.payment_method
              )?.label || ""}

              {dataStep1.isCheckBank === "PAID"
                ? ` (${dataStep1.bank_name})`
                : ""}
            </div>
          </div>

          {data.payment_method === "CARD" && (
            <div className="item">
              <div className="item_right">
                <span className="">{data?.payment_data?.card_number}</span> {" "}
                <span className="">
                  {data?.payment_data?.months &&
                  Object.keys(installmentMonth).includes(
                    data?.payment_data?.months
                  ) ? (
                    <>
                      <span className="space">|</span>{" "}
                      <span>
                        {installmentMonth[data?.payment_data?.months].label}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCancelStep2;
