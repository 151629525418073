import React, {useEffect, useState, FunctionComponent} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'modules/AppSettings/assets/scss/delete_member.scss'
import AppLayout from "core/layouts/AppLayout";
import IconHeader from "core/components/header/IconHeader";
import {RouterName} from "core/router";
import CCheckbox from "core/components/form-controls/CCheckbox";
import CDialog from "core/components/CDialog";
import API from "../../../core/networks/API";
import {API_OWN_BALANCE} from "../../Accumulate/api";
import {numberFormat, removeAllStorage} from "../../../core/utils/helper";
import {API_LEAVE_MEMBERSHIP} from "../api";
import * as action from "../../Auth/store/actions";
import {useDispatch} from "react-redux";



const DeleteMemberPage: FunctionComponent = (props: any) => {

    const navigate = useNavigate();
    const [stateDialog, setStateDialog] = useState(false)
    const [stateChecked, setStateChecked] = useState(false)

    const [balance, setBalance] = useState({
        loading: true,
        balance: 0
    })

    const dispatch = useDispatch();

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const contentAttention = document.getElementById('box-content-attention');
        const contentAttentionHeight = contentAttention ? contentAttention.clientHeight : 0;
        const boxContentPoint = document.getElementById('box-content-point');
        const boxContentPointHeight = boxContentPoint ? boxContentPoint.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - contentAttentionHeight - boxContentPointHeight
        const boxMain = document.getElementById('box-main');
        const boxMainHeight = viewportHeight - headerHeight - 80
        const agreeTerms = document.getElementById('agree-terms');
        if(agreeTerms) agreeTerms.style.height = contentHeight.toString() + 'px'
        if(boxMain) boxMain.style.height = boxMainHeight.toString() + 'px'
    }

    const fetchBalance = async () => {
        try{
            const res = await API.get(API_OWN_BALANCE, {});
            if(res?.data?.data?.total) setBalance({...balance, loading: false, balance: res.data.data.total})
        } catch (e: any) {
            console.log(e)
        }
    }

    const handleLogout = async (e: any) => {
        removeAllStorage();
        dispatch(action.logOutRequest(e));
        navigate(RouterName.LOGIN)
    }
    const leaveMember = async (e: any) => {
        setStateDialog(false)
        try{
            const res = await API.post(API_LEAVE_MEMBERSHIP, {});
            if(res){
                handleLogout(e)
            }
        } catch (e: any) {
            console.log(e)
        }
    }



    useEffect(() => {
        countSize()
        fetchBalance()
    }, [])

    const goBack = () => {
        navigate(-1)
    }
    const handleClick = (state:any) => {
        setStateDialog(true)
    }


    return (
        <AppLayout className={'delete-member-page'}>
            <IconHeader
                id={'main-header'}
                title={'회원탈퇴'}
                iconLeft={true}
                handleLeftClick={goBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
                // fixed={true}
            />
            {/*<div className={'box-line'}></div>*/}
            <div className={`delete-member-page__main`} id="box-main">
                <div className={'delete-member-page__main__content'}>

                    <div className='box-content'>
                        <div className={'box-content__attention'} id='box-content-attention'>
                            <h4>유의사항</h4>
                            <p>회원탈퇴 전에 꼭 확인하세요.</p>
                            <span>
                                1. 회원탈퇴 시 서비스 이용이 불가합니다. <br/><br/>

                                2. 거래정보가 있는 경우, 전자상거래 등에서의 소비자 보호에
                                관한 법률에 따라 계약 또는 청약철회에 관한 기록, 대금결제 및
                                재화 등의 공급에 관한 기록은 5년동안 보존됩니다.<br/><br/>

                                3. 보유하셨던 적립금은 탈퇴와 함께 삭제되며 환불되지 않습니다.<br/><br/>

                                4. 회원탈퇴 후 살구나무집 도시락 서비스에 입력하신 불편 문의 및 주문 문의는 삭제되지 않으며, 회원정보 삭제로 인해 작성자 본인을 확인할 수 없어 편집 및 삭제처리가 원천적으로 불가능 합니다. 불편 문의 및 주문 문의 삭제를 원하시는 경우에는
                                먼저 해당 게시물의 삭제를 요청하신 후 탈퇴를 신청하시기 바랍니다.<br/><br/>

                                5. 이미 결제가 완료된 건은 탈퇴로 취소되지 않습니다.<br/>
                            </span>
                        </div>
                        <div className={'box-content__point'} id='box-content-point'>
                            <span className={'box-content__point__text'}>보유 적립금</span>
                            <span className={'box-content__point__value'}>{numberFormat(balance.balance,0,true)}원</span>
                        </div>
                        <div className={'box-content__agree-terms'} id={'agree-terms'}>
                            <div className="content-checkbox">
                                <CCheckbox
                                    name={'agree_terms'}
                                    label={""}
                                    checked={stateChecked}
                                    handleChange={()=>{
                                        setStateChecked(!stateChecked)
                                    }}
                                />
                                <p>유의사항을 모두 확인하였으며, <br/>
                                    회원탈퇴 시 적립금 소멸에 동의합니다.</p>
                            </div>
                        </div>
                        <div className="d-grid d-grid-fixed-bottom box-content__submit">
                            <button disabled={!stateChecked} onClick={handleClick} className="cbtn btn-primary btn-outline-primary">계정 삭제하기</button>
                        </div>
                    </div>

                </div>
            </div>
            <CDialog
                id={"MD3"}
                show={stateDialog}
                closeBtn={"닫기"}
                submitBtn={'회원 탈퇴'}
                overlay={true}
                handleClose={()=>{
                    setStateDialog(false)
                }}
                handleSubmit={leaveMember}
                className={'modal-confirm-delete-member'}
            >
                <span>회원탈퇴 시 적립금이 소멸됩니다. <br/>
                    정말 동의하십니까?
                </span>
            </CDialog>
        </AppLayout>
    )
}


export default DeleteMemberPage
