import React from "react";
import LoginPage from "../modules/Auth/pages/LoginPage";
import ForgotPwPage from "../modules/Auth/pages/ForgotPwPage";
import SignUpPage from "../modules/Auth/pages/SignUpPage";
import TermsOfService from "../modules/StaticPage/pages/TermsOfServiceOld";
import PolicyUse from "../modules/StaticPage/pages/PrivacyPolicyOld";
import AccumulatePage from "../modules/Accumulate/pages/AccumulatePage";
import MainPage from "../modules/Main/pages/MainPage";
import PrivateRouter from "./components/System/PrivateRouter";
import PublicRouter from "./components/System/PublicRouter";
import {createBrowserRouter} from "react-router-dom";
import {config} from "./config/app";

let routers = [
    {
        name: "LOGIN",
        path: "/",
        element: <PrivateRouter><MainPage/></PrivateRouter>
    },
    {
        path: "*",
        element: <>NotFound</>
    }
]

let routerName = {}

const routerModules = require.context('./../modules/', true, /router.tsx$/);
const modules = require.context('./../modules/', true, /module.ts$/)
modules.keys().forEach((mpath: any) => {
    const moduleJson = modules(mpath).default
    if(moduleJson?.status?.router){
        const key = mpath.toString().replace('module.ts', 'router.tsx');
        const router = routerModules(key);
        // routers = [...routers, ...(router.default || router)];
        const rs = router.default || router
        rs.forEach((r: any) =>{
            routerName = {...routerName, [r.name]: r.path}
            let el = r.auth ? <PrivateRouter>{r.element}</PrivateRouter> : <PublicRouter>{r.element}</PublicRouter>
            routers = [...routers, {
                name: r.name,
                element: el,
                path: r.path
            }]
        })

    }
})

export const RouterName: any = routerName

export const Router =  routers

export const AppRouter = createBrowserRouter(Router, {basename: config.basename});
