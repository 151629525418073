import {useEffect, useState} from "react";

const CSwitcher = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        value: props.value || true,
        handleSwitch: props.handleSwitch || {},
    })
    const [checked, setChecked] = useState(props.value);
    useEffect(() => {
        setChecked(props.value)
    }, [props]);
    const handleSwitch = (e: any) => {
        props.handleSwitch(!checked)
    }

    return <>
        <label className={`switch c-switcher ${checked ? '' : 'unchecked'} ${_propsState.className}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => handleSwitch(e)}
            />
            <span className="slider round"></span>
        </label>
    </>
}

export default CSwitcher