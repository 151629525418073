import {
  AddCartAction,
  AddCartFailedAction,
  AddCartSuccessAction,
  DecreaseQuantityAction,
  DeleteCartAction,
  DeleteCartFailedAction,
  DeleteCartSuccessAction,
  IncreaseQuantityAction,
  ListCartAction,
  ListCartFailedAction,
  ListCartSuccessAction,
  ResetCartErrorAction,
  UpdateCartFailedAction,
  UpdateOrCreateCartAction,
  UpdateQuantityAction,
  UpdateStatusProductAction,
} from "../interfaces/Carts";
import {
  CancelOrderRequestAction,
  CancelOrderSuccessAction,
  GetListOrderHistoryRequestAction,
  UpdateReloadPageListAction,
} from "../interfaces/OrderHistory";
import {
  ADD_CART,
  ADD_CART_FAILED,
  ADD_CART_SUCCESS,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  DECREASE_QUANTITY,
  DELETE_CART,
  DELETE_CART_FAILED,
  DELETE_CART_SUCCESS,
  GET_LAST_ORDER,
  GET_LAST_ORDER_FAILED,
  GET_LAST_ORDER_SUCCESS,
  GET_LIST_CART,
  GET_LIST_CART_FAILED,
  GET_LIST_CART_SUCCESS,
  GET_LIST_ORDER_HISTORY_REQUEST,
  GET_STORES,
  GET_STORES_FAILED,
  GET_STORES_SUCCESS,
  INCREASE_QUANTITY,
  RESET_CART_ERRORS,
  RESET_DATA_PAYMENT,
  UPDATE_CART_FAILED,
  UPDATE_DATA_PAYMENT,
  UPDATE_OR_CREATE_CART,
  UPDATE_QUANTITY,
  UPDATE_RELOAD_PAGE_LIST,
  UPDATE_STATUS_PRODUCT,
} from "./types";
import { DataCarts } from "../interfaces/Types";
import {
  GetLastOrderAction,
  GetLastOrderFailedAction,
  GetLastOrderSuccessAction,
  GetStoresAction,
  GetStoresFailedAction,
  GetStoresSuccessAction,
  ResetDataPaymentAction,
  UpdateDataPaymentAction,
} from "../interfaces/Payments";

export const addCart = (payload: DataCarts[]): AddCartAction => ({
  type: ADD_CART,
  payload,
});

export const addCartSuccess = (payload: DataCarts[]): AddCartSuccessAction => ({
  type: ADD_CART_SUCCESS,
  payload,
});

export const addCartFailed = (payload: DataCarts[]): AddCartFailedAction => ({
  type: ADD_CART_FAILED,
  payload,
});

export const listCarts = (payload: any): any => ({
  type: GET_LIST_CART,
  payload,
});

export const listCartSuccess = (payload: any): any => ({
  type: GET_LIST_CART_SUCCESS,
  payload,
});

export const listCartFailed = (payload: any): any => ({
  type: GET_LIST_CART_FAILED,
  payload,
});

export const increaseQuantity = (payload: any): IncreaseQuantityAction => ({
  type: INCREASE_QUANTITY,
  payload,
});

export const decreaseQuantity = (payload: any): DecreaseQuantityAction => ({
  type: DECREASE_QUANTITY,
  payload,
});

export const updateQuantity = (payload: any): UpdateQuantityAction => ({
  type: UPDATE_QUANTITY,
  payload,
});

export const updateCartFailed = (payload: any): UpdateCartFailedAction => ({
  type: UPDATE_CART_FAILED,
  payload,
});

export const updateOrCreateCart = (payload: any): UpdateOrCreateCartAction => ({
  type: UPDATE_OR_CREATE_CART,
  payload,
});

export const updateStatusProduct = (
  payload: any
): UpdateStatusProductAction => ({
  type: UPDATE_STATUS_PRODUCT,
  payload,
});

export const deleteCart = (payload: any): DeleteCartAction => ({
  type: DELETE_CART,
  payload,
});

export const deleteCartSuccess = (payload: any): DeleteCartSuccessAction => ({
  type: DELETE_CART_SUCCESS,
  payload,
});

export const deleteCartFailed = (payload: any): DeleteCartFailedAction => ({
  type: DELETE_CART_FAILED,
  payload,
});

//OrderHistory
export const getListOrderHistoryRequest = (
  payload: any
): GetListOrderHistoryRequestAction => ({
  type: GET_LIST_ORDER_HISTORY_REQUEST,
  payload,
});

export const cancelOrderRequest = (
  payload: any
): CancelOrderRequestAction => ({
  type: CANCEL_ORDER_REQUEST,
  payload,
});

export const cancelOrderSuccess = (
  payload: any
): CancelOrderSuccessAction => ({
  type: CANCEL_ORDER_SUCCESS,
  payload,
});

export const updateReloadPageListRequest = (
  payload: any
): UpdateReloadPageListAction => ({
  type: UPDATE_RELOAD_PAGE_LIST,
  payload,
});

//payment
export const updateDataPayment = (payload: any): UpdateDataPaymentAction => ({
  type: UPDATE_DATA_PAYMENT,
  payload,
});

export const resetDataPayment = (payload: any): ResetDataPaymentAction => ({
  type: RESET_DATA_PAYMENT,
  payload,
});

export const getStores = (payload: any): GetStoresAction => ({
  type: GET_STORES,
  payload,
});

export const getStoresSuccess = (payload: any): GetStoresSuccessAction => ({
  type: GET_STORES_SUCCESS,
  payload,
});

export const getStoresFailedSuccess = (payload: any): GetStoresFailedAction => ({
  type: GET_STORES_FAILED,
  payload,
});

export const getLastOrder = (payload: any): GetLastOrderAction => ({
  type: GET_LAST_ORDER,
  payload,
});

export const getLastOrderSuccess = (payload: any): GetLastOrderSuccessAction => ({
  type: GET_LAST_ORDER_SUCCESS,
  payload,
});

export const getLastOrderFailed= (payload: any): GetLastOrderFailedAction => ({
  type: GET_LAST_ORDER_FAILED,
  payload,
});

export const resetCartError = (payload: any): ResetCartErrorAction => ({
  type: RESET_CART_ERRORS,
  payload,
});

export type AddCartActionTypes =
  | ListCartAction
  | ListCartSuccessAction
  | ListCartFailedAction
  | AddCartAction
  | AddCartSuccessAction
  | AddCartFailedAction
  | DeleteCartAction
  | IncreaseQuantityAction
  | DecreaseQuantityAction
  | UpdateQuantityAction
  | UpdateOrCreateCartAction
  | UpdateCartFailedAction
  | UpdateStatusProductAction
  | DeleteCartSuccessAction
  | DeleteCartFailedAction
  | GetStoresAction
  | GetStoresSuccessAction
  | GetStoresFailedAction
  | GetLastOrderAction
  | GetLastOrderSuccessAction
  | GetLastOrderFailedAction
  | ResetCartErrorAction;
