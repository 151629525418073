import IconHeader from "core/components/header/IconHeader";
import { RouterName } from "core/router";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppLayout from "core/layouts/AppLayout";
import CFormControl from "core/components/form-controls/CFormControl";
import PlusIcon from "core/assets/images/icon/plus.svg";
import { memo, useEffect, useState } from "react";
import AddressSearch from "../components/AddressSearch";
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { API_CREATE_ADDRESS, API_LIST_ADDRESS, API_UPDATE_ADDRESS } from "../api";
import API from "../../../core/networks/API";
import CPhoneInput from "../../../core/components/form-controls/CPhoneInput";
import { useSelector } from "react-redux";
import { hideBottomButtonInputFocus, phoneNumberFormat } from "../../../core/utils/helper";
import { toast } from "react-toastify";
import { MESSAGE } from "core/constants/Commons";
import CCheckbox from "core/components/form-controls/CCheckbox";
import { useDataPayment, useUpdateDataPayment } from "modules/Orders/hooks/usePayment";

const AddressCreateForm = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const redux = useSelector((state: any) => state.Auth)
    const urlParams = useParams();
    const [, dataOrders] = useDataPayment({});
    const [handleUpdate, ] = useUpdateDataPayment({})
    const [addressFormData, setAddressFormData] = useState<any>({
        loading: false,
        data: {
            address: "",
            detail_address: "",
            shipping_address_name: "",
            shipping_name: "",
            shipping_phone: redux?.user?.phone,
            shipping_contact: "",
            default: "N",
            zipcode: '',
        },
        errors: {},
        editId: urlParams.id,
        valid: false
    })
    const [dialogs, setDialogs] = useState({
        addressSearch: false
    })
    
    const handleGoBack = () => {
        navigate(-1)
    }

    console.log('dataOrders', dataOrders?.shippingAddress);
    
    console.log('location?.state', location?.state);


    const fetchData = async () => {
        try {
            const res = await API.get(API_LIST_ADDRESS + '/' + urlParams.id, {})
            console.log(res)
            if (res.data.status) {
                setAddressFormData({
                    ...addressFormData,
                    data: {
                        address: res.data.data?.address || '',
                        detail_address: res.data.data?.detail_address || '',
                        shipping_address_name: res.data.data?.shipping_address_name || '',
                        shipping_name: res.data.data?.shipping_name || '',
                        shipping_phone: res.data.data?.shipping_phone || '',
                        shipping_contact: res.data.data?.shipping_contact || '',
                        default: res.data.data?.default,
                        zipcode: res.data.data?.zipcode || '',
                    }
                })
            }
        } catch (e) {
            console.log(e)
        }
    }


    const handleInputChange = (e: any) => {
        // console.log(6, e.target.name, e.target.value)
        setAddressFormData({
            ...addressFormData,
            data: {
                ...addressFormData.data,
                [e.target.name]: e.target.value
            }
        })

    }

    const handlePhoneChange = (name: string, value: string) => {
        console.log(name, value)
        console.log(73, addressFormData.data)
    }

    const open = useDaumPostcodePopup('https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');

    const handleSelectAddress = (data: any) => {
        console.log(48, data)
        setAddressFormData({ ...addressFormData, data: { ...addressFormData.data, address: data.address, zipcode: data.zonecode } })

    }

    const formValidate = () => {
        let valid = true
        if (!addressFormData.data.address || !addressFormData.data.detail_address || !addressFormData.data.shipping_address_name || !addressFormData.data.shipping_name || !addressFormData.data.shipping_phone) valid = false
        setAddressFormData({ ...addressFormData, valid: valid })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            setAddressFormData({ ...addressFormData, loading: true })
            let _formData = { ...addressFormData }

            console.log(addressFormData, 'addressFormData');

            _formData.data.shipping_phone = addressFormData.data.shipping_phone.replace(/-/g, '')
            _formData.data.shipping_contact = addressFormData.data.shipping_contact.replace(/-/g, '')


            if (!addressFormData.editId) {
                const res = await API.post(API_CREATE_ADDRESS, _formData.data)
                console.log(res, 'create')
                setAddressFormData({ ...addressFormData, loading: false })
            } else {
                const res = await API.post(API_UPDATE_ADDRESS.replace(/{ID}/g, _formData.editId), { ..._formData.data, _method: 'PATCH' })

                setAddressFormData({ ...addressFormData, loading: false })
                if (res?.data?.data?.id) {
                    handleUpdate({...dataOrders, shippingAddress: res?.data?.data})
                }
            }
            localStorage.setItem('msg_submit_success',MESSAGE.ADDRESS_CREATE_SUCCESS)
            // toast(MESSAGE.ADDRESS_CREATE_SUCCESS)
            navigate(RouterName.ADDRESS_BOOK, {
                state: {
                    ...location?.state,
                }
            })

        } catch (e: any) {
            console.error(e)
            setAddressFormData({ ...addressFormData, loading: false })
        }
    }


    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        let spaceAdd = viewportHeight > 740 ? -50 : 50
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight + spaceAdd
        const listEl = document.getElementById('list-panel');
        if (listEl) listEl.style.height = contentHeight.toString() + 'px'
    }

    useEffect(() => {
        let msg_submit_success = localStorage.getItem('msg_submit_success')
        if(msg_submit_success){
            localStorage.removeItem('msg_submit_success')
        }
        hideBottomButtonInputFocus()
        countSize()
        if (urlParams.id) fetchData()
    }, [])
    useEffect(() => {
        if(redux?.user?.phone && redux?.user?.phone != undefined) {
            setAddressFormData((p: any) => ({
                ...p,
                data: {
                    ...p.data,
                    shipping_phone: redux?.user?.phone
                }
            }))
        }
    }, [redux?.user?.phone])

    useEffect(() => {
        formValidate()
    }, [addressFormData.data])

    const onChange = (e: any) => {
        console.log(e.target.checked, 'eeeee');
        setAddressFormData((p: any) => ({
            ...p,
            data: {
                ...p.data,
                [e.target.name]: e.target.checked ? 'Y' : 'N'
            }
        }))
    }

     //handle go back from native
     (window as any).backFromDeviceAndroid = function() {
        //code webview
        handleGoBack()
    }

    return (
        <AppLayout className={"address-page"}>
            <IconHeader
                title={'배송지 설정'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />
            <form id={"list-panel"} onSubmit={handleSubmit} method={!addressFormData.editId ? 'POST' : 'PATCH'}>
                <div className="row mb-3 form-addrees ">
                    <div className="">
                        <div className="c-form-control">
                            <label className="c-label">배송지 (주소)<span className="required"> *</span></label>
                            <div className="c-input-button-group group-input-address">
                                <CFormControl
                                    disabled={true}
                                    label={""}
                                    desc={""}
                                    type={"input"}
                                    placeholder={""}
                                    name={'zipcode'}
                                    required={true}
                                    handleChange={handleInputChange}
                                    value={addressFormData?.data?.zipcode}
                                    // maxLength={15}
                                    handleShowHideStatePassword={() => { }}
                                    showIcon={false}
                                    className='form-control-address'
                                />
                                <button type="button" onClick={() => { setDialogs({ ...dialogs, addressSearch: true }) }}
                                    className="cbtn btn-primary btn-outline-primary">우편번호 찾기</button>
                            </div>
                            <CFormControl
                                disabled={true}
                                label={""}
                                desc={""}
                                type={"input"}
                                placeholder={""}
                                name={'address'}
                                required={true}
                                handleChange={handleInputChange}
                                value={addressFormData?.data?.address}
                                // maxLength={15}
                                handleShowHideStatePassword={() => { }}
                                showIcon={false}
                                className='form-control-address-detail-kakao'
                            />
                            <CFormControl
                                disabled={addressFormData?.data?.address ? false : true}
                                label={""}
                                desc={""}
                                type={"input"}
                                placeholder={"상세주소를 입력해주세요."}
                                name={'detail_address'}
                                required={true}
                                handleChange={handleInputChange}
                                value={addressFormData?.data?.detail_address}
                                maxLength={40}
                                handleShowHideStatePassword={() => { }}
                                showIcon={false}
                                className='form-control-address-detail'
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="row mb-3">
                    <div className="col" onClick={() => { setDialogs({ ...dialogs, addressSearch: true }) }}>
                        <CFormControl
                            label={"주소"} placeholder={"주소를 입력해주세요."}
                            type={"input"} required={true} disabled={false} readOnly={true}
                            value={addressFormData?.data?.address} name={'address'} className={""}
                            handleChange={handleInputChange}
                            error={addressFormData?.errors?.address}
                        // error={"This is the error message"}
                        />
                    </div>
                </div> */}
                {/* <div className="row mb-3">
                    <div className="col">
                        <CFormControl
                            label={"상세주소"} placeholder={"상세주소를 입력해주세요. (아파트/동/호)"}
                            type={"input"} required={true} disabled={false} readOnly={false}
                            maxLength={30}
                            value={addressFormData?.data?.detail_address} name={'detail_address'} className={""}
                            handleChange={handleInputChange}
                            error={addressFormData?.errors?.detail_address}
                        // error={"This is the error message"}
                        />
                    </div>
                </div> */}
                <div className="row mb-3">
                    <div className="col">
                        <CFormControl
                            label={"배송지명"} placeholder={"배송지 이름을 입력해주세요."}
                            type={"input"} required={true} disabled={false} readOnly={false}
                            maxLength={20}
                            value={addressFormData?.data?.shipping_address_name} name={'shipping_address_name'} className={""}
                            handleChange={handleInputChange}
                            error={addressFormData?.errors?.shipping_address_name}
                        // error={"This is the error message"}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <CFormControl
                            label={"받으시는 분"} placeholder={"받으시는 분의 이름을 입력해주세요."}
                            type={"input"} required={true} disabled={false} readOnly={false}
                            maxLength={20}
                            value={addressFormData?.data?.shipping_name} name={'shipping_name'} className={""}
                            handleChange={handleInputChange}
                            error={addressFormData?.errors?.shipping_name}
                        // error={"This is the error message"}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <CPhoneInput
                            label={"휴대폰 번호"} placeholder={"받으시는 분의 휴대폰 번호를 입력해주세요."} required={true} disabled={false} readOnly={false}
                            value={addressFormData?.data?.shipping_phone}
                            name={'shipping_phone'} className={""}
                            handleChange={handleInputChange}
                        // allPhoneFormat={true}
                        // error={formData?.errors?.shipping_phone}
                        // error={"This is the error message"}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <CPhoneInput
                            label={"추가 연락처"} placeholder={"추가 연락처를 입력해주세요."}
                            type={"number"} required={false} disabled={false} readOnly={false}
                            name={'shipping_contact'} className={""}
                            value={addressFormData?.data?.shipping_contact}
                            handleChange={handleInputChange}
                        // allPhoneFormat={true}
                        // error={formData?.errors?.shipping_contact}
                        // error={"This is the error message"}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <CCheckbox
                            label={"기본 배송지로 저장"}
                            checked={addressFormData?.data?.default === 'Y'}
                            name={`default`}
                            handleChange={onChange}
                        />
                    </div>
                </div>
                <div className="bottom-btn">
                    <div className="d-grid">
                        <button disabled={!addressFormData.valid || addressFormData.loading} type="submit" className="cbtn btn-primary">완료</button>
                    </div>
                </div>
            </form>
            <AddressSearch open={dialogs.addressSearch}
                handleClose={() => { setDialogs({ ...dialogs, addressSearch: false }) }}
                handleSelect={handleSelectAddress}
            />
        </AppLayout>
    )
}

export default AddressCreateForm