import {useEffect, useState} from "react";

import ArrowRightIcon from 'core/assets/images/icon_arrow_right.svg';
const CListItem = (props: any) => {
    const [_propsState, setProps] = useState({
        id: props.id || '',
        className: props.className || '',
        title: props?.title || '',
        time: props.time || '',
        icon: props.icon || false,
        handleClick: props.handleClick || {},
        type: props?.type || 1,
        contentChild: props.contentChild || '',
    })

    const handleClick = (e: any) => {
        props.handleClick(e)
    }

    useEffect(() => {
        setProps(props);
    }, [props]);

    return <>
        <div className={`c-list-item ${_propsState.className}`}  onClick={(e) =>handleClick(e)}>

            {!props?.type || props?.type == 1 ? <div className={`c-list-item__content ${_propsState.time ? ' has-time' : ''}`}>
                <div className={`c-list-item__content__text `}>
                    <p className='item-title'>{_propsState.title}</p>
                    {_propsState.time ? <span className='item-time'>{_propsState.time}</span> : ''}
                </div>
                {_propsState.icon ? <img className={`c-list-item__content__icon `} src={ArrowRightIcon} alt={''}/> : ''}
            </div> : _propsState.contentChild}
        </div>
    </>
}

export default CListItem