import React, {useEffect, useRef, useState} from 'react';
interface InfiniteScrollProps {
    id: string,
    className: string,
    fetchMoreData: () => void;
    filter: any,
    data: any,
    children: React.ReactNode;
    bottom: number;
    loadingElement: React.ReactNode;
}
const InfinityScrollContainer = (props: InfiniteScrollProps) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [_props, setProps] = useState<any>(props)

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            window.localStorage.scrollY = scrollContainer.scrollTop;
            // console.log(17, scrollContainer.scrollTop , scrollContainer.clientHeight, scrollContainer.scrollTop + scrollContainer.clientHeight, scrollContainer.scrollHeight )
            const isAtBottom = scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - (props.bottom || 1);
            // console.log(19, isAtBottom)
            setProps({..._props, reachEnd: isAtBottom});
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(()=>{
        // console.log(39, props)
        setProps({...props, reachEnd: false})
    }, [props])

    useEffect(()=>{
        if(_props.reachEnd && !props.data.loading) {
            console.log("REACH BOTTOM")
            props.fetchMoreData()
        }
    }, [_props])

    return (
        <div className={_props.className} id={_props.id}  ref={scrollContainerRef}>
            {props.children}
            {props.data?.loading ? _props.loadingElement : <></>}
        </div>
    )
}

export default InfinityScrollContainer;