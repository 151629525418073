import IconHeader from "core/components/header/IconHeader";
import AppLayout from "core/layouts/AppLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import  '../assets/scss/paymentFailed.scss'
import { RouterName } from "core/router";
import { useEffect, useState } from "react";
import { useFetch } from "core/hooks/useFetch";
import { API_ORDER_HISTORY, API_PAYMENT } from "../api";
import { useUpdateDataPayment, useUpdatePaymentStatus } from "../hooks/usePayment";
import { DELIVERY_DATA, PAYMENT_METHOD } from "core/constants/Commons";
import { useGetCarts } from "../hooks/useCarts";
import { updateOrCreateCart, updateStatusProduct } from "../store/actions";
import { useDispatch } from "react-redux";
import React from "react";
import API from "core/networks/API";

const PaymentFailed = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataCarts, handleGetCarts] = useGetCarts({});
  const [loading, handleUpdatePaymentStatus] = useUpdatePaymentStatus(); 
  const [paramsDetail, setParamsDetail] = useState<any>({
    id: searchParams.get('order_id') || null,
    'relation[]': ['creator', 'items', 'shippingApp']
  });
  console.log('paramsDetail', paramsDetail);
  
  const {
    data: orderDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({ url: API_ORDER_HISTORY.GET_DETAIL, options: paramsDetail });
  const [handleUpdate, handleReset] = useUpdateDataPayment({});

  useEffect(() => {
    handleGetCarts();
    if (searchParams.get('cancelPayment') === '1') {
      handleUpdatePaymentStatus({
        order_id: searchParams.get('order_id'),
        status: "FAILED"
      })
    }
    //call api update status payment
  }, [])
  
  useEffect(() => {
    //update redux 
    console.log(orderDetail, 'orderDetail');
    
  }, [orderDetail])
  const handleClose = () => {
    if (handleReset) {
      handleReset({});
    }
    dispatch(updateStatusProduct({dataCarts, isSelected: true}));
    navigate(RouterName.MAIN)
  }

  (window as any).backFromDeviceAndroid = function() {
    handleClose()
  }

  const handlePayment = () => {
    //handlePayment
    //if items.lenght === 1
    if (orderDetail.data?.id) {
      
      const _shippingTime = orderDetail.data?.shipping_app?.shipping_time.split(" ");
      const _shippingHour = _shippingTime[1].split(":");
      const _paymentMethod = PAYMENT_METHOD.find((item: any) => item.value === orderDetail.data?.payment_method);
      
      const _shippingNote = DELIVERY_DATA.find((item:any) => item.label === orderDetail.data?.shipping_app?.shipping_note);
      console.log('_shippingNoteContent', _shippingNote);
      

      let dataUpdate = {
        orderId: orderDetail.data?.id,
        buyerName: orderDetail.data?.creator?.name || "",
        phone:  orderDetail.data?.creator?.phone || "",
        shippingTime: {
          date: _shippingTime[0],
          hour: _shippingHour[0],
          minute: _shippingHour[1],
        },
        paymentMethod: _paymentMethod,
        depositorName: "",
        policyPayment: false,
        shippingNoteContent: _shippingNote && _shippingNote?.id ? _shippingNote?.label : orderDetail.data?.shipping_app?.shipping_note,
        shippingNoteId: _shippingNote && _shippingNote?.id ? _shippingNote.id : 7,
        dataProduct: [],
        shippingAddress: {},
        stores: [],
        lastOrder: orderDetail.data,
        type: 'CART_PAYMENT'
      } as any;
      // handleUpdate(dataUpdate)
      if (orderDetail.data?.items.length > 1) {
        dispatch(updateStatusProduct({dataCarts:orderDetail.data?.items, isSelected: false}));
        // dispatch(updateOrCreateCart({ dataCarts: orderDetail.data?.items, isSelected: false }));
      }else{
        let _dataProduct = {
          ...orderDetail.data?.items[0],
          quantity: orderDetail.data?.items[0].qty,
          priceByQuantity: orderDetail.data?.items[0].qty * (+orderDetail.data?.items[0].prod_price),
        }
        console.log(_dataProduct,orderDetail.data?.items[0], '_dataProduct');
        dataUpdate = {...dataUpdate, type: 'DETAIL_PAYMENT', dataProduct: [{..._dataProduct}]}
      }
      handleUpdate(dataUpdate)
      navigate(RouterName.PAYMENT)
    }else{

      navigate(RouterName.PAYMENT)
    }
  }

  const showMethodPayment = (method: any) => {
    // return PAYMENT_METHOD.filter((item: any) => item.value === method)
  }

  const method = PAYMENT_METHOD.find((item: any) => item.value === orderDetail?.data?.payment_method)

  return (
		<AppLayout className={"payment-failed"}>
      <React.StrictMode>
			<main>
        <div className="content">
          <div className="content__reason">
            <p>결제에 실패하여</p>
            <p>주문을 완료하지 못했습니다.</p>
          </div>
          <div className="content__method">
          <p className="" style={{color: '#ff773a'}}>
            {
              !orderDetail?.data?.id ? PAYMENT_METHOD[2].label : method?.label
            }
            
            {
              searchParams.get('reason') && `[${searchParams.get('reason')}]`
            }
            </p>
            {
              orderDetail?.data?.id && <p>주문번호 {orderDetail?.data?.id || ""}</p>
            }
          </div>
          <div className="content__button">
            <div className="item row">
                <div className="col-6 item__left">
                  <div className={`d-grid disabled`} >
                    <button
                      disabled={loadingDetail}
                      className="cbtn btn-primary"
                      onClick={() => handleClose()}
                    >
                      닫기
                    </button>
                  </div>
                </div>
                <div className="col-6 item__right">
                  <div className={`d-grid`}>
                    <button
                      disabled={loadingDetail}
                      className="cbtn btn-primary"
                      onClick={() => handlePayment()}
                    >
                      다시 주문하기
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
			</main>
      </React.StrictMode>
	  </AppLayout>
  )
}

export default PaymentFailed;