import SkeletonComponent from "core/components/Skeleton/skeleton";
import "./../assets/scss/categorieslist.scss";

interface GoodLoading {
  className: string;
}

const GoodListLoadingComponent = ({ className }: GoodLoading) => {
  return (
    // <div className={`good-list-loading`}>
    <>
      <div className="good-list-loading__item">
        <SkeletonComponent
          width={"100%"}
          className={"loading-img"}
          count={1}
          height={150}
          circle={false}
        />
        <SkeletonComponent
          width={"100%"}
          className={"loading-title"}
          count={1}
          height={16}
          circle={false}
        />
        <SkeletonComponent
          width={"30%"}
          className={"loading-price"}
          count={1}
          height={10}
          circle={false}
        />
      </div>
      <div className="good-list-loading__item">
        <SkeletonComponent
          width={"100%"}
          className={"loading-img"}
          count={1}
          height={150}
          circle={false}
        />
        <SkeletonComponent
          width={"100%"}
          className={"loading-title"}
          count={1}
          height={16}
          circle={false}
        />
        <SkeletonComponent
          width={"30%"}
          className={"loading-price"}
          count={1}
          height={10}
          circle={false}
        />
      </div>
      </>
    // </div>
    // <>
    //   <div className="good-list-loading__item">
    //     <SkeletonComponent
    //       width={160}
    //       className={"loading-img"}
    //       count={1}
    //       height={150}
    //       circle={false}
    //     />
    //     <SkeletonComponent
    //       width={160}
    //       className={"loading-title"}
    //       count={1}
    //       height={16}
    //       circle={false}
    //     />
    //     <SkeletonComponent
    //       width={"30%"}
    //       className={"loading-price"}
    //       count={1}
    //       height={10}
    //       circle={false}
    //     />
    //   </div>
    //   <div className="good-list-loading__item">
    //     <SkeletonComponent
    //       width={160}
    //       className={"loading-img"}
    //       count={1}
    //       height={150}
    //       circle={false}
    //     />
    //     <SkeletonComponent
    //       width={160}
    //       className={"loading-title"}
    //       count={1}
    //       height={16}
    //       circle={false}
    //     />
    //     <SkeletonComponent
    //       width={"30%"}
    //       className={"loading-price"}
    //       count={1}
    //       height={10}
    //       circle={false}
    //     />
    //   </div>
    // </>
  );
};

export default GoodListLoadingComponent;
