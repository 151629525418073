
import { useFetch } from "core/hooks/useFetch";
import { RouterName } from "core/router";
import { numberFormat } from "core/utils/helper";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_PRODUCT } from "../api";
import { filterProduct } from "../constants/FilterProduct";
import { useLists, useUpdateReload } from "../hooks/useLists";
import { useCountSize } from "core/hooks/useCountSize";
import {
  FetchCategoriesProps,
  FetchProductProps,
} from "../interfaces/FetchProductProps";
import AppLayout from "core/layouts/AppLayout";
import BottomNavigation from "core/layouts/BottomNavigation";
import InfinityScrollContainer from "core/layouts/InfinityScrollContainer";
import CategoriesComponent from "../components/CategoriesComponent";
import DropdownComponent from "../components/DropdownComponent";
import GoodListLoadingComponent from "../components/GoodListLoading";
import goodSearchIcon from "../assets/icon/goodSearchIcon.svg";
import "./../assets/scss/goodlistpage.scss";
import AutoComplete from "core/components/Autocomplete/AutoComplete";
import CEmptyIcon from "core/components/CEmptyIcon";
import ProductListItem from "core/components/ProductListItem";
import MainHeader from "core/components/header/MainHeader";

const GoodList = (props: any) => {
  const navigate = useNavigate();
  const [params, setParams] = useState<FetchProductProps>({
    order_by: "created_at",
    order_dir: "desc",
    query_field: "name",
    query_value: "",
    page: 0,
    page_size: 0,
    state: "AVAILABLE",
    category_id: "all",
    'relation[]': 'category'
  });

  const [paramsCountSize, setParamsCountSize] = useState<any>(false);
  useCountSize(paramsCountSize)
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState<any>({
    order_by: 'featured_state',
    order_dir: 'asc',
    query_field: "",
    query_value: "",
    page: 1,
    category_id: "all",
    reload: 1
  });
  const [loadingList, dataProductList, listParams, errors] = useLists(filter); 
  
  const [paramCategories] = useState<FetchCategoriesProps>({
    order_by: "order",
    order_dir: "asc",
    page: 0,
    page_size: 0,
    state: "Y",
  });

  const {
    data: dataAllProduct,
    loading,
    error,
  } = useFetch({ url: API_PRODUCT.GET_PRODUCT, options: params });
  
  const {
    data: dataCategories,
    loading: loadingCategories,
    error: errorCategories,
  } = useFetch({ url: API_PRODUCT.GET_CATEGORIES, options: paramCategories });

  const updateReload = useUpdateReload();
  
  const resetScrollY = () => {
    window.localStorage.scrollY = 0;
    let el:  any = document.getElementById('list-panel');
    el.scrollTo(0, 0)
    updateReload({reload: 0})
  }

  const resetScrollX = () => {
    window.localStorage.scrollX = 0;
    let elCategory:  any = document.getElementById('category-scroll');
    elCategory.scrollTo(0, 0)
  }

  useEffect(() => {
    if (+listParams.reload === 1) {
      let elCategory:  any = document.getElementById('category-scroll');
      let el:  any = document.getElementById('list-panel');

      setTimeout(() => {
        elCategory.scrollTo(window.localStorage.scrollX, 0)
        el.scrollTo(0, window.localStorage.scrollY)
      }, 300);

      setFilter({
        ...listParams,
        reload: +listParams.reload,
      })
      setParams({
        ...params,
        query_field: listParams.query_field,
        query_value: listParams.query_value,
      })
    }else{
      resetScrollY();
      setFilter({
        ...filter,
        reload: 0,
      })
    }

    (window as any).webViewCallGetListPath(['/goods-detail'])
  }, [])

  const onSelectCategory = (item: any) => {
    console.log(filter, 'filter');
    resetScrollY();
    setFilter({
      ...filter,
      category_id: item.id,
      page: 1,
      reload: 0
    });
    setParams({
      ...params,
      query_field: 'name',
      query_value: filter.query_value,
    })
  };

  const onSelectFilter = (item: any) => {
    resetScrollY();
    setFilter({
      ...filter,
      page: 1,
      order_by: item.order_by,
      order_dir: item.order_dir,
      reload: 0
    });
  };

  const handChangeItemProduct = () => {};

  const goToOrderDetail = (item: any) => {
    updateReload({ reload: 1})
    navigate(RouterName.GOODS_DETAIL.replace(":id", item.id));
  };

  const loadOptions = (inputValue: string, callback: ([]) => void) => {
    if (inputValue) {
      setParams({
        ...params,
        query_field: 'name', 
        query_value: inputValue,
      });
    }
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };

  const onChange = (e: any) => {};

  const onSelected = (e: any) => {
    updateReload({reload: 1})
    navigate(RouterName.GOODS_DETAIL.replace(":id", e.id));
  };

  const handleClickSearchGood = (value: string | undefined) => {
    resetScrollY();
    resetScrollX();
    setFilter({
      order_by: 'featured_state',
      order_dir: 'asc',
      category_id: "all",
      query_field: "name",
      query_value: value,
      page: 1,
      reload: 0
    });
  };

  const handleLoadMore = () => {
    if(filter.page !== undefined && filter.page < dataProductList.totalPage && !loadingList){
      updateReload({reload: 0})
      setFilter({...filter, reload: 0, page: filter.page+1})
    }
  }

  useEffect(()=>{
    setParamsCountSize(loadingList)
  }, [dataProductList, loadingList])

  const renderProduct = (data: any) => {
    if (data.data && data.data.length > 0) {
      return data.data.map((item: any, key: number) => (
            <ProductListItem
              name={`${item?.name || ''}`}
              price={numberFormat(+item?.price)}
              quantity={item?.quantity}
              handleChange={handChangeItemProduct}
              hasAction={false}
              dataItem={item}
              image={item?.images[0]?.path_original || item?.featured_image}
              key={key}
              type={1}
              handleClick={() => goToOrderDetail(item)}
            />
          ))
    } else {
      if (!loadingList && filter.page <= 1) {
        return (
          <CEmptyIcon
            className={"good-list-empty list-product-order"}
            icon={goodSearchIcon}
            text={"검색 결과 없음"}
            description={"검색을 다시 시도해보세요."}
          />
        );
      }
    }
  };

  
  return (
    <AppLayout className={"good-list-page"}>
      <div className="good-list-page__header" id="summary-panel">
        <div className="good-list-page__header__top">
          <MainHeader
            title={"주문하기"}
            iconRight={true}
          />
          <div className="good-search-group">
            <AutoComplete
              id={"js-goods_search"}
              name={"goods_search"}
              value={params.query_value}
              onSelected={onSelected}
              onChange={onChange}
              errors={""}
              disabled={false}
              placeholder={"메뉴를 검색해 주세요."}
              className={"goods-search"}
              loadOptions={loadOptions}
              dataArr={dataAllProduct?.data || []}
              handleInputChange={handleInputChange}
              loading={loading}
              handleClickSearch={handleClickSearchGood}
            />
          </div>
        </div>
        <CategoriesComponent
          categories={dataCategories?.data || []}
          onSelect={onSelectCategory}
          active={filter.category_id}
          loading={loadingCategories}
        />
        <DropdownComponent
          data={filterProduct}
          onSelect={onSelectFilter}
          active={filter}
        />
      </div>
      <div className="good-list-page__content">
        
        {
          <div ref={scrollRef}>
          <InfinityScrollContainer
            // scrollPos={+window.localStorage.scrollY || 0}
            // reload={filter.reload}
            // setElementSize={elementSize}
            className="list-product-order"
            id={'list-panel'}
            filter={filter}
            data={{...dataProductList, loading: loadingList}}
            fetchMoreData={handleLoadMore}
            bottom={1}
            loadingElement={<>
              <GoodListLoadingComponent className={"list-product-order"} />
            </>}
          >
            {renderProduct(dataProductList)}
          </InfinityScrollContainer>
          
        </div>
        }
        </div>
      <BottomNavigation activeIndex={2} />
    </AppLayout>
  );
};

export default GoodList;

