
import DetailPage from "./pages/DetailPage";
import React from "react";
import AnnoucementPage from "./pages/AnnoucementPage";

const router =  [
    {
        name: "ANNOUCEMENT_LIST",
        auth: true,
        path: "/annoucement/list",
        element: <AnnoucementPage/>
    },
    {
        name: "ANNOUCEMENT_DETAIL",
        auth: true,
        path: "/annoucement/detail/:id",
        element: <DetailPage/>
    }
]
export default router
