
import MainMyInfoPage from "./pages/MainMyInfoPage";
import EditProfilePage from "./pages/EditProfilePage";
import React from "react";

const router =  [
    {
        name: "MY_INFO",
        auth: true,
        path: "/my-info",
        element: <MainMyInfoPage/>
    },
    {
        name: "EDIT_PROFILE",
        auth: true,
        path: "/edit-profile",
        element: <EditProfilePage />
    }
]
export default router
