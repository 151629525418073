import { minMaxValidate } from "core/utils/helper";
import { quantityMinMax } from "modules/Products/constants/QuantityMinMax";
import { useEffect, useState } from "react";

export const useUpdateQuantity = (data: any) => {
  const [quantityNumberArr, setQuantityNumberArr] = useState<string[]>(["1"]);
  const [quantity, setQuantity] = useState<any>(data);

  useEffect(() => {
    setQuantity(data)
  }, [data])
  const handleIncrease = (value: any) => {
    console.log(value, 'handleIncrease');
  };

  const handleDecrease = (value: any) => {
    console.log(value, 'handleDecrease');
  };

  const handleTyping = (value: any) => {
    console.log(value, 'handleTyping');
    setQuantity(value)
    let arrOO = [];
    if (value === "00") {
      arrOO = ["0", "0"];
    } else {
      arrOO = [value];
    }
    let arr = quantity.toString().split("");
    
    let mergedArr = [...arr, ...arrOO];
    let numberMegered = +mergedArr.join("");
    
    if (minMaxValidate(numberMegered, quantityMinMax.MIN, quantityMinMax.MAX)) {
      console.log(mergedArr, 'mergedArr');
      
      setQuantityNumberArr([...mergedArr]);
      setQuantity(+mergedArr.join(""))
      // if (onTypingModal) {
      //   onTypingModal(info, +mergedArr.join(""))
      // }
    }else{
      setQuantityNumberArr(['100']);
      setQuantity(quantityMinMax.MAX)
      // if (onTypingModal) {
      //   onTypingModal(info, max)
      // }
      console.log(numberMegered, 'numberMegered 2');
    }
  };

  const handleRemove = () => {
    let quantityNumber = +quantityNumberArr.join("");

    if (minMaxValidate(quantityNumber, quantityMinMax.MIN, quantityMinMax.MAX)) {
      console.log(quantityNumber, quantityMinMax.MIN, quantityMinMax.MAX, 'quantityNumber, min, max');
      
      let arr = quantity.toString().split("");
      let _removedArr = [...arr];
      if (_removedArr.length > 1) {
        _removedArr.splice(_removedArr.length - 1);
        setQuantityNumberArr([..._removedArr]);
        setQuantity(+_removedArr.join(""))
        // if (onTypingModal) {
        //   onTypingModal(info, +_removedArr.join(""))
        // }
      } else {
        console.log('1');
        setQuantityNumberArr(['']);
        setQuantity('')
        // if (onTypingModal) {
        //   onTypingModal(info, 1)
        // }
      }
    } else {
      console.log('2');
      console.log('1');
      setQuantityNumberArr(['']);
      setQuantity('')
      // if (onTypingModal) {
      //   onTypingModal(info, 1)
      // }
    }
  };

  const handleReset = (value: any) => {
    console.log(value, 'handleReset');
    
  };
  
  return [quantity, handleIncrease, handleDecrease, handleTyping, handleRemove, handleReset];
};