import {useEffect, useState, FunctionComponent} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'modules/Auth/assets/scss/signup.scss'
import AppLayout from "core/layouts/AppLayout";
import CCheckbox from "core/components/form-controls/CCheckbox";
import CFormControl from "core/components/form-controls/CFormControl";
import {detectPlatformIos, hideBottomButtonInputFocus, phoneNumberFormat, validateNumber, validatePassword} from "core/utils/helper";
import IconHeader from "core/components/header/IconHeader";
import VerifyInput from "core/components/form-controls/VerifyInput";
import CodeVerifyInput from "core/components/form-controls/CodeVerifyInput";
import API from "core/networks/API";
import {API_REGISTER, API_REQUEST_OTP, API_VERIFY_OTP, API_VERIFY_PHONE} from "../api";
import CDialog from "core/components/CDialog";
import ArrowRightIcon from 'core/assets/images/icon_arrow_right.svg'
import {useFormSignUp, useIdleTime, useLogin, useUpdateFormSignUp} from "../hooks";
import {RouterName} from "core/router";
import {useDispatch, useSelector} from "react-redux";

import {FETCH_DATA_SUCCESS, LOGIN} from "modules/Auth/store/types";
import {toast} from "react-toastify";
import CPassword from "../../../core/components/form-controls/CPassword";
import CPhoneInput from "../../../core/components/form-controls/CPhoneInput";
import moment from "moment";
import { updateIdleTime } from "../store/actions";
import AddressSearch from "modules/Address/components/AddressSearch";


interface IUser {
    phone: string;
    password: string;
}

interface IPageAlert {
    login: boolean;
    message: string;
    dataError:any;
}

const SignUpPage: FunctionComponent = (props: any) => {
    const redux = useSelector((state: any) => state)
    const [ handleSubmitLogin ] = useLogin({},{})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataFormRedux = useFormSignUp();

    const initStateForm = {
        loading:false,
        statusSubmit:false,
        data:{
            phone: "",
            code: "",
            password: "",
            password_confirmation: "",
            referral_code: "",
            address:'',
            detail_address:'',
            name:'',
            zipcode:'',
            lat:'',
            lng:''
        },
        terms_policy:{
            all:false,
            terms_use:false,
            policy_use:false
        },
        stateShowInputCode:false,
        stateEnableInputPassword:false,
        stateCountDownEnd:false,
        stateInTimeCountDown:false,
        stateCountDownAgain:1,
        stateDataRedux:false,
        textBtnVerifyPhone:'인증받기',
        errors:{},
        timeDefault:'03:00',
        timeCountDownSendOtp:180,
        phoneSubmit:''
    }
    const [stateSignUpForm, setStateSignUpForm] = useState<any>(typeof dataFormRedux != 'string' ? dataFormRedux :initStateForm)
    const [stateError, setStateError] = useState({state: false, text: ''})
    const [stateTermsPolicy, setStateTermsPolicy] = useState({state:false,type:''})
    const [stateDialog, setStateDialog] = useState(false)
    const [stateShowInputCode, setStateShowInputCode] = useState(false)

    const timeOffDevice = useIdleTime()


    const [dialogs, setDialogs] = useState({
        addressSearch: false
    })

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const formInput = document.getElementById('form-input');
        const formInputHeight = formInput ? formInput.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - formInputHeight
        const formTermsPolicy = document.getElementById('form-terms-policy');
        if(formTermsPolicy) formTermsPolicy.style.height = contentHeight.toString() + 'px'
    }

    useEffect(() => {
        localStorage.removeItem('time_count_down_register')
        countSize()
        hideBottomButtonInputFocus()
        dispatch(updateIdleTime(null));
        // (window as any).webViewCallGetListPath(['/signup'])
    }, [])


    useEffect(() => {
        
        if(timeOffDevice != null && stateSignUpForm?.timeCountDownSendOtp > 0 && detectPlatformIos()){
            
            let totalSeconds = stateSignUpForm?.timeCountDownSendOtp - Math.floor(timeOffDevice?.idleTime / 1000) + 2
            console.log(totalSeconds,'totalSeconds',!stateSignUpForm.stateEnableInputPassword , stateSignUpForm.stateShowInputCode)
            if(totalSeconds > 0 && !stateSignUpForm.stateEnableInputPassword && stateSignUpForm.stateShowInputCode){
                let minutes = Math.floor(totalSeconds / 60);
                let seconds = totalSeconds % 60;
                setStateSignUpForm((p: any) => ({
                    ...p,
                    timeCountDownSendOtp:totalSeconds,
                    timeDefault:'0'+minutes+':'+(seconds < 10 ? '0'+seconds : seconds),
                    stateCountDownAgain:p.stateCountDownAgain + 1
                }))
            }
            
        }
        
        // @ts-ignore
    }, [timeOffDevice])

 // @ts-ignore
    window.nativeWebviewResume = function (data:any) {
        try{
            console.log("nativeWebviewResume", data)
            dispatch(updateIdleTime(data))
        } catch (e) {
            console.log("nativeWebviewResume", e)
        }
    }

    useEffect(()=>{
        switch (redux?.Auth?.type) {
            case FETCH_DATA_SUCCESS:
            case LOGIN:
                navigate('/main');
                break;
            default: break;
        }
        // eslint-disable-next-line
    }, [redux?.Auth?.type])

    useUpdateFormSignUp(stateTermsPolicy.state ? stateSignUpForm : '')

    const checkStatusSubmit = (dataForm:any) => {
        let statusSubmit = false
        if (dataForm.data?.phone && dataForm.data?.password && (dataForm.data?.name && dataForm.data?.name.length >= 2) 
            && dataForm.data?.detail_address && dataForm.data?.address && dataForm.data?.password_confirmation && 
            dataForm?.terms_policy?.all) {
            statusSubmit = true
        }
        return statusSubmit
    }

    useEffect(() => {

        setStateSignUpForm((p: any) => ({
            ...p,
            statusSubmit: checkStatusSubmit(stateSignUpForm),
        }))

    }, [stateSignUpForm.data,stateSignUpForm.terms_policy])

    useEffect(() => {
        if(stateSignUpForm.showInputCode){
            setStateSignUpForm((p: any) => ({
                ...p,
                stateCountDownEnd: false,
                stateInTimeCountDown: true
            }))
        }
    }, [stateSignUpForm.showInputCode])


    useEffect(()=>{
        if(stateError?.state){
            toast.dismiss();
            toast(stateError?.text)
            setStateError({state: false, text: ''})
        }
        // eslint-disable-next-line
    }, [stateError?.state])


    useEffect(()=>{
        if(stateSignUpForm.stateCountDownEnd){
            setStateError({
                state: true, text: '인증번호 시간이 만료되었습니다.\n' +
                    '인증번호를 재 전송 받아주세요.\n'
            })
        }
        // eslint-disable-next-line
    }, [stateSignUpForm.stateCountDownEnd])

    const checkEnabledBtnSubmit = () => {
        let stateCheckError = {state: false, text: ''}
        var patternPassword = /^[a-zA-Z0-9]*$/;
        
        if (stateSignUpForm.data.password.length < 8 || stateSignUpForm.data.password.length > 15) {
            stateCheckError.state = true
            stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
            return stateCheckError
        }
        if (stateSignUpForm.data.password.match(patternPassword) == null) {
            stateCheckError.state = true
            stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
            return stateCheckError
        }
        if (patternPassword.test(stateSignUpForm.data.password)) {
            let patternText = /^[a-zA-Z]*$/;
            let patternNumber = /^[0-9]*$/;
            if(patternText.test(stateSignUpForm.data.password)){
                stateCheckError.state = true
                stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
                return stateCheckError
            }
            if(patternNumber.test(stateSignUpForm.data.password)){
                stateCheckError.state = true
                stateCheckError.text = '비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요.'
                return stateCheckError
            }
        }
        if (stateSignUpForm.data.password_confirmation != stateSignUpForm.data.password) {
            stateCheckError.state = true
            stateCheckError.text = '비밀번호가 일치하지 않습니다.\n' +
                '다시 확인해주세요.\n'
            return stateCheckError
        }
        if (stateSignUpForm.data.phone == stateSignUpForm.data.referral_code) {
            stateCheckError.state = true
            stateCheckError.text = '자신의 번호는 추천인 번호로 등록할 수 없습니다.\n'
            return stateCheckError
        }
        return stateCheckError
    }

    const handleInputChange = (e: any) => {

        setStateError({state: false, text: ''})
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let valueValidate = value
        switch(name) {
            case 'phone':
                valueValidate = valueValidate.replaceAll('-', '')
                valueValidate = validateNumber(valueValidate)
                break;
            case 'code':
                valueValidate = validateNumber(valueValidate)
                break;
            case 'referral_code':
                valueValidate = valueValidate.replaceAll('-', '')
                valueValidate = validateNumber(valueValidate)
                break;
            case 'name':

                break;
            case 'detail_address':
                
                break;        
            default:
                valueValidate = validatePassword(valueValidate)
                break;

        }
        if(!valueValidate){
            if(value != ''){
                return false
            }
        }
        if(name == 'phone' || name == 'referral_code'){
            valueValidate = phoneNumberFormat(valueValidate.substring(0, 11))
        }
        // console.log(stateSignUpForm,'stateSignUpFormchangeeee')
        setStateSignUpForm((p: any) => ({
            ...p,
            data:{
                ...p.data,
                [name]:valueValidate
            }
        }))
    }

    const handleToPageLogin = (e: any) => {
        navigate('/login')
    }

    const convertTimeToSecond = (time:any) => {
        let seconds = moment(time, 'HH:mm:ss:').diff(moment().startOf('day'), 'seconds');
        return seconds
    };



    const handleSendOTP = async () => {
        let dataRequestOtp = {
            action: 'SIGNUP',
            phone: stateSignUpForm.data.phone.replaceAll('-','')
        }
        try {
            const {data: response} = await API.post(`${API_REQUEST_OTP}`, dataRequestOtp)
            if(response && response?.code == 200){
                setStateShowInputCode(true)
                hideBottomButtonInputFocus()
                setStateSignUpForm((p: any) => ({
                    ...p,
                    textBtnVerifyPhone:'재 발송',
                    stateCountDownAgain:stateSignUpForm.stateCountDownAgain + 1,
                    stateShowInputCode: true,
                    stateCountDownEnd: false,
                    stateInTimeCountDown: true,
                    timeDefault:'03:00',
                    timeCountDownSendOtp:180,
                    data:{
                        ...p.data,
                        code:''
                    }
                }))


            }
        } catch (e:any) {
            console.log(e);
            let error = e?.response
            setStateDialog(true)
        }

    }

    const handleVerifyPhoneNumber = async () => {

        setStateError({
            state: false, text: ''
        })
        if(!stateSignUpForm.data?.phone){
            setStateError({
                state: true, text: '휴대전화 번호를 입력해주세요.\n'
            })
            return false
        }
        if(stateSignUpForm.data?.phone && stateSignUpForm.data?.phone.length <= 11){
            setStateError({
                state: true, text: '이미 가입된 휴대전화 번호입니다.\n'
            })
            return false
        }
        if(stateSignUpForm.data?.phone &&
            stateSignUpForm.data.phone.length >= 3 &&
            !stateSignUpForm.data.phone.substring(0,3).includes('010')){
            setStateError({
                state: true, text: '올바르지 않은 휴대전화 번호입니다.\n' +
                    '‘010’으로 시작되는 번호를 입력해주세요.\n'
            })
            return false
        }
        // if(stateSignUpForm?.stateCountDownEnd){
        //     setStateSignUpForm({
        //         ...stateSignUpForm,
        //         stateCountDownAgain: stateSignUpForm?.stateCountDownAgain + 1
        //     })
        // }
        let dataVerifyPhone = {
            field: 'phone',
            action: 'SIGNUP',
            value: stateSignUpForm.data.phone.replaceAll('-','')
        }
        if(stateSignUpForm?.stateInTimeCountDown && stateSignUpForm.data?.phone &&
            stateSignUpForm.data?.phone == stateSignUpForm?.phoneSubmit){
            setStateError({
                state: true, text: '인증번호 재 전송은 3분 후에 다시 시도해주세요.'
            })
            return false
        }
        
        try {
            const {data: response} = await API.post(`${API_VERIFY_PHONE}`, dataVerifyPhone)
            if(response && response?.code == 200){
                handleSendOTP()
                setStateSignUpForm({
                    ...stateSignUpForm,
                    phoneSubmit:stateSignUpForm.data?.phone
                })
            }
        } catch (e:any) {
            let error = e?.response
            if(error && error?.status == 422){
                setStateError({state: true, text: '이미 가입된 휴대전화 번호입니다.\n'})
                setStateSignUpForm({
                    ...stateSignUpForm,
                    stateCountDownAgain:stateSignUpForm.stateCountDownAgain+1
                })
            }
        }
    }

    const handleVerifyOTP = async (e: any) => {
        setStateError({
            state: false, text: ''
        })
        if (stateSignUpForm?.stateCountDownEnd) {
            setStateError({
                state: true, text: '인증번호 시간이 만료되었습니다.\n' +
                    '인증번호를 재 전송 받아주세요.\n'
            })
            return false
        }
        if (!stateSignUpForm.data.code) {
            setStateError({
                state: true, text: '인증번호를 입력해주세요.'
            })
            return false
        }
        let dataVerifyOtp = {
            code: stateSignUpForm.data.code,
            phone: stateSignUpForm.data.phone.replaceAll('-','')
        }
        try {
            const {data: response} = await API.post(`${API_VERIFY_OTP}`, dataVerifyOtp)
            if(response && response?.code == 200){
                setStateSignUpForm((p: any) => ({
                    ...p,
                    stateEnableInputPassword: true
                }))
                localStorage.removeItem('time_count_down_register')
            }
        } catch (e:any) {
            let error = e?.response;
            if (error && error?.status === 400) {
                console.log('errhandleVerifyOTP')
                setStateError({
                    state: true, text: '잘못된 인증번호입니다.\n' +
                        '인증번호를 다시 확인해주세요.\n'
                })
            }
        }
    }
    const handleSubmitForm = async (e: any) => {
        e.preventDefault();
        if (checkEnabledBtnSubmit().state) {
            setStateError(checkEnabledBtnSubmit())
            return false
        }
        setStateSignUpForm({
            ...stateSignUpForm,
            statusSubmit:false
        })
        let dataChangePassword = {
            phone: stateSignUpForm.data.phone.replaceAll('-',''),
            password: stateSignUpForm.data.password,
            password_confirmation: stateSignUpForm.data.password_confirmation,
            referral_code: stateSignUpForm.data.referral_code ? stateSignUpForm.data.referral_code.replaceAll('-','') : '',
            name: stateSignUpForm.data.name,
            address: stateSignUpForm.data.address,
            detail_address: stateSignUpForm.data.detail_address,
            zipcode: stateSignUpForm.data.zipcode,
            lng: stateSignUpForm.data.lng,
            lat: stateSignUpForm.data.lat,
        }
        try {
            const {data: response} = await API.post(`${API_REGISTER}`, dataChangePassword)
            if(response && response?.code == 200){
                // navigate('/login')
                handleSubmitLogin(e, dataChangePassword)
            }

        } catch (e:any) {
            setStateSignUpForm({
                ...stateSignUpForm,
                statusSubmit:true
            })
            let error = e?.response;
            console.log(error,'error')
            if (error && error?.status === 400) {
                console.log('errhandleVerifyOTP')
            }
            if (error && error?.status === 422) {
                setStateError({
                    state: true, text: '존재하지 않는 추천인 번호입니다.\n' +
                        '추천인 번호를 다시 확인해주세요.\n'
                })
            }
        }

    }
    const handleTimeEnd = (e: any) => {
        console.log(e, 'handleTimeCountDownEnd')
        if (e && e == '00:00') {
            setStateSignUpForm((p: any) => ({
                ...p,
                stateCountDownEnd: true,
                stateInTimeCountDown: false
            }))
        }
    }
    const handleAcceptToS= (e:any) => {
        let name = e.target.name;
        let checked = e.target.checked;
        if(checked){
            if(name == 'all'){
                setStateSignUpForm({
                    ...stateSignUpForm,
                    terms_policy:{
                        ...stateSignUpForm?.terms_policy,
                        all:true,
                        terms_use:true,
                        policy_use:true
                    }
                })
            }else {
                if(stateSignUpForm.terms_policy?.terms_use || stateSignUpForm.terms_policy?.policy_use){
                    setStateSignUpForm({
                        ...stateSignUpForm,
                        terms_policy:{
                            ...stateSignUpForm?.terms_policy,
                            all:true,
                            terms_use:true,
                            policy_use:true
                        }
                    })
                }else {
                    setStateSignUpForm({
                        ...stateSignUpForm,
                        terms_policy:{
                            ...stateSignUpForm?.terms_policy,
                            [name]:checked
                        }
                    })
                }
            }
        }else {
            if(name == 'all'){
                setStateSignUpForm({
                    ...stateSignUpForm,
                    terms_policy:{
                        ...stateSignUpForm?.terms_policy,
                        all:false,
                        terms_use:false,
                        policy_use:false
                    }
                })
            }else {
                setStateSignUpForm({
                    ...stateSignUpForm,
                    terms_policy:{
                        ...stateSignUpForm?.terms_policy,
                        [name]:false,
                        all:false
                    }
                })
            }
        }
    }

    const handleClickToPageTermsPolicy = (type:string) => {
        setStateTermsPolicy({...stateTermsPolicy,state:true,type: type})
        if(!stateSignUpForm?.stateEnableInputPassword){
            // return
        }
        setTimeout(()=>{
            if(type == 'policy_use'){
                navigate(RouterName.POLICY_USE+'?sign-up=true')
            }else {
                navigate(RouterName.TERMS_OF_SERVICE+'?sign-up=true')
            }
        },200)
    }

    const _handleChangeStateShow = (state:any) => {
        setStateError({state: state, text: ''})
    }


    const updateTimeCountDown = (data:any) =>{
        setStateSignUpForm((p: any) => ({
            ...p,
            timeCountDownSendOtp:data,
        }))
    }
    // console.log(stateSignUpForm,'stateSignUpForm.textBtnVerifyPhone')

    const handleSelectAddress = (data:any) => {
        console.log(48, data)
        setStateSignUpForm((p: any) => ({
            ...p,
            data:{
                ...p.data,
                address:data.address,
                zipcode:data.zonecode,
                // zipcode:data.zonecode,
                // zipcode:data.zonecode,
            }
        }))
        // setAddressFormData({...addressFormData, data: {...addressFormData.data, address: data.address}})

    }
    
    
    //handle go back from native
    (window as any).backFromDeviceAndroid = function() {
        //code webview
        if(dialogs?.addressSearch){
            setDialogs({...dialogs, addressSearch: false})
        }else {
            navigate(-1)
        }
    }

    return (
        <AppLayout className={'signup-page'}>
            <IconHeader
                id={'main-header'}
                title={'회원가입'}
                iconLeft={true}
                handleLeftClick={handleToPageLogin}
                handleRightClick={() => {
                }}
                iconRight={false}
                fixed={true}
            />
            <div className={'signup-page__main'}>
                <form className={'signup-page__main__form'} onSubmit={handleSubmitForm}>
                    <div className={'item-form form-input'} id={'form-input'}>
                        <div className={`form-verify-code ${stateSignUpForm.stateEnableInputPassword ? ' disable' : ''}`}>
                            <CFormControl label={"아이디"} desc={""} type={"empty"} required={true}
                                          className={'verify-input-comp'}>
                                <VerifyInput
                                    placeholder={"아이디(휴대전화 번호)를 입력해주세요."}
                                    button={stateSignUpForm.textBtnVerifyPhone || '인증받기'}
                                    handleChange={handleInputChange}
                                    handleSubmit={handleVerifyPhoneNumber}
                                    name={'phone'}
                                    value={stateSignUpForm.data?.phone}
                                    className={'group-input-phone'}
                                    maxLength={13}
                                    format={'phone'}
                                    buttonDisabled={stateSignUpForm.data?.phone && (stateSignUpForm.data?.phone.length == 13) ? false : true}
                                />
                                {(stateSignUpForm?.stateShowInputCode || stateShowInputCode) ? (<CodeVerifyInput
                                    placeholder={'인증번호를 입력해주세요.'}
                                    button={"확인"}
                                    countdown={stateSignUpForm?.timeCountDownSendOtp}
                                    handleChange={handleInputChange}
                                    handleSubmit={handleVerifyOTP}
                                    value={stateSignUpForm.data?.code}
                                    name={'code'}
                                    handleTimeEnd={handleTimeEnd}
                                    maxLength={4}
                                    // pattern={'/^[0-9\\b]+$/'}
                                    count={stateSignUpForm?.stateCountDownAgain}
                                    timeSetDefault={stateSignUpForm?.timeDefault}
                                    stopTimer={stateSignUpForm?.stateEnableInputPassword}
                                    updateTimeCountDown={updateTimeCountDown}
                                />) : ''}
                            </CFormControl>
                        </div>
                        <div className={'form-password'}>
                            <CPassword
                                disabled={!stateSignUpForm?.stateEnableInputPassword}
                                label={"비밀번호"}
                                desc={"비밀번호는 영문, 숫자 조합 8~15글자를 입력해주세요."}
                                placeholder={"비밀번호를 입력해주세요."}
                                name={'password'}
                                required={true}
                                handleChange={handleInputChange}
                                showPassword={false}
                                value={stateSignUpForm.data?.password}
                                maxLength={15}
                                handleShowHideStatePassword={()=>{}}
                                showIcon={false}
                            />
                            <CPassword
                                disabled={!stateSignUpForm?.stateEnableInputPassword}
                                label={"비밀번호 확인"}
                                desc={""}
                                placeholder={"비밀번호를 다시 한 번 입력해주세요."}
                                name={'password_confirmation'}
                                required={true}
                                handleChange={handleInputChange}
                                showPassword={false}
                                value={stateSignUpForm.data?.password_confirmation}
                                maxLength={15}
                                handleShowHideStatePassword={()=>{}}
                                showIcon={false}
                            />
                            <CFormControl
                                disabled={false}
                                label={"이름"}
                                desc={""}
                                type={"input"} 
                                placeholder={"이름을 입력해주세요.(10자 이내)"}
                                name={'name'}
                                required={true}
                                handleChange={handleInputChange}
                                value={stateSignUpForm.data?.name}
                                maxLength={10}
                                handleShowHideStatePassword={()=>{}}
                                showIcon={false}
                                format='only_text'
                            />
                            <div className="form-addrees ">
                                <div className="">
                                    <div className="c-form-control">
                                        <label className="c-label">배송지 (주소)<span className="required"> *</span></label>
                                        <div className="c-input-button-group group-input-address">
                                            <CFormControl
                                                disabled={true}
                                                label={""}
                                                desc={""}
                                                type={"input"} 
                                                placeholder={""}
                                                name={'zipcode'}
                                                required={true}
                                                handleChange={handleInputChange}
                                                value={stateSignUpForm.data?.zipcode}
                                                // maxLength={15}
                                                handleShowHideStatePassword={()=>{}}
                                                showIcon={false}
                                                className='form-control-address'
                                            />
                                            <button type="button" onClick={()=>{setDialogs({...dialogs, addressSearch: true})}} 
                                            className="cbtn btn-primary btn-outline-primary">우편번호 찾기</button>
                                        </div>
                                        <CFormControl
                                                disabled={true}
                                                label={""}
                                                desc={""}
                                                type={"input"} 
                                                placeholder={""}
                                                name={'address'}
                                                required={true}
                                                handleChange={handleInputChange}
                                                value={stateSignUpForm.data?.address}
                                                // maxLength={15}
                                                handleShowHideStatePassword={()=>{}}
                                                showIcon={false}
                                                className='form-control-address-detail-kakao'
                                        />
                                        <CFormControl
                                                disabled={!stateSignUpForm.data?.address ? true : false}
                                                label={""}
                                                desc={""}
                                                type={"input"} 
                                                placeholder={"상세주소를 입력해주세요."}
                                                name={'detail_address'}
                                                required={true}
                                                handleChange={handleInputChange}
                                                value={stateSignUpForm.data?.detail_address}
                                                maxLength={40}
                                                handleShowHideStatePassword={()=>{}}
                                                showIcon={false}
                                                className='form-control-address-detail'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'form-referral-number'}>
                            <CPhoneInput
                                label={"추천인 휴대폰 번호"}
                                placeholder={"- 없이 숫자만 입력해주세요."}
                                required={false} disabled={false} readonly={false}
                                name={'referral_code'} className={""}
                                value={stateSignUpForm.data?.referral_code}
                                handleChange={handleInputChange}
                                warning={""}
                            />
                        </div>
                    </div>
                    <div className={'item-form form-terms-policy'} id={'form-terms-policy'}>
                        <div className={'form-policy-and-terms'}>
                            <div className={'form-policy-and-terms__all'}>
                                <CCheckbox
                                    label={"전체 동의 하기"}
                                    checked={stateSignUpForm.terms_policy?.all}
                                    handleChange={handleAcceptToS}
                                    name={'all'}
                                />
                            </div>
                            <div className={'form-policy-and-terms__item'}>
                                <CCheckbox
                                    label={"(필수)서비스 이용 약관"}
                                    checked={stateSignUpForm.terms_policy?.terms_use}
                                    handleChange={handleAcceptToS}
                                    name={'terms_use'}
                                />
                                <div className={'form-policy-and-terms__item__icon'} onClick={()=>handleClickToPageTermsPolicy('terms_use')}>
                                    <img src={ArrowRightIcon} />
                                </div>
                            </div>
                            <div className={'form-policy-and-terms__item'}>
                                <CCheckbox
                                    name={'policy_use'}
                                    label={"(필수)개인정보 수집 및 이용동의"}
                                    checked={stateSignUpForm.terms_policy?.policy_use}
                                    handleChange={handleAcceptToS}
                                />
                                <div className={'form-policy-and-terms__item__icon'} onClick={()=>handleClickToPageTermsPolicy('policy_use')}>
                                    <img src={ArrowRightIcon} />
                                </div>
                            </div>
                        </div>
                    </div>
                   <div className="bottom-btn">
                        <div className=" d-grid item-form item-form-btn">
                            <button type="submit" disabled={!stateSignUpForm.statusSubmit} className="cbtn btn-primary">완료</button>
                        </div>
                    </div>

                </form>
            </div>

            <AddressSearch open={dialogs.addressSearch}
                           handleClose={()=>{setDialogs({...dialogs, addressSearch: false})}}
                           handleSelect={handleSelectAddress}
            />
            <CDialog
                id={"MD3"}
                show={stateDialog}
                closeBtn={"확인"}
                submitBtn={''}
                overlay={true}
                handleClose={()=>{
                    setStateDialog(false)
                }}
                className={'dialog-custom-button'}
            >
                <span>인증번호 전송에 실패했습니다.<br/>
                휴대전화 번호를 다시 확인해주세요.
                </span>
            </CDialog>

        </AppLayout>
    )
}


export default SignUpPage
