import "modules/Policy/assets/scss/policy.scss";
import AppLayout from "core/layouts/AppLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const DeliveryProductPrecautions = (props: any) => {

  const dataText = `살구나무집 도시락 배달상품 주의사항
 
  1. 고객님의 주문에 따라 개별적으로 생산 또는 조리된 배달음식은 업주에게 회복할 수 없는 손해가 발생하므로 단순 변심에 의한 청약철회는 할 수 없습니다.

  2. 고객님과 수 차례 연락을 시도한 후 연락이 되지 않는 경우 배달음식이 변질되거나 부패될 우려로 식품위생법상 위반될 여지가 있어 별도로 보관하지 않으며, 재 배달 또는 환불 처리가 어려울 수 있습니다.
  
  3. 배달상품의 주문계약은 구매조건(상품의 내용 및 종류, 가격, 결제수단, 포인트/할인 쿠폰 적용여부 등)에 동의하고 주문함으로써 성립합니다. 구매조건에 대한 동의는 고객님께서 과실없이 배달상품을 주문했다는 입증자료로 활용되며, 주문취소 또는 보상이 어려울 수 있으니, 사전에 면밀한 확인 부탁드립니다.
  
  4. 고객님은 다음 각호의 귀책사유로 조리된 음식을 수령하지 못하더라도 고객님은 대금 지급의무 또는 손해배상금을 부담합니다.
  
  1) 배달주소지를 고객님의 과실로 잘못 등록하거나 작성한 경우 단, 배달주소지 오류는 재 배달하지 않습니다.
  
  2) 고객님과 수차례 연락을 시도하였으나 연락이 되지 않는 경우 단, 회사정책에 따라 서비스 이용(ID 정지 등)에 제한이 있을 수 있습니다.
  
  5. 살구나무집 도시락 앱 내에 등록하여 고객님이 주문한 조리된 음식 등 상품 이외에 다음의 각호와 같은 회사에서 허용(등록)하지 않는 상품 또는 용역행위를 요청한 경우 이행할 수 없는 주문상품으로 판단하여 강제 주문 취소가 진행될 수 있습니다.

     1) 담배 구매요청

     2) 살구나무집 도시락 앱 내에 등록되지 않은 주류 구매 요청

     3) 음란물 상품 등의 구매 요청

     4) 기타 용역행위의 요청(세탁물 가져오기, 심부름 등)
     
  6. 살구나무집 도시락 앱 내에 등록된 메뉴 외 요청사항에 고객님께서 작성한 추가 메뉴는 배달 상품을 수령하는 시점에 추가메뉴에 대한 상품 배달을 하지 않으며, 해당 상품의 현장결제를 금지합니다.
  `
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <>
    <div>

      {/* <span dangerouslySetInnerHTML={{__html: dataText.replace(/\n/g, "<br/>")}}></span> */}

      <div>
        <p><strong>살구나무집 도시락 배달상품 주의사항</strong></p>
        <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
        <p><span style={{ fontWeight: 400 }}>1. 고객님의 주문에 따라 개별적으로 생산 또는 조리된 배달음식은 업주에게 회복할 수 없는 손해가 발생하므로 단순 변심에 의한 청약철회는 할 수 없습니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>2. 고객님과 수 차례 연락을 시도한 후 연락이 되지 않는 경우 배달음식이 변질되거나 부패될 우려로 식품위생법상 위반될 여지가 있어 별도로 보관하지 않으며, 재 배달 또는 환불 처리가 어려울 수 있습니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>3. 배달상품의 주문계약은 구매조건(상품의 내용 및 종류, 가격, 결제수단, 포인트/할인 쿠폰 적용여부 등)에 동의하고 주문함으로써 성립합니다. 구매조건에 대한 동의는 고객님께서 과실없이 배달상품을 주문했다는 입증자료로 활용되며, 주문취소 또는 보상이 어려울 수 있으니, 사전에 면밀한 확인 부탁드립니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>4. 고객님은 다음 각호의 귀책사유로 조리된 음식을 수령하지 못하더라도 고객님은 대금 지급의무 또는 손해배상금을 부담합니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>1) 배달주소지를 고객님의 과실로 잘못 등록하거나 작성한 경우 단, 배달주소지 오류는 재 배달하지 않습니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>2) 고객님과 수차례 연락을 시도하였으나 연락이 되지 않는 경우 단, 회사정책에 따라 서비스 이용(ID 정지 등)에 제한이 있을 수 있습니다.</span></p>
        <p><span style={{ fontWeight: 400 }}>5. 살구나무집 도시락 앱 내에 등록하여 고객님이 주문한 조리된 음식 등 상품 이외에 다음의 각호와 같은 회사에서 허용(등록)하지 않는 상품 또는 용역행위를 요청한 경우 이행할 수 없는 주문상품으로 판단하여 강제 주문 취소가 진행될 수 있습니다.</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>1) 담배 구매요청</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>2) 살구나무집 도시락 앱 내에 등록되지 않은 주류 구매 요청</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>3) 음란물 상품 등의 구매 요청</span></p>
        <p style={{ paddingLeft: '15px' }}><span style={{ fontWeight: 400 }}>4) 기타 용역행위의 요청(세탁물 가져오기, 심부름 등)</span></p>
        <p><span style={{ fontWeight: 400 }}>6. 살구나무집 도시락 앱 내에 등록된 메뉴 외 요청사항에 고객님께서 작성한 추가 메뉴는 배달 상품을 수령하는 시점에 추가메뉴에 대한 상품 배달을 하지 않으며, 해당 상품의 현장결제를 금지합니다.</span></p>
      </div>


    </div>
  </>
};

export default DeliveryProductPrecautions;