import API from "core/networks/API";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_PAYMENT } from "../api";
import { getLastOrder, getStores } from "../store/actions";
import orderValidationSchema from "../utils/orderValidationSchema";
import { useCarts } from "./useCarts";
import { useDataPayment } from "./usePayment";
import { getTotalQuantity } from "core/utils/helper";
import { useUser } from "modules/Auth/hooks";

export const useOrder = (form: any) => {
  const carts = useCarts({isSelected: true});
  const [, dataPayment] = useDataPayment({});
  const [isLoading, setIsLoading] = useState<any>(false);

  const orderProcess = async (data: any) => {
    try {
      const res = await API.post(API_PAYMENT.ORDER, data); //
      const dataRes = res.data;
      console.log('dataRes', dataRes);
      
      if (res.status === 200 && dataRes.code === 200) {
        setIsLoading(false);
        let sndGoodName = dataPayment.type === 'DETAIL_PAYMENT' ? dataPayment.dataProduct[0].prod_name : carts[0].prod_name;
        let quantity = dataPayment.type === 'DETAIL_PAYMENT' ? `${dataPayment.dataProduct[0].quantity}건` : `${getTotalQuantity(carts)}건`;
        if (sndGoodName.length > 30) {
          sndGoodName = `${sndGoodName.substring(0, 27 - quantity.length)}...${quantity}`;
        }
        let dataOrderSuccess = {
          ...dataRes.data,
          product_name: sndGoodName,
        }
        if (form.orderSuccess) {
          return form.orderSuccess(dataOrderSuccess)
        }
      }else{
        if (form.orderFailed) {
          // const errors = error?.response?.data;
          // return form.orderFailed(errors)
        }
      }
    }catch (error: any) {
      setIsLoading(false);
      if (form.orderFailed) {
        const errors = error?.response?.data;
        return form.orderFailed(errors)
      }
    }
  }

  const orderValidation = (data: any) => {
    orderValidationSchema
      .validate(data)
      .then(function (dataInvalid: any) {
        orderProcess(dataInvalid);
      })
      .catch(function (err: any) {
        setIsLoading(false);
        if (form.orderFailed) {
          return form.orderFailed(err)
        }
      });
  };

  const _handleOrder = (dataOrder: any) => {
    setIsLoading(true);
    let formData:any = {};
    let productPayment: {
      cart_id: any; 
      prod_id: any; 
      prod_image_path: any;
      prod_name: any;
      prod_price: any;
      quantity: any;
      cat_id: any;
    }[] = [];

    if (dataOrder.type === 'DETAIL_PAYMENT') {
      productPayment = [
        {
          cart_id: dataOrder.dataProduct[0].id,
          prod_id: dataOrder.dataProduct[0].prod_id,
          prod_image_path: dataOrder.dataProduct[0]?.featured_image || '',
          prod_name: dataOrder.dataProduct[0]?.prod_name || '',
          prod_price: dataOrder.dataProduct[0]?.prod_price || '',
          quantity: dataOrder.dataProduct[0]?.quantity,
          cat_id: dataOrder.dataProduct[0]?.cat_id,
        }
      ]
    }else{
      if (carts.length > 0) {
        carts.map((item: any) => {
          productPayment.push({
            cart_id: item.id,
            prod_id: item.prod_id,
            prod_image_path: item?.product?.featured_image || '',
            prod_name: item?.prod_name || '',
            prod_price: item?.prod_price || '',
            quantity: item.quantity,
            cat_id: item?.product?.cat_id
          });
        });
      }
    }
    console.log(dataOrder, 'dataOrder');
    
    formData = {
      order_name: dataOrder?.buyerName || "",
      shipping_time_day: dataOrder?.shippingTime?.date,
      shipping_time_hour: dataOrder?.shippingTime?.hour,
      shipping_time_minute: dataOrder?.shippingTime?.minute,
      shipping_time: `${dataOrder?.shippingTime?.date || '00-00-00'} ${dataOrder?.shippingTime?.hour || '00'}:${dataOrder?.shippingTime?.minute || '00'}:00`,
      payment_method: dataOrder?.paymentMethod?.value || '', //CARD, MONEY
      store_id:
        dataOrder?.paymentMethod?.value === "MONEY"
          ? +dataOrder?.stores[0]?.id
          : "",
      sender_account_name:
        dataOrder?.paymentMethod?.value === "MONEY"
          ? dataOrder?.buyerName
          : "",
      policy_payment: dataOrder?.policyPayment || false,
      shipping_note: dataOrder?.shippingNoteContent || "", // request shipping
      items: productPayment,
      address_id: dataOrder.shippingAddress?.id || null,
      card_number: `${dataOrder?.cardManualInfo?.card_number_1}${dataOrder?.cardManualInfo?.card_number_2}${dataOrder?.cardManualInfo?.card_number_3}${dataOrder?.cardManualInfo?.card_number_4}`,
      expiry_date: `${dataOrder?.cardManualInfo?.expire_year}${dataOrder?.cardManualInfo?.expire_month}`,
      install_month: `${dataOrder?.cardManualInfo?.installment_month?.value || ""}`, 
    };
    console.log('formData', formData);
    
    orderValidation(formData);
  }

  return [isLoading, _handleOrder]
}

export const useStores = (data: any) => {
  const [loading, setLoading] = useState<any>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStores(data));
  }, [])
  return [loading]
}

export const useLastOrder = (data: any) => {
  const [loadingStores, dataOrders] = useDataPayment({});
  console.log('dataOrders 111', dataOrders);
  
  const loading = useSelector((state: any) => state.Orders.paymentReducer?.loadingLastOrder || false);
  const user = useUser();
  const dispatch = useDispatch();
  // const getStores = (data: any) => {
  //   setLoading(true);
  //   const res = API.get(API_PRODUCT.GET_STORES, data);
  //   console.log(res, 'res');
    
  // }
  // console.log('user', user);
  useEffect(() => {
    console.log('last order');
    
    let data = {
      order_by: "created_at",
      order_dir: "desc",
      first: false,
    }
    if (dataOrders?.paymentMethod?.value === '') {
      console.log('00000000000000000000000');
      
      dispatch(getLastOrder(data));
    }
  }, [])
  
  return [loading]
}

