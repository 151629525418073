import CRadiobox from "core/components/form-controls/CRadiobox";
import {phoneNumberFormat} from "core/utils/helper";
import SkeletonComponent from "../../../core/components/Skeleton/skeleton";

interface addressProps {
    data: any;
    onClick: Function;
    onDelete: Function;
    onEdit: Function;
}

const AddressItem = ({data, onClick, onDelete, onEdit}:addressProps) => {

    const handleClick = (e: any) => {
        onClick(e)
    }


    const renderItem = (data: any) => {
        return data.map((item: any, idx: number) => {
            return <div className="address-item" key={idx}>
                        <div className="row" >
                            <div className="col-9">
                                <CRadiobox checked={item?.default === 'Y'} value={item?.id} label={<h3>{item?.shipping_address_name}{item?.default == 'Y' ? <label className="default-address">기본 배송지</label> : ''}</h3>} name={"address-item"} handleClick={() => handleClick(item)} handleChange={() => {}}/>
                            </div>
                            <div className="col-3 text-end">
                                <span onClick={() => onDelete(item)}>삭제</span> | <span onClick={() => onEdit(item)}>수정</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {item?.shipping_name } 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p>{item?.zipcode ? `(${item?.zipcode})` : ''} {item?.address} </p>
                                {item?.detail_address ? <p>{item?.detail_address}</p> : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {phoneNumberFormat(item?.shipping_phone)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {phoneNumberFormat(item?.shipping_contact)}
                            </div>
                        </div>
                    </div> 
        })
    }

    return (
        <>
            {
                data.length > 0 ? renderItem(data)
                : <div className="address-item">
                    <div className="row">
                        <div className="col-9">
                            <SkeletonComponent width={200} count={1} height={20}/>
                        </div>
                        <div className="col-3 text-end">
        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <SkeletonComponent width={300} count={1} height={20}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <SkeletonComponent width={250} count={1} height={13}/>
                            <SkeletonComponent width={250} count={1} height={13}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <SkeletonComponent width={120} count={1} height={20}/>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AddressItem;