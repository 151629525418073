import { config } from "core/config/app"

const api_version = config.apiVersion;
export const API_ORDER_HISTORY = {
  GET_LIST: `/app/${api_version}/order`,
  GET_DETAIL: `/app/${api_version}/order`,
  UPDATE_SHIPPING_ADDRESS: `/app/${api_version}/order/shipping-address`,
  CANCEL_ORDER: `/app/${api_version}/order/cancel`,
  ADD_CART_BY_ORDER: `/app/${api_version}/order/add-cart`,
}

export const API_CART = {
  CART_LIST: `/app/${api_version}/cart`,
  CART_ADD: `/app/${api_version}/cart`,
  CART_UPDATE: `/app/${api_version}/cart`,
  CART_DELETE: `/app/${api_version}/cart`,
  CART_VERIFY: `/app/${api_version}/order/verify`,
}

export const API_PAYMENT = {
  GET_BANK: `/app/${api_version}/banks`,
  ORDER: `/app/${api_version}/order`,
  PAYMENT_RESULT: `/1.0/pg/payment-result`,
  UPDATE_PAYMENT_STATUS: `/app/${api_version}/order/update-payment`,
}

