import { useEffect, useState } from "react";

import ProductImage from "core/assets/images/img_product.png";
import CCheckbox from "../form-controls/CCheckbox";
import CSpinNumber from "../CSpinNumber";
import IncreaseSmallEnabled from 'core/assets/images/icon/increase_small_enabled.svg';
import IncreaseSmallDisabled from 'core/assets/images/icon/increase_small_disabled.svg';
import DecreaseSmallEnabled from 'core/assets/images/icon/decrease_small_enabled.svg';
import DecreaseSmallDisabled from 'core/assets/images/icon/decrease_small_disabled.svg';
import FeaturedProduct from 'core/assets/images/featured_product.svg';

const ProductListItem = (props: any) => {
  const [_propsState, setProps] = useState({
    id: props.id || "",
    className: props.className || "",
    name: props.name || "",
    price: props.price || "",
    hasAction: props.hasAction || false,
    handleChange: props.handleChange || {},
    handleRemove: props.handleRemove || {},
    checked: props.checked || false,
    dataItem: props.dataItem || {},
    type: props.type || 1,
    image: props.image,
    handleClick: props.handleClick || {},
    isCheckbox: props.isCheckbox || false,
    handleOnIncrease: props.handleOnIncrease || {},
    handleOnDecrease: props.handleOnDecrease || {},
    onClickInput: props.onClickInput || {},
    index: props.key || null,
  });
  const [stateChecked, setStateChecked] = useState<boolean>(props.checked);

  useEffect(() => {
    setProps(props);
  }, [props]);

  useEffect(() => {
    setStateChecked(props.checked)
  }, [props.checked]);


  const handleClick = (e: any) => {
    props.handleClick(e);
  };

  const _handleRemoveItem = () => {
    // document
    //   .getElementsByClassName(`js_product_item_${_propsState?.dataItem?.id}`)[0]
    //   .remove();
    props.handleRemove(_propsState);
  };
  const handleChangeCheckBox = (e: any) => {
    let _propsStateMap = { ..._propsState };
    _propsStateMap.checked = !stateChecked;
    setProps(_propsStateMap);
    props.handleChange(_propsStateMap);
    setStateChecked(!stateChecked);
  };

  const onIncrease = (quantity: number) => {
    _propsState.handleOnIncrease(quantity, _propsState);
    // console.log(quantity, 'onIncrease');
    // let _propsStateMap = { ..._propsState };
    // _propsStateMap.quantity = quantity;
    // setProps(_propsStateMap);
    // props.handleChange(_propsStateMap);
    // setStateQuantity(quantity);
  };

  const onDecrease = (quantity: number) => {
    _propsState.handleOnDecrease(quantity, _propsState);
    // console.log(quantity, 'onDecrease');
    // let _propsStateMap = { ..._propsState };
    // _propsStateMap.quantity = quantity;
    // setProps(_propsStateMap);
    // props.handleChange(_propsStateMap);
    // setStateQuantity(quantity);
  };
  const onClickInput = () => {
    _propsState.onClickInput(_propsState)
  };

  return (
    <>
      {_propsState.type == 1 ? (
        <div
          className={`product-list-item type-big js_product_item_${_propsState?.dataItem?.id}`}
          onClick={handleClick}
          key={_propsState.index}
        >
          <div className="product-list-item__content">
            <div className="box-content">
              <div className="product-list-item__product">
                <div className="image-product">
                  <img alt={""} src={_propsState.image} />
                </div>
                <div className={"info-product"}>
                  <span className={"info-product__name"}>
                    {_propsState.name}
                  </span>
                  <span className={"info-product__price"}>
                    {_propsState.price}원
                  </span>
                </div>
              </div>
            </div>
            { _propsState.dataItem.featured_state === 'Y' &&
             <div className="featured-product">
                <img src={FeaturedProduct} alt="Featured Product" />
              </div>
            }
          </div>
        </div>
      ) : (
        <div
          className={`product-list-item js_product_item_${_propsState?.dataItem?.id}`}
          key={_propsState.index}
        >
          <div className="product-list-item__content">
            <div className="box-content">
              {_propsState.hasAction && _propsState.isCheckbox ? (
                <div className={"product-list-item__checkbox"}>
                  <CCheckbox
                    checked={stateChecked}
                    name={"autologin"}
                    handleChange={handleChangeCheckBox}
                  />
                </div>
              ) : (
                ""
              )}
              <div className={"product-list-item__product"}>
                <div className={"image-product"}>
                  <img alt={""} src={_propsState.image} />
                </div>
                { _propsState.dataItem.product?.featured_state === 'Y' &&
                  <div className="featured-product-cart">
                      <img src={FeaturedProduct} alt="Featured Product" />
                    </div>
                  }
                <div className={"info-product"}>
                  <span className={"info-product__name"}>
                    {_propsState.name}
                  </span>
                  <span className={"info-product__price"}>
                    {_propsState.price}원
                  </span>
                </div>
              </div>
            </div>

            {_propsState.hasAction ? (
              <>
                <CSpinNumber
                  className={""}
                  value={props?.quantity || 1}
                  min={1}
                  max={100}
                  onIncrease={onIncrease}
                  onDecrease={onDecrease}
                  onClickInput={onClickInput}
                  increaseIconEnabled={IncreaseSmallEnabled}
                  increaseIconDisabled={IncreaseSmallDisabled}
                  decreaseIconEnabled={DecreaseSmallEnabled}
                  decreaseIconDisabled={DecreaseSmallDisabled}
                />
                <div className={"product-list-item__action"}>
                  <button
                    type="button"
                    className={"product-list-item__action__delete"}
                    onClick={() => _handleRemoveItem()}
                  >
                    삭제
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductListItem;
