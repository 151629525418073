
import 'modules/Consultant/assets/scss/consutant.scss'
import AppLayout from "core/layouts/AppLayout";
import {RouterName} from "core/router";
import IconHeader from "core/components/header/IconHeader";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {phoneNumberFormat} from "core/utils/helper";
import moment from "moment";
import CEmptyIcon from "core/components/CEmptyIcon";
import EmptyIcon from "modules/Consultant/assets/icon/empty.svg";
import CListItem from "core/components/CListItem";
import SkeletonComponent from "../../../core/components/Skeleton/skeleton";
import InfinityScrollContainer from "../../../core/layouts/InfinityScrollContainer";
import API from "../../../core/networks/API";
import {API_ACCUMULATE_TRANSACTION} from "../../Accumulate/api";
import {API_LIST_CONSULT} from "../api";
import {toast} from "react-toastify";
import {getCategory, insertParam} from "../utils/helpers";


const ConsultPage = (props: any) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>({
        loading: false,
        total: 0,
        totalPage: 0,
        list: [],
        statusLoad:false,
        dataEmpty:false
    })

    const [filter, setFilter] = useState<any>({
        infinity: true,
        page: 1,
        page_size: 20,
        order_by: "id",
        order_dir: "desc",
        timestamp:moment().format('YYYY-MM-DD H:mm:ss'),
    })
    const handleGoBack = () => {
        navigate(RouterName.MY_INFO) //fixbug 42035
    }

    const handleGotoDetail = (item: any) => {
        navigate(RouterName.CONSULT_DETAIL.replace(/:id/g, item.id))
    }

    // useEffect(() => {
    //     (window as any).webViewCallGetListPath(['/consult/create', '/consult/edit/','/consult'])
    // }, [])
    
    //handle go back from native
    (window as any).backFromDeviceAndroid = function() {
        //code webview
        handleGoBack()
    }

    const fetchData = async () => {
        try{
            const res = await API.get(API_LIST_CONSULT, filter);
            console.log(res?.data?.data?.data,'666')
            if(res?.data?.data?.data) {
                setData({
                    ...data,
                    loading: false,
                    list: (filter.infinity && filter.page>1) ? [...data.list, ...res.data.data.data]: res.data.data.data,
                    total: res.data.data.total,
                    totalPage: res.data.data.last_page,
                    statusLoad: false,
                    dataEmpty:res?.data?.data?.data.length > 0 ? false : true
                })
            }
        } catch (e: any) {
            setData({
                ...data,
                statusLoad: false
            })
            console.log(e)
        }
    }

    const renderList = () => {
        if(!data.loading) {
            if (data.list.length > 0) {
                return data.list.map((item: any, k: number) => {
                    return <div className="consult-item"
                        onClick={() => handleGotoDetail(item)}
                        key={k}
                    >
                        <div className="time">{moment(item.created_at).format("YYYY-MM-DD H:mm")}</div>
                        <div className="title">
                            <span className={`status ${item.status == "PENDING" ? 'pending' : ''}`}>{item.status == "PENDING" ? '답변 대기' : '답변 완료'}</span>
                            <div className="subject">{getCategory(item.type).label}</div>
                        </div>
                        <div className="description">{item.subject}</div>
                    </div>
                })
            }

        }
        return [1,2,3].map((i, k) => <CListItem
            className={''}
            title={<SkeletonComponent count={1} height={20} width={350}/>}
            time={<SkeletonComponent count={1} height={13} width={100}/>}
            icon={false}
            handleClick={() => {}}
            key={k}
        />)
    }


    const handleLoadMore = () => {
        if(filter.page < data.totalPage && !data.loading && !data.statusLoad){
            setFilter({...filter, page: filter.page+1})
            setData({
                ...data,
                statusLoad: true
            })
        }
    }

    const countSize = () => {
        const viewportHeight = window.innerHeight;
        const headerElement = document.getElementById('main-header');
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const submaryEl = document.getElementById('summary-panel');
        const submaryHeight = submaryEl ? submaryEl.clientHeight : 0;
        const filterEl = document.getElementById('filter-panel');
        const filterHeight = filterEl ? filterEl.clientHeight : 0;
        const displayEl = document.getElementById('display-panel');
        const displayHeight = displayEl ? displayEl.clientHeight : 0;
        const contentHeight = viewportHeight - headerHeight - submaryHeight - filterHeight - displayHeight - 64
        const listEl = document.getElementById('list-panel');
        const boxEmpty = document.getElementById('box-empty');
        if(listEl) listEl.style.height = contentHeight.toString() + 'px'
        if(boxEmpty) boxEmpty.style.height = contentHeight.toString() + 'px'
    }

    useEffect(()=>{
        fetchData()
    }, [filter])

    useEffect(()=>{
        countSize()
    }, [data])

    useEffect(()=>{
        // fetchData()
        countSize()
        toast.dismiss()
        let deleteConsultant = localStorage.getItem("delete_consultant")
        let createUpdateConsultant = localStorage.getItem("create_update_consultant")
        if(deleteConsultant || createUpdateConsultant){
            let dataShowToast = {type:'',msg:''}
            if(deleteConsultant){
                dataShowToast.type = 'delete-success'
                dataShowToast.msg = '1:1 문의가 삭제되었습니다.'
            }
            if(createUpdateConsultant){
                dataShowToast.type = 'create-update-success'
                dataShowToast.msg = '문의가 접수되었습니다.'
            }
            
            if(dataShowToast.msg) {
                toast.dismiss();
                toast(dataShowToast.msg)
                setTimeout(()=>{
                    localStorage.removeItem('delete_consultant')
                    localStorage.removeItem('create_update_consultant')
                },1000)
            }
            
        }
        (window as any).webViewCallGetListPath(['/consult/create', '/consult/edit/','/consult'])
    }, [])

    return (
        <AppLayout className={"consult-page"}>
            <IconHeader
                title={'1:1 문의'}
                iconLeft={true}
                handleLeftClick={handleGoBack}
                handleRightClick={() => {
                    navigate(RouterName.MAIN)
                }}
                iconRight={true}
            />

            {!data.loading && data.list.length == 0 ? (data?.dataEmpty ? <div style={{padding: '16px', paddingTop: '190px'}} className='box-empty' id='box-empty'>
                    <CEmptyIcon icon={EmptyIcon} text={"1:1 문의 내역이 없습니다."}/>
                </div> : '') :
                <InfinityScrollContainer
                    className={`list scroll-container ${data?.statusLoad ? ' load-data' : ''}`}
                    id={'list-panel'}
                    filter={filter}
                    data={data}
                    fetchMoreData={handleLoadMore}
                    bottom={1}
                    loadingElement={<>
                    <CListItem
                        className={''}
                        title={<SkeletonComponent count={1} height={20} width={350}/>}
                        time={<SkeletonComponent count={1} height={13} width={100}/>}
                        icon={false}
                        handleClick={() => {}}
                        key={1}
                    />
                    </>}
                >
                    {renderList()}
                </InfinityScrollContainer>
            }
            <button type={'button'} className="floatCTA" onClick={()=>navigate(RouterName.CONSULT_FORM)}>
                문의하기
            </button>
        </AppLayout>
    )
}


export default ConsultPage
