import React from "react";
import ReactLoading from "react-loading";

interface LoadingProps {
  status: Boolean;
}

const Loading = ({ status }: LoadingProps) => {
  return (
    <>
      {status && (
        <div className="loading-page">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#ff773a"}
            height={"20%"}
            className="img-loading"
          />
        </div>
      )}
    </>
  );
};

export default Loading;
