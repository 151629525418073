import { RefObject, useEffect } from 'react';

const useOnClickOutside = <T extends HTMLElement>(ref: RefObject<T>, onFunc: (arg0: boolean) => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!ref?.current?.contains(event.target as Node)) {
        if(typeof onFunc == 'function') onFunc(false);
        // onFunc(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onFunc]);
};

export default useOnClickOutside;
