import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListProductsRequest, updateReloadPageListRequest } from "../store/actions";
import API from "core/networks/API";
import { API_PRODUCT } from "../api";
// import { getListOrderHistoryRequest, updateReloadPageListRequest } from "../store/actions";

export const useLists = (data: any) => {
  const dispatch = useDispatch();
  const [param] = useState<any>({
    order_by: 'featured_state',
    page_size: 10,
    infinity: true,
    state: "AVAILABLE",
    'relation[]': 'category'
  });
  const products = useSelector((state: any) => state.Products || {});
  const loadingLists = products.loadingListProduct;
  const lists = products.lists || [];
  const listParams = products.listParams;
  const errors = {};
  const params = {
    ...param,
    ...data,
  }

  console.log(data.reload, 'data.reload ');
  
  
  useEffect(() => {
    if (+data.reload === 0) {
      dispatch(getListProductsRequest(params));
    }
  }, [dispatch, getListProductsRequest, data]);


  return [loadingLists, lists, listParams, errors];
};

export const useUpdateReload = () => {
  const dispatch = useDispatch();

  const handleUpdate = (value: any) => {
    console.log('handleUpdate');
    
    dispatch(updateReloadPageListRequest(value));
  }

  return handleUpdate
}

export const useCheckIsExistProduct = (form: any) => {
  const [loading, setLoading] = useState<any>(false);
  const handleCheck = async (dataProdPayment: any) => {
    setLoading(true);
    try {
      const resp = await API.get(`${API_PRODUCT.GET_PRODUCT}/${dataProdPayment.prod_id}`, {id: dataProdPayment.prod_id});
        console.log(resp.data.data, 'resp');
        if (resp.data.code === 200 && resp.data.data.state === "AVAILABLE") {
          setLoading(false);
          if (form.onSuccess) {
            return form.onSuccess(dataProdPayment);
          }
        }else{
          setLoading(false)
          if (form.onFailed) {
            return form.onFailed();
          }
        }
    } catch (error: any) {
      console.log(error.response, 'error.response');
      
      setLoading(false)
      if (form.onFailed) {
        return form.onFailed(error?.response?.data);
      }
    }
    
  }
  return [loading, handleCheck]
}

export const useGetProductNew = (cb: any) => {

  const [loading, setLoading] = useState<any>(false);
  const handleGetProd = async (data: any) => {
      try {
        const res: any = await API.get(`${API_PRODUCT.GET_PRODUCT}/${data?.id}`, data);
        console.log(89, res);
        return cb.getProdSuccess(res?.data?.data)
      } catch (error: any) {
        
      }
  }

  return [loading, handleGetProd]
}