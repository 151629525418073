import {useEffect, useState} from "react";

import CheckedIcon from 'core/assets/images/forms/checked.svg';
import UncheckIcon from 'core/assets/images/forms/uncheck.svg';
const CRadiobox = (props: any) => {
    const [_props, setProps] = useState({
        name: props.name,
        value: props.value,
        label: props.label || '',
        checked: props.checked || false,
        disabled: props.disabled || false,
        readOnly: props.readOnly || false,
        error: props.error || ''
    })

    const handleChange = (e: any) => {
        setProps({..._props, checked: !_props.checked});
        props.handleChange(e)
    }

    const handleClick = (e: any) => {
       if ( props.handleClick) {
        props.handleClick(e)
       }
    }

    useEffect(()=>{
        setProps(props)
    }, [props])

    return <>
        <label className="c-form-control c-form-control--radio">
            <div className="radio-el">
                <input type={"radio"} checked={_props.checked} name={_props.name} readOnly={_props.readOnly} onChange={handleChange} onClick={handleClick}/>
                <span></span>
            </div>
            {_props.label} {props.children}
        </label>
        {_props.error ? <p className="c-form-error">{_props.error}</p> : <></>}
    </>
}

export default CRadiobox