import { useNavigate } from "react-router-dom";

interface DirectByUrlProps{
  origin: string, 
  path: string
}
export const useOpenBrowser = () => {
  const navigate = useNavigate();
  const origin  = window.location.origin;
  const path  = 'dashboard';
  
  const checkUrlInApp = (url: string) => {
    if (url.includes(origin) && !url.includes(`${origin}/${path}`)) {
        return true;
    }else{
        return false;
    }
  }

  const handleDirect = (url: string) => {
    if(url) {
      const isCheckUrl = checkUrlInApp(url);
      if (isCheckUrl) {
          navigate(url.replace(window.location.origin, ''));
      }else{
          (window as any).webViewOpenBrowser(url);
      }
    }
  }

  return handleDirect
}