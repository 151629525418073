import CDialog from "core/components/CDialog";
import Loading from "core/components/Loading";
import IconHeader from "core/components/header/IconHeader";
import AppLayout from "core/layouts/AppLayout";
import { RouterName } from "core/router";
import { getLocalUrl, getTotalQuantity } from "core/utils/helper";
import { useUser } from "modules/Auth/hooks";
import { useAddress } from "modules/Products/hooks/useAddress";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ORDER_HISTORY, API_PAYMENT } from "../api";
import "../assets/scss/paymentpage.scss";
import ButtonPaymentComponent from "../components/ButtonPaymentComponent";
import OrderInformationComponent from "../components/OrderInformationComponent";
import OrderListComponent from "../components/OrderListComponent";
import PaymentMethodComponent from "../components/PaymentMethodComponent";
import PolicyPaymentComponent from "../components/PolicyPaymentComponent";
import TotalPaymentAmountComponent from "../components/TotalPaymentAmountComponent";
import { useCartVerify, useCarts, useGetCarts } from "../hooks/useCarts";
import { useLastOrder, useOrder } from "../hooks/useOrder";
import { useDataPayment, useUpdateDataPayment } from "../hooks/usePayment";
import KsPayForm from "./KsPayForm";
import React from "react";
import { useFetch } from "core/hooks/useFetch";
import { DELIVERY_DATA, PAYMENT_METHOD } from "core/constants/Commons";
import { updateStatusProduct } from "../store/actions";
import { useDispatch } from "react-redux";
import { useGetProductNew } from "modules/Products/hooks/useLists";
import { useUpdateReload } from "../hooks/useLists";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useUser();
  const formRef: any = useRef();
  const [loadingLastOrder] = useLastOrder({});
  const carts = useCarts({ isSelected: true });
  const [dataCarts, handleGetCarts] = useGetCarts({});
  const [paramsAddress] = useState<any>({
    default: "Y",
    first: true,
  });
  const [loadingAddressDefault, dataAddress] = useAddress(paramsAddress);
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [modalDialog, setModalDialog] = useState<any>({
    isShow: false,
    title: "",
    content: "",
    type: "",
    btnClose: "닫기",
    btnSubmit: "주소 설정",
  });
  const [handleUpdate, handleReset] = useUpdateDataPayment({});
  const [loadingStores, dataOrders] = useDataPayment({});
  const [paramsDetail, setParamsDetail] = useState<any>({
    id: searchParams.get('order_id') || null,
    'relation[]': ['creator', 'items', 'shippingApp']
  });
  const updateReload = useUpdateReload();
  const {
    data: orderDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({ url: API_ORDER_HISTORY.GET_DETAIL, options: paramsDetail });

  useEffect(() => {
    updateReload({reload: 0})
    window.scrollTo(0, 0);
    (window as any).webViewCallGetListPath(['/payment-success?order_id', '/payment-failed?order_id', '/address'])
    if (!searchParams.get('order_id')) {
      handleUpdate({
        ...dataOrders,
        buyerName: user.userInfo?.name,
        phone: user.userInfo?.phone,
      });
    }

    console.log('order_id', searchParams.get('order_id'));
    
  }, []);

  useEffect(() => {
    if (orderDetail.data?.id) {
      
      const _shippingTime = orderDetail.data?.shipping_app?.shipping_time.split(" ");
      const _shippingHour = _shippingTime[1].split(":");
      const _paymentMethod = PAYMENT_METHOD.find((item: any) => item.value === orderDetail.data?.payment_method);
      
      const _shippingNote = DELIVERY_DATA.find((item:any) => item.label === orderDetail.data?.shipping_app?.shipping_note);
      
      console.log('orderDetail.data?.shipping_app?.shipping_time', orderDetail.data?.shipping_app?.shipping_time);
      console.log('_shippingTime', _shippingTime);
      console.log('_shippingHour', _shippingHour);
      

      let dataUpdate = {
        orderId: orderDetail.data?.id,
        buyerName: orderDetail.data?.creator?.name || "",
        phone:  orderDetail.data?.creator?.phone || "",
        shippingTime: {
          date: _shippingTime[0],
          hour: _shippingHour[0],
          minute: _shippingHour[1],
        },
        paymentMethod: _paymentMethod,
        depositorName: "",
        policyPayment: true,
        shippingNoteContent: _shippingNote && _shippingNote?.id ? _shippingNote?.label : orderDetail.data?.shipping_app?.shipping_note,
        shippingNoteId: _shippingNote && _shippingNote?.id ? _shippingNote.id : 7,
        dataProduct: [],
        shippingAddress: orderDetail.data?.shipping_app || {},
        stores: [],
        lastOrder: orderDetail.data,
        type: 'CART_PAYMENT'
      } as any;
      // handleUpdate(dataUpdate)
      if (orderDetail.data?.items.length > 1) {
        dispatch(updateStatusProduct({dataCarts:orderDetail.data?.items, isSelected: false}));
        // dispatch(updateOrCreateCart({ dataCarts: orderDetail.data?.items, isSelected: false }));
      }else{
        let _dataProduct = {
          ...orderDetail.data?.items[0],
          quantity: orderDetail.data?.items[0].qty,
          priceByQuantity: orderDetail.data?.items[0].qty * (+orderDetail.data?.items[0].prod_price),
        }
        console.log(_dataProduct,orderDetail.data?.items[0], '_dataProduct');
        dataUpdate = {...dataUpdate, type: 'DETAIL_PAYMENT', dataProduct: [{..._dataProduct}]}
      }
      console.log('handleUpdate', dataUpdate);
      
      handleUpdate(dataUpdate)
      // navigate(RouterName.PAYMENT)
    }
  }, [orderDetail.data?.id, loadingDetail])

  console.log(dataOrders, 'dataOrders 123');
  
  // (window as any).nativeCallPaymentCancel = function(){
  //   navigate(RouterName.MAIN)
  // }

  useEffect(() => {
    
    if (dataAddress?.id && !dataOrders?.shippingAddress?.id && !searchParams.get('order_id')) {
      handleUpdate({
        ...dataOrders,
        buyerName: user.userInfo?.name,
        phone: user.userInfo?.phone,
        shippingAddress: dataAddress,
      });
    }
  }, [dataAddress, loadingAddressDefault]);

  const handleChangeAddress = (value: string) => {
    navigate(value === 'update' ? RouterName.ADDRESS_BOOK : RouterName.ADDRESS_CREATE, {
      state: {
        back_type: RouterName.PAYMENT,
        back_url: RouterName.PAYMENT,
      },
    });
  };

  const callPaymentToKsNet = (data: any) => {
    if (formRef.current) {
      formRef.current.sndReply.value = data.sndReply;
      formRef.current.sndOrdernumber.value = data.sndOrdernumber;
      formRef.current.sndGoodname.value = data.sndGoodname;
      formRef.current.sndAmount.value = data.sndAmount;
      formRef.current.sndOrdername.value = data.sndOrdername;
      formRef.current.sndMobile.value = user?.userInfo.phone;
      console.log(formRef.current, "formRef.current");
      formRef.current.submit();
    }
  }

  const _orderSuccess = (data: any) => {
    setLoadingPayment(true);
    if (data.payment_method === "CARD") {
      if (data?.mock_payment === true) {
        setTimeout(() => {
          setLoadingPayment(false);
          navigate(`${RouterName.PAYMENT_SUCCESS}?order_id=${data.id}`);
        }, 1000);
      }else if(data?.mock_payment === false){
        setTimeout(() => {
          setLoadingPayment(false);
          navigate(`${RouterName.PAYMENT_FAILED}?order_id=${data.id}&reason=${data.reason}`);
        }, 1000);
      }else{
        // if (formRef.current) {
        //   formRef.current.sndReply.value = getLocalUrl(
        //     API_PAYMENT.PAYMENT_RESULT
        //   );
        //   formRef.current.sndOrdernumber.value = data.id;
        //   formRef.current.sndGoodname.value = data.product_name;
        //   formRef.current.sndAmount.value = data.amount;
        //   formRef.current.sndOrdername.value = data.order_name;
        //   formRef.current.sndMobile.value = user?.userInfo.phone;
        //   console.log(formRef.current, "formRef.current");
        //   formRef.current.submit();
        // }
        //2008000185
        (window as any).webViewSendOrderId(data.id);
        const dataForm = {
          sndReply: `${getLocalUrl(API_PAYMENT.PAYMENT_RESULT)}/${data?.hash}`,
          sndOrdernumber: data.id,
          sndGoodname: data.product_name,
          sndAmount: data.amount, 
          sndOrdername: data.order_name, 
        }
        callPaymentToKsNet(dataForm);
      }
    } else {
      setTimeout(() => {
        setLoadingPayment(false);
        navigate(`${RouterName.PAYMENT_SUCCESS}?order_id=${data.id}`);
      }, 1000);
    }
  };

  const _orderFailed = (errors: any) => {
    console.log(errors, 'errors');
    if (errors?.path === "address_id") {
      console.log(111111111111, errors?.path);
      setModalDialog({
        isShow: true,
        title: "",
        content: errors.message,
        type: "NO_ADDRESS",
        btnClose: "닫기",
        btnSubmit: "주소 설정",
      });
    } else if (errors?.code === 422) {
      console.log(4222222222222);
      
      let html = 
                `서버와 통신에 실패하였습니다.
                <br />
                1:1 문의 게시판을 이용하거나
                <br />
                전화 문의를 해주세요.
                <br />`;
      setModalDialog({
        isShow: true,
        title: "",
        content: html,
        type: "PAYMENT_ERROR",
        btnClose: "확인",
        btnSubmit: "1:1 문의",
      });
    } else if (errors?.code === 5000){
      setLoadingPayment(false);
      //cheat code failed
        // navigate(`${RouterName.PAYMENT_FAILED}?order_id=${""}&reason=${""}`);
        let html = `
              카드번호 또는 유효기간 확인 후 재입력 해주세요
            <br />`
        ;
        setModalDialog({
          isShow: true,
          title: "",
          content: html,
          type: "CARD_MANUAL_ERROR",
          btnClose: "",
          btnSubmit: "확인",
        });
    }else {
      console.log('errors.message', errors.message);
      toast(errors?.message || errors?.msg || 'errors');
      return;
    }
  };

  const [loadingOrder, handleOrder] = useOrder({
    orderSuccess: _orderSuccess,
    orderFailed: _orderFailed,
  });

  const _getProdSuccess = (data: any) => {
    console.log(data, '_getProdSuccess');
    const _data = {
      ...dataOrders.dataProduct[0],
      prod_name: `[${data?.category?.name || ''}] ${data?.name || ''}`,
      prod_image: data?.images && data?.images.length > 0 ? data?.images[0]?.path_medium : '',
      prod_price: +data?.price,
      priceByQuantity: +data?.price * dataOrders.dataProduct[0].quantity,
      cat_id: data?.cat_id,
      featured_image: data?.featured_image || '',
    }
    handleUpdate({
      ...dataOrders,
      dataProduct: [_data],
      type: "DETAIL_PAYMENT",
    });
  }
  const _getProdFailed = () => {

  }

  const [loadingGetProd, handleGetProd] = useGetProductNew({
    getProdSuccess: _getProdSuccess,
    getProdFailed: _getProdFailed,
  });

  const submitFormOrder = () => {
    if (handleOrder) {
      handleOrder({ 
        ...dataOrders, 
        dataAddress,
      });
    }
  };
  
  const handleConfirm = () => {
    switch (modalDialog.type) {
      case "NO_ADDRESS":
        navigate(RouterName.ADDRESS_BOOK, {
          state: {
            back_type: RouterName.PAYMENT,
            back_url: RouterName.PAYMENT,
          },
        });
        break;
      case "PAYMENT_ERROR":
        if (handleReset) {
          handleReset({});
        }
        navigate(RouterName.CONSULT_LIST);
        break;
      case "CARD_MANUAL_ERROR":
        handleCancelDialog()
        break;
      case "CART_VERIFY":
        console.log(modalDialog, 'modalDialog');
        switch (modalDialog?.code) {
          case 8200:
          handleGetCarts();
          navigate(RouterName.GOODS_LIST, {
            state: {
              back_type: "", 
              back_url: "",
            },
          });
          break;
        case 8201:
          if (dataOrders?.type === "DETAIL_PAYMENT") {
            console.log('DETAIL_PAYMENT');
            let _param = {
              id: dataOrders.dataProduct[0].prod_id,
              "relation[]": "category",
            }
            handleGetProd(_param)
          }else{
            handleGetCarts();
          }
          setModalDialog({
            isShow: false,
            title: "",
            content: "",
            type: "",
          });
        break;
        default:
          navigate(RouterName.GOODS_LIST, {
            state: {
              back_type: "", 
              back_url: "",
            },
          });
          break;
      }
        break;
      default:
        break;
    }
  };

  const _verifySuccess = async () => {
    console.log('_verifySuccess');
    return {
      code: 200
    }
  }
  const _verifyFailed = async (errors: any) => {
    console.log('_verifyFailed', errors);
    return {
      code: 400,
      errors,
    };
  }
  const [loadingVerify, handleCartVerify] =  useCartVerify({onSuccess: _verifySuccess, onFailed: _verifyFailed})

  const handleSubmitOrder = async (e: any) => {
    e.preventDefault();
    //check payment again
    console.log(dataOrders, 'dataOrders');
    console.log(324, carts);
    let dataCartVerify;
    if (dataOrders?.type === "DETAIL_PAYMENT") {
      dataCartVerify = [
        {
          cart_id: dataOrders.dataProduct[0]?.id,
          prod_id: dataOrders.dataProduct[0].prod_id,
          prod_image_path: dataOrders.dataProduct[0]?.featured_image || '',
          prod_name: dataOrders.dataProduct[0]?.prod_name || '',
          prod_price: dataOrders.dataProduct[0]?.prod_price || '',
          quantity: dataOrders.dataProduct[0]?.quantity,
          cat_id: dataOrders.dataProduct[0]?.cat_id,
        }
      ]
    }else{
      dataCartVerify = carts
    }
    console.log(374, dataCartVerify);

    const _cartVerify = await handleCartVerify(dataCartVerify);
    if (_cartVerify.code === 200 || dataOrders?.orderId) {
      console.log(329, '_cartVerify.code === 200');
      
      if (dataOrders?.orderId) {
        //payment again
        console.log(dataOrders, 'dataOrders');
        
        let sndGoodName = dataOrders.type === 'DETAIL_PAYMENT' ? dataOrders.dataProduct[0].prod_name : carts[0].prod_name;
        let quantity = dataOrders.type === 'DETAIL_PAYMENT' ? `${dataOrders.dataProduct[0].quantity}건` : `${getTotalQuantity(carts)}건`;
        if (sndGoodName.length > 30) {
          sndGoodName = `${sndGoodName.substring(0, 27 - quantity.length)}...${quantity}`;
        }
        const dataForm = {
          sndReply: `${getLocalUrl(API_PAYMENT.PAYMENT_RESULT)}/${dataOrders.lastOrder?.hash}`,
          sndOrdernumber: dataOrders.orderId,
          sndGoodname: sndGoodName,
          sndAmount: +dataOrders.lastOrder.amount, 
          sndOrdername: dataOrders.buyerName || user?.userInfo?.name, 
        }
        console.log(dataForm, 'dataForm');
        callPaymentToKsNet(dataForm)
        
      }else{
        submitFormOrder();
      }
    }else{
      console.log(354, _cartVerify.errors);
      setModalDialog({
        isShow: true,
        title: "",
        content: _cartVerify?.errors?.msg || 'errors',
        type: "CART_VERIFY",
        code: _cartVerify.errors?.code || 8200,
        btnClose: "",
        btnSubmit: "확인",
      })
    }
    console.log(356, 'async');
    
  }

  const handleGoback = () => {
    if (handleReset) {
      handleReset({});
    }
    if (dataOrders.type === "DETAIL_PAYMENT") {
      if (dataOrders?.dataProduct.length > 0) {
        navigate(
          RouterName.GOODS_DETAIL.replace(
            ":id",
            dataOrders.dataProduct[0]?.prod_id
          )
        );
      } else {
        navigate(RouterName.CARTS_LIST, {
          state: {
            back_type: RouterName.PAYMENT,
            back_url: RouterName.MAIN 
          }
        });
      }
    } else {
      navigate(RouterName.CARTS_LIST, {
        state: {
          back_type: RouterName.PAYMENT,
          back_url: RouterName.MAIN 
        }
      });
    }
  };

  const handleCancelDialog = () => {
    setModalDialog({
      isShow: false,
      title: "",
      content: "",
      type: "",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [loadingStores, loadingLastOrder])

  return (
    <AppLayout className={"payment-page"}>
      <IconHeader
        title={"주문결제"}
        iconLeft={true}
        handleLeftClick={() => handleGoback()}
        handleRightClick={() => {
          navigate(RouterName.MAIN);
        }}
        iconRight={true}
        id={"main-header"}
      />
      <React.StrictMode>
        <main>
          <form onSubmit={handleSubmitOrder}>
            <div
              className="payment-page__content"
              id="list-panel"
              onTouchMove={() => toast.dismiss()}
            >
              <OrderInformationComponent
                dataOrders={dataOrders}
                user={user}
                handleChangeAddress={handleChangeAddress}
              />
              <OrderListComponent dataOrders={dataOrders} carts={carts} />
              <PaymentMethodComponent 
                dataOrders={dataOrders} 
                loadingLastOrder={loadingLastOrder}
              />
              <TotalPaymentAmountComponent
                dataOrders={dataOrders}
                carts={carts}
                loadingOrder={loadingOrder}
              />
              <PolicyPaymentComponent dataOrders={dataOrders} />
              <ButtonPaymentComponent
                loadingOrder={loadingOrder || loadingPayment}
                dataOrders={dataOrders}
                carts={carts}
              />
            </div>
          </form>
          <KsPayForm formRef={formRef} dataOrders={dataOrders} />
        </main>
      </React.StrictMode>
      <CDialog
        id={"js-error-quantity"}
        className={`modal-dialog-no-address ${modalDialog.type === 'CART_VERIFY' && "msg-cart-verify"}`}
        show={modalDialog.isShow || false}
        title={modalDialog.title}
        submitBtn={modalDialog.btnSubmit}
        closeBtn={modalDialog.btnClose}
        overlay
        handleSubmit={() => handleConfirm()}
        handleClose={() => handleCancelDialog()}
      >
        <div className="content-dialog" dangerouslySetInnerHTML={{__html: modalDialog.content || ''}}></div>
      </CDialog>
      <Loading status={loadingPayment || loadingOrder || loadingStores || loadingLastOrder} />
    </AppLayout>
  );
};

export default Payment;
