export const dataHours = [
  { id: 1, label: '00 시', value: '00', disable: false },
  { id: 2, label: '01 시', value: '01', disable: false },
  { id: 3, label: '02 시', value: '02', disable: false },
  { id: 4, label: '03 시', value: '03', disable: false },
  { id: 5, label: '04 시', value: '04', disable: false },
  { id: 6, label: '05 시', value: '05', disable: false },
  { id: 7, label: '06 시', value: '06', disable: false },
  { id: 8, label: '07 시', value: '07', disable: false },
  { id: 9, label: '08 시', value: '08', disable: false },
  { id: 10, label: '09 시', value: '09', disable: false },
  { id: 11, label: '10 시', value: '10', disable: false },
  { id: 12, label: '11 시', value: '11', disable: false },
  { id: 13, label: '12 시', value: '12', disable: false },
  { id: 14, label: '13 시', value: '13', disable: false },
  { id: 15, label: '14 시', value: '14', disable: false },
  { id: 16, label: '15 시', value: '15', disable: false },
  { id: 17, label: '16 시', value: '16', disable: false },
  { id: 18, label: '17 시', value: '17', disable: false },
  { id: 19, label: '18 시', value: '18', disable: false },
  { id: 20, label: '19 시', value: '19', disable: false },
  { id: 21, label: '20 시', value: '20', disable: false },
  { id: 22, label: '21 시', value: '21', disable: false },
  { id: 23, label: '22 시', value: '22', disable: false },
  { id: 24, label: '23 시', value: '23', disable: false },
];

export const dataMinus = [
  { id: 1, label: '00 분', value: '00', disable: false },
  { id: 2, label: '10 분', value: '10', disable: false },
  { id: 3, label: '20 분', value: '20', disable: false },
  { id: 4, label: '30 분', value: '30', disable: false },
  { id: 5, label: '40 분', value: '40', disable: false },
  { id: 6, label: '50 분', value: '50', disable: false },
];

export const INSTALLMENT_MONTH = [
  { id: 1, label: '일시불', value: '00'},
  { id: 3, label: '02개월', value: '02'},
  { id: 4, label: '03개월', value: '03'},
  { id: 5, label: '04개월', value: '04'},
  { id: 6, label: '05개월', value: '05'},
  { id: 7, label: '06 개월', value: '06'},
  { id: 8, label: '07 개월', value: '07'},
  { id: 9, label: '08 개월', value: '08'},
  { id: 10, label: '09 개월', value: '09'},
  { id: 11, label: '10 개월', value: '10'},
  { id: 12, label: '11 개월', value: '11'},
  { id: 13, label: '12 개월', value: '12'},
];

export const installmentMonth = {
  '00': {
    label: '일시불',
    value: '00'
  },
  // '01': {
  //   label: '01개월',
  //   value: '01'
  // },
  '02': {
    label: '02개월', 
    value: '02'
  },
  '03': {
    label: '03개월', 
    value: '03'
  },
  '04': {
    label: '04개월', 
    value: '04'
  },
  '05': {
    label: '05개월', 
    value: '05'
  },
  '06': {
    label: '06개월', 
    value: '06'
  },
  '07': {
    label: '07개월', 
    value: '07'
  },
  '08': {
    label: '08개월', 
    value: '08'
  },
  '09': {
    label: '09개월', 
    value: '09'
  },
  '10': {
    label: '10개월', 
    value: '10'
  },
  '11': {
    label: '11개월', 
    value: '11'
  },
  '12': {
    label: '12개월', 
    value: '12'
  },
} as any;