import * as yup from "yup";
import { MESSAGE } from "core/constants/Commons";

const orderCancelValidationSchema = yup.object().shape({
  bank_account_name: yup.string().when("isCheckBank", (val, schema) => {
    if (val[0] === 'PAID') {
      return schema.required("bank_account_name");
    }
    return schema;
  }),
  bank_account_number: yup.string().when("isCheckBank", (val, schema) => {
    if (val[0] === 'PAID') {
      return schema.required("bank_account_number");
    }
    return schema;
  }),
  bank_id: yup.string().when("isCheckBank", (val, schema) => {
    if (val[0] === 'PAID') {
      return schema.required("bank_id");
    }
    // return schema;
  }),
  content: yup.string().when("id", (val, schema) => {
    if (val[0] === '3') {
      return schema.required("content");
    }
    return schema;
  }),
  id: yup.string().required("id"),
});

export default orderCancelValidationSchema;
