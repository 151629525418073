import CCheckbox from "core/components/form-controls/CCheckbox";

interface CartTotal {
  dataCarts: any;
  selectAll?: (value: any | undefined) => void;
  removeCartSelected?: () => void;
  productSelectedInCarts: any
}

const CartTotalComponent = ({ dataCarts, selectAll, removeCartSelected, productSelectedInCarts }: CartTotal) => {

  const handleSelectAll = (e: any) => {
    if (selectAll) {
      selectAll({
        id: 'all',
        checked: e.target.checked
      })
    }
  }

  const handleDelete = () => {
    if (removeCartSelected && productSelectedInCarts.length > 0) {
      removeCartSelected();
    }
  }

  return (
    <div className="cart-total" id="filter-panel">
      <div className="cart-total__group">
        <div className="gr-left">
          <div className="check-total">
            <CCheckbox 
              checked={productSelectedInCarts.length === dataCarts.length} 
              name={"check"} handleChange={(e: any) => handleSelectAll(e)} 
              label={`전체 선택 (${productSelectedInCarts.length}/${dataCarts.length})`}   
            />
          </div>
        </div>
        <div className="gr-right" onClick={() => handleDelete()}>
          <label htmlFor="" className={`${productSelectedInCarts.length > 0 ? 'enabled' : 'disabled'}`}>선택삭제</label>
        </div>
      </div>
    </div>
  );
};

export default CartTotalComponent;
