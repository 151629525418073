import {
  GET_APP_SETTING,
  GET_APP_SETTING_SUCCESS,
  GET_APP_SETTING_FAILED,
} from "./types";

import { 
	GetAppSettingAction, 
	GetAppSettingFailedAction, 
	GetAppSettingSuccessAction 
} from "../interfaces/AppSetting";

export const getAppSetting = (payload: any): GetAppSettingAction => ({
  type: GET_APP_SETTING,
  payload,
});

export const getAppSettingSuccess = (payload: any): GetAppSettingSuccessAction => ({
  type: GET_APP_SETTING_SUCCESS,
  payload,
});


export const getAppSettingFailed = (payload: any): GetAppSettingFailedAction => ({
  type: GET_APP_SETTING_FAILED,
  payload,
});



export type GetAppSettingActionTypes =
  | GetAppSettingAction
	| GetAppSettingSuccessAction
	| GetAppSettingFailedAction
